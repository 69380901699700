export const inventoryLocationCol = (isUserHasAccessToUpdateUoms?: boolean) => [
  // If user has access to update uoms we need to display button(5 columns) else - 4
  {
    id: 1,
    span: isUserHasAccessToUpdateUoms ? 4 : 6,
    title: 'UOM',
    className: '',
  },
  {
    id: 2,
    span: isUserHasAccessToUpdateUoms ? 3 : 4,
    title: 'Qty',
    className: 'text-center',
  },
  {
    id: 3,
    span: isUserHasAccessToUpdateUoms ? 4 : 5,
    title: 'CONT',
    className: 'text-center',
  },
  {
    id: 4,
    span: isUserHasAccessToUpdateUoms ? 7 : 9,
    title: 'Package ID',
    className: '',
  },
];
