import { IStoresSearchParams } from 'store/modules/stores/reducer';
import baseAxios from './axiosInstance';
import { IStore } from '../interfaces';

const mainPath = 'store';
const storeUserPath = 'storeuser';
const storesAPI = {
  async getStores(per_page = 10, page = 1, search_params?: IStoresSearchParams) {
    const params = { limit: per_page, page, ...search_params };
    const { data } = await baseAxios.get(mainPath, { params });
    return data;
  },

  async getStore(id: number) {
    return baseAxios.get(`${mainPath}/${id}`);
  },

  postStore(store: IStore) {
    if (store.id) {
      return baseAxios.put(`${mainPath}/${store.id}`, store);
    }
    return baseAxios.post(`${mainPath}`, store);
  },

  deleteStore(store: IStore) {
    return baseAxios.delete(`${mainPath}/${store.id}`);
  },

  reportStore(store_id: number, dataTime: any) {
    return baseAxios.patch(`${mainPath}/${store_id}/report`, dataTime);
  },

  postUsersForStore(id: number, userId: number) {
    return baseAxios.post(`${storeUserPath}/${id}`, { userId });
  },

  deleteUserForStore(id: number, userId: number) {
    return baseAxios.patch(`${storeUserPath}/${id}`, { userId });
  },
};

export default storesAPI;
