import React, { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import {
  ButtonWithSelect, CustomButton, CustomInput, CustomTextArea, SelectWithSearch,
} from 'components/globals';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App';
import TerminateModal from 'components/views/orders/TerminateModal';
import { outboundOrdersActionCreator } from 'store/modules/outbound/action-creators';
import { ICouriers, IOutboundOrderInfo, IStore } from 'interfaces';
import { couriersActionCreator, storesActionCreator } from 'store/action-creators';
import { OrderStatus } from 'constants/orders/status';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { ConfirmationModal } from 'components/confirmationModal';
import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import {
  allowedRolesForDispatchOutboundOrder,
  allowedRolesForTerminateOrderAfterStatusCreated,
  allowedRolesForTerminateOrderOnStatusCreated,
} from 'constants/outbound/pageAbilities';

import './outboundInfo.scss';

interface OutboundInfoBlockProps {
  selectedOrder: IOutboundOrderInfo;
}

function OutboundInfoBlock({ selectedOrder }: OutboundInfoBlockProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [storeId, setStoreId] = useState(null);
  const [courierId, setCourierId] = useState(null);
  const [courierNewName, setCourierNewName] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [isDropdownBtnActive, setIsDropdownBtnActive] = useState(false);
  const [isTerminateModalVisible, setIsTerminateModalVisible] = useState(false);
  const [isDispatchModalVisible, setIsDispatchModalVisible] = useState(false);

  const { tracking_number, consignee_id, courier_id } = selectedOrder;

  const user = useSelector((state: RootState) => state.authReducer.user);
  const stores = useSelector((state: RootState) => state.storesReducer.stores);
  const couriers = useSelector((state: RootState) => state.couriersReducer.couriers);

  const storesOptions = stores?.map((store: IStore) => ({
    value: store.company_name,
    id: store.id,
  }));

  const couriersOptions = couriers.map((courier: ICouriers) => ({
    value: courier.name,
    id: courier.id,
  }));

  const isTerminated = selectedOrder?.status === OrderStatus.Terminated;
  const isDispatched = selectedOrder?.status === OrderStatus.Dispatched;
  const isPicked = selectedOrder?.status === OrderStatus.Picked;
  const isReadonly = isTerminated || isDispatched;

  const isConditionForInternalNote = userRole[user.role_id] === UserRoles.WAREHOUSE_ADMIN || userRole[user.role_id] === UserRoles.SUPER_ADMIN;
  const isTerminateVisibleByUserRole = selectedOrder?.status === OrderStatus.Created
    ? isAllowedForUserRole(allowedRolesForTerminateOrderOnStatusCreated, userRole[user.role_id])
    : isAllowedForUserRole(allowedRolesForTerminateOrderAfterStatusCreated, userRole[user.role_id]);

  const conditionDispatch = !!(tracking_number && consignee_id?.id && courier_id?.id);

  const initialValues = useMemo(() => {
    let result = null;
    if (selectedOrder) {
      result = {
        store: selectedOrder.store,
        tracking_number: selectedOrder.tracking_number,
        reference_number: selectedOrder.reference_number,
        notes: selectedOrder.notes,
        placed_on: selectedOrder.placed_on,
        created_by: `${selectedOrder.created_by?.name}(${selectedOrder.created_by?.role})`,
        courier: selectedOrder.courier,
        internal_id: selectedOrder.internal_id,
        terminate_reason: selectedOrder.terminate_reason,
        internal_note: selectedOrder.internal_note,
      };
    }

    return result;
  }, [selectedOrder]);

  const getStoreIdByCompanyName = (company: string) => {
    const findedId = stores?.find((store: any) => store.company_name === company);
    setStoreId(findedId.id);
  };

  const getCourierIdByCourierName = (courierName: string) => {
    const findedId = couriers?.find((courier: any) => courier.name.toLowerCase().trim() === courierName.toLowerCase().trim());
    setCourierId(findedId.id);
    setCourierNewName(courierName);
  };

  const save = async (body: any) => {
    const findedCourier = couriers.find((courier) => courier.name?.toLowerCase().trim() === courierNewName?.toLowerCase().trim());
    body.store_id = storeId;
    body.courier_id = courierId;

    if (!findedCourier && !!courierNewName) {
      // @ts-ignore
      const createdCourier: ICouriers = await dispatch(couriersActionCreator.createCourier({ name: courierNewName.trim() }));
      body.courier_id = createdCourier?.id;
    } else {
      body.courier_id = courierId;
    }
    if (selectedOrder?.id) {
      await dispatch(outboundOrdersActionCreator.updateOutboundOrder(selectedOrder?.id, body));
    } else {
      await dispatch(outboundOrdersActionCreator.createOutboundOrder(body));
    }
    setSaveDisabled(true);
  };

  const handleChangeCourier = (courierName: string) => {
    const foundId = couriers?.find((courier: any) => courier.name.toLowerCase().trim() === courierName.toLowerCase().trim());
    if (foundId) {
      setCourierId(foundId.id);
    }
    // const findedCourier = couriers.find((courier) => courier.name.toLowerCase().trim() === courierName.toLowerCase().trim());
    // if (!findedCourier) {
    setCourierNewName(courierName);
    // }
  };

  useEffect(() => {
    setSaveDisabled(true);
    setStoreId(selectedOrder?.store_id?.id);
    setCourierId(selectedOrder?.courier_id?.id);
    setCourierNewName(selectedOrder?.courier);
  }, [selectedOrder]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    dispatch(couriersActionCreator.getCouriers());
  }, [saveDisabled]);

  useEffect(() => {
    (async () => {
      dispatch(storesActionCreator.fetchStores(1000));
    })();

    return () => {
      dispatch(storesActionCreator.clearStores());
    };
  }, []);

  return (
    <div className="out-order-info-container">
      <Form
        form={form}
        onFinish={save}
        onFieldsChange={() => {
          setSaveDisabled(false);
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={12}>
            <Form.Item
              name="store"
              rules={[
                {
                  required: true,
                  message: 'Store is required',
                },
              ]}
            >
              <SelectWithSearch
                required
                readonly={isReadonly}
                disabled={!!selectedOrder?.orderItems?.length}
                className={`${isReadonly ? 'readonly-field' : ''}`}
                onSelect={(e) => getStoreIdByCompanyName(e)}
                options={storesOptions}
                placeholder={t('orders.store')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="courier"
              rules={[
                {
                  required: isPicked,
                  message: 'Courier services is required',
                },
              ]}
            >
              <SelectWithSearch
                required={isPicked}
                withInput
                value={courierNewName}
                readonly={isReadonly}
                onSelect={(e) => getCourierIdByCourierName(e)}
                onChange={(e) => handleChangeCourier(e)}
                className={`${isReadonly ? 'readonly-field' : ''}`}
                options={couriersOptions}
                isOnlyplaceholder
                placeholder={t('orders.courierServices')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="reference_number">
              <CustomInput
                readonly={isReadonly}
                className={`${isReadonly ? 'readonly-field' : ''}`}
                placeholder={t('orders.referenceNumber')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tracking_number"
              normalize={(input) => input.toUpperCase()}
              rules={[
                {
                  required: selectedOrder?.status === OrderStatus.Picked,
                  message: 'Tracking number is required',
                },
              ]}
            >
              <CustomInput
                readonly={isReadonly}
                className={`${isReadonly ? 'readonly-field' : ''}`}
                required={selectedOrder?.status === OrderStatus.Picked}
                placeholder={t('orders.trackingNumber')}
              />
            </Form.Item>
          </Col>
          {isTerminated ? (
            <>
              <Col span={12}>
                <CustomInput
                  value={selectedOrder?.placed_on}
                  readonly={isReadonly}
                  className={`${isReadonly ? 'readonly-field' : ''}`}
                  placeholder={t('orders.placedOn')}
                />
              </Col>
              <Col span={12}>
                <CustomInput
                  value={selectedOrder?.terminated}
                  readonly={isReadonly}
                  className={`${isReadonly ? 'readonly-field' : ''}`}
                  placeholder={t('orders.terminated')}
                />
              </Col>
            </>
          ) : null}

          <Col span={24}>
            <Row gutter={[24, 0]}>
              <Col span={12}>
                <Form.Item name="notes">
                  <CustomTextArea
                    readonly={isReadonly}
                    className={`${isReadonly ? 'readonly-field' : ''} ${isConditionForInternalNote ? 'textarea-notes' : ''}`}
                    placeholder={t('orders.note')}
                  />
                </Form.Item>
              </Col>
              <Row gutter={[24, 0]}>
                <Col span={24}>
                  {isTerminated ? (
                    <Col span={24}>
                      <Form.Item name="terminate_reason">
                        <CustomTextArea
                          readonly
                          className={`${isTerminated ? 'readonly-field' : ''}`}
                          placeholder={t('orders.terminateReason')}
                        />
                      </Form.Item>
                    </Col>
                  ) : null}
                  {!isTerminated ? (
                    <>
                      <Col className="margin-col" span={24}>
                        <Form.Item name="placed_on">
                          <CustomInput
                            className={`small-input ${isReadonly ? 'readonly-field' : ''}`}
                            isOnlyplaceholder
                            readonly
                            placeholder={t('orders.placedOn')}
                          />
                        </Form.Item>
                      </Col>
                      {!isDispatched && selectedOrder?.id ? (
                        <Col className="margin-col" span={24}>
                          <Form.Item name="created_by">
                            <CustomInput
                              className={`small-input ${isReadonly ? 'readonly-field' : ''}`}
                              isOnlyplaceholder
                              readonly
                              placeholder={t('orders.createdBy')}
                            />
                          </Form.Item>
                        </Col>
                      ) : null}

                      {isConditionForInternalNote
                        ? (
                          <Col className="col-input" style={{ marginBottom: '24px' }}>
                            <Form.Item name="internal_note">
                              <CustomInput className={`${isReadonly ? 'readonly-field' : ''}`} readonly={isReadonly} placeholder="Internal note" />
                            </Form.Item>
                          </Col>
                        )
                        : null}
                      {isDispatched ? (
                        <Col className="margin-col" span={24}>
                          <Form.Item>
                            <CustomInput
                              value={selectedOrder?.dispatched}
                              className={`small-input ${isReadonly ? 'readonly-field' : ''}`}
                              isOnlyplaceholder
                              readonly
                              placeholder={t('orders.dispatched')}
                            />
                          </Form.Item>
                        </Col>
                      ) : null}
                    </>
                  ) : null}
                </Col>
              </Row>
            </Row>
          </Col>
          <Col className="ds-flex" span={24}>
            <Form.Item>
              <ButtonWithSelect
                className="outbound-save-btn"
                isDropdownActive={isDropdownBtnActive}
                setIsDropdownActive={setIsDropdownBtnActive}
                disabled={saveDisabled}
                isDropdownEnabled={1}
                mainButtonProps={{
                  title: t('orders.save'),
                }}
                htmlType="submit"
              >
                {selectedOrder?.id && !isTerminated && !isDispatched && isTerminateVisibleByUserRole ? (
                  <CustomButton onClick={() => setIsTerminateModalVisible(true)}>{t('orders.terminate')}</CustomButton>
                ) : null}
              </ButtonWithSelect>
            </Form.Item>
            {selectedOrder?.status === OrderStatus.Picked
            && isAllowedForUserRole(allowedRolesForDispatchOutboundOrder, userRole[user.role_id]) ? (
              <CustomButton
                disabled={!conditionDispatch}
                isImportant
                className="dispatch-button"
                onClick={() => setIsDispatchModalVisible(true)}
              >
                {t('orders.dispatch')}
              </CustomButton>
              ) : null}
          </Col>
        </Row>
      </Form>
      <TerminateModal
        actionCreator={outboundOrdersActionCreator}
        isTerminateModalVisible={isTerminateModalVisible}
        closeCallback={setIsTerminateModalVisible}
        selectedOrder={selectedOrder}
      />
      <ConfirmationModal
        isModalVisible={isDispatchModalVisible}
        confirmText={t('orders.confirmDispatch')}
        closeCallback={() => setIsDispatchModalVisible(!isDispatchModalVisible)}
        confirmAction={() => dispatch(outboundOrdersActionCreator.dispatchOrderId(selectedOrder?.id))}
      />
    </div>
  );
}

export default OutboundInfoBlock;
