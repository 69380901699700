import { placementType } from 'components/globals/customPopover/CustomPopover';
import ItemExtIdsToolTip from 'components/views/items/itemExtIdsToolTip/ItemExtIdsToolTip';
import { IItem } from 'interfaces';
import emptyImage from '../../assets/images/defaultItemIcon.png';

const renderImage = (imgPath: any) => (
  <img
    style={{
      width: '59px',
      height: '44px',
      objectFit: 'cover',
      position: 'absolute',
      borderRadius: '10px',
      top: 'calc(50% - 23px)',
      left: '30px',
    }}
    src={imgPath || emptyImage}
    alt="item_photo"
  />
);

export const renderItemExtId = (defaultExtIName: string, item: IItem, position?: placementType, className = '', getIdsForId?: boolean) => (
  <div
    className="d-flex align-center"
    style={{ width: '200px', overflow: 'hidden' }}
  >
    {defaultExtIName && <ItemExtIdsToolTip item={item} position={position} className={className} getIdsForId={getIdsForId} />}
    <div style={{
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    }}
    >
      {defaultExtIName}

    </div>
  </div>
);

export const tableColumns = [
  {
    title: '',
    dataIndex: 'download_url',
    width: '15',
    render: renderImage,
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Default External ID',
    dataIndex: 'external_id',
    render: (externalId, item) => renderItemExtId(externalId, item, 'top', '', true),
  },
  {
    title: 'Store',
    dataIndex: 'company_name',
  },
];

export const measurementSystemOptions = [
  {
    value: 1,
    content: 'Metric',
  },
  {
    value: 0,
    content: 'Imperial',
  },
];

export const ItemInventoryStatuses = {
  1: 'Inbound',
  2: 'Outbound',
  3: 'Allocated',
  4: 'Processing',
  5: 'Reserved',
  6: 'Picked',
};

export const ItemExtIds = {
  1: 'FNSKU',
  2: 'UPC',
  3: 'ASIN',
  4: 'GCIS',
  5: 'EAN',
  6: 'ASIN',
  7: 'ISBN',
  8: 'SKU',
  9: 'Listing ID',
};
