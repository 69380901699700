import { IPagination, IReduxAction } from 'interfaces';
import { IDocument } from 'interfaces/documents';
import { ItemOptionModel } from 'interfaces/outbound';
import { OutboundOrdersAction } from './types';

export interface IOutboundSearchParams{
  store_id: number | null;
  search: string;
  show_closed: boolean;
}
interface IOutboundState {
  orders: any[],
  selectedOrder: any,
  outboundItems: any[],
  orderItems: any[],
  outboundDocuments: IDocument[],
  itemOptions: ItemOptionModel[],
  pagination: IPagination,
  consignees: any[],
  uomsData: any[],
  search_params: IOutboundSearchParams,
}

const initialState: IOutboundState = {
  orders: [],
  outboundItems: [],
  outboundDocuments: [],
  orderItems: [],
  itemOptions: [],
  selectedOrder: {
    id: null,
    notes: null,
    placed_on: '',
    created_by: '',
    store_id: null,
    internal_id: '',
    courier_id: null,
    consignee_id: null,
    tracking_number: null,
    reference_number: null,
    documents: null,
  },
  consignees: [],
  pagination: { current_page: 1, per_page: 10 },
  uomsData: [],
  search_params: { store_id: null, search: '', show_closed: false },
};

export default function outboundOrdersReducer(state: IOutboundState = initialState, action: IReduxAction) {
  switch (action.type) {
    case OutboundOrdersAction.SET_OUTBOUND_ORDERS:
      return { ...state, orders: action.payload };
    case OutboundOrdersAction.SET_SELECTED_OUTBOUND_ORDER:
      return { ...state, selectedOrder: action.payload };
    case OutboundOrdersAction.SET_OUTBOUND_ITEMS:
      return { ...state, outboundItems: action.payload };
    case OutboundOrdersAction.SET_OUTBOUND_ORDER_ITEMS:
      return { ...state, orderItems: action.payload };
    case OutboundOrdersAction.SET_OUTBOUND_ITEM_OPTIONS:
      return { ...state, itemOptions: action.payload };
    case OutboundOrdersAction.SET_CONSIGNEES:
      return { ...state, consignees: action.payload };
    case OutboundOrdersAction.SET_OUTBOUND_PAGINATION:
      return { ...state, pagination: action.payload };
    case OutboundOrdersAction.SET_UOMS_DATA:
      return { ...state, uomsData: [...action.payload] };
    case OutboundOrdersAction.SET_SEARCH_PARAMS:
      return { ...state, search_params: action.payload };
    default:
      return state;
  }
}
