import { CellStyle, WorkSheet } from 'xlsx-js-style';
import { ExcelColumnName } from './paramsForCellExcel';

export enum CellFillColors {
  GRAY = 'D8D8D8',
  LIGHT_YELLOW = 'FEFCD8'
}

export enum CellBorderColor {
  BLACK = '000000',
}

// Styles for each cell
export const bold: Pick<CellStyle, 'font'> = {
  font: {
    bold: true,
  },
};

export const border: Pick<CellStyle, 'border'> = {
  border: {
    top: { style: 'thin', color: { rgb: CellBorderColor.BLACK } },
    right: { style: 'thin', color: { rgb: CellBorderColor.BLACK } },
    bottom: { style: 'thin', color: { rgb: CellBorderColor.BLACK } },
    left: { style: 'thin', color: { rgb: CellBorderColor.BLACK } },
  },
};

export const applyFillColor = (rgb: CellFillColors): Pick<CellStyle, 'fill'> => ({
  fill: { fgColor: { rgb } },
});

export const applyCellStyle = (ws: WorkSheet, column: ExcelColumnName | string, row: number | string, style: CellStyle) => {
  ws[`${column}${row}`].s = style;
};
