export const checkMinAndMax = (valueName: any, min?: any, max?: any) => ({
  getFieldValue,
}: any) => ({
  validator() {
    const value = +getFieldValue(valueName);
    if (min) {
      if (value < min) {
        return Promise.reject(new Error(`Value should be more than ${min}`));
      }
    }
    if (max) {
      if (value > max) {
        return Promise.reject(new Error(`Value should be less than ${max}`));
      }
    }
    return Promise.resolve();
  },
});
