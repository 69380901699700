import React, { useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { RootState } from 'App';
import { ILevel, ILocation } from 'interfaces';
import { locationsActionCreator } from '../../../../store/modules/locations/action-creators';
import { CustomButton, CustomCheckbox, CustomInput } from '../../../globals';
import { infoBlockModes } from '../../../../constants/infoBlockModes';

import { checkMinAndMax } from '../../../../utils/checkMinAndMaxRangeInInput';

interface LevelInfoProps {
  mode: infoBlockModes;
  setMode: (mode: infoBlockModes) => void;
  isReadonlyByUserRole: boolean;
}

function LevelInfo({ mode = infoBlockModes.VIEW, setMode, isReadonlyByUserRole }: LevelInfoProps) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [isAvailable, setIsAvailable] = useState(false);

  const { selectedRack, locations, selectedLevel } = useSelector((state: RootState) => state.locationsReducer);

  const initialValues = useMemo(() => {
    const locationsOfSelectedLevel = locations?.filter((loc: ILocation) => loc.level === selectedLevel);
    const allLocationsUnvailable = locationsOfSelectedLevel?.every((loc: ILocation) => !loc.active);

    setIsAvailable(!allLocationsUnvailable);

    return { height: selectedLevel, active: !allLocationsUnvailable };
  }, [selectedLevel, mode, locations]);

  const save = async (body: ILevel) => {
    await dispatch(locationsActionCreator.updateLevel(selectedRack.id, selectedLevel, body));

    dispatch(locationsActionCreator.fetchRacks());
    setMode(infoBlockModes.VIEW);
  };

  return (
    <Form form={form} onFinish={save} initialValues={initialValues}>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            name="height"
            rules={[
              {
                required: true,
                message: 'Height is required',
              },
              checkMinAndMax('height', 1, 500),
            ]}
          >
            <CustomInput
              readonly={isReadonlyByUserRole}
              required
              disabled={mode === infoBlockModes.VIEW}
              type="numbers"
              placeholder="Height/CM"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="active">
            <CustomCheckbox
              readonly={isReadonlyByUserRole}
              checked={!isAvailable}
              onChange={(e) => {
                setIsAvailable(!e.target.checked);
                form.setFieldsValue({ active: !e.target.checked });
              }}
              title="Not available"
              disabled={mode === infoBlockModes.VIEW}
            />
          </Form.Item>
        </Col>

        <Col offset={15} span={8}>
          {mode === infoBlockModes.EDIT && (
            <div>
              <Form.Item>
                <CustomButton htmlType="submit">Save</CustomButton>
              </Form.Item>
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
}

export default LevelInfo;
