import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App';
import {
  IExternal, IExternalIdType, IItem, IStore, IUnit,
} from 'interfaces';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { userRole } from 'constants/users/userRolesAndTypes';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import UploadDocument from 'components/globals/uploadDocument/UploadDocument';
import { isDocumentsRemoveDisabled } from 'constants/document/documentAbilities';
import { storesActionCreator, itemsActionCreator } from 'store/action-creators';
import { CustomTable, SelectWithSearch } from '../../components/globals';
import { MainWrapper, RightCard, RightWrapper } from '../../components/layout';
import { infoBlockModes } from '../../constants/infoBlockModes';
import { tableColumns } from '../../constants/items/itemsData';
import { getEditCloseIcons } from '../../components/layout/rightCard';
import {
  allowedRolesForUpdateItemsPage,
  requiredAbilitiesForExternalIdIBlock,
  requiredAbilitiesForItemInfoBlock,
  requiredAbilitiesItemUomsBlock,
} from '../../constants/items/pageAbilities';
import ItemInformationBlock from '../../components/views/items/itemInfo/ItemInformationBlock';
import ExternalIDsBlock from '../../components/views/items/externalIDs/ExternalIDsBlock';
import UnitOfMeasurement from '../../components/views/items/unitOfMeasurement/UnitOfMeasurement';

import './items.scss';

function Items() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [filteredExtIdTypes, setFilteredExtIdTypes] = useState([]);
  const [isRightBlockOpen, setIsRightBlockOpen] = useState(false);
  const [mode, setMode] = useState(infoBlockModes.VIEW);
  const [selectedStore, setSelectedStore] = useState('All stores');
  const [storesOptions, setStoreOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null);

  const {
    items, pagination, selectedItem, externalIDTypes,
  } = useSelector((state: RootState) => state.itemsReducer);

  const stores = useSelector((state: RootState) => state.storesReducer.stores);
  const user = useSelector((state: RootState) => state.authReducer.user);

  const { item_measurement_units, external_ids } = selectedItem || {};
  const selectItem = async (itemId: number) => {
    await dispatch(itemsActionCreator.fetchItem(itemId));
    setIsRightBlockOpen(true);
    setMode(infoBlockModes.VIEW);
  };

  const externalIdsParams = useSelector((state: RootState) => state.inventoryReducer.external_ids);

  const getStoreIdByValue = (storeValue: any) => {
    const currentStore = storesOptions?.find((store) => store.value === storeValue);
    return currentStore ? currentStore.id : null;
  };

  const newItem = () => {
    const newItemBody = {
      name: '',
      description: '',
      picture: '',
      metric: 1,
      store_id: 0,
      internal_id: '',
      item_measurement_units: [],
      external_ids: [],
    };

    dispatch(itemsActionCreator.setSelectedItem(newItemBody));
    setMode(infoBlockModes.CREATE);
    setIsRightBlockOpen(true);
  };

  const getIcon = () => {
    if (mode === infoBlockModes.CREATE) return '';

    return getEditCloseIcons(
      mode,
      () => setMode(infoBlockModes.EDIT),
      () => {
        setMode(infoBlockModes.VIEW);
        selectItem(selectedItem.id);
      },
    );
  };

  const updateMeasurementUnits = async (index: number, newValue) => {
    let oldValues = [...item_measurement_units];

    if (newValue.hasOwnProperty('default')) {
      oldValues = oldValues.map((extId) => ({ ...extId, default: false }));
    }

    oldValues[index] = { ...oldValues[index], ...newValue };
    await dispatch(itemsActionCreator.setSelectedItem({ ...selectedItem, item_measurement_units: oldValues }));
  };

  const handleUploadDocument = (itemId, file) => {
    dispatch(itemsActionCreator.uploadDocument(itemId, file));
  };
  const handleRemoveDocument = (itemId, fileId) => {
    dispatch(itemsActionCreator.removeDocument(itemId, fileId));
  };

  useEffect(() => {
    const mappedTypes = externalIDTypes.map((x: IExternalIdType) => ({
      content: x.name,
      value: +x.id,
    }));

    if (selectedItem) {
      const addedTypesIdsList = external_ids.map((extId: IExternal) => extId.external_id_type_id);
      setFilteredExtIdTypes(mappedTypes?.filter((item: IItem) => !addedTypesIdsList.includes(item.value)));
      if (selectedItem.id) {
        const indexOdSelectedItem = items.findIndex((item: IItem) => item.item_id === selectedItem.id);

        const itemsCopy = [...items];
        const [defaultExternalId] = (selectedItem.external_ids || []).filter((extId: IExternal) => extId.default);
        itemsCopy[indexOdSelectedItem] = {
          ...itemsCopy[indexOdSelectedItem],
          externalId: defaultExternalId?.external_id_value || '',
        };

        itemsCopy[indexOdSelectedItem].externalId = defaultExternalId?.external_id_value || '';
        dispatch(itemsActionCreator.setItems(itemsCopy));
      }
    } else setFilteredExtIdTypes(mappedTypes);
  }, [selectedItem]);

  useEffect(() => {
    if (stores && stores.length) {
      const storesOptionsArray = stores.map((store: IStore) => ({
        value: store.company_name,
        id: store.id,
      }));
      const storesOptions = [{ value: 'All stores', id: 0 }, ...storesOptionsArray];

      setStoreOptions(storesOptions);
    }
  }, [stores]);

  useEffect(() => {
    dispatch(
      itemsActionCreator.fetchItems(null, null, {
        store_id: getStoreIdByValue(selectedStore) || null,
        search: searchValue !== '' ? searchValue : null,
      }),
    );
  }, [selectedStore, searchValue]);

  useEffect(() => {
    dispatch(itemsActionCreator.fetchExternalIDTypes());
  }, []);

  const handleClickStores = () => {
    if (storesOptions.length === 0) { dispatch(storesActionCreator.fetchStores(1000, null, null)); }
  };

  return (
    <>
      <MainWrapper
        title={t('items.itemsTitle')}
        buttons={!isAllowedForUserRole(allowedRolesForUpdateItemsPage, userRole[user.role_id]) && []}
        iconAction={newItem}
        isFullScreen={!isRightBlockOpen}
      >
        <CustomTable
          className="items"
          pagination={pagination}
          columns={tableColumns}
          itemClick={(item: IItem) => selectItem(item.item_id)}
          selectedItem={selectedItem}
          setSearchValue={setSearchValue}
          onSearch={setSearchValue}
          onClickSelect={handleClickStores}
          select={(
            <SelectWithSearch
              onSelect={(store) => {
                setSelectedStore(store);
              }}
              onChange={(value) => value}
              options={storesOptions}
              value={selectedStore}
              isOnlyPlaceholder
              className="table-select"
            />
          )}
          tableData={items.map((item: IItem) => ({
            ...item,
            key: item.item_id,
            external_ids: externalIdsParams[item.item_id],
          }))}
          getNewItems={(page: number, per_page: number) => dispatch(
            itemsActionCreator.fetchItems(per_page, page, {
              store_id: getStoreIdByValue(selectedStore) || null,
              search: searchValue !== '' ? searchValue : null,
            }),
          )}
        />
      </MainWrapper>
      {isRightBlockOpen && (
        <RightWrapper
          title={selectedItem?.id ? `${selectedItem?.name}` : t('items.newItem')}
          onCancelClick={() => {
            setIsRightBlockOpen(false);
          }}
        >
          <RightCard
            className="items-info-right-block"
            title={t('items.itemInformation')}
            icon={isAllowedForUserRole(allowedRolesForUpdateItemsPage, userRole[user.role_id]) ? getIcon() : null}
          >
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForItemInfoBlock}>
              <ItemInformationBlock
                user={user}
                isReadonlyByUserRole={!isAllowedForUserRole(allowedRolesForUpdateItemsPage, userRole[user.role_id])}
                setMode={setMode}
                mode={mode}
              />
            </WithAuthWrapper>
          </RightCard>

          <RightCard className="item-external-ids-block-right-card" title={t('items.externalIDs')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForExternalIdIBlock}>
              <ExternalIDsBlock
                externalIDTypes={externalIDTypes}
                types={filteredExtIdTypes}
                externalIdsArray={external_ids}
                selectedItem={selectedItem}
                user={user}
              />
            </WithAuthWrapper>
          </RightCard>

          <RightCard className="items-uom-right-block" title={t('items.units')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesItemUomsBlock}>
              {item_measurement_units?.map((uom: IUnit, index: number) => (
                <UnitOfMeasurement
                  uom={uom}
                  itemId={selectedItem.id}
                  key={index}
                  metric={selectedItem.metric}
                  setNewValue={(newValue) => updateMeasurementUnits(index, newValue)}
                  isReadonlyByUserRole={!isAllowedForUserRole(allowedRolesForUpdateItemsPage, userRole[user.role_id])}
                />
              ))}
            </WithAuthWrapper>
          </RightCard>
          <RightCard title={t('common.documents')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesItemUomsBlock}>
              <UploadDocument
                selected={selectedItem}
                isRemoveDisabled={(doc) => isDocumentsRemoveDisabled(doc, user)}
                uploadDocument={handleUploadDocument}
                removeDocument={handleRemoveDocument}
              />
            </WithAuthWrapper>
          </RightCard>
        </RightWrapper>
      )}
    </>
  );
}

export default Items;
