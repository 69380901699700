export enum AbilityActions {
    CREATE='CREATE',
    READ='READ',
    UPDATE='UPDATE',
    DELETE='DELETE',
  }
export enum AbilitySubjects {
    USERS='USERS',
    STORES='STORES',
    ITEMS='ITEMS',
    WAREHOUSES='WAREHOUSES',
    LOCATIONS='LOCATIONS',
    INBOUND='INBOUND',
    INVENTORY='INVENTORY',
    OUTBOUND='OUTBOUND',
    REPORTS='REPORTS',
  }
