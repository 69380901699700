import React, { useEffect, useRef, useState } from 'react';
import { AutoComplete } from 'antd';
import { SelectArrowIcon } from 'assets/images';
import OutsideClick from '../../../utils/outsideClick';
import CustomPlaceholder from '../customPlaceholder/CustomPlaceholder';

import './selectWithSearchStyle.scss';

function SelectWithSearch({
  onChange,
  defaultValue,
  onSelect,
  onSearch,
  readonly = false,
  className = '',
  selectPropRef,
  disabled,
  isClose = false,
  value = '',
  options = [],
  placeholder,
  withInput = false,
  required,
  setNothingSelected,
  isOnlyPlaceholder,
  dropdownClassName = 'default-select-with-search-dropdown',
  ...rest
}: any) {
  const [isActive, setIsActive] = useState(!!value);
  const [autocompleteValue, setAutocompleteValue] = useState(value);
  const [isArrowClicked, setIsArrowClicked] = useState(false);
  const selectRef = useRef(null);
  const placeholderRef = useRef(null);
  const [isSelectChanged, setIsSelectChanged] = useState(false);

  const setFocus = () => {
    if (placeholderRef.current) placeholderRef.current.focus();
  };

  const outsideClicked = OutsideClick(selectRef, () => {
    setIsActive(false);
    setIsArrowClicked(false);
  });
  OutsideClick(selectRef, () => {
    setIsActive(false);
    setIsArrowClicked(false);
  });

  const handleDropdownVisibleChange = (isOpen: any) => {
    setIsActive(isOpen);
  };

  const setValue = (newSearchValue: any) => {
    const filteredOptions = options.filter((option) => option?.value?.toUpperCase().includes(autocompleteValue.toUpperCase()));

    if (setNothingSelected) setNothingSelected(!filteredOptions.length);

    setAutocompleteValue(newSearchValue);
  };

  useEffect(() => {
    setAutocompleteValue(value);
  }, [value]);

  useEffect(() => {
    if (isActive && outsideClicked && readonly) {
      setIsActive(false);

      if (!withInput) {
        const findedValue = options.find((element) => element.value === autocompleteValue);
        if (!findedValue) {
          setAutocompleteValue('');
        }
      } else {
        setAutocompleteValue(value);
      }
    }
    if (isArrowClicked || isActive || outsideClicked) {
      setIsArrowClicked(false);
    }
  }, [outsideClicked, isActive]);

  useEffect(() => {
    setIsActive(false);
    setIsArrowClicked(false);
  }, [placeholder]);

  useEffect(() => {
    if (selectRef && selectRef.current) {
      selectRef.current.click();
    }
  }, []);

  useEffect(() => {
    if (isClose) setIsActive(false);
  }, [isClose]);

  useEffect(() => {
    if (isArrowClicked) {
      handleDropdownVisibleChange(true);
    }
  }, [isArrowClicked]);

  return (
    <div
      ref={selectRef}
      className={`custom-select-with-search-wrapper ${
        readonly ? 'readonly-select-with-search-container' : ''
      } ${className} ${disabled ? 'custom-select-with-search-disabled' : ''}`}
    >
      <CustomPlaceholder
        readonly={readonly}
        isOnlyPlaceholder={isOnlyPlaceholder}
        isActive={isActive}
        value={value}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        setIsActive={setIsActive}
        setFocus={setFocus}
      />
      <AutoComplete
        ref={placeholderRef}
        value={autocompleteValue}
        defaultValue={defaultValue}
        onChange={(value) => {
          onChange(value);
          setIsSelectChanged(true);
        }}
        options={options}
        onSelect={(value) => {
          onSelect(value);
          setIsSelectChanged(false);
        }}
        open={isArrowClicked || isActive}
        onDropdownVisibleChange={handleDropdownVisibleChange}
        disabled={disabled || readonly}
        onSearch={setValue}
        className={readonly ? 'readonly-select' : ''}
        dropdownClassName={dropdownClassName}
        getPopupContainer={(trigger) => trigger.parentNode}
        filterOption={
          isSelectChanged
            ? (inputValue, option) => (autocompleteValue
              ? option?.value?.toString().toUpperCase().includes(autocompleteValue.toUpperCase())
              : option)
            : false
        }
        {...rest}
      />
      {!readonly ? (
        <div
          onClick={() => {
            setIsActive(false);
            setIsArrowClicked(!isArrowClicked);
          }}
          className={`select-arrow-block ${isActive || isArrowClicked ? 'active-arrow' : ''}`}
        >
          <SelectArrowIcon />
        </div>
      ) : null}
    </div>
  );
}

export default SelectWithSearch;
