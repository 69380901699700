import React from 'react';
import { Popover } from 'antd';

import './customPopover.scss';

interface CustomPopoverProps {
  children?: any;
  trigger?: triggerType;
  placement?: placementType;
  content?: any;
  className?: string;
  onMouseEnterI?: any;
}
export type placementType =
  | 'top'
  | 'topLeft'
  | 'topRight'
  | 'leftTop'
  | 'left'
  | 'leftBottom'
  | 'rightTop'
  | 'right'
  | 'rightBottom'
  | 'bottomLeft'
  | 'bottom'
  | 'bottomRight';

export type triggerType = 'hover' | 'click' | 'focus'
function CustomPopover({
  className = '',
  children,
  content = '',
  trigger = 'hover',
  placement = 'topLeft',
  onMouseEnterI = () => {},
}: CustomPopoverProps) {
  return (
    <Popover
      overlayClassName={`popover-container ${className}`}
      placement={placement}
      content={content}
      trigger={trigger}
    >
      <div onMouseEnter={onMouseEnterI}>
        {children || <div className="popover-circle-block">i</div>}
      </div>
    </Popover>
  );
}

export default CustomPopover;
