import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import { AbilityActions, AbilitySubjects } from 'constants/usersPermissions';
import { IUser } from 'interfaces';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { InboundOrderStatus } from './status';

export const requiredAbilitiesForInboundInfoBlock = [{ subject: AbilitySubjects.INBOUND, action: AbilityActions.READ }];

export const requiredAbilitiesForInboundItemsBlock = [
  { subject: AbilitySubjects.INBOUND, action: AbilityActions.READ },
];

export const requiredAbilitiesForInboundDocumentsBlock = [
  { subject: AbilitySubjects.INBOUND, action: AbilityActions.READ },
];

export const allowedRolesForCreateReceiveOnGate = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForUpdateInboundInfoInInboundOrder = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.STORE_ADMIN,
  UserRoles.STORE_INTERN,
];

export const allowedRolesForOnGateOrderInfo = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForCreateInboundOrder = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.STORE_ADMIN,
  UserRoles.STORE_INTERN,
];

export const allowedRolesForAddOnGateItems = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForAddOrUpdateOnGateUoms = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForAcknowledgeInboundOrder = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForUpdateResultUomsInInbound = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForReceiveInboundOrder = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
];

export const allowedRolesForAcceptInboundOrder = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForTerminateOrder = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.STORE_ADMIN,
];

export const allowedRolesForSeeExpectedAndResultAtArrivedStatus = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForUploadDocuments = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
  UserRoles.STORE_ADMIN,
  UserRoles.STORE_INTERN,
];

export const allowedRolesForUploadDocumentsAfterDispatchStatus = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForDispatchInboundOrder = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.STORE_ADMIN,
  UserRoles.STORE_INTERN,
];

export const allowedRolesForRemoveInboundDocuments = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const isDocumentsDisabled = (orderStatus: InboundOrderStatus, user: IUser) => isAllowedForUserRole(
  orderStatus === InboundOrderStatus.Arrived
      || orderStatus === InboundOrderStatus.PartiallyArrived
      || orderStatus === InboundOrderStatus.Closed
      || orderStatus === InboundOrderStatus.Terminated
    ? allowedRolesForUploadDocumentsAfterDispatchStatus
    : allowedRolesForUploadDocuments,
  userRole[user.role_id as unknown as number],
);
