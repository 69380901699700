/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { RootState } from 'App';
import { CustomButton } from 'components/globals';
import { AddItemModal } from 'components/views/inbound';
import { OrderStatus } from 'constants/orders/status';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { outboundOrdersActionCreator } from 'store/modules/outbound/action-creators';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { ConfirmationModal } from 'components/confirmationModal';
import { userRole } from 'constants/users/userRolesAndTypes';
import { sortBy } from 'lodash';
import {
  allowedRolesForChangeOutboundStatuses,
  allowedRolesForGoToPickOutbound,
} from 'constants/outbound/pageAbilities';
import CustomPopover from 'components/globals/customPopover/CustomPopover';
import OutboundItem from './outboundItem/OutboundItem';

import './outboundItems.scss';

function OutboundItems() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [itemToAdd, setItemToAdd] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isClaimDisabled, setIsClaimDisabled] = useState(false);
  const [isClaimDisabledByRequest, setIsClaimDisabledByRequest] = useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);
  const [selectedOrderItems, setSelectedOrderItems] = useState([]);
  const [isValidQuantityInItems, setIsValidQuantityInItems] = useState({});
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isRemoveSearchClicked, setIsRemoveSearchClicked] = useState(false);
  const [isChangeLocationsDisabled, setIsChangeLocationsDisabled] = useState(false);

  const {
    selectedOrder, orderItems, outboundItems, uomsData,
  } = useSelector(
    (state: RootState) => state.outboundOrdersReducer,
  );
  const user = useSelector((state: RootState) => state.authReducer.user);

  const eachIds = selectedOrderItems.map((item) => item.each_id).filter((each) => each);
  const cartonPackageIds = selectedOrderItems.map((item) => item.package_id).filter((item) => item);
  const isSameCartons = cartonPackageIds.length !== new Set<number>(cartonPackageIds).size;
  const isSameEaches = eachIds.length !== new Set<number>(eachIds).size;

  // Array with uoms of all order items and of all locations in order items
  let allUomsfAllOrderItems = [];

  orderItems
    .map((orderItem) => orderItem.options)
    .flat(1)
    .forEach((orderItemLocations) => allUomsfAllOrderItems.push([
      ...orderItemLocations.bins,
      ...orderItemLocations.locations,
      ...orderItemLocations.floor,
    ]));

  allUomsfAllOrderItems = allUomsfAllOrderItems
    .flat(1)
    .map((loc) => loc.uoms)
    .flat(1);

  const handleAddButtonClick = async () => {
    await dispatch(outboundOrdersActionCreator.fetchOutboundItems(selectedOrder?.store_id?.id));
    setIsModalVisible(true);
  };

  const handleGoToPick = () => {
    const data = uomsData
      .filter((value, index, self) => index === self.findIndex((t) => t.itemInvId === value.itemInvId))
      .map((uom) => ({ itemInvId: uom.itemInvId, quantity: uom.quantity }))
      .filter((uom) => uom.quantity);
    dispatch(outboundOrdersActionCreator.goToPick(selectedOrder?.id, { itemsToBePicked: data }));
  };

  const confirmClick = () => {
    setIsConfirmModalVisible(true);
  };
  const changeLocationsClick = () => {
    dispatch(outboundOrdersActionCreator.changeLocationsOrder(selectedOrder?.id));
  };

  const addOrderItem = async () => {
    await dispatch(outboundOrdersActionCreator.fetchOutboundItemOptions(itemToAdd));

    await dispatch(
      outboundOrdersActionCreator.createOutboundOrderItem({
        orderId: selectedOrder?.id,
        itemId: itemToAdd.id,
        carton_id: itemToAdd.carton_id,
        each_id: itemToAdd.each_id,
      }),
    );
    setIsModalVisible(false);
  };

  const claimClick = () => {
    dispatch(outboundOrdersActionCreator.claimOutboundItem(selectedOrder?.id));
  };
  const unclaimClick = () => {
    dispatch(outboundOrdersActionCreator.unclaimOutboundItem(selectedOrder?.id));
  };

  const renderButtons = (status: OrderStatus, isValidClaimed?: boolean | undefined) => {
    let firstButtonData = {
      isImportant: false,
      className: '',
      onClick: () => {},
      disabled: false,
      text: '',
      orderItems: null,
    };

    let secondButtonData: any = {
      className: '',
      onClick: () => {},
      disabled: false,
      text: '',
    };

    switch (status) {
      case OrderStatus.Created: {
        firstButtonData = {
          ...firstButtonData,
          className: 'outbound-claim-item-button',
          onClick: claimClick,
          disabled: isClaimDisabled || !orderItems.length || isSameCartons || isSameEaches || isClaimDisabledByRequest,
          text: t('orders.claim'),
        };

        secondButtonData = {
          ...secondButtonData,
          className: 'outbound-add-item-button',
          onClick: handleAddButtonClick,
          disabled: false,
          text: t('orders.addItem'),
        };
        break;
      }
      case OrderStatus.Claimed: {
        firstButtonData = {
          ...firstButtonData,
          className: 'unclaim-button',
          onClick: unclaimClick,
          disabled: false,
          text: t('orders.unclaim'),
        };

        isAllowedForUserRole(allowedRolesForGoToPickOutbound, userRole[user.role_id])
          ? (secondButtonData = {
            ...secondButtonData,
            className: 'outbound-go-to-pick-button',
            onClick: handleGoToPick,
            disabled: isValidClaimed,
            text: t('orders.goToPick'),
          })
          : (secondButtonData = {
            ...secondButtonData,
            disabled: true,
            text: t('orders.yourOrderIsProcessing'),
            className: 'processing-order-text',
          });

        break;
      }
      case OrderStatus.ReadyToPick: {
        firstButtonData = {
          ...firstButtonData,
          isImportant: true,
          className: 'confirm-button',
          onClick: confirmClick,
          disabled: isConfirmDisabled,
          text: t('orders.confirm'),
        };

        secondButtonData = {
          ...secondButtonData,
          className: 'change-location-button',
          onClick: changeLocationsClick,
          disabled: isChangeLocationsDisabled,
          text: t('orders.changeLocations'),
        };
        break;
      }

      case OrderStatus.Picked: {
        firstButtonData = {
          ...firstButtonData,
          className: 'ds-none',
        };

        secondButtonData = {
          ...secondButtonData,
          className: 'ds-none',
        };
        break;
      }

      default:
        break;
    }

    return isAllowedForUserRole(allowedRolesForChangeOutboundStatuses, userRole[user.role_id])
      || selectedOrder?.status === OrderStatus.Created
      || selectedOrder?.status === OrderStatus.Claimed ? (
        <Col className="ds-flex end pos-relative" span={24}>
          {isSameCartons || isSameEaches ? (
            <CustomPopover
              className="outbound-claim-popover"
              trigger="click"
              content={<p className="claim-error-text">{t('orders.claimError')}</p>}
            >
              <div className="outbound-error-message-circle">i</div>
            </CustomPopover>
          ) : null}
          <CustomButton
            disabled={firstButtonData.disabled}
            onClick={firstButtonData.onClick}
            className={firstButtonData.className}
            isImportant={firstButtonData.isImportant}
          >
            {firstButtonData.text}
          </CustomButton>

          <CustomButton
            disabled={secondButtonData.disabled}
            onClick={secondButtonData.onClick}
            className={secondButtonData.className}
          >
            {secondButtonData.text}
          </CustomButton>
        </Col>
      ) : (
        <div className="processing-out-order-text">{t('orders.yourOrderIsProcessing')}</div>
      );
  };

  const renderButtonsByOrderStatus = useMemo(() => {
    let result = null;
    if (selectedOrder) {
      const isValidClaimed = Object.values(isValidQuantityInItems).some((isValid: any) => !isValid);
      switch (selectedOrder.status) {
        case OrderStatus.Claimed: {
          result = renderButtons(OrderStatus.Claimed, isValidClaimed);
          break;
        }
        case OrderStatus.Created: {
          result = renderButtons(OrderStatus.Created);
          break;
        }
        case OrderStatus.ReadyToPick: {
          result = renderButtons(OrderStatus.ReadyToPick);
          break;
        }
        case OrderStatus.Picked: {
          result = renderButtons(OrderStatus.Picked);
          break;
        }
        default: {
          result = null;
        }
      }
      return result;
    }
  }, [
    selectedOrder,
    uomsData,
    isClaimDisabled,
    isConfirmDisabled,
    isChangeLocationsDisabled,
    isValidQuantityInItems,
    isClaimDisabledByRequest,
  ]);

  const setOrderItems = async (index, orderItemBody) => {
    const orderItemsCopy = JSON.parse(JSON.stringify(orderItems));

    orderItemsCopy[index] = { ...orderItemsCopy[index], ...orderItemBody };

    await dispatch(outboundOrdersActionCreator.setOutboundOrderItems(orderItemsCopy));
  };

  const checkIsClaimDisabled = () => {
    if (!isClaimDisabledByRequest) {
      setIsClaimDisabled(false);

      const arrayOfBiggerQuantitiesThenMax = [];
      const arraOfZeroQuantity = [];
      orderItems?.forEach((orderItem) => {
        const findedOption = orderItem.options.find(
          (option) => option?.each_id === orderItem?.each_id || option?.carton_id === orderItem?.carton_id,
        );
        if (findedOption) {
          const isBiggerThenMaxQuantity = orderItem.quantity > findedOption.maxQuantity;
          const isQuantityZero = !orderItem.quantity;

          arrayOfBiggerQuantitiesThenMax.push(isBiggerThenMaxQuantity);
          arraOfZeroQuantity.push(isQuantityZero);
        }
      });
      setIsClaimDisabled(arrayOfBiggerQuantitiesThenMax.includes(true) || arraOfZeroQuantity.includes(true));
    }
  };

  useEffect(() => {
    checkIsClaimDisabled();
  }, [selectedOrder, orderItems, isClaimDisabledByRequest]);

  useEffect(() => {
    if (selectedOrder.status === OrderStatus.Created) {
      const mappedOrderItems = orderItems?.map((item: any) => {
        let findedOption = null;
        if (item.carton_id) {
          findedOption = item.options.find((option) => option.carton_id === item.carton_id);
        } else {
          findedOption = item.options.find((option) => option.each_id === item.each_id);
        }

        item.package_id = findedOption?.package_id;
        return item;
      });

      setSelectedOrderItems(mappedOrderItems);
    }
  }, [orderItems]);

  useEffect(() => {
    setIsValidQuantityInItems({});
  }, [selectedOrder?.status]);

  useEffect(() => {
    setItemToAdd(null);
  }, [isModalVisible]);

  useEffect(() => {
    if (isRemoveSearchClicked) {
      dispatch(outboundOrdersActionCreator.fetchOutboundItems(selectedOrder?.store_id?.id, searchValue));
    }
    setIsRemoveSearchClicked(false);
  }, [isRemoveSearchClicked]);

  return (
    <Row className="outbound-items-wrapper">
      {renderButtonsByOrderStatus}
      <Row style={{ width: '100%' }}>
        {orderItems && orderItems.length ? (
          orderItems.map((orderItem: any, index) => (
            <OutboundItem
              orderItem={orderItem}
              selectedOrder={selectedOrder}
              allUomsfAllOrderItems={allUomsfAllOrderItems}
              isValidQuantityInItems={isValidQuantityInItems}
              selectedOrderItems={selectedOrderItems}
              setIsClaimDisabledByRequest={setIsClaimDisabledByRequest}
              setIsConfirmDisabled={(disabled) => setIsConfirmDisabled(disabled)}
              setIsChangeLocationsDisabled={(disabled) => setIsChangeLocationsDisabled(disabled)}
              setIsValidQuantityInItems={(newState: any) => setIsValidQuantityInItems((prevState: any) => ({ ...prevState, ...newState }))}
              setSelectedOrderItems={setSelectedOrderItems}
              setOrderItems={(orderItemBody) => {
                setOrderItems(index, orderItemBody);
              }}
            />
          ))
        ) : (
          <p className="no-items-text">No items</p>
        )}
      </Row>
      <AddItemModal
        setIsModalVisible={setIsModalVisible}
        addOrderItem={addOrderItem}
        isModalVisible={isModalVisible}
        itemToAdd={itemToAdd}
        setItemToAdd={setItemToAdd}
        items={outboundItems}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setIsRemoveSearchClicked={setIsRemoveSearchClicked}
        onSearch={() => dispatch(outboundOrdersActionCreator.fetchOutboundItems(selectedOrder?.store_id?.id, searchValue))}
      />

      <ConfirmationModal
        isModalVisible={isConfirmModalVisible}
        closeCallback={() => setIsConfirmModalVisible(!isConfirmModalVisible)}
        confirmText={t('orders.confirmationModalText')}
        confirmAction={() => dispatch(outboundOrdersActionCreator.confirmItemInvetoryId(selectedOrder?.id))}
      />
    </Row>
  );
}

export default OutboundItems;
