import { IReduxAction, IUserRole } from 'interfaces';
import { ERolesAction } from './types';

interface IRrolesState{
  roles: IUserRole[];
}

const initialState: IRrolesState = {
  roles: [],
};

export default function rolesReducer(state: IRrolesState = initialState, action: IReduxAction) {
  switch (action.type) {
    case ERolesAction.SET_ROLES:
      return { ...state, roles: action.payload };
    default:
      return state;
  }
}
