export const tableColumns = [
  {
    title: 'Order #',
    dataIndex: 'id',
  },
  {
    title: 'Store',
    dataIndex: 'store',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Placed on',
    dataIndex: 'placedOn',
  },
  {
    title: 'Created by',
    dataIndex: 'createdBy',
  },
  {
    title: 'Consignee',
    dataIndex: 'consignee',
  },
];

export const orderSourseConst = {
  1: 'Internal',
  2: 'Stroke',
  3: 'Platform',
};
