import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App';
import { IConvertedOrder, IStore } from 'interfaces';
import InboundInfoBlock from 'components/views/inbound/InboundInfoBlock/InboundInfoBlock';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { userRole } from 'constants/users/userRolesAndTypes';
import { orderType, orderTypeNumbers } from 'constants/orders/status';
import UploadDocument from 'components/globals/uploadDocument/UploadDocument';
import { isDocumentsRemoveDisabled } from 'constants/document/documentAbilities';
import {
  allowedRolesForCreateInboundOrder,
  allowedRolesForCreateReceiveOnGate,
  allowedRolesForOnGateOrderInfo,
  allowedRolesForUpdateInboundInfoInInboundOrder,
  isDocumentsDisabled,
  requiredAbilitiesForInboundDocumentsBlock,
  requiredAbilitiesForInboundInfoBlock,
  requiredAbilitiesForInboundItemsBlock,
} from '../../constants/orders/pageAbilities';
import { CustomButton, CustomTable, SelectWithSearch } from '../../components/globals';
import { MainWrapper, RightCard, RightWrapper } from '../../components/layout';
import { AddItemModal, InboundItems, UnsavedDataModal } from '../../components/views';
import { ordersActionCreator, itemsActionCreator, storesActionCreator } from '../../store/action-creators';
import { tableColumns } from '../../constants/orders/ordersData';

import './inbound.scss';

function Inbound() {
  const [isRightBlockOpen, setIsRightBlockOpen] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUnsavedDataPresent, setIsUnsavedDataPresent] = useState(false);
  const [unsavedDataModalVisible, setUnsavedDataModalVisible] = useState(false);
  const [saveAndProceedAction, setSaveAndProceedAction] = useState(() => {});
  const [cancelAction, setCancelAction] = useState(() => {});
  const [itemToAdd, setItemToAdd] = useState(null);
  const [items, setItems] = useState([]);
  const [orderInfoToSave, setOrderInfoToSave] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [isRemoveSearchClicked, setIsRemoveSearchClicked] = useState(false);
  const [selectedStore, setSelectedStore] = useState('All stores');
  const [storesOptions, setStoreOptions] = useState([]);
  const [searchOrderValue, setSearchOrderValue] = useState('');
  const [showClosedFilter, setShowClosedFilter] = useState(false);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    orders,
    pagination,
    selectedOrder,
    orderItems: selectedOrderItems,
  } = useSelector((state: RootState) => state.ordersReducer);

  const user = useSelector((state: RootState) => state.authReducer.user);
  const stores = useSelector((state: RootState) => state.storesReducer.stores);

  const itemsOfStore = useSelector((state: RootState) => state.itemsReducer.items);
  const orderItemsIds = selectedOrderItems.map((orderItem) => orderItem.item.id);

  const isOnGate = selectedOrder?.type === orderType.ON_GATE || selectedOrder?.type === orderTypeNumbers.ON_GATE;

  const itemClick = async (order: IConvertedOrder) => {
    // order number it is order id (antd table transform it into header prop)
    await dispatch(ordersActionCreator.fetchOrder(order.number));
    setIsRightBlockOpen(true);
  };

  const getStoreIdByValue = (storeValue: any) => {
    const currentStore = storesOptions?.find((store) => store.value === storeValue);
    return currentStore ? currentStore.id : null;
  };

  // do not display already added items
  const filteredItemsOfStore = itemsOfStore
    .filter((item) => !orderItemsIds.includes(item.id))
    .map((item) => {
      const defaultExtId = item?.external_ids?.find((extID) => extID?.default)?.external_id_value || '';
      return { ...item, defaultExtId };
    });

  const setNewValueOrderItem = async (index: number, newValue) => {
    const newOrderItems = [...selectedOrderItems];

    const orderItemOldValue = newOrderItems[index];
    newOrderItems[index] = { ...orderItemOldValue, ...newValue };
    await dispatch(ordersActionCreator.setOrderItems(newOrderItems));
  };

  const renderRightWrapperTitle = () => {
    if (selectedOrder?.id) {
      return (
        <div>
          {t('orders.order')}
          {' #'}
          {selectedOrder?.id}
          <span className="right-wrapper-order-status">
            {' '}
            -
            {' '}
            {selectedOrder?.status}
          </span>
        </div>
      );
    }
    return t('orders.newOrder');
  };

  const addOrderItem = async () => {
    const newOrderItems = [...selectedOrderItems];

    const findedExtId = itemToAdd?.external_ids?.find((extId) => extId?.default);
    newOrderItems.push({
      item: {
        name: itemToAdd.name,
        idToDisplay: findedExtId?.external_id_value || itemToAdd.id,
        id: itemToAdd.id,
        order_id: selectedOrder.id,
        external_ids: itemToAdd.external_ids,
      },
      measurement_units: [],
    });
    await dispatch(ordersActionCreator.setOrderItems(newOrderItems));

    setIsModalVisible(false);
  };

  const generateOrderOnGateBody = () => {
    dispatch(ordersActionCreator.generateBodyOfNewOrderGate());
    setIsRightBlockOpen(true);
  };

  const generateInboundBody = () => {
    dispatch(ordersActionCreator.generateBodyOfNewInbound());
    setIsRightBlockOpen(true);
  };

  const saveMainOrderInfo = async () => {
    let response;

    if (selectedOrder.id) {
      response = await dispatch(ordersActionCreator.updateOrder(selectedOrder.id, orderInfoToSave));
    } else {
      response = await dispatch(ordersActionCreator.createOrder(orderInfoToSave));
    }
    setIsUnsavedDataPresent(false);
    return response;
  };

  const openModal = async () => {
    await dispatch(itemsActionCreator.fetchItems(1000, 1, { store_id: selectedOrder?.store_id, search: '' }));
    setIsModalVisible(true);
  };

  const removeItem = (itemId: number) => {
    dispatch(ordersActionCreator.removeOrderItem(itemId));
  };

  const checkChangesAndRunOnSave = (functionToRun) => {
    if (isUnsavedDataPresent) {
      setSaveAndProceedAction(() => async () => {
        setIsRightBlockOpen(false);
        await saveMainOrderInfo();
        await functionToRun();
        setIsUnsavedDataPresent(false);
        setUnsavedDataModalVisible(false);
      });
      setUnsavedDataModalVisible(true);
    } else {
      functionToRun();
    }
    setCancelAction(() => async () => {
      functionToRun();
      setIsUnsavedDataPresent(false);
    });
  };

  const handleUploadDocument = (orderId, file) => {
    dispatch(ordersActionCreator.uploadDocument(orderId, file));
  };
  const handleRemoveDocument = (orderId, fileId) => {
    dispatch(ordersActionCreator.removeDocument(orderId, fileId));
  };

  useEffect(() => {
    setItems(filteredItemsOfStore);
  }, [itemsOfStore]);

  useEffect(() => {
    setItemToAdd(null);
  }, [isModalVisible]);

  useEffect(() => {
    if (stores && stores.length) {
      const storesOptionsArray = stores.map((store: IStore) => ({
        value: store.company_name,
        id: store.id,
      }));
      const storesOptions = [{ value: 'All stores', id: 0 }, ...storesOptionsArray];

      setStoreOptions(storesOptions);
    }
  }, [stores]);

  useEffect(() => {
    if (isRemoveSearchClicked) {
      dispatch(itemsActionCreator.fetchItems(1000, 1, { store_id: selectedOrder.store_id, search: searchValue }));
    }
    setIsRemoveSearchClicked(false);
  }, [isRemoveSearchClicked]);

  useEffect(() => {
    dispatch(
      ordersActionCreator.fetchOrders(null, null, true, {
        store_id: getStoreIdByValue(selectedStore) || null,
        search: searchOrderValue !== '' ? searchOrderValue : null,
        show_closed: showClosedFilter,
      }),
    );
  }, [selectedStore, searchOrderValue, showClosedFilter]);

  useEffect(() => {
    dispatch(ordersActionCreator.fetchOrders());
    dispatch(ordersActionCreator.fetchConditions());
    dispatch(storesActionCreator.fetchStores(1000));

    // return () => {
    //   dispatch(ordersActionCreator.clearOrdersState());
    //   dispatch(itemsActionCreator.clearItems());
    //   dispatch(storesActionCreator.clearStores());
    // };
  }, []);

  return (
    <>
      <MainWrapper
        title={t('orders.inboundOrders')}
        isFullScreen={!isRightBlockOpen}
        buttons={(
          <div className="header-buttons">
            {isAllowedForUserRole(allowedRolesForCreateReceiveOnGate, userRole[user.role_id]) && (
              <CustomButton
                className="receive-on-gate-btn"
                onClick={() => checkChangesAndRunOnSave(() => generateOrderOnGateBody())}
              >
                Receive on gate
              </CustomButton>
            )}
            {isAllowedForUserRole(allowedRolesForCreateInboundOrder, userRole[user.role_id]) && (
              <CustomButton
                className="new-inbound-btn"
                onClick={() => checkChangesAndRunOnSave(() => generateInboundBody())}
              >
                New inbound order
              </CustomButton>
            )}
          </div>
        )}
      >
        <CustomTable
          className="orders"
          pagination={pagination}
          columns={tableColumns}
          itemClick={(order: IConvertedOrder) => checkChangesAndRunOnSave(() => itemClick(order))}
          selectedItem={selectedOrder}
          filterCheckbox={{
            checkboxTitle: t('orders.showClosed'),
            checkboxValue: showClosedFilter,
            changeAction: () => setShowClosedFilter(!showClosedFilter),
          }}
          setSearchValue={setSearchOrderValue}
          onSearch={setSearchOrderValue}
          select={(
            <SelectWithSearch
              onSelect={(store) => {
                setSelectedStore(store);
              }}
              onChange={(value) => value}
              options={storesOptions}
              value={selectedStore}
              isOnlyPlaceholder
              className="table-select"
            />
          )}
          tableData={orders.map((order: IConvertedOrder) => ({
            ...order,
            key: order.number,
          }))}
          getNewItems={(page: number, per_page: number) => dispatch(
            ordersActionCreator.fetchOrders(per_page, page, true, {
              store_id: getStoreIdByValue(selectedStore) || null,
              search: searchOrderValue !== '' ? searchOrderValue : null,
              show_closed: showClosedFilter,
            }),
          )}
        />
      </MainWrapper>

      {isRightBlockOpen && (
        <RightWrapper
          title={renderRightWrapperTitle()}
          className="orders-right-wrapper"
          onCancelClick={() => {
            setIsRightBlockOpen(false);
            setIsUnsavedDataPresent(false);
          }}
        >
          <RightCard className="order-info-container" title={t('orders.orderInformation')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForInboundInfoBlock}>
              <InboundInfoBlock
                user={user}
                isReadonlyByUserRole={
                  isOnGate
                    ? !isAllowedForUserRole(allowedRolesForOnGateOrderInfo, userRole[user.role_id])
                    : !isAllowedForUserRole(allowedRolesForUpdateInboundInfoInInboundOrder, userRole[user.role_id])
                }
                selectedOrder={selectedOrder}
                setIsUnsavedDataPresent={setIsUnsavedDataPresent}
                setOrderInfoToSave={setOrderInfoToSave}
              />
            </WithAuthWrapper>
          </RightCard>

          <RightCard
            collapsible={selectedOrder?.id ? '' : 'disabled'}
            className="right-card-padding"
            title={t('orders.items')}
          >
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForInboundItemsBlock}>
              <InboundItems
                user={user}
                selectedOrder={selectedOrder}
                openModal={openModal}
                removeItem={removeItem}
                selectedOrderItems={selectedOrderItems}
                setNewValueOrderItem={setNewValueOrderItem}
              />
            </WithAuthWrapper>
          </RightCard>

          <RightCard collapsible={selectedOrder?.id ? '' : 'disabled'} title={t('common.documents')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForInboundDocumentsBlock}>
              <UploadDocument
                selected={selectedOrder}
                isRemoveDisabled={(doc) => isDocumentsRemoveDisabled(doc, user)}
                isDocumentsDisabled={isDocumentsDisabled(selectedOrder?.status, user)}
                uploadDocument={handleUploadDocument}
                removeDocument={handleRemoveDocument}
              />
            </WithAuthWrapper>
          </RightCard>
        </RightWrapper>
      )}

      <AddItemModal
        items={items}
        setIsModalVisible={setIsModalVisible}
        addOrderItem={addOrderItem}
        isModalVisible={isModalVisible}
        itemToAdd={itemToAdd}
        setItemToAdd={setItemToAdd}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setIsRemoveSearchClicked={setIsRemoveSearchClicked}
        onSearch={() => dispatch(itemsActionCreator.fetchItems(1000, 1, { store_id: selectedOrder.store_id, search: searchValue }))}
      />

      <UnsavedDataModal
        setIsModalVisible={setUnsavedDataModalVisible}
        isModalVisible={unsavedDataModalVisible}
        onSaveClick={saveAndProceedAction}
        cancelClick={cancelAction}
      />
    </>
  );
}

export default Inbound;
