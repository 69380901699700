import { EGeneralAction } from './types';
import { notifier } from '../../../utils/notifier';

export const generalActionCreator = {
  setLoading: (isLoading: number) => ({
    type: EGeneralAction.SET_IS_LOADING,
    payload: isLoading,
  }),
  setError: (error: string) => {
    notifier('danger', 'Error!', error);
    return { type: EGeneralAction.SET_ERROR, payload: error };
  },

  setIsLoading: (loadingValue: number) => async (dispatch, getState) => {
    const { isLoading } = getState().generalReducer;
    await dispatch(generalActionCreator.setLoading(+isLoading + loadingValue));
  },
};
