import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { IInventoryLocation, IParentLocation, IUOM } from 'interfaces';
import { inventoryLocationCol } from 'constants/inventory/inventoryLocationCol';
import LocationMeasurementUnit from '../locationMeasurementUnit/LocationMeasurementUnit';
import { CustomButton } from '../../../../globals';
import { inventoryActionCreator } from '../../../../../store/action-creators';

import './location.scss';

interface LocationProps {
  location: IInventoryLocation;
  isMoveClicked: boolean;
  parentLocation: IParentLocation;
  uomId: number;
  isUserHasAccessToUpdateUoms: boolean;
  setUomId: (id: number) => void;
  setIsMoveClicked: (value: boolean) => void;
}

function Location({
  location,
  isMoveClicked,
  setIsMoveClicked,
  parentLocation,
  setUomId,
  uomId,
  isUserHasAccessToUpdateUoms,
}: LocationProps) {
  const [isCurrentLocationClicked, setIsCurrentLocationClicked] = useState(false);

  const dispatch = useDispatch();

  const move = async (type: string, id: number) => {
    const locationData = { location_id: null, bin_id: null };
    switch (type) {
      case 'bins':
        locationData.bin_id = id;
        break;
      case 'locations':
        locationData.location_id = id;
        break;
      default:
        break;
    }
    const data = await dispatch(inventoryActionCreator.moveItemToAnotherLocation(uomId, locationData));

    if (data) {
      setIsMoveClicked(false);
      setUomId(null);
    }
  };

  useEffect(() => {
    const locationUOMIds = location.uom.map((uom: IUOM) => uom.id);
    setIsCurrentLocationClicked(locationUOMIds.includes(uomId));
  }, [uomId]);

  const handleMove = () => (isMoveClicked ? move(parentLocation?.type, location?.id) : null);

  return (
    <div
      className={`inventory-loc-container ${isMoveClicked && 'inventory-loc-moved-container'} ${
        !isMoveClicked || !location.available || isCurrentLocationClicked
          ? 'inventory-loc-unavailable-container'
          : 'inventory-loc-available-container'
      }`}
      onClick={handleMove}
    >
      <Row className={`loc-header ${location?.name === 'floor' && 'floor-header'}`}>
        <Col span={24} style={{ display: 'flex' }}>
          <CustomButton
            disabled={!isMoveClicked || !location.available || isCurrentLocationClicked || !isUserHasAccessToUpdateUoms}
            className={`location-name-button ${parentLocation.type === 'bins' ? 'loc-bin-name-button' : ''}`}
          >
            {`${location?.name} ${parentLocation.type === 'bins' ? '(Bin)' : ''}`}
          </CustomButton>
          {location.custom_name && <div className="loc-header_custom-name">{location.custom_name}</div>}
        </Col>
      </Row>

      <Row
        className={`mu-titles-block ${location?.name === 'floor' && 'floor-block'} ${
          parentLocation?.type === 'bins' && 'bin-titles-block'
        }`}
      >
        {inventoryLocationCol(isUserHasAccessToUpdateUoms).map((col) => (
          <Col className={col.className} key={col.id} span={col.span}>
            {col.title}
          </Col>
        ))}
      </Row>

      <Row className={`uom-block ${location?.name === 'floor' && 'floor-block'}`}>
        {location.uom.map((uom: IUOM, index: number) => (
          <Col key={index} span={24}>
            <LocationMeasurementUnit
              setIsMoveClicked={setIsMoveClicked}
              uom={uom}
              setUomId={(id: number) => setUomId(id)}
              clickedUOMId={uomId}
              key={index}
              colItems={inventoryLocationCol(isUserHasAccessToUpdateUoms)}
              isUserHasAccessToUpdateUoms={isUserHasAccessToUpdateUoms}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default Location;
