import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Statistic } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { CustomButton, CustomInput } from '../../../components/globals';
import { authActionCreator } from '../../../store/modules/auth/action-creators';

import './style.scss';

function Forgot({ setAuthState }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [error, setError] = useState('');
  const [isTimer, setIsTimer] = useState(false);

  const { Countdown } = Statistic;
  const deadline = Date.now() + 1000 * 60;

  const initialFormValues = {
    email: '',
  };

  const handleSubmitClick = async (values) => {
    const isSend = await dispatch(authActionCreator.resetPassword(values.email || emailValue));
    if (isSend) {
      setIsEmailSend(!!isSend);
      setIsTimer(true);
    } else {
      setError('User with this email is not registered');
    }
  };

  const handleChange = async (email: string) => {
    setEmailValue(email);
    setError('');
  };

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, []);

  return (
    <>
      <CloseOutlined className="closed-icon" onClick={() => setAuthState('login')} />
      <div className="forgot-block">
        {isEmailSend ? (
          <>
            <div className="forgot-text-after">
              <p>Check the recovery link we've sent to</p>
              <p>{emailValue}</p>
              <p> If you don't see it, check your spam folder.</p>
            </div>
            <div className="forgot-text-after-small">
              <p>If you have not received an email,</p>
              <p>you can resubmit your request.</p>
            </div>
          </>
        ) : (
          <>
            <div className="forgot-title">Forgot password</div>
            <div className="forgot-text-before">
              <p>Enter your email address. You will receive a link</p>
              <p>to create a new password via email.</p>
            </div>
          </>
        ) }

        <Form
          form={form}
          autoComplete="off"
          onFinish={handleSubmitClick}
          className="forgot-form"
        >
          {isEmailSend ? null : (
            <Form.Item
              name="email"
              rules={[{
                required: true,
                message: 'Please input your email!',
              }]}
              validateStatus={error ? 'error' : 'success'}
              extra={error ? (<span className="forgot-error-text">{error}</span>) : null}
            >
              <CustomInput required placeholder="Email" onChange={(e) => handleChange(e.target.value)} />
            </Form.Item>
          )}
          <CustomButton type="primary" htmlType="submit" className="forgot-sbm-btn" disabled={isTimer}>Send email</CustomButton>
          {isEmailSend
            && isTimer
            && (
            <span className="forgot-timer">
              Resend via
              <Countdown value={deadline} format="s" onFinish={() => setIsTimer(false)} />
              sec.
            </span>
            )}
        </Form>
      </div>
    </>
  );
}

export default Forgot;
