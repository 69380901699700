import { Modal } from 'antd';
import { MouseEvent } from 'react';

import './modalWindowStyles.scss';

interface ModalWindowProps {
  isModalVisible: boolean;
  title: string;
  handleCancelModal: (e: MouseEvent) => void;
  className?: string;
  children: any;
  width?: number;
  bodyStyle?: any;
}

function ModalWindow({
  isModalVisible,
  title,
  handleCancelModal,
  className = '',
  children,
  width = 580,
  bodyStyle,
}: ModalWindowProps) {
  return (
    <div>
      <Modal
        className={`custom-modal-window-wrapper ${className}`}
        visible={isModalVisible}
        title={title}
        onCancel={handleCancelModal}
        footer={null}
        width={width}
        bodyStyle={bodyStyle}
        centered
      >
        <div className="dialog-body">{children}</div>
      </Modal>
    </div>
  );
}

export default ModalWindow;
