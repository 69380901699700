import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IBin } from 'interfaces';
import { RootState } from 'App';
import { locationsActionCreator } from '../../../../store/action-creators';
import { CustomButton } from '../../../globals';
import Bin from './Bin/Bin';

import './bins.scss';

interface BinsProps{
  isReadonlyByUserRole: boolean;
}

function Bins({ isReadonlyByUserRole }: BinsProps) {
  const dispatch = useDispatch();

  const bins = useSelector((state:RootState) => state.locationsReducer.bins);

  const addBin = async () => {
    await dispatch(locationsActionCreator.addBin());
  };

  const setNewValue = (newValue, index: number) => {
    const updatedArray = [...bins];
    updatedArray[index] = { ...updatedArray[index], ...newValue };
    dispatch(locationsActionCreator.setBins(updatedArray));
  };
  return (
    <div className="bins-container">
      <div className="bins">
        {bins?.length ? (
          bins.map((bin: IBin, index: number) => (
            <Bin isReadonlyByUserRole={isReadonlyByUserRole} bin={bin} key={bin.id} setNewValue={(newValue) => setNewValue(newValue, index)} />
          ))
        ) : (
          <Row>
            <Col span={24}>
              <div className="no-bins-block">No bins</div>
            </Col>
          </Row>
        )}
      </div>
      {!isReadonlyByUserRole && (
      <div className="bins-button-wrapper">
        <CustomButton onClick={addBin}>Add +</CustomButton>
      </div>
      )}

    </div>
  );
}

export default Bins;
