import { UserRoles } from 'constants/users/userRolesAndTypes';
import { AbilityActions, AbilitySubjects } from 'constants/usersPermissions';

export const requiredAbilitiesForItemInfoBlock = [
  { subject: AbilitySubjects.ITEMS, action: AbilityActions.READ },
];

export const requiredAbilitiesForExternalIdIBlock = [
  { subject: AbilitySubjects.ITEMS, action: AbilityActions.READ },
];

export const requiredAbilitiesItemUomsBlock = [
  { subject: AbilitySubjects.ITEMS, action: AbilityActions.READ },
];

export const allowedRolesForUpdateItemsPage = [UserRoles.SUPER_ADMIN, UserRoles.STORE_ADMIN, UserRoles.WAREHOUSE_ADMIN];
