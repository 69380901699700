import { OrderStatus } from 'constants/orders/status';
import { UserRoles } from 'constants/users/userRolesAndTypes';
import { AbilityActions, AbilitySubjects } from 'constants/usersPermissions';

export const requiredAbilitiesForOutboundInfoBlock = [
  { subject: AbilitySubjects.OUTBOUND, action: AbilityActions.READ },
];

export const requiredAbilitiesForConsigneeBlock = [
  { subject: AbilitySubjects.OUTBOUND, action: AbilityActions.READ },
];

export const requiredAbilitiesForOutboundItemsBlock = [
  { subject: AbilitySubjects.OUTBOUND, action: AbilityActions.READ },
];

export const requiredAbilitiesForOutboundDocumentsBlock = [
  { subject: AbilitySubjects.OUTBOUND, action: AbilityActions.READ },
];

export const allowedRolesForGoToPickOutbound = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForResetPicking = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForChangeOutboundStatuses = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForDispatchOutboundOrder = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForSeeItemsWithLocationsBlock = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const allowedRolesForTerminateOrderOnStatusCreated = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
  UserRoles.STORE_ADMIN,
];

export const allowedRolesForTerminateOrderAfterStatusCreated = [
  UserRoles.SUPER_ADMIN,
  UserRoles.WAREHOUSE_ADMIN,
  UserRoles.WAREHOUSE_INTERN,
];

export const isDocumentsDisabled = (orderStatus: OrderStatus) => orderStatus !== OrderStatus.Terminated
  && orderStatus !== OrderStatus.Dispatched
  && orderStatus !== OrderStatus.Closed;
