/* eslint-disable no-mixed-operators */
import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'App';
import {
  IOrderMeasurementUnit, ISelectedOrder, IStore, IUser,
} from 'interfaces';
import CustomDatePicker from 'components/globals/CustomDatePicker/CustomDatePicker';
import {
  InboundOrderStatus, orderType, orderTypeNumbers, printInboundForStatusOrder,
} from 'constants/orders/status';
import TerminateModal from 'components/views/orders/TerminateModal';
import moment from 'moment';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { allowedRolesForReceiveInboundOrder, allowedRolesForTerminateOrder } from 'constants/orders/pageAbilities';
import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import { ConfirmationModal } from 'components/confirmationModal';
import { ordersActionCreator, storesActionCreator } from '../../../../store/action-creators';
import { convertTime, timeRefactor } from '../../../../utils/timeHelpers';
import {
  CustomButton,
  CustomTextArea,
  SelectWithSearch,
  CustomInput,
  ButtonWithSelect,
  ModalWindow,
} from '../../../globals';
import ReportInboundPrint from './Print/ReportInbound';

import './inboundInfo.scss';

interface InboundInfoBlockProps {
  user: IUser;
  selectedOrder: ISelectedOrder;
  isReadonlyByUserRole: boolean;
  setIsUnsavedDataPresent: (value: boolean) => void;
  setOrderInfoToSave: (order: ISelectedOrder) => void;
}

function InboundInfoBlock({
  user, selectedOrder, isReadonlyByUserRole, setIsUnsavedDataPresent, setOrderInfoToSave,
}: InboundInfoBlockProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const selectedOrderItems = useSelector((state: RootState) => state.ordersReducer.orderItems);
  const isClosedOrder = selectedOrder?.status === InboundOrderStatus.Terminated || selectedOrder?.status === InboundOrderStatus.Closed;

  const isOnGate = selectedOrder?.type === orderType.ON_GATE || selectedOrder?.type === orderTypeNumbers.ON_GATE;

  const isTerminateAvailable = selectedOrder?.status === InboundOrderStatus.Created
    || selectedOrder?.status === InboundOrderStatus.Confirmed
    || selectedOrder?.status === InboundOrderStatus.Ready;

  const isExpectedRequired = !isOnGate && selectedOrder?.status && selectedOrder?.status !== InboundOrderStatus.Created;
  const isUserCanReceiveInboundOrder = isAllowedForUserRole(
    allowedRolesForReceiveInboundOrder,
    userRole[user.role_id as unknown as number],
  );

  const [storeId, setStoreId] = useState(null);
  const [expectedDate, setExpectedDate] = useState(undefined);
  const [isOpenModalReport, setIsOpenModalReport] = useState(false);
  const [saveButtonDisabled, setSaveButtonDisabled] = useState(true);
  const [isDropdownBtnActive, setIsDropdownBtnActive] = useState(false);
  const [expectedErrorMessage, setExpectedErrorMessage] = useState(false);
  const [isTerminateModalVisible, setIsTerminateModalVisible] = useState(false);
  const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

  const stores = useSelector((state: RootState) => state.storesReducer.stores);

  const isConditionForInternalNote = userRole[user.role_id as unknown as number] === UserRoles.WAREHOUSE_ADMIN
   || userRole[user.role_id as unknown as number] === UserRoles.SUPER_ADMIN;

  const storesOptions = stores.map((store: IStore) => ({
    value: store.company_name,
    id: store.id,
  }));

  const initialValues = useMemo(() => {
    let result = null;
    if (selectedOrder) {
      result = {
        type: selectedOrder.type,
        user: `${selectedOrder?.user?.name}(${selectedOrder?.user?.role})`,
        store: selectedOrder.store,
        notes: selectedOrder.notes,
        status: selectedOrder.status,
        tracking_number: selectedOrder.tracking_number,
        terminate_reason: selectedOrder.terminate_reason,
        create_date: convertTime(selectedOrder.createDate),
        update_date: convertTime(selectedOrder.updateDate),
        expected: selectedOrder.expected ? moment(selectedOrder.expected) : null,
        internal_note: selectedOrder.internal_note,
      };
    }

    return result;
  }, [selectedOrder]);

  const receiveOrder = async () => {
    await dispatch(ordersActionCreator.receiveOrder(selectedOrder.id));
  };

  const isDisabledReceiveBtn = (uoms: IOrderMeasurementUnit[]) => {
    if (!uoms?.length) return true;
    return !uoms.every((mu: IOrderMeasurementUnit) => mu?.is_received);
  };

  const save = async (body) => {
    if (isExpectedRequired && !expectedDate) {
      setExpectedErrorMessage(true);
      return;
    }
    body.type = selectedOrder?.type;
    body.store_id = storeId;
    body.expected = expectedDate ? timeRefactor(expectedDate).toString() : null;

    let response;
    if (selectedOrder.id) {
      response = await dispatch(ordersActionCreator.updateOrder(selectedOrder.id, body));
    } else {
      response = await dispatch(ordersActionCreator.createOrder(body));
    }
    if (response) {
      setIsUnsavedDataPresent(false);
    }
  };

  const getStoreIdByCompanyName = (company: string) => {
    const findedId = stores?.find((store: any) => store.company_name === company);
    setStoreId(findedId.id);
  };

  useEffect(() => {
    if (selectedOrder) {
      setSaveButtonDisabled(true);
      setExpectedDate(selectedOrder.expected);
      setStoreId(selectedOrder.store_id);
    }
  }, [selectedOrder]);

  useEffect(() => {
    setIsUnsavedDataPresent(false);
  }, [selectedOrder?.status]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    (async () => {
      dispatch(storesActionCreator.fetchStores(1000));
    })();

    return () => {
      dispatch(storesActionCreator.clearStores());
    };
  }, []);

  const isPrintActiveInbound = printInboundForStatusOrder.some((elem) => elem === selectedOrder?.status);
  const isPrintActiveOnGate = selectedOrder?.status === InboundOrderStatus.Closed;

  const isReceiveBtnVisible = () => {
    if (isUserCanReceiveInboundOrder) {
      // if order on gate and status is not closed
      if (isOnGate && !isClosedOrder) {
        return true;
      }

      // if order inbound and statuses are Arrived or Partially arrived
      if (
        selectedOrder?.status === InboundOrderStatus.Arrived
        || selectedOrder?.status === InboundOrderStatus.PartiallyArrived
      ) {
        return true;
      }
    }

    return false;
  };

  return (
    <div className="order-info-container">
      <Form
        form={form}
        onFinish={save}
        onFieldsChange={() => {
          setSaveButtonDisabled(false);
          setIsUnsavedDataPresent(true);
          setOrderInfoToSave({
            ...form.getFieldsValue(),
            store_id: storeId,
            expected: expectedDate ? timeRefactor(expectedDate).toString() : null,
          });
        }}
      >
        <Row gutter={[26, 26]}>
          <Col span={12}>
            <Form.Item
              name="store"
              rules={[
                {
                  required: true,
                  message: 'Store is required',
                },
              ]}
            >
              <SelectWithSearch
                className="order-info-store-select"
                onSelect={(e) => getStoreIdByCompanyName(e)}
                disabled={!!selectedOrderItems?.length}
                placeholder={t('orders.store')}
                readonly={isClosedOrder || isReadonlyByUserRole}
                options={storesOptions}
                required
                setNothingSelected={(isNothingSelected) => setSaveButtonDisabled(isNothingSelected)}
              />
            </Form.Item>
          </Col>
          {isOnGate ? (
            <Col span={12}>
              <Form.Item name="status">
                <CustomInput readonly placeholder={t('orders.orderStatus')} />
              </Form.Item>
            </Col>
          ) : null}
          {!isOnGate ? (
            <Col span={12}>
              <Form.Item name="tracking_number" normalize={(input) => input.toUpperCase()}>
                <CustomInput placeholder={t('orders.trackingNumber')} readonly={isClosedOrder || isReadonlyByUserRole} />
              </Form.Item>
            </Col>
          ) : null}

          {!isOnGate ? (
            <Col span={12}>
              {/* style={{ paddingLeft: '0', margin: '0 12px' }}> */}
              <CustomDatePicker
                readonly={isClosedOrder || isReadonlyByUserRole}
                value={expectedDate ? moment(expectedDate) : null}
                className={`inbound-date-picker ${expectedErrorMessage ? 'error-date-picker' : ''}`}
                placheholder="mm/dd/yyyy"
                placheholderTitle={t('orders.expected')}
                minDate={moment(new Date())}
                handleChange={(value) => {
                  setExpectedDate(value);
                  setSaveButtonDisabled(false);
                  setExpectedErrorMessage(!!(isExpectedRequired && !value));
                }}
              />
              {expectedErrorMessage && <p className="expected-message-error">Expected date is required</p>}
            </Col>
          ) : null}

          {!isOnGate && selectedOrder?.id && (
          <Col span={12}>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item name="create_date">
                  <CustomInput placeholder={t('orders.dateCreated')} readonly />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          )}

          <Col span={24}>
            <Row gutter={[26, 0]}>
              <Col span={12}>
                <Form.Item name="notes">
                  <CustomTextArea
                    className={`order-info-textarea-block  ${isConditionForInternalNote ? 'textarea-notes' : ''}`}
                    readonly={isClosedOrder || isReadonlyByUserRole}
                    placeholder={t('orders.note')}
                  />
                </Form.Item>
              </Col>

              {selectedOrder?.id && (
                <Col className="second-line-inputs-wrapper" span={12}>
                  <Row gutter={12}>
                    {isOnGate && (
                    <Col span={12}>
                      <Form.Item name="create_date">
                        <CustomInput placeholder={t('orders.dateCreated')} readonly />
                      </Form.Item>
                    </Col>
                    )}
                    <Col span={isOnGate ? 12 : 24}>
                      <Form.Item name="update_date">
                        <CustomInput placeholder={t('orders.dateUpdated')} readonly />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Col className="col-input">
                    <Form.Item name="user">
                      <CustomInput readonly placeholder={t('orders.createdBy')} />
                    </Form.Item>
                  </Col>
                  {isConditionForInternalNote
                    ? (
                      <Col className="col-input">
                        <Form.Item name="internal_note">
                          <CustomInput readonly={isClosedOrder || isReadonlyByUserRole} placeholder="Internal note" />
                        </Form.Item>
                      </Col>
                    )
                    : null}
                </Col>
              )}
              {!isOnGate && selectedOrder?.status === InboundOrderStatus.Terminated ? (
                <Col span={24}>
                  <Form.Item name="terminate_reason">
                    <CustomTextArea
                      className="terminate-reason-textarea"
                      placeholder={t('orders.terminateRreason')}
                      readonly={isClosedOrder}
                    />
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Col>

          {isOnGate ? (
            <Col span={5} offset={!isClosedOrder && isReceiveBtnVisible() ? 10 : 18}>
              <Form.Item>
                <ButtonWithSelect
                  className={`save-btn-with-dropdown ${isClosedOrder ? 'disabled-save-btn-with-dropdown' : ''}`}
                  mainButtonProps={{ title: t('common.save') }}
                  isDropdownActive={isDropdownBtnActive}
                  setIsDropdownActive={setIsDropdownBtnActive}
                  htmlType="submit"
                  disabled={isClosedOrder || saveButtonDisabled}
                  isDropdownEnabled={selectedOrder?.status === InboundOrderStatus.Terminated ? 0 : 1}
                >
                  {isPrintActiveOnGate && (
                    <CustomButton onClick={() => setIsOpenModalReport(true)}>{t('orders.print')}</CustomButton>
                  )}
                </ButtonWithSelect>
              </Form.Item>
            </Col>
          ) : (
            <Col className="align-right" span={isReceiveBtnVisible() ? 15 : 24}>
              <ButtonWithSelect
                className={`save-btn-with-dropdown ${isClosedOrder ? 'disabled-save-btn-with-dropdown' : ''}`}
                mainButtonProps={{ title: t('common.save') }}
                isDropdownActive={isDropdownBtnActive}
                setIsDropdownActive={setIsDropdownBtnActive}
                htmlType="submit"
                disabled={isClosedOrder || saveButtonDisabled}
                isDropdownEnabled={selectedOrder?.status === InboundOrderStatus.Terminated ? 0 : 1}
              >
                {selectedOrder?.status
                && isTerminateAvailable
                && isAllowedForUserRole(allowedRolesForTerminateOrder, userRole[user.role_id as unknown as number]) ? (
                  <CustomButton onClick={() => setIsTerminateModalVisible(true)}>{t('orders.terminate')}</CustomButton>
                  ) : null}
                {isPrintActiveInbound && (
                  <CustomButton onClick={() => setIsOpenModalReport(true)}>{t('orders.print')}</CustomButton>
                )}
              </ButtonWithSelect>
            </Col>
          )}
          {isReceiveBtnVisible() && (
            <Col span={9}>
              <CustomButton
                isImportant
                disabled={
                  isOnGate
                    ? isDisabledReceiveBtn(selectedOrderItems.map((item) => item.measurement_units).flat())
                    : isDisabledReceiveBtn(selectedOrderItems.map((item) => item.result_measurement_units).flat())
                      || selectedOrder?.status === InboundOrderStatus.PartiallyArrived
                }
                onClick={() => setIsConfirmationModalVisible(true)}
              >
                Receive Order
              </CustomButton>
            </Col>
          )}
        </Row>
      </Form>
      <ConfirmationModal
        confirmAction={receiveOrder}
        confirmText={t('orders.confirmationReceive')}
        closeCallback={setIsConfirmationModalVisible}
        isModalVisible={isConfirmationModalVisible}
      />
      <TerminateModal
        actionCreator={ordersActionCreator}
        isTerminateModalVisible={isTerminateModalVisible}
        closeCallback={setIsTerminateModalVisible}
        selectedOrder={selectedOrder}
      />
      <ModalWindow
        title=""
        className="current-row-modal"
        handleCancelModal={() => setIsOpenModalReport(false)}
        isModalVisible={isOpenModalReport}
        bodyStyle={{ height: '711px' }}
        width={611}
      >
        <ReportInboundPrint stores={stores} order={selectedOrder} orderItems={selectedOrderItems} />
      </ModalWindow>
    </div>
  );
}

export default InboundInfoBlock;
