import { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App';
import { TrashIcon } from 'assets/images';
import { IRack } from 'interfaces';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { userRole } from 'constants/users/userRolesAndTypes';
import { locationsActionCreator } from '../../store/modules/locations/action-creators';
import { MainWrapper, RightCard, RightWrapper } from '../../components/layout';

import Rack from '../../components/views/locations/rack/Rack';
import { rackMode } from '../../constants/locations/rackMode';
import { infoBlockModes } from '../../constants/infoBlockModes';
import { getEditCloseIcons } from '../../components/layout/rightCard';
import RackInfo from '../../components/views/locations/rackInfo/RackInfo';
import LevelInfo from '../../components/views/locations/levelInfo/LevelInfo';
import ColumnInfo from '../../components/views/locations/ColumnInfo/ColumnInfo';
import LocationsLegenda from '../../components/views/locations/locationsLegenda/LocationsLegenda';
import NewRackModal from '../../components/views/locations/newRackModal/NewRackModal';
import Bins from '../../components/views/locations/Bins/Bins';
import LocationInfo from '../../components/views/locations/LocationInfo/LocationInfo';
import {
  allowedRolesForCreateNewRack,
  allowedRolesForUpateOrRemoveAllLocations,
  allowedRolesForUpdateColumnInfo,
  allowedRolesForUpdateColumnsAndLevelsInRack,
  allowedRolesForUpdateLevelInfo,
  allowedRolesForUpdateLocationInfo,
  allowedRolesForUpdateOrRemoveBins,
  allowedRolesForUpdateOrRemoveRacks,
  requiredAbilitiesForBinsBlock,
  requiredAbilitiesForRackInfoBlock,
} from '../../constants/locations/pageAbilities';

import './locations.scss';

function Locations() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.authReducer.user);

  const {
    racks, selectedRack, selectedLocation, selectedLevel, selectedColumn,
  } = useSelector(
    (state: RootState) => state.locationsReducer,
  );

  const [mode, setMode] = useState(infoBlockModes.VIEW);
  const [isRightBlockOpen, setIsRightBlockOpen] = useState(false);
  const [typeOfCard, setTypeOfCard] = useState(rackMode.noSelected);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const getIcon = () => {
    if (mode === infoBlockModes.CREATE) return '';
    return getEditCloseIcons(
      mode,
      () => setMode(infoBlockModes.EDIT),
      () => {
        setMode(infoBlockModes.VIEW);
      },
    );
  };

  const renderRightInfoBlock = useMemo(() => {
    let result;
    switch (typeOfCard) {
      case rackMode.createRack:
        result = (
          <NewRackModal
            isModalVisible={typeOfCard === rackMode.createRack}
            setIsModalVisible={() => setTypeOfCard(rackMode.noSelected)}
          />
        );
        break;
      case rackMode.selectedRack:
        result = (
          <WithAuthWrapper requiredAbilities={requiredAbilitiesForRackInfoBlock}>
            <RackInfo
              isReadonlyByUserRole={!isAllowedForUserRole(allowedRolesForUpdateOrRemoveRacks, userRole[user.role_id])}
              rack={selectedRack}
              mode={mode}
              setMode={setMode}
            />
          </WithAuthWrapper>
        );

        break;
      case rackMode.selectedCell:
        result = (
          <WithAuthWrapper requiredAbilities={requiredAbilitiesForRackInfoBlock}>
            <LocationInfo
              isReadonlyByUserRole={!isAllowedForUserRole(allowedRolesForUpdateLocationInfo, userRole[user.role_id])}
              mode={mode}
              setMode={setMode}
            />
          </WithAuthWrapper>
        );

        break;
      case rackMode.selectedLevel:
        result = (
          <WithAuthWrapper requiredAbilities={requiredAbilitiesForRackInfoBlock}>
            <LevelInfo
              isReadonlyByUserRole={!isAllowedForUserRole(allowedRolesForUpdateLevelInfo, userRole[user.role_id])}
              mode={mode}
              setMode={setMode}
            />
          </WithAuthWrapper>
        );

        break;
      case rackMode.selectedColumn:
        result = (
          <WithAuthWrapper requiredAbilities={requiredAbilitiesForRackInfoBlock}>
            <ColumnInfo
              isReadonlyByUserRole={!isAllowedForUserRole(allowedRolesForUpdateColumnInfo, userRole[user.role_id])}
              mode={mode}
              setMode={setMode}
            />
          </WithAuthWrapper>
        );

        break;
      default:
        result = 0;
    }
    return result;
  }, [typeOfCard, mode, selectedRack]);

  const renderRightCardTitle = useMemo(() => {
    let result;
    switch (typeOfCard) {
      case rackMode.selectedRack:
        result = t('locations.rackProperties');
        break;
      case rackMode.selectedCell:
        result = `${t('locations.location')} ${selectedLocation?.name || ''}
        ${selectedLocation?.custom_name ? `(${selectedLocation?.custom_name})` : ''} ${t('locations.properties')}`;
        break;
      case rackMode.selectedLevel:
        result = `Selected level ${selectedLevel}`;
        break;
      case rackMode.selectedColumn:
        result = `Selected column ${selectedColumn}`;
        break;
      default:
        result = 0;
    }
    return result;
  }, [typeOfCard, selectedColumn, selectedLevel, selectedLocation]);

  const selectSmth = (rack: IRack, callback = () => {}) => {
    setMode(infoBlockModes.VIEW);
    dispatch(locationsActionCreator.setSelectedRack(rack));
    callback();
    setIsRightBlockOpen(true);
  };
  const selectColumn = (column: number, rack: IRack) => {
    selectSmth(rack, () => {
      setTypeOfCard(rackMode.selectedColumn);
      dispatch(locationsActionCreator.setSelectedLevel(0));
      dispatch(locationsActionCreator.setSelectedColumn(column));
    });
  };

  const selectLevel = (level: number, rack: IRack) => {
    selectSmth(rack, () => {
      setTypeOfCard(rackMode.selectedLevel);
      dispatch(locationsActionCreator.setSelectedLevel(level));
      dispatch(locationsActionCreator.setSelectedColumn(0));
    });
  };

  const selectLocation = (level: number, column: number, rack: IRack) => {
    selectSmth(rack, () => {
      setTypeOfCard(rackMode.selectedCell);
      dispatch(locationsActionCreator.setSelectedLevel(level));
      dispatch(locationsActionCreator.setSelectedColumn(column));
    });
  };

  const selectRack = (rack: IRack) => {
    selectSmth(rack, () => {
      setTypeOfCard(rackMode.selectedRack);
      dispatch(locationsActionCreator.setSelectedLevel(0));
      dispatch(locationsActionCreator.setSelectedColumn(0));
    });
  };

  useEffect(() => {
    dispatch(locationsActionCreator.fetchRacks());
    dispatch(locationsActionCreator.fetchSpecialConditions());
    return () => {
      dispatch(locationsActionCreator.clearLocations());
    };
  }, []);

  useEffect(() => {
    if (selectedRack) {
      dispatch(locationsActionCreator.fetchLocations(selectedRack.id));
      dispatch(locationsActionCreator.setSelectedRack(racks.find((rack: IRack) => rack.id === selectedRack.id)));
    } else {
      dispatch(locationsActionCreator.setLocations([]));
      dispatch(locationsActionCreator.setSelectedRack(null));
    }
  }, [selectedRack?.id]);

  useEffect(() => {
    if (selectedLocation) {
      dispatch(locationsActionCreator.fetchBins(selectedLocation.id));
    }
  }, [selectedLocation]);

  return (
    <>
      <MainWrapper
        className="locations-main-wrapper-container"
        title={t('sidebar.locations')}
        iconAction={() => {
          setTypeOfCard(rackMode.createRack);
          dispatch(locationsActionCreator.setSelectedLocation(null));
          setIsModalVisible(true);
          setIsRightBlockOpen(false);
        }}
        buttons={!isAllowedForUserRole(allowedRolesForCreateNewRack, userRole[user.role_id]) && []}
        isFullScreen={!isRightBlockOpen}
      >
        <LocationsLegenda isRightBlockOpen={isRightBlockOpen} />

        <div className={`racks-container ${isRightBlockOpen ? 'racks-container-adaptive' : ''}`}>
          {racks.map((rack: IRack) => (
            <Rack
              rack={rack}
              key={rack.id}
              onClickRack={() => selectRack(rack)}
              selectCell={(level: number, column: number, rack: IRack) => selectLocation(level, column, rack)}
              selectedRackId={selectedRack?.id}
              selectedLevel={selectedLevel}
              selectedColumn={selectedColumn}
              selectColumn={(column: number, rack: IRack) => selectColumn(column, rack)}
              selectLevel={(level: number, rack: IRack) => selectLevel(level, rack)}
              isReadonlyByUserRole={!isAllowedForUserRole(allowedRolesForUpdateColumnsAndLevelsInRack, userRole[user.role_id])}
            />
          ))}
        </div>
      </MainWrapper>
      {isRightBlockOpen && (
        <RightWrapper
          title={`${selectedRack?.name} ${selectedRack?.custom_name ? `(${selectedRack?.custom_name})` : ''} `}
          onCancelClick={() => setIsRightBlockOpen(false)}
        >
          <RightCard
            className={typeOfCard === rackMode.selectedRack ? 'locations-right-info-rack-card' : ''}
            icon={
              isAllowedForUserRole(allowedRolesForUpateOrRemoveAllLocations, userRole[user.role_id])
              && (typeOfCard === rackMode.selectedRack
                ? [
                  getIcon(),

                  <div
                    className="delete-rack-button-wrapper"
                    onClick={() => {
                      dispatch(locationsActionCreator.deleteRack(selectedRack.id));
                      setIsRightBlockOpen(false);
                    }}
                  >
                    <TrashIcon />
                  </div>,
                ]
                : getIcon())
            }
            title={renderRightCardTitle}
          >
            {renderRightInfoBlock}
          </RightCard>
          {typeOfCard === rackMode.selectedCell && selectedLocation?.misc_items && (
            <RightCard className="locations-bins-right-card" title="Bins">
              <WithAuthWrapper requiredAbilities={requiredAbilitiesForBinsBlock}>
                <Bins
                  isReadonlyByUserRole={
                    !isAllowedForUserRole(allowedRolesForUpdateOrRemoveBins, userRole[user.role_id])
                  }
                />
              </WithAuthWrapper>
            </RightCard>
          )}
        </RightWrapper>
      )}
      {isModalVisible && (
        <NewRackModal
          className="new-rack-modal-block"
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      )}
    </>
  );
}

export default Locations;
