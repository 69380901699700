import baseAxios from './axiosInstance';

const mainPath = 'courier';

const couriersAPI = {
  async getCouriers() {
    const { data } = await baseAxios.get(`/${mainPath}`);
    return data;
  },
  createCourier(courierName: any) {
    return baseAxios.post(`/${mainPath}`, courierName);
  },
};

export default couriersAPI;
