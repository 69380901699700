import { IPagination, IReduxAction, IUser } from 'interfaces';
import { EUsersAction } from './types';

export interface IUserSearchParams {
  role_id?: number | null;
  search?: string;
  relation_type?: number | null;
}
interface IUsersState {
  users: IUser[];
  selectedUser: IUser;
  pagination: IPagination;
  search_params: IUserSearchParams;
}

const initialState: IUsersState = {
  users: [],
  selectedUser: null,
  pagination: { current_page: 1, per_page: 10 },
  search_params: { role_id: null, search: '', relation_type: null },
};

export default function usersReducer(state: IUsersState = initialState, action: IReduxAction) {
  switch (action.type) {
    case EUsersAction.SET_USERS:
      return { ...state, users: action.payload };
    case EUsersAction.SET_SELECTED_USER:
      return { ...state, selectedUser: action.payload };
    case EUsersAction.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case EUsersAction.SET_SEARCH_PARAMS:
      return { ...state, search_params: action.payload };
    default:
      return state;
  }
}
