import { userAbility } from 'interfaces/userAbility';

export const isUserHasAccess = (
  requiredAbilities: userAbility[],
  userAbility: userAbility[],
): boolean => {
  const isAccess = requiredAbilities?.every((requiredAbility) => userAbility?.some(
    (userAbility) => userAbility.action === requiredAbility.action && userAbility.subject === requiredAbility.subject,
  ));

  return isAccess;
};
