import { colors } from 'assets/css/variables';

function UploadPhotoIcon() {
  return (
    <svg width="44" height="41" viewBox="0 0 44 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M17.8567 0C16.9461 3.39981e-05 16.0521 0.243568 15.2672 0.705367C14.4824 1.16717 13.8354 1.83043 13.3932 2.62643L11.8481 5.40664H7.32572C5.49348 5.40664 3.73628 6.1345 2.44069 7.43009C1.14509 8.72569 0.417236 10.4829 0.417236 12.3151V32.7402C0.417236 34.5725 1.14509 36.3297 2.44069 37.6253C3.73628 38.9209 5.49348 39.6487 7.32572 39.6487H20.8375C20.4339 38.6808 20.1296 37.6744 19.9292 36.645H7.32572C6.29011 36.645 5.29691 36.2336 4.56462 35.5013C3.83232 34.769 3.42093 33.7758 3.42093 32.7402V12.3151C3.42093 11.2795 3.83232 10.2863 4.56462 9.55402C5.29691 8.82173 6.29011 8.41033 7.32572 8.41033H13.6155L16.0184 4.08502C16.2005 3.75723 16.467 3.48412 16.7903 3.29399C17.1135 3.10386 17.4817 3.00363 17.8567 3.00369H26.231C26.6059 3.00363 26.9741 3.10386 27.2974 3.29399C27.6206 3.48412 27.8871 3.75723 28.0692 4.08502L30.4722 8.41033H36.7619C37.7975 8.41033 38.7907 8.82173 39.523 9.55402C40.2553 10.2863 40.6667 11.2795 40.6667 12.3151V18.9833C41.7264 19.3738 42.7332 19.8772 43.6704 20.478V12.3151C43.6704 10.4829 42.9425 8.72569 41.6469 7.43009C40.3513 6.1345 38.5941 5.40664 36.7619 5.40664H32.2395L30.6944 2.62643C30.2522 1.83043 29.6052 1.16717 28.8204 0.705367C28.0356 0.243568 27.1415 3.39981e-05 26.231 0H17.8567Z"
        fill={colors.grayCardLight}
      />
      <path
        d="M22.0436 12.0156C24.0576 12.0156 26.0207 12.648 27.656 13.8235C29.2913 14.999 30.5163 16.6583 31.158 18.5673C30.1788 18.8328 29.238 19.192 28.3465 19.633C27.9956 18.526 27.3592 17.5311 26.5013 16.7484C25.6435 15.9657 24.5945 15.4229 23.4601 15.1746C22.3257 14.9264 21.1459 14.9814 20.0395 15.3343C18.9331 15.6871 17.9393 16.3253 17.1581 17.1845C16.3769 18.0437 15.8359 19.0936 15.5896 20.2285C15.3433 21.3633 15.4004 22.543 15.7552 23.6488C16.11 24.7545 16.7499 25.7472 17.6104 26.527C18.471 27.3067 19.5218 27.8459 20.6571 28.0902C20.3039 29.0177 20.0359 29.9885 19.8641 30.9917C17.5635 30.4593 15.5394 29.0986 14.1779 27.1692C12.8164 25.2398 12.2128 22.8767 12.4822 20.5307C12.7517 18.1847 13.8753 16.02 15.6388 14.4495C17.4023 12.879 19.6822 12.0126 22.0436 12.0156Z"
        fill={colors.grayCardLight}
      />
      <path
        d="M43.7647 31.9561C43.7647 36.9001 39.7566 40.9083 34.8125 40.9083C29.8685 40.9083 25.8604 36.9001 25.8604 31.9561C25.8604 27.012 29.8685 23.0039 34.8125 23.0039C39.7566 23.0039 43.7647 27.012 43.7647 31.9561ZM35.6264 27.0731C35.6264 26.8572 35.5406 26.6502 35.388 26.4976C35.2354 26.345 35.0284 26.2592 34.8125 26.2592C34.5967 26.2592 34.3897 26.345 34.2371 26.4976C34.0844 26.6502 33.9987 26.8572 33.9987 27.0731V31.1422H29.9295C29.7137 31.1422 29.5067 31.228 29.3541 31.3806C29.2014 31.5332 29.1157 31.7402 29.1157 31.9561C29.1157 32.1719 29.2014 32.3789 29.3541 32.5316C29.5067 32.6842 29.7137 32.7699 29.9295 32.7699H33.9987V36.8391C33.9987 37.0549 34.0844 37.2619 34.2371 37.4146C34.3897 37.5672 34.5967 37.6529 34.8125 37.6529C35.0284 37.6529 35.2354 37.5672 35.388 37.4146C35.5406 37.2619 35.6264 37.0549 35.6264 36.8391V32.7699H39.6955C39.9114 32.7699 40.1184 32.6842 40.271 32.5316C40.4236 32.3789 40.5094 32.1719 40.5094 31.9561C40.5094 31.7402 40.4236 31.5332 40.271 31.3806C40.1184 31.228 39.9114 31.1422 39.6955 31.1422H35.6264V27.0731Z"
        fill={colors.grayCardLight}
      />
    </svg>
  );
}

export default UploadPhotoIcon;
