import { colors } from 'assets/css/variables';

function DatePickerIcon() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 7.39956C1 4.74862 3.14901 2.59961 5.79994 2.59961H12.1999C14.8508 2.59961 16.9998 4.74862 16.9998 7.39956H1Z" fill={colors.secondaryLight} />
      <path d="M13.7995 2.60059H4.19987C2.43263 2.60059 1 4.03322 1 5.80046V13.8001C1 15.5674 2.43263 17 4.19987 17H13.7995C15.5667 17 16.9994 15.5674 16.9994 13.8001V5.80046C16.9994 4.03322 15.5667 2.60059 13.7995 2.60059Z" stroke={colors.blueLight} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M1 7.39975H16.9994M5.79981 1V4.19987V1ZM12.1996 1V4.19987V1Z" stroke={colors.blueLight} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default DatePickerIcon;
