export const colors = {
  blue: '#3dc4ef',
  black: 'black',
  error: '#ff5f68',
  white: 'white',
  grayText: '#acbcc6',
  grayLight: '#E9F1F2',
  grayHight: '#63777D',
  darkText: '#18384A',
  darkHight: '#0D1019',
  grayCardLight: '#CDD8DF',
  green: '#419731',
  secondaryLight: '#e9eff2',
  orange: '#EEA230',
  allocatedColor: '#B5D9E4',
  processingColor: '#33BCE8',
  success: '#0BDEAB',
  blueLegenda: '#1eaae7',
  blueLight: '#6F8D9E',
  yellowLegenda: '#fdf428',
};
