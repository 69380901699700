import { renderItemExtId } from 'constants/items/itemsData';

export const tableColumns = [
  {
    title: 'Order #',
    dataIndex: 'number',
  },
  {
    title: 'Store',
    dataIndex: 'store',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
  {
    title: 'Date Created ',
    dataIndex: 'dateIssued',
  },
  {
    title: 'Expected',
    dataIndex: 'expected',
  },
  {
    title: 'Created by',
    dataIndex: 'created_by',
  },
];

export const itemColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Default External ID',
    dataIndex: 'external_id',
    render: (externalId, item) => renderItemExtId(externalId, item, 'top', '', true),
  },
  {
    title: 'Store',
    dataIndex: 'company_name',
  },
];
