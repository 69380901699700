import { useState } from 'react';
import { Upload, message, Image } from 'antd';
import { UploadPhotoIcon } from 'assets/images';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/lib/upload';

import './uploadPhoto.scss';

interface UploadPhotoProps{
  className?: string;
  disabled?: boolean;
  readonly?: boolean;
  accept?: string;
  imageObject: any;
  preview?: boolean;
  handleUploadPhoto?: any;
}

function UploadPhoto({
  className, disabled, accept = 'image/*', imageObject, readonly = false, preview = false, handleUploadPhoto,
}: UploadPhotoProps) {
  const [loading, setLoading] = useState(false);

  const beforeUpload = (file: RcFile) => {
    // TODO if we will need validation in future
    // const isLt2M = file.size / 1024 / 1024 < 2;
    // if (!isLt2M) {
    //   message.error('Image must smaller than 2MB!');
    // }
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file, file.name);
    formData.append('document_id', imageObject?.id || '');
    handleUploadPhoto(formData);
    setLoading(false);
  };

  const uploadButton = (
    <div className="upload-button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}

      <div className="upload-image-button">
        <UploadPhotoIcon />

        <p>Upload image</p>
      </div>
    </div>
  );

  return (
    <div className={`custom-upload-image-wrapper ${className} ${readonly ? 'readonly-upload-image-container' : ''} `}>
      <Upload
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        disabled={disabled || readonly}
        accept={accept}
      >
        {imageObject
          ? <Image preview={preview && disabled} width="100%" height="calc(100% - 3px)" src={imageObject?.download_url} />
          : uploadButton}
      </Upload>
    </div>
  );
}

export default UploadPhoto;
