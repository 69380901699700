import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { IUnit } from 'interfaces';
import { CopyIcon } from 'assets/images';
import { timeoutToSave } from 'constants/saveOnFlyTimeout';
import { itemsActionCreator } from '../../../../store/action-creators';
import { CustomInput, CustomCheckbox } from '../../../globals';
import CustomRadioButton from '../../../globals/customRadioButton/CustomRadioButton';
import copyToClipboard from '../../../../utils/copyToClipboard';
import CopiedMessageBlock from '../../../globals/copiedMessageBlock/CopiedMessageBlock';

import './unitOfMeasurement.scss';

interface UnitOfMeasurementProps {
  uom: IUnit;
  itemId: number;
  setNewValue: (value: unknown) => void;
  metric: number;
  isReadonlyByUserRole: boolean;
}

function UnitOfMeasurement({
  uom, itemId, setNewValue, metric, isReadonlyByUserRole,
}: UnitOfMeasurementProps) {
  const dispatch = useDispatch();

  const [delayTimeout, setDelayTimeout] = useState(null);
  const [dimensionsValue, setDimensionsValue] = useState(true);
  const [containsValue, setContainsValue] = useState(true);
  const [isCodeCopied, setIsCodeCopied] = useState(false);

  const updateUOM = async (newValue) => {
    setNewValue(newValue);

    const isCondition = newValue.default || newValue.weight || newValue.height || newValue.length || newValue.width || +newValue.contains;

    if (isCondition) {
      if (delayTimeout) {
        clearTimeout(delayTimeout);
      }
      setDelayTimeout(
        setTimeout(() => {
          dispatch(itemsActionCreator.updateUnit(itemId, { ...uom, ...newValue }));
        }, timeoutToSave),
      );
    }
  };

  return (
    <Row className="item-uom-block">
      <Col span={24}>
        {uom.carton_id ? (
          <Col>
            <p className="item-uom-title">Master Carton</p>
            <span className="item-uom-internal_id">{`ID: ${uom.internal_id}`}</span>
            <div className="item-related-block">
              <div
                className="copy-uom-internal_id-block"
                onClick={() => {
                  copyToClipboard(uom.internal_id);
                  setIsCodeCopied(true);
                }}
              >
                <CopyIcon />
              </div>
              {isCodeCopied && <CopiedMessageBlock isCopied={isCodeCopied} setIsCopied={setIsCodeCopied} text="ID " />}
            </div>
          </Col>
        ) : (
          <Col>
            <p className="item-uom-title">Each</p>
          </Col>
        )}

        <Row align="middle">
          <Col span={6}>
            <CustomRadioButton
              checked={!!uom.default}
              value={uom.default}
              onClick={() => updateUOM({ default: 1 })}
              title="Default"
              readonly={isReadonlyByUserRole}
            />
          </Col>

          <Col span={uom.carton_id ? 7 : 0}>
            {uom.carton_id && (
              <CustomCheckbox
                checked={containsValue}
                onChange={() => setContainsValue(!containsValue)}
                title="Contains"
              />
            )}
          </Col>

          <Col span={4}>
            <CustomCheckbox
              checked={dimensionsValue}
              onChange={() => setDimensionsValue(!dimensionsValue)}
              title="Dimensions"
            />
          </Col>
        </Row>
        {containsValue && uom.carton_id && (
          <Row className="item-uom-contains-block" align="middle">
            <Col span={4}>
              <p>Contains</p>
            </Col>

            <Col span={9}>
              <CustomInput
                className={`${!+uom.contains ? 'error-input' : ''}`}
                type="numbers"
                value={uom.contains}
                onChange={(e) => updateUOM({ contains: e.target.value })}
                isOnlyPlaceholder
                placeholder="Count"
                readonly={isReadonlyByUserRole}
              />
            </Col>

            <Col span={4}>
              <p>of Each</p>
            </Col>
          </Row>
        )}

        {dimensionsValue && (
          <Row className="uom-inputs-container" gutter={24}>
            <Col span={6}>
              <CustomInput
                type="floats"
                value={uom.length}
                onChange={(e) => updateUOM({ length: e.target.value })}
                isOnlyPlaceholder
                placeholder={`Length/${metric ? 'CM' : 'IN'}`}
                readonly={isReadonlyByUserRole}
              />
            </Col>

            <Col span={6}>
              <CustomInput
                type="floats"
                value={uom.width}
                onChange={(e) => updateUOM({ width: e.target.value })}
                isOnlyPlaceholder
                placeholder={`Width/${metric ? 'CM' : 'IN'}`}
                readonly={isReadonlyByUserRole}
              />
            </Col>

            <Col span={6}>
              <CustomInput
                type="floats"
                value={uom.height}
                onChange={(e) => updateUOM({ height: e.target.value })}
                isOnlyPlaceholder
                placeholder={`Height/${metric ? 'CM' : 'IN'}`}
                readonly={isReadonlyByUserRole}
              />
            </Col>

            <Col span={6}>
              <CustomInput
                type="floats"
                value={uom.weight}
                onChange={(e) => updateUOM({ weight: e.target.value })}
                isOnlyPlaceholder
                placeholder={`Weight/${metric ? 'KG' : 'LB'}`}
                readonly={isReadonlyByUserRole}
              />
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
}

export default UnitOfMeasurement;
