import { Col, Row } from 'antd';
import React from 'react';

interface OutboundItemHeaderProps {
  isStoreUser?: boolean;
}

function OutboundItemHeader({ isStoreUser }: OutboundItemHeaderProps) {
  return isStoreUser ? (
    <Row className="titles">
      <Col span={7}>
        <span className="uom-title-header">UOM </span>
      </Col>
      <Col span={7}>
        <span className="uom-title-header">Quantity </span>
      </Col>
      <Col span={5}>
        <span className="uom-title-header">Contains </span>
      </Col>

      <Col span={5}>
        <span className="uom-title-header">Total </span>
      </Col>
    </Row>
  ) : (
    <Row className="titles">
      <Col span={5}>
        <span className="uom-title-header">UOM </span>
      </Col>
      <Col span={7}>
        <span className="uom-title-header">Package ID </span>
      </Col>
      <Col span={6}>
        <span className="uom-title-header">Quantity </span>
      </Col>

      <Col span={4}>
        <span className="uom-title-header">Contains </span>
      </Col>

      <Col span={2}>
        <span className="uom-title-header">Total </span>
      </Col>
    </Row>
  );
}

export default OutboundItemHeader;
