import { UserRoles } from 'constants/users/userRolesAndTypes';
import { AbilityActions, AbilitySubjects } from 'constants/usersPermissions';

export const requiredAbilitiesForStoreInfoBlock = [
  { subject: AbilitySubjects.STORES, action: AbilityActions.READ },
];

export const requiredAbilitiesForStoreStaffBlock = [
  { subject: AbilitySubjects.STORES, action: AbilityActions.READ },
];

export const allowedRolesForCreateStores = [UserRoles.SUPER_ADMIN, UserRoles.WAREHOUSE_ADMIN];

export const allowedRolesForSeeStoresPage = [UserRoles.SUPER_ADMIN, UserRoles.WAREHOUSE_ADMIN, UserRoles.STORE_ADMIN];
