import { ICouriers } from 'interfaces';

import { couriersAPI } from 'services';
import { tryCatchHandler } from 'utils';
import { CouriersAction } from './types';

export const couriersActionCreator = {
  setCouriers: (data: ICouriers[]) => ({
    type: CouriersAction.SET_COURIERS,
    payload: data,
  }),

  getCouriers: () => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const response = await couriersAPI.getCouriers();
      dispatch(couriersActionCreator.setCouriers(response));
      return true;
    });
  },
  createCourier: (courierName: {name: string}) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await couriersAPI.createCourier(courierName);

    if (data) {
      dispatch(couriersActionCreator.getCouriers());
    }
    return data;
  }),
};
