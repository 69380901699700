import axios from 'axios';
import { configs } from 'utils/getEnv';
import { auth } from '../config';
import { timeout } from '../utils';

const baseAxios = axios.create({
  baseURL: 'http://localhost:3001/',
});

const getUser = async () => {
  const { currentUser } = await auth;
  if (currentUser) return currentUser;
  await timeout(1500);

  return (await auth).currentUser;
};

baseAxios.interceptors.request.use(
  async (config) => {
    const currentUser = await getUser();

    if (!currentUser) {
      setTimeout(() => {
        if (window.location.href !== '/auth') { window.location.href = '/auth'; }
      }, 500);
      return Promise.reject(new Error('Firebase token error'));
    }

    const token = await currentUser.getIdToken();

    let headers = {};

    if (config.baseURL !== configs.REACT_APP_API_URL && process.env.REACT_APP_MODE !== 'local') {
      config.baseURL = configs.REACT_APP_API_URL;
      baseAxios.defaults.baseURL = configs.REACT_APP_API_URL;
    }

    if (!token) {
      setTimeout(() => {
        if (window.location.href !== '/auth') { window.location.href = '/auth'; }
      }, 500);
      return Promise.reject(new Error('Firebase token error'));
    }

    if (token) {
      headers = { Authorization: `Bearer ${token}` };
    }

    config.headers = headers;

    if (config.method === 'put') {
      try {
        if (config.data) {
          const newData = JSON.parse(JSON.stringify(config.data));
          // set null instead of ''
          for (const key in newData) {
            if (Object.prototype.hasOwnProperty.call(newData, key)) {
              if (newData[key] === '') newData[key] = null;
            }
          }

          config.data = newData;
        }
      } catch (axiosError) {
        console.error({ putAxiosError: axiosError });
      }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

baseAxios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (!error.response) {
      console.error({ axiosError: error });
      return Promise.reject(new Error('Axios error'));
    }
    if (error.response.status === 401) {
      setTimeout(() => {
        if (window.location.href !== '/auth') { window.location.href = '/auth'; }
      }, 500);
      return Promise.reject(new Error('Unauthorized access'));
    }
    return Promise.reject(error);
  },
);

export default baseAxios;
