import { IInventorySearchParams } from 'store/modules/inventory/reducer';
import baseAxios from './axiosInstance';

const mainPath = 'inventory';

const inventoryAPI = {
  async getItemsInInventory(per_page = 10, page = 1, search_params?: IInventorySearchParams) {
    const params = { limit: per_page, page, ...search_params };
    const { data } = await baseAxios.get(mainPath, { params });
    return data;
  },
  getItemInInventory(item_id: number) {
    return baseAxios.get(`${mainPath}/${item_id}`);
  },
  async getItemWarehouses(item_id: number) {
    const response = await baseAxios.get(`${mainPath}/${item_id}/warehouses`);
    return response;
  },
  moveItemToAnotherLocation(item_inventory_id: number, location_id = null, bin_id = null) {
    return baseAxios.patch(`${mainPath}/${item_inventory_id}/move`, { location_id, bin_id });
  },

  mergeSameItems(item_inventory_id: number) {
    return baseAxios.patch(`${mainPath}/${item_inventory_id}/merge`);
  },

  splitUOM(item_inventory_id: number, quantity: number) {
    return baseAxios.patch(`${mainPath}/${item_inventory_id}/split`, { quantity });
  },

  pickOutUOM(item_inventory_id: number, quantity: number) {
    return baseAxios.patch(`${mainPath}/${item_inventory_id}/pickout`, { quantity });
  },

  async reportInventory(item_inventory_id: number, dataTime: any) {
    return baseAxios.patch(`${mainPath}/${item_inventory_id}/report`, dataTime);
  },

};

export default inventoryAPI;
