import {
  getAbbreviationByState, isCanadaExistState, isUsaExistState,
} from 'constants/outbound/states';
import { IAxiosResponse, IPagination } from 'interfaces';
import { IWarehouse } from 'interfaces/warehouses';
import warehousesAPI from 'services/warehousesAPI';
import { notifier, tryCatchHandler } from 'utils';
import { getPagination } from 'utils/getPagination';
import { IWarehousesSearchParams } from './reducer';
import { EWarehousesAction } from './types';

export const warehousesActionCreator = {
  setWarehouses: (warehouses: IWarehouse[]) => ({
    type: EWarehousesAction.SET_WAREHOUSES,
    payload: warehouses.sort((a: IWarehouse, b: IWarehouse) => a.id - b.id),
  }),
  setPagination: (pagination: IPagination) => ({
    type: EWarehousesAction.SET_PAGINATION,
    payload: pagination,
  }),
  setSelectedWarehouse: (selectedWarehouse: IWarehouse) => ({
    type: EWarehousesAction.SET_SELECTED_WAREHOUSE,
    payload: selectedWarehouse,
  }),
  setSearchParams: (search: IWarehousesSearchParams) => ({
    type: EWarehousesAction.SET_SEARCH_PARAMS,
    payload: search,
  }),

  fetchWarehouses: (per_page?: number, page?: number, search_params?: IWarehousesSearchParams) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const { pagination, search_params: searchFromStore } = getState().warehousesReducer;

      const search = search_params || searchFromStore;
      const isSearch = !!search;

      const perPageToSearch = per_page || pagination.per_page;
      const pageToSearch = page || pagination.current_page;

      await dispatch(warehousesActionCreator.setSearchParams(search));
      const { data, meta }: IAxiosResponse<IWarehouse[]> = await warehousesAPI.getAllWarehouses(
        perPageToSearch,
        getPagination(isSearch, page, pageToSearch),
        search,
      );

      const warehouses = data?.map((warehouse: IWarehouse) => {
        const isCountryCanadaOrUSA = warehouse.country === 'Canada' || warehouse.country === 'USA';
        const isStateExistInCanadaOrUSA = isCanadaExistState(warehouse.state) || isUsaExistState(warehouse.state);
        return {
          ...warehouse,
          address: `${warehouse.country}, ${warehouse.address_1}`,
          stateColumn: isCountryCanadaOrUSA && isStateExistInCanadaOrUSA ? getAbbreviationByState(warehouse.state) : warehouse.state,
        };
      });

      dispatch(warehousesActionCreator.setWarehouses(warehouses));
      dispatch(
        warehousesActionCreator.setPagination({
          total: meta.total,
          per_page: meta.per_page,
          current_page: meta.current_page,
        }),
      );
    });
  },

  fetchWarehouse: (warehouse_id: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: IAxiosResponse<IWarehouse> = await warehousesAPI.getWarehouse(warehouse_id);

      dispatch(warehousesActionCreator.setSelectedWarehouse(data));
    });
  },

  createWarehouse: (warehouseBody: IWarehouse) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: IAxiosResponse<IWarehouse> = await warehousesAPI.createWarehouse(warehouseBody);
      const {
        pagination: { per_page, total },
      } = getState().warehousesReducer;
      const newPage = Math.ceil((total + 1) / per_page);

      await dispatch(warehousesActionCreator.fetchWarehouses(per_page, newPage));
      await dispatch(warehousesActionCreator.fetchWarehouse(data?.id));
      notifier('success', 'Success!', 'Warehouse created');
    });
  },

  updatehWarehouse: (warehouseBody: IWarehouse) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await warehousesAPI.updateWarehouse(warehouseBody);
      const {
        selectedWarehouse,
        pagination: { per_page, current_page },
      } = getState().warehousesReducer;

      await dispatch(warehousesActionCreator.fetchWarehouses(per_page, current_page));
      await dispatch(warehousesActionCreator.fetchWarehouse(selectedWarehouse?.id));

      notifier('success', 'Success!', 'Warehouse saved');
    });
  },

  addUserToWarehouse: (warehouseId: number, userId: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const response = await warehousesAPI.postUsersToWarehouse(warehouseId, userId);

    const { selectedWarehouse } = getState().warehousesReducer;
    await dispatch(warehousesActionCreator.fetchWarehouse(selectedWarehouse.id));

    return response;
  }),

  deleteUserFromWarehouse: (warehouseId: number, userId: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const response = await warehousesAPI.deleteUserFromWarehouse(warehouseId, userId);

    const { selectedWarehouse } = getState().warehousesReducer;
    await dispatch(warehousesActionCreator.fetchWarehouse(selectedWarehouse.id));

    return response;
  }),

  clearWarehouses: () => async (dispatch) => {
    dispatch(warehousesActionCreator.setWarehouses([]));
    dispatch(warehousesActionCreator.setSelectedWarehouse(null));
  },
};
