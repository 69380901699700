import { RootState } from 'App';
import { RelationTypes } from 'constants/users/userRolesAndTypes';
import { IUser } from 'interfaces';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tableModalColumns } from '../../../constants/stores/storesData';
import { rolesActionCreator, usersActionCreator } from '../../../store/action-creators';
import {
  CustomButton, CustomTable, ModalWindow, SelectWithSearch,
} from '../../globals';

import './addUserModal.scss';

interface AddUserModalProps {
  className?: string;
  isModalVisible: boolean;
  userRelationType: RelationTypes;
  addedUsersEmailsList: string[];
  addUser: (user: IUser) => void;
  setIsModalVisible: (value: boolean) => void;
}

function AddUserModal({
  setIsModalVisible,
  isModalVisible,
  className = '',
  addUser,
  userRelationType,
  addedUsersEmailsList = [],
}: AddUserModalProps) {
  const dispatch = useDispatch();

  const [searchValue, setSearchValue] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserRole, setSelectedUserRole] = useState('All roles');

  const roles = useSelector((state: RootState) => state.rolesReducer.roles);

  const rolesOptionsArray = roles.map((role) => ({
    value: role.title,
    id: role.id,
  }));

  const rolesOptions = [{ value: 'All roles', id: 0 }, ...rolesOptionsArray];

  const getRoleIdByValue = (roleValue: string) => {
    const currentRole = rolesOptions?.find((role) => role.value === roleValue);
    return currentRole ? currentRole.id : null;
  };

  // do not display already added users
  const usersData = useSelector((state: RootState) => state.usersReducer.users).filter(
    (user: IUser) => !addedUsersEmailsList.includes(user.email),
  );

  const onAddClick = async () => {
    await addUser(selectedUser);
    setIsModalVisible(false);
  };

  useEffect(() => {
    (async () => {
      if (isModalVisible) {
        await dispatch(usersActionCreator.fetchUsers(1000, 1, { role_id: null, search: '', relation_type: userRelationType }));
      }
    })();
  }, [isModalVisible]);

  useEffect(() => {
    (async () => {
      if (isModalVisible) {
        dispatch(
          usersActionCreator.fetchUsers(
            1000,
            1,
            { role_id: getRoleIdByValue(selectedUserRole) || null, search: searchValue !== '' ? searchValue : null, relation_type: userRelationType },

          ),
        );
      }
    })();
  }, [selectedUserRole, searchValue]);

  useEffect(() => {
    dispatch(rolesActionCreator.fetchRoles());
  }, []);

  return (
    <ModalWindow
      className={className}
      title="Users"
      handleCancelModal={() => setIsModalVisible(false)}
      isModalVisible={isModalVisible}
    >
      <CustomTable
        scroll={{ y: 268 }}
        className="modal-table"
        setSearchValue={setSearchValue}
        onSearch={setSearchValue}
        select={(
          <SelectWithSearch
            onSelect={(role) => {
              setSelectedUserRole(role);
            }}
            onChange={(value) => value}
            options={rolesOptions}
            value={selectedUserRole}
            isOnlyPlaceholder
            className="add-related-users-table-select"
          />
        )}
        noPagination
        columns={tableModalColumns}
        itemClick={(user: IUser) => setSelectedUser(user)}
        tableData={usersData.map((user: IUser) => ({
          ...user,
          role: user?.role_id?.title || '',
        }))}
      />

      <CustomButton className="add-item-button" disabled={!usersData.length || !selectedUser?.id} onClick={onAddClick}>
        Add +
      </CustomButton>
    </ModalWindow>
  );
}

export default React.memo(AddUserModal);
