import { colors } from 'assets/css/variables';

function LocationsIcon() {
  return (
    <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0002 9.07039C11.6365 9.07039 11.2764 9.14077 10.9404 9.27751C10.6044 9.41426 10.2992 9.61469 10.042 9.86736C9.78486 10.12 9.58088 10.42 9.44172 10.7501C9.30255 11.0803 9.23092 11.4341 9.23092 11.7914C9.23092 12.1488 9.30255 12.5026 9.44172 12.8327C9.58088 13.1629 9.78486 13.4628 10.042 13.7155C10.2992 13.9682 10.6044 14.1686 10.9404 14.3054C11.2764 14.4421 11.6365 14.5125 12.0002 14.5125C12.7346 14.5125 13.439 14.2258 13.9583 13.7155C14.4776 13.2052 14.7694 12.5131 14.7694 11.7914C14.7694 11.0698 14.4776 10.3777 13.9583 9.86736C13.439 9.35707 12.7346 9.07039 12.0002 9.07039ZM7.38477 11.7914C7.38477 10.5887 7.87103 9.43514 8.73658 8.58465C9.60213 7.73415 10.7761 7.25635 12.0002 7.25635C13.2242 7.25635 14.3982 7.73415 15.2637 8.58465C16.1293 9.43514 16.6155 10.5887 16.6155 11.7914C16.6155 12.9942 16.1293 14.1477 15.2637 14.9982C14.3982 15.8487 13.2242 16.3265 12.0002 16.3265C10.7761 16.3265 9.60213 15.8487 8.73658 14.9982C7.87103 14.1477 7.38477 12.9942 7.38477 11.7914Z"
        fill={colors.grayCardLight}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1.81404C6.28554 1.81404 1.84615 5.70817 1.84615 11.1866C1.84615 15.637 3.85723 18.889 6.17354 21.0428C7.20339 22.0055 8.37155 22.8143 9.64062 23.4434C10.7471 23.9816 11.5951 24.1872 12 24.1872C12.4062 24.1872 13.2529 23.9816 14.3594 23.4434C15.6284 22.8143 16.7966 22.0055 17.8265 21.0428C20.1428 18.889 22.1538 15.637 22.1538 11.1866C22.1538 5.70817 17.7145 1.81404 12 1.81404ZM0 11.1866C0 4.57138 5.40677 0 12 0C18.5932 0 24 4.57138 24 11.1866C24 16.2296 21.7034 19.9339 19.0966 22.3598C17.9331 23.4468 16.6131 24.3596 15.1791 25.0688C13.9778 25.6541 12.8258 26 12 26C11.1754 26 10.0222 25.6541 8.82092 25.0688C7.38686 24.3596 6.06686 23.4468 4.90339 22.3598C2.29662 19.9339 0 16.2308 0 11.1866Z"
        fill={colors.blue}
      />
    </svg>
  );
}

export default LocationsIcon;
