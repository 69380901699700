import Logo from './Logo';
import LockIcon from './LockIcon';
import EditIcon from './EditIcon';
import CopyIcon from './CopyIcon';
import PlusIcon from './PlusIcon';
import CrossIcon from './CrossIcon';
import UsersIcon from './UsersIcon';
import ItemsIcon from './ItemsIcon';
import MinusIcon from './MinusIcon';
import CloseIcon from './CloseIcon';
import OrdersIcon from './OrdersIcon';
import StoresIcon from './StoresIcon';
import Icon3PLCRM from './Icon3PLCRM';
import ReportsIcon from './ReportsIcon';
import WelcomeIcon from './WelcomeIcon';
import OutboundIcon from './OutboundIcon';
import DownLoadIcon from './DownLoadIcon';
import InventoryIcon from './InventoryIcon';
import LocationsIcon from './LocationsIcon';
import MiscItemsIcon from './MiscItemsIcon';
import ThreeDotsIcon from './ThreeDotsIcon';
import TrashIcon from './trashIcon/TrashIcon';
import OpenButtonIcon from './OpenButtonIcon';
import WarehousesIcon from './WarehousesIcon';
import SelectArrowIcon from './SelectArrowIcon';
import BinslegendaIcon from './BinslegendaIcon';
import UploadPhotoIcon from './UploadPhotoIcon';
import SearchDropdownIcon from './SearchDropdownIcon';
import SelectedBinIcon from './selectedBinIcon/SelectedBinIcon';
import IconWarningInfo from './IconWarningInfo';

export {
  Logo,
  LockIcon,
  PlusIcon,
  CopyIcon,
  EditIcon,
  MinusIcon,
  CrossIcon,
  CloseIcon,
  TrashIcon,
  ItemsIcon,
  UsersIcon,
  OrdersIcon,
  StoresIcon,
  Icon3PLCRM,
  WelcomeIcon,
  ReportsIcon,
  OutboundIcon,
  DownLoadIcon,
  InventoryIcon,
  LocationsIcon,
  MiscItemsIcon,
  ThreeDotsIcon,
  OpenButtonIcon,
  UploadPhotoIcon,
  WarehousesIcon,
  BinslegendaIcon,
  SelectedBinIcon,
  SelectArrowIcon,
  SearchDropdownIcon,
  IconWarningInfo,
};

export const sidebar = {
  users: <UsersIcon />,
  stores: <StoresIcon />,
  items: <ItemsIcon />,
  warehouses: <WarehousesIcon />,
  locations: <LocationsIcon />,
  inbound: <OrdersIcon />,
  inventory: <InventoryIcon />,
  outbound: <OutboundIcon />,
  reports: <ReportsIcon />,
};
