import moment from 'moment';
import { Document } from '@react-pdf/renderer';
import { Col, Row, Table } from 'antd';

import './StoreReportRowStyle.scss';

const fullTable = {
  columns: () => [{
    title: 'Date',
    dataIndex: 'date',
    width: '73px',
  },
  {
    title: 'Transaction Type',
    dataIndex: 'transactionType',
    width: '94px',
  },
  {
    title: 'Order number',
    dataIndex: 'id',
    width: '80px',
  },
  {
    title: 'Reference number',
    dataIndex: 'reference_number',
    width: '100px',
  },
  {
    title: () => <div className="text-center">Qty(eaches)</div>,
    dataIndex: 'qty',
    render: (text) => <div style={{ textAlign: 'end' }}>{text}</div>,
    width: '73px',
  },
  {
    title: 'Note',
    dataIndex: 'note',
    width: '117px',
  }],

  data: (data) => data.map((item) => ({
    key: item.internal_id,
    date: item.date,
    transactionType: item.transaction_type,
    id: item.order_id,
    qty: item.transaction_type === 'Outbound' ? -item.totalContains : item.totalContains,
    note: item.notes,
    reference_number: item.reference_number,
  })),
};

export function StoreReportRow({
  data,
  time,
  storeName,
}) {
  const timeRefactor = (time) => moment(time, 'MM.DD.YYYY')
    .format('MM.DD.YYYY');

  const externalInfo = (item) => (
    <span>
      {item.skuType.length ? (
        <span>
          {item.skuType}
          :
          {' '}
          <span className="item-header">{item.sku}</span>
        </span>
      ) : 'ID: Not defined' }
    </span>
  );

  return (
    <Document>
      <div className="root-store-report-row">
        <div className="header">
          Stock In/Out
          {' '}
          {storeName}
        </div>

        <Row className="time-block">
          <Col span={8} />
          <Col span={8}>
            {timeRefactor(time.pickerStart)}
            {' '}
            to
            {' '}
            {timeRefactor(time.pickerEnd)}
          </Col>
          <Col style={{ textAlign: 'end' }} span={8}>
            Date :
            {' '}
            {timeRefactor(new Date())}
          </Col>
        </Row>

        {data.map((item, index) => (
          <>
            <Row style={{ marginBottom: '15px' }}>
              <Col flex="0 0 auto">
                {externalInfo(item)}
              </Col>
              <Col flex="0 0 auto" style={{ marginLeft: '20px' }}>
                Item:
                {' '}
                <span className="item-header">
                  {item.name}
                </span>
              </Col>
            </Row>
            <div className="table-report-store-full-table">
              <Table
                pagination={false}
                columns={fullTable.columns()}
                dataSource={fullTable.data(item.orders)}
                bordered
              />
            </div>
            <Row className="count-block-from-table">
              <Col>
                Number of Events:
                {' '}
                {item.numberOfEvents}
              </Col>
              <Col span={7}>
                Total Inbound (Eaches):
                {' '}
                {item.totalUsageInbound}
              </Col>

              <Col span={7}>
                Total Outbound (Eaches):
                {' '}
                {item.totalUsageOutbound}
              </Col>
              <Col>
                Current Qty:
                {' '}
                {item.currentQty}
              </Col>
            </Row>
            {index + 1 !== data.length && <div style={{ pageBreakBefore: 'always' }} />}
          </>
        ))}
      </div>
    </Document>
  );
}
