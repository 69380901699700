import React, { useEffect, useMemo, useState } from 'react';
import { Pagination, Table } from 'antd';

import Search from 'antd/lib/input/Search';
import { useSelector } from 'react-redux';
import { RootState } from 'App';
import { useWindowDimensions } from 'utils';
import { CrossIcon, SearchDropdownIcon } from 'assets/images';
import { CustomCheckbox } from '../checkbox';

import './commonTable.scss';
import './tableSearch.scss';
import './tablePagination.scss';

const selectOptionValues = [10, 20, 50, 100];

function CustomTable({
  tableData,
  columns,
  pagination,
  getNewItems,
  scroll,
  select = '',
  onClickSelect = () => {},
  noSearch,
  filterCheckbox = {
    checkboxTitle: '',
    checkboxValue: null,
    changeAction: null,
    disabled: false,
  },
  className,
  noPagination,
  selectedItem = null,
  setSearchValue,
  onSearch,
  handleTableChange,
  itemClick = () => {},
}: any) {
  const isLoading = useSelector((state: RootState) => state.generalReducer.isLoading);

  const [countOfDocuments, setCountOfDocuments] = useState(columns.length);
  const [tableColumnsWithWidth, setTableColumnsWithWidth] = useState([]);
  const [valueToSearch, setValueToSearch] = useState('');
  const { height } = useWindowDimensions();

  useEffect(() => {
    const columnsWidthSum = columns.reduce((acc: any, value: any) => acc + (value.width || 0), 0);
    const columnthWithoutWidthLength = columns.filter((c: any) => !c.width).length;

    setTableColumnsWithWidth(
      columns.map((c: any) => ({
        ...c,
        width: `${c.width || (100 - columnsWidthSum) / columnthWithoutWidthLength}%`,
      })),
    );
  }, [columns, columns.length]);

  const itemRender = (current: any, type: any, originalElement: any) => {
    if (type === 'prev') {
      return <button>Prev</button>;
    }
    if (type === 'next') {
      return <button>Next</button>;
    }
    return originalElement;
  };

  const handlePaginationChange = (page: any, per_page: any) => {
    getNewItems(page, per_page);
  };

  const removeAllStyles = () => {
    const [table] = Array.from(document.getElementsByClassName(className));
    const trs = table.getElementsByClassName('ant-table-row');
    Array.from(trs).forEach((x) => x.classList.remove('active-row'));
  };

  const handleClickItem = (e: any, item: any) => {
    itemClick(item);

    removeAllStyles();
    e.target.parentElement.classList.add('active-row');
  };

  const renderRemoveSearchValueButton = useMemo(() => {
    if (valueToSearch?.length) {
      return (
        <div
          className="remove-search-value-cross"
          onClick={() => {
            setValueToSearch('');
            setSearchValue('');
          }}
        >
          <CrossIcon />
        </div>
      );
    }
  }, [valueToSearch]);

  useEffect(() => {
    setCountOfDocuments(tableData.length);
  }, [tableData.length]);

  useEffect(() => {
    // this is logic to add styles to active row in table(after click)
    removeAllStyles();
    if (selectedItem) {
      const trs = document.getElementsByClassName('ant-table-row');

      const indexOfSelectedItem = tableData.findIndex((row: any) => row.id === selectedItem.id);

      if (indexOfSelectedItem !== -1) {
        const row = Array.from(trs)[indexOfSelectedItem];

        row.classList.add('active-row');
      }
    }
  }, [selectedItem]);

  useEffect(() => {
    if (isLoading <= 0) {
      const c: HTMLInputElement = document.querySelector('.ant-select-selection-item');

      if (c.innerText.includes('/')) {
        c.innerText = c.innerText.slice(0, c.innerText.length - 7);
      }
    }
    if (noPagination) {
      removeAllStyles();
    }
  }, [isLoading]);

  return (
    <div className={`common-table-wrapper ${className}`}>
      <div className="table-select-search-wrapper">
        <div
          className={`${select ? 'select-block' : 'ds-none'}`}
          onClick={onClickSelect}
        >
          {select}

        </div>

        <div className="table-search">
          {!noSearch && (
            <div className="search-wrapper">
              <Search
                placeholder="Search"
                value={valueToSearch}
                onChange={(e) => setValueToSearch(e.target.value)}
                onSearch={onSearch}
                style={{ width: '90%' }}
              />

              <div className="search-dropdown-block">
                <SearchDropdownIcon />
              </div>
              {renderRemoveSearchValueButton}
            </div>
          )}
        </div>
        {!!filterCheckbox.checkboxTitle.length && (
          <div className="table-filter-checkbox-wrapper">
            <CustomCheckbox
              title={filterCheckbox.checkboxTitle}
              checked={filterCheckbox.checkboxValue}
              onChange={filterCheckbox.changeAction}
              disabled={filterCheckbox.disabled}
            />
          </div>
        )}
      </div>

      <div className="table-pagination-wrapper">
        <div className="common-table">
          <Table
            scroll={scroll || { y: `${height - 377}px` }}
            onRow={(record) => ({
              onClick: (e) => {
                handleClickItem(e, record);
              },
            })}
            columns={tableColumnsWithWidth}
            dataSource={tableData.map((value, index) => ({
              ...value,
              key: index,
            }))}
            onChange={handleTableChange}
            pagination={false}
          />
        </div>
        {!noPagination && (
          <div className="table-pagination">
            <div className="pagination-select-info">
              <span>Show</span>

              <span>Entries</span>
            </div>

            <p className="amount-of-entries">
              {countOfDocuments}
              {' '}
              of
              {' '}
              {pagination?.total}
              {' '}
              entries
            </p>

            <Pagination
              showSizeChanger
              onChange={handlePaginationChange}
              pageSizeOptions={selectOptionValues}
              pageSize={pagination?.per_page}
              current={pagination?.current_page}
              total={pagination?.total}
              itemRender={itemRender}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomTable;
