import { FirebaseOptions, initializeApp } from 'firebase/app';
import { Auth, getAuth } from 'firebase/auth';
import { timeout } from '../utils/timeout';
import { configs } from '../utils/getEnv';

const getAuthFirebase:()=>Promise<Auth> = async () => {
  await timeout(1500);

  const firebaseConfig: FirebaseOptions = {
    apiKey: configs.REACT_APP_FIREBASE_API_KEY || 'AIzaSyCgchZsaqqTXxjD3rVB15j6ooqIDiNz6U4',
    projectId: configs.REACT_APP_FIREBASE_PROJECT_ID || 'pl-crm-test',
  };
  const app = await initializeApp(firebaseConfig);

  const auth = getAuth(app);
  return auth;
};
export const auth = getAuthFirebase();
