import { Redirect, Route } from 'react-router-dom';

function PrivateRoute({
  children,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={({
        location,
      }: any) => (true
        ? children
        : (
          <Redirect to={{ pathname: '/auth', state: { from: location } }} />
        ))}
    />
  );
}

export default PrivateRoute;
