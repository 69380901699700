import { Col, Row } from 'antd';
import { DownLoadIcon, LockIcon, TrashIcon } from 'assets/images';
import { CustomButton } from 'components/globals';
import { IDocument } from 'interfaces/documents';
import { useTranslation } from 'react-i18next';
import { convertTime } from 'utils';
import CustomPopover from '../customPopover/CustomPopover';

import './documentStyle.scss';

interface DocumentProps {
  document: IDocument;
  documentId: number;
  isDisabled?: boolean;
  isRemoveDisabled: boolean;
  isRemoveClicked: boolean;
  setDocumentId: (id: number) => void;
  setIsRemoveClicked: (value: boolean) => void;
  handleRemoveDocument: (documentID: number) => void;
}

function Document({
  document,
  documentId,
  isDisabled = false,
  isRemoveClicked,
  isRemoveDisabled,
  setDocumentId,
  setIsRemoveClicked,
  handleRemoveDocument,
}: DocumentProps) {
  const { t } = useTranslation();

  const handleTrashClick = () => {
    if (isDisabled) {
      setIsRemoveClicked(true);
      setDocumentId(document?.id);
    }
  };

  const mathSizeFile = (x) => {
    const units = ['bytes', 'KB', 'MB'];
    let l = 0; let
      n = parseInt(x, 10) || 0;

    while (n >= 1024 && ++l) {
      n /= 1024;
    }

    return (`${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`);
  };

  return isRemoveClicked && documentId === document?.id ? (
    <div className="removing-doc-confirm-block">
      <span className="delete-doc-text">
        <span>{`${t('common.uploadFile.deleteDocument')}`}</span>
        {' '}
        {`"${document.name}" ?`}
      </span>
      <CustomButton className="yes-btn" onClick={() => handleRemoveDocument(document?.id)}>
        {t('common.yes')}
      </CustomButton>
      <CustomButton className="no-btn" onClick={() => setIsRemoveClicked(false)}>
        {t('common.no')}
      </CustomButton>
    </div>
  ) : (
    <div className="document-wrapper">
      <Row>
        <Col className="doc-name-wrap" span={19}>
          <p className="doc-name">
            {document.name}
            <span className="doc-size">
              {mathSizeFile(document.size)}
            </span>
            {!document?.is_manually ? (
              <CustomPopover content={t('common.uploadFile.generatedBySystem')}>
                <span className="lock-icon">
                  <LockIcon />
                </span>
              </CustomPopover>
            ) : null}
          </p>
          <span className="date-of-doc">{convertTime(document.created_at)}</span>
        </Col>
        <Col className="doc-btns-wrap" span={5}>
          <a href={document.download_url} download className="download-doc-btn">
            <DownLoadIcon />
          </a>
          {document?.is_manually ? (
            <div className="remove-doc-btn" onClick={!isDisabled || isRemoveDisabled ? null : handleTrashClick}>
              <TrashIcon isDisabled={!isDisabled || isRemoveDisabled} />
            </div>
          ) : null}
        </Col>
      </Row>
    </div>
  );
}

export default Document;
