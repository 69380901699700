/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
import React, { useMemo, useState } from 'react';
import { CustomButton } from 'components/globals';
import { ISelectedOrder, IUser } from 'interfaces';
import { useDispatch } from 'react-redux';
import { InboundOrderStatus } from 'constants/orders/status';
import { useTranslation } from 'react-i18next';
import { ordersActionCreator } from 'store/action-creators';
import { Col } from 'antd';
import CustomPopover from 'components/globals/customPopover/CustomPopover';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { sortBy } from 'lodash';
import {
  allowedRolesForAcceptInboundOrder,
  allowedRolesForAcknowledgeInboundOrder,
  allowedRolesForDispatchInboundOrder,
} from 'constants/orders/pageAbilities';
import { userRole } from 'constants/users/userRolesAndTypes';
import { ConfirmationModal } from 'components/confirmationModal';
import ItemWithUOM from '../itemWithUOM/ItemWithUOM';

import './inboundItems.scss';

interface InboundItemsProps {
  user: IUser;
  selectedOrder: ISelectedOrder;
  openModal: () => void;
  selectedOrderItems: any;
  removeItem: (itemId: number) => void;
  setNewValueOrderItem: (id: number, value: any) => void;
}

function InboundItems({
  user,
  selectedOrder,
  openModal,
  selectedOrderItems,
  removeItem,
  setNewValueOrderItem,
}: InboundItemsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({ action: null, text: '' });

  const confirmClick = () => {
    dispatch(ordersActionCreator.confirmInboundOrder(selectedOrder?.id));
  };

  const unconfirmClick = () => {
    dispatch(ordersActionCreator.unconfirmInboundOrder(selectedOrder?.id));
  };

  const renderButtons = (status: InboundOrderStatus) => {
    let firstButtonData = {
      isImportant: false,
      className: '',
      onClick: () => {},
      disabled: false,
      text: '',
    };

    let secondButtonData = {
      isImportant: false,
      className: '',
      onClick: () => {},
      disabled: false,
      text: '',
    };

    switch (status) {
      case InboundOrderStatus.Created: {
        firstButtonData = {
          ...firstButtonData,
          onClick: confirmClick,
          disabled:
            !selectedOrderItems?.length
            || !selectedOrderItems?.every((orderItem) => orderItem?.expected_measurement_units?.length),
          text: t('orders.confirm'),
        };

        secondButtonData = {
          ...secondButtonData,
          onClick: openModal,
          disabled: !selectedOrder?.id || selectedOrder?.status === 'Closed',
          text: t('orders.addItem'),
        };
        break;
      }
      case InboundOrderStatus.OnGate: {
        firstButtonData = {
          ...firstButtonData,
          className: 'display-none',
        };

        isAllowedForUserRole(allowedRolesForAcknowledgeInboundOrder, userRole[user.role_id as unknown as number])
          ? (secondButtonData = {
            ...secondButtonData,
            onClick: openModal,
            disabled: !selectedOrder?.id || selectedOrder?.status === 'Closed',
            text: t('orders.addItem'),
          })
          : (secondButtonData = {
            ...secondButtonData,
            className: 'display-none',
          });
        break;
      }
      case InboundOrderStatus.Confirmed: {
        firstButtonData = {
          ...firstButtonData,
          onClick: unconfirmClick,
          text: t('orders.unconfirm'),
        };

        isAllowedForUserRole(allowedRolesForAcknowledgeInboundOrder, userRole[user.role_id as unknown as number])
          ? (secondButtonData = {
            ...secondButtonData,
            isImportant: true,
            onClick: () => {
              setIsModalVisible(true);
              setConfirmationModalData({
                action: () => dispatch(ordersActionCreator.acknowledgeInboundOrder(selectedOrder?.id)),
                text: t('orders.confirmCorrectItemsAndQuantity'),
              });
            },
            disabled: !selectedOrder?.expected,
            text: t('orders.acknowledge'),
          })
          : (secondButtonData = {
            ...secondButtonData,
            disabled: true,
            text: t('orders.yourOrderIsProcessing'),
            className: 'processing-order-text',
          });

        break;
      }
      case InboundOrderStatus.Ready: {
        firstButtonData = {
          ...firstButtonData,
          className: 'display-none',
        };
        {
          isAllowedForUserRole(allowedRolesForDispatchInboundOrder, userRole[user.role_id as unknown as number])
            ? (secondButtonData = {
              ...secondButtonData,
              isImportant: true,
              onClick: () => {
                setIsModalVisible(true);
                setConfirmationModalData({
                  action: () => dispatch(ordersActionCreator.dispatchInboundOrder(selectedOrder?.id)),
                  text: t('orders.confirmItemsDispatched'),
                });
              },
              text: t('orders.dispatch'),
            })
            : (secondButtonData = { ...secondButtonData, className: 'display-none' });
        }
        secondButtonData = {
          ...secondButtonData,
          isImportant: true,
          onClick: () => {
            setIsModalVisible(true);
            setConfirmationModalData({
              action: () => dispatch(ordersActionCreator.dispatchInboundOrder(selectedOrder?.id)),
              text: t('orders.confirmItemsDispatched'),
            });
          },
          text: t('orders.dispatch'),
        };
        break;
      }
      case InboundOrderStatus.Dispatched: {
        firstButtonData = {
          ...firstButtonData,
          className: 'display-none',
        };

        secondButtonData = {
          ...secondButtonData,
          isImportant: true,
          onClick: () => {
            setIsModalVisible(true);
            setConfirmationModalData({
              action: () => dispatch(ordersActionCreator.acceptInboundOrder(selectedOrder?.id)),
              text: t('orders.confirmItemsDelivered'),
            });
          },
          text: t('orders.accepting'),
          className: isAllowedForUserRole(
            allowedRolesForAcceptInboundOrder,
            userRole[user.role_id as unknown as number],
          )
            ? ''
            : 'display-none',
        };
        break;
      }
      case InboundOrderStatus.Arrived: {
        firstButtonData = {
          ...firstButtonData,
          className: 'display-none',
        };

        secondButtonData = {
          ...secondButtonData,
          className: 'display-none',
          onClick: openModal,
          disabled: !selectedOrder?.id || selectedOrder?.status === 'Closed',
          text: t('orders.addItem'),
        };
        break;
      }
      case InboundOrderStatus.PartiallyArrived: {
        firstButtonData = {
          ...firstButtonData,
          className: 'display-none',
        };

        secondButtonData = {
          ...secondButtonData,
          className: 'display-none',
          onClick: openModal,
          disabled: !selectedOrder?.id || selectedOrder?.status === 'Closed',
          text: t('orders.addItem'),
        };
        break;
      }
      default:
        break;
    }
    return (
      <Col className="buttons-layout pos-relative" span={24}>
        <CustomButton
          disabled={firstButtonData.disabled}
          onClick={firstButtonData.onClick}
          className={firstButtonData.className}
          isImportant={firstButtonData.isImportant}
        >
          {firstButtonData.text}
        </CustomButton>

        {selectedOrder?.status === InboundOrderStatus.Confirmed
        && !selectedOrder?.expected
        && isAllowedForUserRole(allowedRolesForAcknowledgeInboundOrder, userRole[user.role_id as unknown as number]) ? (
          <CustomPopover
            className="inbound-acknowledge-popover"
            trigger="click"
            content={<p className="acknowledge-error-text">Expected date is required</p>}
          >
            <div className="outbound-error-message-circle">i</div>
          </CustomPopover>
          ) : null}
        <CustomButton
          disabled={secondButtonData.disabled}
          onClick={secondButtonData.onClick}
          className={secondButtonData.className}
          isImportant={secondButtonData.isImportant}
        >
          {secondButtonData.text}
        </CustomButton>
      </Col>
    );
  };

  const renderButtonsByOrderStatus = useMemo(() => {
    let result = null;
    if (selectedOrder) {
      switch (selectedOrder.status) {
        case InboundOrderStatus.Created: {
          result = renderButtons(InboundOrderStatus.Created);
          break;
        }
        case InboundOrderStatus.OnGate: {
          result = renderButtons(InboundOrderStatus.OnGate);
          break;
        }
        case InboundOrderStatus.Confirmed: {
          result = renderButtons(InboundOrderStatus.Confirmed);
          break;
        }
        case InboundOrderStatus.Ready: {
          result = renderButtons(InboundOrderStatus.Ready);
          break;
        }
        case InboundOrderStatus.Dispatched: {
          result = renderButtons(InboundOrderStatus.Dispatched);
          break;
        }
        case InboundOrderStatus.Arrived: {
          result = renderButtons(InboundOrderStatus.Arrived);
          break;
        }
        case InboundOrderStatus.PartiallyArrived: {
          result = renderButtons(InboundOrderStatus.PartiallyArrived);
          break;
        }
        default: {
          result = null;
        }
      }
      return result;
    }
  }, [selectedOrder, selectedOrderItems]);

  return (
    <div className="inbound-items-wrapper">
      {renderButtonsByOrderStatus}

      {selectedOrderItems
        .map((orderItem, index: number) => (
          <ItemWithUOM
            orderItem={orderItem}
            removeItem={removeItem}
            key={index}
            setNewValueOrderItem={(value) => setNewValueOrderItem(index, value)}
          />
        ))}
      <ConfirmationModal
        isModalVisible={isModalVisible}
        closeCallback={setIsModalVisible}
        confirmAction={confirmationModalData.action}
        confirmText={confirmationModalData.text}
      />
    </div>
  );
}

export default InboundItems;
