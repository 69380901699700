import React, { useEffect, useMemo, useState } from 'react';

// helpers
import { RootState } from 'App';
import { IConsignee } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { countryList } from 'constants/users/counrties';
import { canadaStates, usStates } from 'constants/outbound/states';
import { outboundOrdersActionCreator } from 'store/modules/outbound/action-creators';

// components
import {
  Col, Form, Radio, Row,
} from 'antd';
import {
  CustomButton, CustomInput, CustomSelect, CustomTextArea, SelectWithSearch,
} from 'components/globals';
import CustomRadioButton from 'components/globals/customRadioButton/CustomRadioButton';

import './consigneeBlock.scss';
import { OrderStatus } from 'constants/orders/status';

function mappedConsignee(consignee: IConsignee = null) {
  return {
    is_commercial: consignee?.is_commercial,
    name: '',
    email: consignee?.email,
    address: consignee?.address,
    city: consignee?.city,
    phone: consignee?.phone,
    state: consignee?.state,
    country: consignee?.country,
    postal_code: consignee?.postal_code,
    note: consignee?.note,
  };
}

function ConsigneeBlock() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    selectedOrder: { consignee_id, id: selectedOrderId, status },
    consignees,
  } = useSelector((state: RootState) => state.outboundOrdersReducer);

  const isReadOnly = status === OrderStatus.Dispatched || status === OrderStatus.Terminated;

  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [company, setCompany] = useState('');
  const [isComercial, setIsComercial] = useState(1);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [isInputsDisabled, setIsInputsDisabled] = useState(null);
  const [isCountryUpdated, setIsCountryUpdated] = useState(false);
  const [isCompanySelected, setIsCompanySelected] = useState(consignee_id);

  const usStatesOptions = usStates.map((state, index) => ({ value: state.name, id: index }));
  const countryOptions = countryList.map((country, index) => ({ value: country, id: index }));
  const canadaStatesOptions = canadaStates.map((state, index) => ({ value: state.name, id: index }));
  const companyArray = consignees
    .filter((consignee) => consignee.is_commercial) // Only commercial consignees has company name
    .map((consignee, index) => ({ value: consignee.company, id: index }));

  const companyOptions = Array.from(new Map(companyArray.map((item) => [item.value, item])).values());

  const initialValues = useMemo(() => {
    const result = {
      company: consignee_id?.company && !!isComercial ? consignee_id?.company : '',
      name: consignee_id?.name || '',
      email: consignee_id?.email || '',
      address: consignee_id?.address || '',
      city: consignee_id?.city || '',
      phone: consignee_id?.phone || '',
      state: consignee_id?.state ? consignee_id?.state : '',
      country: consignee_id?.country ? consignee_id?.country : '',
      postal_code: consignee_id?.postal_code || '',
      note: consignee_id?.note || '',
      is_commercial: consignee_id?.isCommercial || 0,
    };
    setIsInputsDisabled(false);
    return result;
  }, [consignee_id]);

  const save = async (body: IConsignee) => {
    if (consignee_id?.id && isComercial) {
      await dispatch(
        outboundOrdersActionCreator.updateConsignee(consignee_id?.id, { ...body, is_commercial: isComercial, state }),
      );
    } else {
      await dispatch(
        outboundOrdersActionCreator.createConsignee(selectedOrderId, {
          ...body,
          is_commercial: isComercial,
          company: isComercial ? company : '',
        }),
      );
    }
    setSaveDisabled(true);
    setIsCompanySelected(true);
  };

  const handleSelectCompany = (e) => {
    setCompany(e);
    const findedConsignee = consignees.find((consignee) => consignee.company === e);
    if (findedConsignee) {
      const result = mappedConsignee(findedConsignee);
      form.setFieldsValue({ ...initialValues, ...result, company: findedConsignee.company });
    }
    setSaveDisabled(false);
    setIsCompanySelected(true);
    setIsInputsDisabled(true);
  };

  const handleSelectState = (e) => {
    setState(e);
    form.setFieldsValue({ state: e });
  };

  const handleChangeState = (e) => {
    setState(e.target.value);
    form.setFieldsValue({ state: e.target.value });
  };

  const handleChangeIsCommercial = (e) => {
    setIsComercial(e.target.value);
    setIsInputsDisabled(false);

    const consigneeData = mappedConsignee();
    form.setFieldsValue({ ...initialValues, ...consigneeData, company: '' });
  };

  //! DEPENDECIES
  //! -------------------------------------------------------------------------------------------------------------------------------------------------------
  //! 1) Case when changing value equals to an existed company then ----> need to inizialiating all data.                                                   |
  //! 2) Case when changing value doesn't equals to an existed company then ----> clear all data besides company: in case when company was chosen already.  |
  //! 3) Case when we create a new consignee and we write a new not existed company we doensn't need to clear all data.                                     |
  //! 1. First case --> we need to disable all data besides company.                                                                                       |
  //! 2. Second case --> we need to undisable all data.                                                                                                    |
  //! -------------------------------------------------------------------------------------------------------------------------------------------------------
  //! DEPENDECIES

  const handleChangeCompany = (companyValue: string) => {
    const findedConsigneeByCompany = consignees.find((consignee: IConsignee) => consignee.company === companyValue);
    if (findedConsigneeByCompany) {
      setIsInputsDisabled(true);
      setIsCompanySelected(true);

      form.setFieldsValue({ ...initialValues, ...findedConsigneeByCompany, company: companyValue });
    } else if (!findedConsigneeByCompany) {
      if (isCompanySelected) {
        setIsInputsDisabled(false);
        setIsCompanySelected(false);
        const consigneeData = mappedConsignee();
        form.setFieldsValue({ ...initialValues, ...consigneeData, company: companyValue });
      }
    }

    setCompany(companyValue);
  };

  useEffect(() => {
    if (!consignee_id) {
      setState('');
      setCountry('');
      setCompany('');
      form.setFieldsValue({
        ...initialValues,
        state: '',
        country: '',
        company: '',
      });
      setIsComercial(1);
      setIsCompanySelected(false);
    } else {
      setIsComercial(consignee_id.is_commercial);
      setState(consignee_id.state);
      setCountry(consignee_id.country);
      setCompany(consignee_id.company);

      form.setFieldsValue({
        ...initialValues,
        state: consignee_id?.state,
        country: consignee_id?.country,
        company: consignee_id?.company,
      });
      setIsCompanySelected(true);
    }
  }, [consignee_id, initialValues, form]);

  useEffect(() => {
    form.setFieldsValue({ company });
  }, [company]);

  useEffect(() => {
    if (!isCompanySelected) {
      const result = mappedConsignee();
      form.setFieldsValue({ ...initialValues, ...result, company });
    }
  }, [isCompanySelected]);

  useEffect(() => {
    setSaveDisabled(true);
    setIsComercial(consignee_id?.is_commercial);
    form.setFieldsValue({
      ...initialValues,
      is_commercial: consignee_id?.is_commercial,
    });
  }, [selectedOrderId]);

  useEffect(() => {
    setIsCountryUpdated(false);
  }, [isCountryUpdated]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    dispatch(outboundOrdersActionCreator.fetchAllConsignees());
  }, []);

  return (
    <div className="out-order-consinee-wrapper">
      <Form
        form={form}
        onFinish={save}
        initialValues={initialValues}
        onFieldsChange={() => {
          setSaveDisabled(false);
        }}
      >
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Row>
              <Radio.Group className="radio-group-block" onChange={handleChangeIsCommercial} value={isComercial}>
                <Col span={7}>
                  <CustomRadioButton disabled={isReadOnly} value={1} title={t('orders.consignee.commercial')} />
                </Col>
                <Col span={7}>
                  <CustomRadioButton disabled={isReadOnly} value={0} title={t('orders.consignee.residence')} />
                </Col>
              </Radio.Group>
            </Row>
          </Col>

          <Col span={12} style={{ display: isComercial ? 'block' : 'none' }}>
            <Form.Item
              name="company"
              rules={[
                {
                  required: !!isComercial,
                  message: `${t('orders.consignee.company')} is required`,
                },
                {
                  min: 3,
                  message: `${t('orders.consignee.company')} should be at least 3 signs`,
                },
              ]}
            >
              <SelectWithSearch
                withInput
                readonly={isReadOnly}
                value={company}
                onChange={handleChangeCompany}
                onSelect={handleSelectCompany}
                options={companyOptions}
                required={!!isComercial}
                isClose={saveDisabled}
                placeholder={t('orders.consignee.company')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t('orders.consignee.name')} is required`,
                },
                {
                  min: 3,
                  message: `${t('orders.consignee.name')} should be at least 3 signs`,
                },
              ]}
            >
              <CustomInput
                readonly={isReadOnly}
                required
                placeholder={`${t('orders.consignee.name')}`}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="address"
              rules={[
                {
                  required: true,
                  message: `${t('orders.consignee.address')} is required`,
                },
                {
                  min: 3,
                  message: `${t('orders.consignee.address')} should be at least 3 signs`,
                },
              ]}
            >
              <CustomInput
                disabled={isInputsDisabled}
                readonly={isReadOnly}
                required
                placeholder={t('orders.consignee.address')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: `${t('orders.consignee.city')} is required`,
                },
                {
                  min: 3,
                  message: `${t('orders.consignee.city')} should be at least 3 signs`,
                },
              ]}
            >
              <CustomInput
                disabled={isInputsDisabled}
                readonly={isReadOnly}
                required
                placeholder={t('orders.consignee.city')}
              />
            </Form.Item>
          </Col>

          <Col span={12}>

            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: `${t('orders.consignee.country')} is required`,
                },
              ]}
            >
              <SelectWithSearch
                disabled={isInputsDisabled}
                readonly={isReadOnly}
                onSelect={(e) => {
                  setCountry(e);
                  setState('');
                  form.setFieldsValue({ state: '' });
                }}
                onChange={(country) => {
                  if (!country.length) {
                    setIsCountryUpdated(true);
                    form.setFieldsValue({ state: '' });
                  }
                  setCountry(country);
                  form.setFieldsValue({ state: '' });
                }}
                options={countryOptions}
                required
                placeholder={t('orders.consignee.country')}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="postal_code"
              normalize={(input) => input.toUpperCase()}
              rules={[
                {
                  required: true,
                  message: `${t('orders.consignee.postal')} is required`,
                },
                {
                  min: 3,
                  message: `${t('orders.consignee.postal')} should be at least 3 signs`,
                },
              ]}
            >
              <CustomInput
                disabled={isInputsDisabled}
                readonly={isReadOnly}
                required
                placeholder={t('orders.consignee.postal')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="state"
              rules={[
                {
                  required: country === 'Canada' || country === 'USA',
                  message: `${t('orders.consignee.state')} is required`,
                },
              ]}
            >
              {country === 'Canada' || country === 'USA' ? (
                <SelectWithSearch
                  disabled={isInputsDisabled}
                  readonly={isReadOnly}
                  value={state}
                  onSelect={handleSelectState}
                  options={country === 'Canada' ? canadaStatesOptions : usStatesOptions}
                  required
                  placeholder={t('orders.consignee.state')}
                />
              ) : (
                <CustomInput
                  disabled={isInputsDisabled}
                  readonly={isReadOnly}
                  value={state}
                  onChange={handleChangeState}
                  required={country === 'Canada' || country === 'USA'}
                  placeholder={t('orders.consignee.state')}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  min: 8,
                  message: `${t('orders.consignee.phone')} should be at least 8 signs`,
                },
              ]}
            >
              <CustomInput
                disabled={isInputsDisabled}
                readonly={isReadOnly}
                type="phones"
                placeholder={t('orders.consignee.phone')}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  type: 'email',
                  message: 'Should be a valid email',
                },
              ]}
            >
              <CustomInput
                disabled={isInputsDisabled}
                readonly={isReadOnly}
                placeholder={t('orders.consignee.email')}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="note">
              <CustomTextArea readonly={isReadOnly} placeholder="Note" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item>
              <CustomButton disabled={saveDisabled || isReadOnly} className="consignee-save-button" htmlType="submit">
                Save
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default ConsigneeBlock;
