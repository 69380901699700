import { colors } from 'assets/css/variables';

function IconWarningInfo() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="14" height="14" rx="7" fill="white" stroke={colors.error} />
      <path
        d="M7.51164 4.76C7.30497 4.76 7.13164 4.69 6.99164 4.55C6.85164 4.41 6.78164 4.23667 6.78164 4.03C6.78164 3.82333 6.85164 3.65 6.99164 3.51C7.13164 3.37 7.30497 3.3 7.51164 3.3C7.71164 3.3 7.88164 3.37 8.02164 3.51C8.16164 3.65 8.23164 3.82333 8.23164 4.03C8.23164 4.23667 8.16164 4.41 8.02164 4.55C7.88164 4.69 7.71164 4.76 7.51164 4.76ZM8.07164 5.49V11H6.93164V5.49H8.07164Z"
        fill={colors.darkText}
      />
    </svg>
  );
}

export default IconWarningInfo;
