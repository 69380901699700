import { IWarehousesSearchParams } from 'store/modules/warehouses/reducer';
import baseAxios from './axiosInstance';

const mainPath = 'warehouse';
const warehouseUserPath = 'warehouseuser';

const warehousesAPI = {
  async getAllWarehouses(per_page = 10, page = 1, search_params: IWarehousesSearchParams) {
    const params = { limit: per_page, page, ...search_params };
    const { data } = await baseAxios.get(mainPath, { params });
    return data;
  },
  async getWarehouse(warehouse_id: number) {
    const response = await baseAxios.get(`${mainPath}/${warehouse_id}`);
    return response;
  },
  createWarehouse(warehouse: any) {
    return baseAxios.post(mainPath, warehouse);
  },
  updateWarehouse(warehouse: any) {
    return baseAxios.put(`${mainPath}/${warehouse.id}`, warehouse);
  },
  postUsersToWarehouse(warehouseId: number, userId: number) {
    return baseAxios.post(`${warehouseUserPath}/${warehouseId}`, { userId });
  },

  deleteUserFromWarehouse(warehouseId: number, userId: number) {
    return baseAxios.patch(`${warehouseUserPath}/${warehouseId}`, { userId });
  },
};

export default warehousesAPI;
