import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App';
import { IUser } from 'interfaces';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { requiredAbilitiesForUserInfoBlock } from '../../constants/users/pageAbilities';
import { rolesActionCreator, usersActionCreator } from '../../store/action-creators';
import { MainWrapper, RightWrapper, RightCard } from '../../components/layout';
import UserInfoBlock from '../../components/views/users/userInfo/UserInfoBlock';
import { CustomTable, SelectWithSearch } from '../../components/globals';
import { columns } from '../../constants/users/usersData';
import { infoBlockModes } from '../../constants/infoBlockModes';
import { getEditCloseIcons } from '../../components/layout/rightCard';

import './Users.scss';

function Users() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { users, selectedUser, pagination } = useSelector((state: RootState) => state.usersReducer);
  const roles = useSelector((state: RootState) => state.rolesReducer.roles);

  const [searchValue, setSearchValue] = useState(null);
  const [mode, setMode] = useState(infoBlockModes.VIEW);
  const [isRightBlockOpen, setIsRightBlockOpen] = useState(false);
  const [selectedUserRole, setSelectedUserRole] = useState('All roles');

  const rolesOptionsArray = roles.map((role) => ({
    value: role.title,
    id: role.id,
  }));

  const rolesOptions = [{ value: 'All roles', id: 0 }, ...rolesOptionsArray];

  const getRoleIdByValue = (roleValue: string) => {
    const currentRole = rolesOptions?.find((role) => role.value === roleValue);
    return currentRole ? currentRole.id : null;
  };

  const getIcon = () => {
    if (mode === infoBlockModes.CREATE) return '';

    return getEditCloseIcons(
      mode,
      () => setMode(infoBlockModes.EDIT),
      () => {
        setMode(infoBlockModes.VIEW);
        dispatch(usersActionCreator.fetchUser(selectedUser?.id));
      },
    );
  };

  const newUser = async () => {
    const user = {
      email: '',
      name: '',
      role_id: null,
      relation_type: null,
      active: true,
      password: '',
      password_confirmation: '',
      avatar: null,
    };

    dispatch(usersActionCreator.setSelectedUser(user));
    setMode(infoBlockModes.CREATE);
    setIsRightBlockOpen(true);
  };

  const selectUser = async (userId: number) => {
    await dispatch(usersActionCreator.fetchUser(userId));
    setIsRightBlockOpen(true);
    setMode(infoBlockModes.VIEW);
  };

  useEffect(() => {
    dispatch(
      usersActionCreator.fetchUsers(null, null, {
        role_id: getRoleIdByValue(selectedUserRole) || null,
        search: searchValue !== '' ? searchValue : null,
      }),
    );
  }, [selectedUserRole, searchValue]);

  useEffect(() => {
    (async () => {
      dispatch(usersActionCreator.fetchUsers());
      dispatch(rolesActionCreator.fetchRoles());
    })();

    return () => {
      dispatch(usersActionCreator.clearUsers());
    };
  }, []);

  return (
    <>
      <MainWrapper title={t('sidebar.users')} iconAction={newUser} isFullScreen={!isRightBlockOpen}>
        <CustomTable
          className="orders"
          pagination={pagination}
          columns={columns}
          itemClick={(user: IUser) => selectUser(user.id)}
          selectedItem={selectedUser}
          setSearchValue={setSearchValue}
          onSearch={setSearchValue}
          select={(
            <SelectWithSearch
              onSelect={(role) => {
                setSelectedUserRole(role);
              }}
              onChange={(value) => value}
              options={rolesOptions}
              value={selectedUserRole}
              isOnlyPlaceholder
              className="table-select"
            />
          )}
          tableData={users.map((user: IUser) => ({
            ...user,
            role: user?.role_id?.title || '',
          }))}
          getNewItems={(page: number, per_page: number) => dispatch(
            usersActionCreator.fetchUsers(per_page, page, {
              role_id: getRoleIdByValue(selectedUserRole) || null,
              search: searchValue !== '' ? searchValue : null,
            }),
          )}
        />
      </MainWrapper>

      {isRightBlockOpen && (
        <RightWrapper
          title={selectedUser?.id ? `${selectedUser?.name}` : t('users.newUser')}
          onCancelClick={() => {
            setIsRightBlockOpen(false);
          }}
        >
          <RightCard title={t('users.userInformation')} icon={getIcon()}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForUserInfoBlock}>
              <UserInfoBlock className="user-info-wrapper" mode={mode} setMode={setMode} />
            </WithAuthWrapper>
          </RightCard>
        </RightWrapper>
      )}
    </>
  );
}

export default Users;
