export const tableColumns = [{
  title: 'Name',
  dataIndex: 'company_name',
},
{
  title: 'Country',
  dataIndex: 'country',
}, {
  title: 'Email',
  dataIndex: 'email',
},
];

export const tableModalColumns = [{
  title: 'Name',
  dataIndex: 'name',
},
{
  title: 'Email',
  dataIndex: 'email',
},
{
  title: 'User role',
  dataIndex: 'role',
},
];
