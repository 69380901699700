export const EInventoryAction = {
  SET_ALL_ITEMS: 'SET_ALL_ITEMS',
  SET_SELECTED_ITEM: 'SET_SELECTED_ITEM',
  SET_ITEM_WAREHOUSES: 'SET_ITEM_WAREHOUSES',
  SET_PAGINATION: 'SET_PAGINATION_INVENTORY',
  SET_REPORT_ITEM: 'SET_REPORT_ITEM',
  SET_SEARCH_PARAMS: 'SET_SEARCH_PARAMS',
  SET_EXTERNAL_IDS: 'SET_EXTERNAL_IDS',
  ADD_EXTERNAL_IDS: 'ADD_EXTERNAL_IDS',
};
