import React from 'react';

function LockIcon() {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.63636 4.79393V3.17067C8.65589 2.34838 8.28424 1.55311 7.60275 0.958895C6.92125 0.364679 5.98541 0.0198986 5 0C4.01459 0.0198986 3.07875 0.364679 2.39725 0.958895C1.71576 1.55311 1.34411 2.34838 1.36364 3.17067V4.79393H1C0.447715 4.79393 0 5.24165 0 5.79393V11.2415C0 11.4426 0.0957789 11.6356 0.266266 11.7778C0.436754 11.9201 0.667985 12 0.909091 12H9.09091C9.33202 12 9.56325 11.9201 9.73373 11.7778C9.90422 11.6356 10 11.4426 10 11.2415V5.79393C10 5.24165 9.55229 4.79393 9 4.79393H8.63636ZM5.45455 8.67383V9.7244H4.54545V8.6397C4.32416 8.54469 4.14845 8.3894 4.04748 8.1996C3.94651 8.0098 3.92635 7.7969 3.99035 7.59623C4.05436 7.39556 4.19867 7.21918 4.39934 7.09636C4.60002 6.97354 4.84499 6.91166 5.09361 6.92099C5.34224 6.93033 5.57956 7.01031 5.7662 7.14768C5.95284 7.28504 6.07759 7.47152 6.11973 7.67618C6.16187 7.88084 6.11888 8.09137 5.99789 8.27283C5.87689 8.4543 5.68518 8.59579 5.45455 8.67383ZM7.72727 4.79393H2.27273V3.17067C2.25313 2.54952 2.52898 1.94715 3.04001 1.49517C3.55103 1.0432 4.25568 0.778362 5 0.758534C5.74432 0.778362 6.44897 1.0432 6.95999 1.49517C7.47102 1.94715 7.74687 2.54952 7.72727 3.17067V4.79393Z" fill="#ACBCC6" />
    </svg>

  );
}

export default LockIcon;
