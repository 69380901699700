import { useTranslation } from 'react-i18next';
import { CustomButton, ModalWindow } from 'components/globals';

import './confirmationModal.scss';

interface ConfirmationModalProps {
    confirmAction: any;
    confirmText: string;
    isModalVisible: boolean;
    closeCallback: (value: boolean) => void;
  }

function ConfirmationModal({
  confirmAction, isModalVisible, confirmText, closeCallback,
}: ConfirmationModalProps) {
  const { t } = useTranslation();

  return (
    <ModalWindow
      className="custom-confirmatiom-modal"
      title={t('orders.confirmation')}
      handleCancelModal={() => closeCallback(false)}
      isModalVisible={isModalVisible}
    >
      <p className="confirmation-text">{confirmText}</p>

      <div className="d-flex">
        <CustomButton
          className="confirm-button"
          isImportant
          onClick={() => {
            confirmAction();
            closeCallback(false);
          }}
        >
          {t('orders.confirm')}
        </CustomButton>
        <CustomButton className="cancel-button" onClick={() => closeCallback(false)}>
          {t('orders.cancel')}
        </CustomButton>
      </div>
    </ModalWindow>
  );
}

export default ConfirmationModal;
