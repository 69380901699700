import { colors } from 'assets/css/variables';

function CopyIcon() {
  return (

    <div className="copy-icon-container">
      <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.8">
          <path
            d="M11.4855 14.8486C11.4855 14.5459 11.2401 14.3005 10.9374 14.3005V14.3005C10.6348 14.3005 10.3894 14.5468 10.3894 14.8495V14.8495C10.3894 15.1533 10.1431 15.4005 9.83937 15.4005H2.34615C1.79386 15.4005 1.34615 14.9528 1.34615 14.4005V4.67347C1.34615 4.37078 1.59153 4.1254 1.89422 4.1254V4.1254C2.19692 4.1254 2.4423 3.87906 2.4423 3.57636V3.57636C2.4423 3.2726 2.19605 3.02539 1.89229 3.02539H1.25C0.697716 3.02539 0.25 3.47311 0.25 4.02539V15.5005C0.25 16.0528 0.697715 16.5005 1.25 16.5005H10.4855C11.0378 16.5005 11.4855 16.0528 11.4855 15.5005V14.8486Z"
            fill={colors.darkText}
          />
          <path
            d="M3.7886 0.75C3.23631 0.75 2.7886 1.19772 2.7886 1.75V12.9501C2.7886 13.5024 3.23631 13.9501 3.7886 13.9501H12.7501C13.3024 13.9501 13.7501 13.5024 13.7501 12.9501V5.88542C13.7501 5.62082 13.6452 5.36699 13.4584 5.17956L9.33752 1.04414C9.14989 0.855841 8.895 0.75 8.62917 0.75H3.7886ZM12.6539 11.8501C12.6539 12.4024 12.2062 12.8501 11.6539 12.8501H4.88474C4.33246 12.8501 3.88475 12.4024 3.88475 11.8501V2.85001C3.88475 2.29773 4.33246 1.85001 4.88475 1.85001H6.44723C6.99951 1.85001 7.44723 2.29772 7.44723 2.85001V5.07506C7.44723 6.17963 8.34266 7.07506 9.44723 7.07506H11.6539C12.2062 7.07506 12.6539 7.52277 12.6539 8.07506V11.8501ZM12.6539 5.94165C12.6539 5.96009 12.639 5.97505 12.6205 5.97505H10.5434C9.4388 5.97505 8.54337 5.07962 8.54337 3.97505V1.88322C8.54337 1.86488 8.55824 1.85001 8.57658 1.85001V1.85001C8.58541 1.85001 8.59387 1.85352 8.60011 1.85978L12.6442 5.91807C12.6504 5.92433 12.6539 5.93281 12.6539 5.94165V5.94165Z"
            fill={colors.darkText}
          />
        </g>
      </svg>
    </div>
  );
}

export default CopyIcon;
