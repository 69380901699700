export const OutboundOrdersAction = {
  SET_OUTBOUND_ORDERS: 'SET_OUTBOUND_ORDERS',
  SET_SELECTED_OUTBOUND_ORDER: 'SET_SELECTED_OUTBOUND_ORDER',
  SET_OUTBOUND_ITEMS: 'SET_OUTBOUND_ITEMS',
  SET_OUTBOUND_PAGINATION: 'SET_OUTBOUND_PAGINATION',
  SET_OUTBOUND_ORDER_ITEMS: 'SET_OUTBOUND_ORDER_ITEMS',
  SET_OUTBOUND_ITEM_OPTIONS: 'SET_OUTBOUND_ITEM_OPTIONS',
  SET_OUTBOUND_DOCUMENTS: 'SET_OUTBOUND_DOCUMENTS',
  SET_CONSIGNEES: 'SET_CONSIGNEES',
  SET_UOMS_DATA: 'SET_UOMS_DATA',
  SET_SEARCH_PARAMS: 'SET_SEARCH_PARAMS',
};
