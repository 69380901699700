import { ITerminateReason } from 'interfaces';
import { IInboundSearchParams } from 'store/modules/inbound/reducer';
import baseAxios from './axiosInstance';

const mainPathInbound = 'inb-orders';

const inboundOrderAPI = {
  async getAllOrders(per_page = 10, page = 1, search_params?: IInboundSearchParams) {
    const params = { limit: per_page, page, ...search_params };
    const { data } = await baseAxios.get(mainPathInbound, { params });
    return data;
  },
  async getOrder(order_id: any) {
    const { data } = await baseAxios.get(`${mainPathInbound}/${order_id}`);
    return data;
  },

  async getConditions() {
    return baseAxios.get(`${mainPathInbound}/conditions`);
  },

  createOrder(body: any) {
    return baseAxios.post(`${mainPathInbound}`, body);
  },

  createMeasurementUnit(order_id: any, uomBody: any) {
    return baseAxios.post(`${mainPathInbound}/${order_id}/uom`, uomBody);
  },

  createInboundMeasurementUnit(order_id: number, uomBody: any) {
    return baseAxios.post(`${mainPathInbound}/${order_id}/expected/uom`, uomBody);
  },

  updateMeasurementUnit(order_id: any, uomBody: any) {
    return baseAxios.put(`${mainPathInbound}/${order_id}/uom`, uomBody);
  },

  updateOrder(order_id: any, body: any) {
    return baseAxios.put(`${mainPathInbound}/${order_id}`, body);
  },

  saveUOMofItem(order_id: any, item_id: any, measurement_units: any) {
    return baseAxios.patch(`${mainPathInbound}/${order_id}/order-items`, {
      item_id,
      packages: measurement_units,
    });
  },

  receiveMeasurementUnit(measurement_unit_id: number) {
    return baseAxios.patch(`${mainPathInbound}/order-items/${measurement_unit_id}/receive`);
  },
  splitMeasurementUnit(measurement_unit_id: number, quantityToSplit: any, currentQuantity: any) {
    return baseAxios.patch(`${mainPathInbound}/order-items/${measurement_unit_id}/split`, {
      quantity: quantityToSplit,
      currentQuantity,
    });
  },
  receiveOrder(order_id: number) {
    return baseAxios.put(`${mainPathInbound}/${order_id}/receive`);
  },
  deleteMeasurementUnit(measurement_unit_id: number) {
    return baseAxios.delete(`${mainPathInbound}/order-items/${measurement_unit_id}`);
  },

  mergeMeasurementUnit(measurement_unit_id: number) {
    return baseAxios.patch(`${mainPathInbound}/order-items/${measurement_unit_id}/merge`);
  },
  confirmInboundOrder(orderId: number) {
    return baseAxios.patch(`${mainPathInbound}/${orderId}/confirm`);
  },
  unconfirmInboundOrder(orderId: number) {
    return baseAxios.patch(`${mainPathInbound}/${orderId}/unconfirm`);
  },
  acknowledgeInboundOrder(orderId: number) {
    return baseAxios.patch(`${mainPathInbound}/${orderId}/acknowledge`);
  },
  dispatchInboundOrder(orderId: number) {
    return baseAxios.patch(`${mainPathInbound}/${orderId}/dispatch`);
  },
  acceptInboundOrder(orderId: number) {
    return baseAxios.patch(`${mainPathInbound}/${orderId}/accept`);
  },
  terminateInboundOrder(orderId: number, reasonData: ITerminateReason) {
    return baseAxios.patch(`${mainPathInbound}/${orderId}/terminate`, reasonData);
  },
};

export default inboundOrderAPI;
