import React, { useEffect, useRef, useState } from 'react';
import { Input } from 'antd';
import OutsideClick from '../../../utils/outsideClick';
import CustomPlaceholder from '../customPlaceholder/CustomPlaceholder';

import './customTextAreaStyle.scss';

function CustomTextArea({
  placeholder,
  value,
  disabled,
  required,
  isOnlyPlaceholder = false,
  className = '',
  readonly = false,
  defaultValue,
  onChange,
}: any) {
  const { TextArea } = Input;
  const [isActive, setIsActive] = useState(!!value);
  const placeholderRef = useRef(null);
  const textAreaBlockRef = useRef(null);
  const setFocus = () => {
    if (placeholderRef?.current) placeholderRef.current.focus();
  };

  const outsideClicked = OutsideClick(textAreaBlockRef, () => setIsActive(false));

  useEffect(() => {
    if (isActive && outsideClicked && readonly) {
      setIsActive(false);
    }
  }, [outsideClicked, isActive]);

  useEffect(() => {
    if (textAreaBlockRef && textAreaBlockRef.current && textAreaBlockRef.current.children[1]?.value) {
      textAreaBlockRef.current.click();
    }
  }, []);

  return (
    <div
      ref={textAreaBlockRef}
      className={`text-area-block ${readonly ? 'readonly-text-area-container' : ''} ${className}`}
      onClick={() => setIsActive(true)}
    >
      <CustomPlaceholder
        isOnlyPlaceholder={isOnlyPlaceholder}
        isActive={isActive}
        readonly={readonly}
        value={value || textAreaBlockRef.current?.children[1]?.value}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        setIsActive={setIsActive}
        setFocus={setFocus}
      />

      <TextArea disabled={disabled || readonly} onChange={onChange} defaultValue={defaultValue} value={value} />
    </div>
  );
}

export default CustomTextArea;
