import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import reducers from './reducers';

export default function configureStore() {
  const rootReducer = combineReducers(reducers);
  const store = createStore(rootReducer, applyMiddleware(thunk));

  return store;
}
