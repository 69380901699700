import { UserRoles } from 'constants/users/userRolesAndTypes';
import { AbilityActions, AbilitySubjects } from 'constants/usersPermissions';

export const requiredAbilitiesForRackInfoBlock = [
  { subject: AbilitySubjects.LOCATIONS, action: AbilityActions.READ },
];

export const requiredAbilitiesForLocationInfoBlock = [
  { subject: AbilitySubjects.LOCATIONS, action: AbilityActions.READ },
];

export const requiredAbilitiesForLevelInfoBlock = [
  { subject: AbilitySubjects.LOCATIONS, action: AbilityActions.READ },
];

export const requiredAbilitiesForColumnInfoBlock = [
  { subject: AbilitySubjects.LOCATIONS, action: AbilityActions.READ },
];

export const requiredAbilitiesForBinsBlock = [
  { subject: AbilitySubjects.LOCATIONS, action: AbilityActions.READ },
];

export const allowedRolesForCreateNewRack = [UserRoles.WAREHOUSE_ADMIN, UserRoles.SUPER_ADMIN];

export const allowedRolesForUpateOrRemoveAllLocations = [UserRoles.WAREHOUSE_ADMIN, UserRoles.SUPER_ADMIN];

export const allowedRolesForUpdateOrRemoveRacks = [UserRoles.WAREHOUSE_ADMIN, UserRoles.SUPER_ADMIN];

export const allowedRolesForUpdateLocationInfo = [UserRoles.WAREHOUSE_ADMIN, UserRoles.SUPER_ADMIN];

export const allowedRolesForUpdateLevelInfo = [UserRoles.WAREHOUSE_ADMIN, UserRoles.SUPER_ADMIN];

export const allowedRolesForUpdateColumnInfo = [UserRoles.WAREHOUSE_ADMIN, UserRoles.SUPER_ADMIN];

export const allowedRolesForUpdateOrRemoveBins = [UserRoles.WAREHOUSE_ADMIN, UserRoles.SUPER_ADMIN];

export const allowedRolesForUpdateColumnsAndLevelsInRack = [UserRoles.WAREHOUSE_ADMIN, UserRoles.SUPER_ADMIN];
