import { useSelector } from 'react-redux';
import { ReportType } from '../constants/reportType';
import { inventoryActionCreator } from '../store/modules/inventory/action-creators';
import { storesActionCreator } from '../store/action-creators';
import { RootState } from '../App';

// Created this helper for easy add future reports
const fetchReportState = (type: ReportType, selectedId, pickerStartRef, pickerEndRef, dispatch) => {
  switch (type) {
    case ReportType.INVENTORY:
      dispatch(inventoryActionCreator.fetchReport(selectedId, pickerStartRef, pickerEndRef));
      break;
    case ReportType.STOCK:
    case ReportType.STORE:
      dispatch(storesActionCreator.fetchReport(selectedId, pickerStartRef, pickerEndRef, type));
      break;
    default:
      return null;
  }
};

const getStateFromSelector = (type: ReportType) => {
  switch (type) {
    case ReportType.INVENTORY:
      return useSelector((state:RootState) => state.inventoryReducer.reportItem);
    case ReportType.STORE:
      return useSelector((state:RootState) => state.storesReducer.reportStore);
    case ReportType.STOCK:
      return useSelector((state:RootState) => state.storesReducer.reportStock);
    default:
      return null;
  }
};

const clearReportState = (type: ReportType, dispatch) => {
  switch (type) {
    case ReportType.INVENTORY:
      dispatch(inventoryActionCreator.clearReportInventory());
      break;
    case ReportType.STORE:
      dispatch(storesActionCreator.clearReportStore());
      break;
    case ReportType.STOCK:
      dispatch(storesActionCreator.clearReportStock());
      break;
    default:
      return null;
  }
};

export { fetchReportState, getStateFromSelector, clearReportState };
