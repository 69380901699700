import { CustomButton, ModalWindow } from '../../../globals';
import './unsavedDataModalStyles.scss';

interface UnsavedDataModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  onSaveClick: any;
  cancelClick: any;
}

function UnsavedDataModal({
  setIsModalVisible, isModalVisible, onSaveClick, cancelClick,
}: UnsavedDataModalProps) {
  return (
    <ModalWindow
      className="unsaved-data-modal"
      title="Items"
      handleCancelModal={() => setIsModalVisible(false)}
      isModalVisible={isModalVisible}
    >
      <p>There are unsaved data in the order </p>

      <div>
        <div className="buttons-container">
          <CustomButton className="save-btn" onClick={() => onSaveClick()}>
            Save and proceed
          </CustomButton>

          <CustomButton
            className="cancel-btn"
            onClick={() => {
              setIsModalVisible(false);
              cancelClick();
            }}
          >
            Continue without save
          </CustomButton>
        </div>
      </div>
    </ModalWindow>
  );
}

export default UnsavedDataModal;
