import { IInventoryItem, IPagination, IReduxAction } from 'interfaces';
import { EInventoryAction } from './types';

export interface IInventorySearchParams {
  store_id?: number | null;
  search?: string;
}
interface IInventoryState {
  allItems: IInventoryItem[];
  selectedItem: IInventoryItem;
  reportItem: any;
  itemWarehouses: any;
  pagination: IPagination;
  search_params: IInventorySearchParams;
  external_ids: any;
}

const initialState: IInventoryState = {
  allItems: [],
  selectedItem: null,
  reportItem: null,
  itemWarehouses: null,
  pagination: {
    current_page: 1,
    per_page: 10,
  },
  search_params: { store_id: null, search: '' },
  external_ids: {},
};

export default function inventoryReducer(state: IInventoryState = initialState, action: IReduxAction) {
  switch (action.type) {
    case EInventoryAction.SET_ALL_ITEMS:
      return { ...state, allItems: action.payload };
    case EInventoryAction.SET_SELECTED_ITEM:
      return { ...state, selectedItem: action.payload };
    case EInventoryAction.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case EInventoryAction.SET_REPORT_ITEM:
      return { ...state, reportItem: action.payload };
    case EInventoryAction.SET_ITEM_WAREHOUSES:
      return { ...state, itemWarehouses: action.payload };
    case EInventoryAction.SET_SEARCH_PARAMS:
      return { ...state, search_params: action.payload };
    case EInventoryAction.SET_EXTERNAL_IDS:
      return {
        ...state,
        external_ids: {
          ...state.external_ids,
          [action.payload.item_id]: action.payload.external_ids,
        },
      };
    case EInventoryAction.ADD_EXTERNAL_IDS:
      return {
        ...state,
        external_ids: {
          ...action.payload.external_ids,
        },
      };
    default:
      return state;
  }
}
