import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { inventoryActionCreator, itemsActionCreator } from 'store/action-creators';
import {
  IExternal, IExternalIdType, IItem, IUser,
} from 'interfaces';
import CustomPopover from 'components/globals/customPopover/CustomPopover';
import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import { allowedRolesForUpdateItemsPage } from 'constants/items/pageAbilities';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { RootState } from 'App';
import { CustomButton } from '../../../globals';
import ExternalID from './externalId/ExternalID';

import './externalIDs.scss';

interface ExternalIDsBlockProps {
  externalIDTypes: IExternalIdType[];
  types: any;
  externalIdsArray: IExternal[];
  selectedItem: IItem;
  user: any;
}

function ExternalIDsBlock({
  types, externalIDTypes, externalIdsArray, selectedItem, user,
}: ExternalIDsBlockProps) {
  const dispatch = useDispatch();

  const [extIDsData, setExtIDsData] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [isSameTypes, setIsSameTypes] = useState(false);
  const [extIdTypeOptions, setExtIdTypeOptions] = useState(types);
  const externalIdsParams = useSelector((state: RootState) => state.inventoryReducer.external_ids);

  const isExtIdFieldsReadonly = !isAllowedForUserRole(allowedRolesForUpdateItemsPage, userRole[user.role_id]);

  const handleSubmit = () => {
    dispatch(itemsActionCreator.addExternalIDs(extIDsData));

    delete externalIdsParams[selectedItem?.id];
    dispatch(inventoryActionCreator.addExternalIds(externalIdsParams));
  };

  const AddNewExternalId = () => {
    setExtIDsData((prevState: any[]) => [
      ...prevState,
      {
        default: extIDsData.length ? 0 : 1,
        external_id_type_id: types[0]?.value,
        external_id_value: '',
        isFail: true,
        item_id: selectedItem?.id,
      },
    ]);
  };

  useEffect(() => {
    const mappedExternalIdsArray = externalIdsArray?.map((extID: any) => {
      if (extID.hasOwnProperty('isFail')) {
        extID.isFail = true;
      }
      return extID;
    });
    setExtIdTypeOptions(types);

    setExtIDsData(mappedExternalIdsArray);
    setIsSaveDisabled(true);
  }, [selectedItem, types]);

  useEffect(() => {
    const idTypes = extIDsData?.map((id) => id.external_id_type_id);

    setIsSameTypes(idTypes?.length !== new Set<number>(idTypes).size);
    setIsSaveDisabled(extIDsData.some((id) => id.isFail) || isSameTypes);
  }, [extIDsData]);

  return (
    <div className="external-ids-block-wrapper">
      {extIDsData?.length ? (
        extIDsData.map((extID: IExternal, index: number) => (
          <ExternalID
            isExtIdFieldsReadonly={isExtIdFieldsReadonly}
            setExtIDsData={(newData: IExternal[]) => setExtIDsData(() => [...newData])}
            key={index}
            extIDsData={extIDsData}
            extID={extID}
            externalIdIndex={index}
            setIsSaveDisabled={setIsSaveDisabled}
            externalIDTypes={externalIDTypes}
            types={extIdTypeOptions}
          />
        ))
      ) : (
        <div className="no-external-ids-block"> No External IDs</div>
      )}
      <div className="add-save-buttons-wrapper">
        {isAllowedForUserRole(allowedRolesForUpdateItemsPage, userRole[user.role_id]) && (
        <CustomButton className="add-ext-id-btn" onClick={AddNewExternalId} disabled={!selectedItem?.id}>
          Add +
        </CustomButton>
        )}
        {extIDsData?.length && isAllowedForUserRole(allowedRolesForUpdateItemsPage, userRole[user.role_id]) ? (
          <div className="pos-relative">
            {isSameTypes ? (
              <CustomPopover
                className="save-ext-id-popover"
                content={<span className="error-message">ID type shoud be unique!</span>}
                trigger="click"
              >
                <div className="outbound-error-message-circle">i</div>
              </CustomPopover>
            ) : null}

            <CustomButton disabled={isSaveDisabled || isSameTypes} onClick={handleSubmit}>
              Save
            </CustomButton>

          </div>
        ) : null}

      </div>
    </div>
  );
}

export default ExternalIDsBlock;
