export const EOrdersAction = {
  SET_ORDERS: 'SET_ORDERS',
  SET_SELECTED_ORDER: 'SET_SELECTED_ORDER',
  SET_ORDER_ITEMS: 'SET_ORDER_ITEMS',

  SET_CONDITIONS: 'SET_CONDITIONS',
  SET_MEASUREMENT_UNITS_OPTIONS: 'SET_MEASUREMENT_UNITS_OPTIONS',

  SET_PAGINATION: 'SET_PAGINATION_ORDERS',
  SET_SEARCH_PARAMS: 'SET_SEARCH_PARAMS',
};
