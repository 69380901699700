import { colors } from 'assets/css/variables';

function ThreeDotsIcon({ isActive = false }) {
  return (
    <svg cursor="pointer" width="4" height="19" viewBox="0 0 4 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.875 3.75C2.91053 3.75 3.75 2.91053 3.75 1.875C3.75 0.839466 2.91053 0 1.875 0C0.839466 0 0 0.839466 0 1.875C0 2.91053 0.839466 3.75 1.875 3.75Z"
        fill={isActive ? colors.blue : colors.darkText}
      />
      <path
        d="M1.875 11.25C2.91053 11.25 3.75 10.4105 3.75 9.375C3.75 8.33947 2.91053 7.5 1.875 7.5C0.839466 7.5 0 8.33947 0 9.375C0 10.4105 0.839466 11.25 1.875 11.25Z"
        fill={isActive ? colors.blue : colors.darkText}
      />
      <path
        d="M1.875 18.75C2.91053 18.75 3.75 17.9105 3.75 16.875C3.75 15.8395 2.91053 15 1.875 15C0.839466 15 0 15.8395 0 16.875C0 17.9105 0.839466 18.75 1.875 18.75Z"
        fill={isActive ? colors.blue : colors.darkText}
      />
    </svg>
  );
}

export default ThreeDotsIcon;
