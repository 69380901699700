import { Col, Row, Select } from 'antd';
import { CustomInput, CustomSelect } from 'components/globals';
import { IOutboundUOM } from 'interfaces';
import { IconWarningInfo } from 'assets/images';
import CustomPopover from 'components/globals/customPopover/CustomPopover';
import { OptionModel, OptionTextType } from '../OutboundItem';

import './createdItem.scss';

const { Option } = Select;
interface СreatedItemComponentProps {
  uomOptions: OptionModel[];
  packageIdOptions: OptionModel[];
  uomOption: string;
  uom: IOutboundUOM;
  quantity: number;
  isStoreUser?: boolean;
  handleSelectOption: (option: OptionTextType) => void;
  handleChangeQuantity: (quantity: number) => void;
  handleSelectPackageId: (packageId: string) => void;
}

function СreatedItemComponent({
  uomOptions,
  uomOption,
  uom,
  quantity,
  isStoreUser,
  packageIdOptions,
  handleSelectOption,
  handleChangeQuantity,
  handleSelectPackageId,
}: СreatedItemComponentProps) {
  return isStoreUser ? (
    <div>
      <Row className="created-item-wrapper" style={{ display: 'flex', alignItems: 'end' }}>
        <Col span={7}>
          <CustomSelect
            onSelect={(option: OptionTextType) => handleSelectOption(option)}
            options={uomOptions}
            value={uomOption}
            className="uom-select"
          />
        </Col>
        <Col className="center" span={7} style={{ height: '30px' }}>
          {uom?.carton_id && !uom.master ? (
            <CustomSelect
              options={packageIdOptions}
              onSelect={(value: string) => handleSelectPackageId(value)}
              value={uom?.package_id}
              withCustomOptions
              className="uom-package-id-select"
            >
              {packageIdOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  <div className="package-id-select-option">
                    <div>
                      Contains:
                      {' '}
                      {option?.contains}
                    </div>
                  </div>
                </Option>
              ))}
            </CustomSelect>
          ) : (
            uom?.package_id
          )}
        </Col>
        <Col className="max-quantity-wrapper" span={5}>
          <CustomInput
            value={quantity}
            onChange={handleChangeQuantity}
            withoutZero
            type="numbers"
            className={`uom-input ${quantity > uom?.maxQuantity || !quantity ? 'error-input' : ''}`}
          />

          <div className="max-quantity-info">
            {uom?.damaged > 0 && (
              <div className="uom-in-stock-custom-component_popover">
                <CustomPopover
                  className="uom-in-stock-popover"
                  content={<div className="text-sm-10">Some of uoms are damaged</div>}
                  trigger="hover"
                >
                  <div style={{ display: 'flex' }}>
                    <IconWarningInfo />
                  </div>
                </CustomPopover>
              </div>
            )}
            <span>
              Max.
              <div className="quantity-count">{uom?.maxQuantity}</div>
            </span>
          </div>
        </Col>
        <Col span={5}style={{ height: '30px' }}>{uom?.contains ? +uom.contains * quantity : quantity}</Col>
      </Row>
    </div>
  ) : (
    <div>
      <Row className="created-item-wrapper" style={{ display: 'flex', alignItems: 'end' }}>
        <Col span={5}>
          <CustomSelect
            onSelect={(option: OptionTextType) => handleSelectOption(option)}
            options={uomOptions}
            value={uomOption}
            className="uom-select"
          />
        </Col>
        <Col className="center" span={7} style={{ height: '30px' }}>
          {uom?.carton_id && !uom.master ? (
            <CustomSelect
              options={packageIdOptions}
              onSelect={(value: string) => handleSelectPackageId(value)}
              value={uom?.package_id}
              withCustomOptions
              className="uom-package-id-select"
            >
              {packageIdOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  <div className="package-id-select-option">
                    <div>{option?.value}</div>
                    <div>
                      Contains:
                      {option?.contains}
                    </div>
                  </div>
                </Option>
              ))}
            </CustomSelect>
          ) : (
            uom?.package_id
          )}
        </Col>
        <Col className="max-quantity-wrapper" span={6}>
          <CustomInput
            value={quantity}
            onChange={handleChangeQuantity}
            withoutZero
            type="numbers"
            className={`uom-input ${quantity > uom?.maxQuantity || !quantity ? 'error-input' : ''}`}
          />

          <div className="max-quantity-info">
            {uom?.damaged > 0 && (
              <div className="uom-in-stock-custom-component_popover">
                <CustomPopover
                  className="uom-in-stock-popover"
                  content={<div className="text-sm-10">Some of uoms are damaged</div>}
                  trigger="hover"
                >
                  <div style={{ display: 'flex' }}>
                    <IconWarningInfo />
                  </div>
                </CustomPopover>
              </div>
            )}
            <span>
              Max.
              <div className="quantity-count">{uom?.maxQuantity}</div>
            </span>
          </div>
        </Col>
        <Col span={4} style={{ height: '30px' }}>{uom?.contains}</Col>
        <Col span={2} style={{ height: '30px' }}>{uom?.contains ? +uom.contains * quantity : quantity}</Col>
      </Row>
    </div>
  );
}

export default СreatedItemComponent;
