export const orderStatuses = {
  1: 'Created',
  2: 'Ready',
  3: 'Terminated',
  4: 'Change request',
  5: 'Shipped',
  6: 'Arrived',
  7: 'Partially arrived',
  8: 'Closed',
  9: 'On gate',
  10: 'Claimed',
  11: 'Ready to pick',
  12: 'Picked',
  13: 'Dispatched',
  14: 'Confirmed',
};

export const printInboundForStatusOrder = ['Ready', 'Dispatched', 'Confirmed', 'Arrived', 'Closed'];

export enum OrderStatus {
  'Created' = 'Created',
  'Claimed' = 'Claimed',
  'ReadyToPick' = 'Ready to pick',
  'Picked' = 'Picked',
  'Terminated' = 'Terminated',
  'Dispatched' = 'Dispatched',
  'Closed' = 'Closed',
  'OnGate' = 'On gate',
}

export enum InboundOrderStatus{
  'OnGate' = 'On gate',
  'Created' = 'Created',
  'Confirmed' = 'Confirmed',
  'Ready' = 'Ready',
  'Dispatched' = 'Dispatched',
  'Arrived' = 'Arrived',
  'PartiallyArrived' = 'Partially arrived',
  'Closed' = 'Closed',
  'Terminated' = 'Terminated',
}

export const orderSourse = {
  1: 'Internal',
  2: 'External',
};

export const orderTypes = {
  1: 'On Gate',
  2: 'Outbound',
  3: 'Inbound',
};

export enum orderType {
  ON_GATE = 'On Gate',
  OUTBOUND = 'Outbound',
  INBOUND = 'Inbound',
}

export enum orderTypeNumbers {
  ON_GATE = 1,
  OUTBOUND = 2,
  INBOUND = 3,
}
