import { useEffect, useState } from 'react';
import { Document, UploadFile } from 'components/globals';

interface OrdersDocumentsBlockProps {
  isDocumentsDisabled?: boolean;
  selected: any;
  removeDocument: any,
  uploadDocument: any,
  isRemoveDisabled?: any;
}

function UploadDocument({
  selected,
  isRemoveDisabled,
  removeDocument,
  uploadDocument,
  isDocumentsDisabled = true,
}: OrdersDocumentsBlockProps) {
  const [documentId, setDocumentId] = useState(null);
  const [isRemoveClicked, setIsRemoveClicked] = useState(false);

  const getFileToUpload = (file: FormData) => {
    uploadDocument(selected.id, file);
  };

  const handleRemoveDocument = (docId: number) => {
    removeDocument(selected.id, docId);
    setDocumentId(null);
    setIsRemoveClicked(false);
  };

  useEffect(() => {
    setIsRemoveClicked(false);
  }, [selected]);

  return (
    <div className="upload-documents-wrapper">
      <UploadFile handleUploadFile={getFileToUpload} disabled={isDocumentsDisabled} />
      {selected?.documents?.map((doc) => (
        <Document
          setDocumentId={(id) => setDocumentId(id)}
          documentId={documentId}
          handleRemoveDocument={handleRemoveDocument}
          document={doc}
          isDisabled={isDocumentsDisabled}
          isRemoveDisabled={isRemoveDisabled?.(doc)}
          isRemoveClicked={isRemoveClicked}
          setIsRemoveClicked={setIsRemoveClicked}
        />
      ))}
    </div>
  );
}

export default UploadDocument;
