import React from 'react';
import { Col } from 'antd';
import { ItemInventoryStatuses } from 'constants/items/itemsData';
import CustomPopover from 'components/globals/customPopover/CustomPopover';

import './warehouseUom.scss';

interface WarehouseUomProps {
  uom: any;
}

function WarehouseUom({ uom }: WarehouseUomProps) {
  const contentPopoverStatus = () => (
    <div className="custom-popover-content-status">
      <div className="d-flex">
        <div className="title">Staus</div>
        <div className="title-info">{ItemInventoryStatuses[uom?.status]}</div>
      </div>

      <div className="d-flex">
        <div className="title">Condit.</div>
        <div className="title-info">{uom?.condition}</div>
      </div>
    </div>
  );
  return (
    <div className="warehouse-uom-block">
      <Col span={7} className="d-flex mar-bot-7">
        <CustomPopover className="warehouse-uom-statuses-popover" content={contentPopoverStatus}>
          <div className={`popover-circle ${ItemInventoryStatuses[uom?.status]}`}>i</div>
        </CustomPopover>
        {uom.title}
      </Col>
      <Col span={6}>{uom.quantity}</Col>
      <Col span={5}>{uom.contains}</Col>
      <Col span={6}>{uom.contains ? uom.quantity * uom.contains : uom.quantity}</Col>
    </div>
  );
}

export default WarehouseUom;
