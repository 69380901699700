import { colors } from 'assets/css/variables';

function CloseIcon() {
  return (

    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.8" d="M13 13L1 1M13 1L1 13" stroke={colors.darkText} strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export default CloseIcon;
