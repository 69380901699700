import { useMemo } from 'react';
import { BinslegendaIcon, MiscItemsIcon } from 'assets/images';

import './legendaItem.scss';
import { colors } from 'assets/css/variables';

function LegendaItem({ title }: any) {
  const handleBackground = useMemo(() => {
    let result = null;
    switch (title) {
      case 'Not available':
        result = colors.error;
        break;
      case 'Occupied':
        result = colors.blueLegenda;
        break;
      case 'Available':
        result = colors.secondaryLight;
        break;
      case 'Special conditions':
        result = colors.yellowLegenda;
        break;
      default:
        result = 'transparent';
    }
    return result;
  }, [title]);

  const handleSvgBackground = useMemo(() => {
    let result = null;
    if (title === 'Misc items') {
      result = <MiscItemsIcon />;
    } else if (title === 'Bins') {
      result = <BinslegendaIcon />;
    } else {
      result = null;
    }

    return result;
  }, [title]);

  return (
    <div className="legenda-item-block">
      <div className="color-block" style={{ background: handleBackground }}>
        {handleSvgBackground}
      </div>
      {title}
    </div>
  );
}

export default LegendaItem;
