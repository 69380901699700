import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { InboundOrderStatus, orderType } from 'constants/orders/status';
import { ICondition, IOrderMeasurementUnit, ISelectedOrder } from 'interfaces';
import { calculateTotalOfUnits } from 'utils/calculateTotalOfUoms';

interface FooterOfItemBlockProps {
  conditions: any[];
  selectedOrder?: ISelectedOrder;
  isResultUoms?: boolean;
  uoms: IOrderMeasurementUnit[];
  expectedUoms?: IOrderMeasurementUnit[];
}

function FooterOfItemBlock({
  conditions,
  uoms,
  selectedOrder,
  expectedUoms,
  isResultUoms = false,
}: FooterOfItemBlockProps) {
  const [isAnyUomLost, setIsAnyUomsLost] = useState(false);
  const [isTotalOfUomsCorrect, setIsTotalOfUomsCorrect] = useState(null);

  const calculateNumOfUnitsOfCondition = (conditionName: string) => {
    const condition = conditions.find((c: ICondition) => c.name === conditionName);

    if (!condition) return 0;

    const measurement_units_of_condition = uoms.filter((mu: IOrderMeasurementUnit) => mu.condition_id === condition.id);

    return measurement_units_of_condition.reduce(
      (acc, val) => (val.contains ? +val.quantity * +val.contains : +val.quantity) + acc,
      0,
    );
  };

  useEffect(() => {
    if (calculateNumOfUnitsOfCondition('Lost') > 0) {
      setIsAnyUomsLost(true);
    } else {
      setIsAnyUomsLost(false);
    }
  }, [calculateNumOfUnitsOfCondition('Lost'), selectedOrder]);

  useEffect(() => {
    if (isResultUoms) {
      if (
        selectedOrder?.status === InboundOrderStatus.Arrived
        || selectedOrder?.status === InboundOrderStatus.PartiallyArrived
        || selectedOrder?.status === InboundOrderStatus.Closed
      ) {
        if (uoms && expectedUoms) {
          if (calculateTotalOfUnits(uoms) !== calculateTotalOfUnits(expectedUoms)) {
            setIsTotalOfUomsCorrect(false);
          } else {
            setIsTotalOfUomsCorrect(true);
          }
        }
      }
    } else {
      setIsTotalOfUomsCorrect(true);
    }
  }, [uoms, expectedUoms, selectedOrder]);

  return (
    <div className={`item-container-footer ${isResultUoms ? 'result-item-container-footer' : ''}`}>
      <Row gutter={10} className="footer-titles">
        <Col span={5}>
          <span>Damaged: </span>
          {calculateNumOfUnitsOfCondition('Damaged')}
        </Col>

        <Col span={5}>
          <span>In transit: </span>
          {calculateNumOfUnitsOfCondition('In Transit')}
        </Col>

        <Col className={`${isAnyUomLost ? 'bad-result' : ''}`} span={4}>
          <span>Lost: </span>
          {calculateNumOfUnitsOfCondition('Lost')}
        </Col>

        <Col span={5}>
          <span>Ok: </span>
          {calculateNumOfUnitsOfCondition('OK')}
        </Col>

        <Col className={`total-text ${!isTotalOfUomsCorrect ? 'incorrect-total-text' : ''}`} span={5}>
          <span className="uom-title-header">Total:</span>
          <span className="uom-title-header">{calculateTotalOfUnits(uoms)}</span>
        </Col>
      </Row>
    </div>
  );
}

export default FooterOfItemBlock;
