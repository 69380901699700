import { IAxiosResponse, IPagination, IUser } from 'interfaces';
import photosAPI from 'services/photosAPI';
import { getPagination } from 'utils/getPagination';
import { EUsersAction } from './types';
import { usersAPI } from '../../../services';
import { notifier, tryCatchHandler } from '../../../utils';
import { IUserSearchParams } from './reducer';

export const usersActionCreator = {
  setUsers: (users: IUser[]) => ({
    type: EUsersAction.SET_USERS,
    payload: users.sort((a: IUser, b: IUser) => a.id - b.id),
  }),
  setSelectedUser: (selectedUser: IUser) => ({
    type: EUsersAction.SET_SELECTED_USER,
    payload: selectedUser,
  }),
  setPagination: (pagination: IPagination) => ({
    type: EUsersAction.SET_PAGINATION,
    payload: pagination,
  }),
  setSearchParams: (search: IUserSearchParams) => ({
    type: EUsersAction.SET_SEARCH_PARAMS,
    payload: search,
  }),
  fetchUsers: (per_page?: number, page?: number, search_params?: IUserSearchParams) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const { pagination, search_params: searchFromStore } = getState().usersReducer;

      const search = search_params || searchFromStore;
      const perPageToSearch = per_page || pagination.per_page;
      const pageToSearch = page || pagination.current_page;

      const isSearch = !!search;

      await dispatch(usersActionCreator.setSearchParams(search));
      const { data, meta }: IAxiosResponse<IUser[]> = await usersAPI.getUsers(
        perPageToSearch,
        getPagination(isSearch, page, pageToSearch),
        search,
      );

      dispatch(usersActionCreator.setUsers(data));
      dispatch(
        usersActionCreator.setPagination({
          total: meta.total,
          per_page: meta.per_page,
          current_page: meta.current_page,
        }),
      );
    });
  },

  fetchUser: (id: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: IAxiosResponse<IUser> = await usersAPI.getUser(id);
      dispatch(usersActionCreator.setSelectedUser(data));
    });
  },

  createUser: (user: IUser) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { pagination } = getState().usersReducer;

    const response = await usersAPI.createOrUpdateUser(user);
    notifier('success', 'Success!', 'User created');
    await usersAPI.forgotPassword(user.email);

    await dispatch(usersActionCreator.fetchUsers(pagination.per_page, pagination.current_page));
    await dispatch(usersActionCreator.fetchUser(response.data.id));

    return response;
  }),

  updateUser: (user: IUser) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { pagination } = getState().usersReducer;

    const response = await usersAPI.createOrUpdateUser(user);

    dispatch(usersActionCreator.setSelectedUser(user));
    notifier('success', 'Success!', 'Changes are saved successfully');

    await dispatch(usersActionCreator.fetchUsers(pagination.per_page, pagination.current_page));
    await dispatch(usersActionCreator.fetchUser(user.id));

    return response;
  }),

  updateUserRole: (userId: number, userRoleId: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const response = await usersAPI.updateUserRole(userId, userRoleId);
    if (response) await dispatch(usersActionCreator.fetchUser(userId));
    notifier('success', 'Success!', 'Changes are saved successfully');
    return response;
  }),

  uploadPhoto: (file: FormData) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await photosAPI.uploadPhoto(file);
    return data;
  }),

  deleteUser: (user: IUser) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await usersAPI.deleteUser(user);
      const { users }: { users: IUser[] } = getState().usersReducer;
      const usersWithoutDeleted = users.filter((item: IUser) => item.id !== user.id);
      dispatch(usersActionCreator.setUsers(usersWithoutDeleted));
      dispatch(usersActionCreator.setSelectedUser(null));
      notifier('success', 'Success!', 'User deleted');
    });
  },
  clearUsers: () => async (dispatch) => {
    dispatch(usersActionCreator.setUsers([]));
    dispatch(usersActionCreator.setSelectedUser(null));
  },
};
