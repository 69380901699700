import React, { useEffect, useRef, useState } from 'react';
import { Collapse } from 'antd';
import { OutsideClick } from '../../../utils';
import './rightCard.scss';

function RightCard({
  title, icon, className = '', body, children, collapsible,
}: any) {
  const { Panel } = Collapse;

  const [open, setOpen] = useState([]);

  const iconRef = useRef(null);
  const clickedOutsideIcon = OutsideClick(iconRef);

  const handleChange = (e: any) => {
    if (icon) {
      if (clickedOutsideIcon) {
        setOpen(e);
      }
    } else {
      setOpen(e);
    }
  };

  useEffect(() => {
    setOpen(!collapsible ? ['1'] : []);
  }, [collapsible]);

  return (
    <div className={`right-card-block ${className} ${!open.includes('1') ? 'closed-card-block' : 'active-card-block'}`}>
      <Collapse collapsible={collapsible} activeKey={open} onChange={handleChange}>
        <Panel
          header={(
            <div className="right-card-header">
              {title}

              <div className="header-icon" ref={iconRef}>
                {icon}
              </div>
            </div>
          )}
          key="1"
        >
          <div>{body}</div>

          <div className="right-card-body">{children}</div>
        </Panel>
      </Collapse>
    </div>
  );
}

export default RightCard;
