import { useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import { write, WorkSheet } from 'xlsx-js-style';
import {
  calculateLengthCellExcel, setDataToExcelRecentActivity, setDataToExcelStock, setFileName, updateDataActivity, updateDataStock,
} from 'utils/paramsForCellExcel';
import { CustomButton } from 'components/globals';
import { EXCEL_TYPE } from '../ReportRow';
import { ReportType } from '../../../../../constants/reportType';

function ReportXLS({
  data, disabled, handleReport, reportType, type, setReportType, isWareHouseAdmin, from, to, itemOrStoreName,
}) {
  const [xlsxData, setXlsxData] = useState([]);
  const [wscols, setWscols] = useState([]);
  const [isConfirmTrigger, setConfirmTrigger] = useState({});
  const conditionForSaveExcel = Boolean(reportType === EXCEL_TYPE && data);

  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  const saveAsExcel = () => {
    let ws: WorkSheet;
    switch (type) {
      case ReportType.STORE:
      case ReportType.INVENTORY:
        ws = setDataToExcelRecentActivity(data, from, to, xlsxData);
        break;
      case ReportType.STOCK:
        ws = setDataToExcelStock(data, from, xlsxData);
        break;
      default:
        return;
    }
    ws['!cols'] = wscols; // created width params for cell, function has standard params width ( 15 )

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = write(wb, { bookType: 'xlsx', type: 'array' });
    const dataBlob = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataBlob, setFileName(type, itemOrStoreName, from, to));
  };

  useEffect(() => {
    if (conditionForSaveExcel) {
      let dataOrders;

      if (type === ReportType.STORE && data?.length) {
        dataOrders = updateDataActivity(data, isWareHouseAdmin);
      } else if (type === ReportType.INVENTORY && data.orders) {
        dataOrders = updateDataActivity([data], isWareHouseAdmin);
      } else if (type === ReportType.STOCK && data.length) {
        dataOrders = updateDataStock(data);
      }

      setWscols(calculateLengthCellExcel(dataOrders));
      setXlsxData(dataOrders);
      setConfirmTrigger({ });
    }
  }, [data, reportType]);

  useEffect(() => {
    if (conditionForSaveExcel && isConfirmTrigger) {
      setReportType('');
      saveAsExcel();
    }
  }, [isConfirmTrigger]);

  return (
    <CustomButton disabled={disabled} onClick={handleReport}>
      Save as xls
    </CustomButton>
  );
}

export default ReportXLS;
