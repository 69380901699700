import { useDispatch } from 'react-redux';
import { MinusIcon, SelectedBinIcon, PlusIcon } from 'assets/images';
import { IRack } from 'interfaces';
import { colors } from 'assets/css/variables';
import { locationsActionCreator } from '../../../../store/action-creators';
import LegendaItem from './LegendaItem';
import CustomPopover from '../../../globals/customPopover/CustomPopover';

import './rack.scss';

interface RackProps {
  rack: IRack;
  children?: any;
  isModal?: boolean;
  selectedRackId?: number;
  selectedLevel?: number;
  selectedColumn?: number;
  selectColumn?: any;
  selectLevel?: any;
  selectCell: any;
  onClickRack?: any;
  className?: string;
  isReadonlyByUserRole?: boolean;
}

function Rack({
  rack,
  selectColumn = () => {},
  selectLevel = () => {},
  selectCell = () => {},
  onClickRack,
  selectedRackId,
  selectedLevel,
  selectedColumn,
  isModal = false,
  children,
  className = '',
  isReadonlyByUserRole,
}: RackProps) {
  const ckeckSelected = (column: any, level: any) => selectedRackId === rack.id && column === selectedColumn && level === selectedLevel;

  const dispatch = useDispatch();

  const getColorClassname = ({ rack, levelIdx, columnIdx }: any) => {
    const coordinateInfo = `${rack.levels + 1 - levelIdx} ${columnIdx}`;

    // set color to location
    let result = 'rack-available';

    if ((rack.not_available || []).includes(coordinateInfo)) {
      result = 'rack-not-available';
    } else if ((rack.occupied || []).includes(coordinateInfo)) {
      result = 'rack-ocuped';
    }

    return result;
  };

  const getAdditionalStatuses = ({ rack, levelIdx, columnIdx }: any) => {
    // Set additional statuses to location
    const coordinateInfo = `${rack.levels + 1 - levelIdx} ${columnIdx}`;

    const legendItems = [];

    if ((rack.special_conditions || []).includes(coordinateInfo)) {
      legendItems.push('Special conditions');
    }
    if ((rack.misc_items || []).includes(coordinateInfo)) {
      legendItems.push('Misc items');
    }
    if ((rack.bins || []).includes(coordinateInfo)) {
      legendItems.push('Bins');
    }

    return legendItems;
  };

  const conditionRackOccupied = (cell) => (rack.occupied?.length
    ? Math.max.apply(
      null,
      rack.occupied.map((item) => +item[cell]),
    )
    : 1);

  return (
    <div className="rack-wrapper">
      <div className="rack">
        <div
          className={`rack-title ${selectedRackId === rack.id && !isModal ? 'selected-rack-title' : ''}`}
          onClick={onClickRack}
        >
          {rack.name}
          {' '}
          {rack.custom_name ? `(${rack.custom_name})` : ''}
        </div>

        <div>
          {new Array(rack.levels + 1).fill('').map((level, levelIdx) => (
            <div className="rack-row" key={levelIdx}>
              {new Array(rack.columns + 1).fill('').map((column, columnIdx) => {
                if (levelIdx === 0 && columnIdx === 0) {
                  return <div className="cell hidden" key={`${levelIdx}-${columnIdx}`} />;
                }
                if (levelIdx === 0 && columnIdx !== 0) {
                  return (
                    <div
                      className={`noborder column ${isModal && 'modal-column'}
                      ${ckeckSelected(columnIdx, levelIdx) && 'selected'}`}
                      onClick={() => !isModal && selectColumn(columnIdx, rack)}
                      key={`${levelIdx}-${columnIdx}`}
                    >
                      {columnIdx < 10 ? `0${columnIdx}` : columnIdx}
                    </div>
                  );
                }
                if (levelIdx !== 0 && columnIdx === 0) {
                  return (
                    <div
                      className={`noborder level ${isModal && 'modal-level'} ${
                        ckeckSelected(columnIdx, rack.levels + 1 - levelIdx) && 'selected'
                      }`}
                      onClick={() => !isModal && selectLevel(rack.levels + 1 - levelIdx, rack)}
                      key={`${levelIdx}-${columnIdx}`}
                    >
                      {rack.levels + 1 - levelIdx}
                    </div>
                  );
                }
                const background = getColorClassname({ rack, levelIdx, columnIdx });
                const additionalStatuses = getAdditionalStatuses({ rack, levelIdx, columnIdx });
                return (
                  <div
                    className={`cell ${background}  ${
                      ckeckSelected(columnIdx, rack.levels + 1 - levelIdx) && 'selected'
                    }`}
                    onClick={() => selectCell(rack.levels + 1 - levelIdx, columnIdx, rack)}
                    key={`${levelIdx}-${columnIdx}`}
                  >
                    {!!additionalStatuses.length && (
                      <CustomPopover
                        className="rack-info-popover"
                        placement="rightBottom"
                        content={additionalStatuses.map((item) => (
                          <LegendaItem title={item} key={item} />
                        ))}
                      >
                        <div className="items-circle">{additionalStatuses.length}</div>
                      </CustomPopover>
                    )}
                    {ckeckSelected(columnIdx, rack.levels + 1 - levelIdx) && (
                      <SelectedBinIcon
                        color={
                          !(background === 'rack-ocuped' || background === 'rack-not-available')
                            ? colors.blueLegenda
                            : ''
                        }
                      />
                    )}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
        {children}
        {!isReadonlyByUserRole && (
          <div className={`plus-minus-buttons-wrapper ${isModal && 'ds-none'}`}>
            <div className="button-box">
              Column
              <div
                className="button plus"
                onClick={() => dispatch(locationsActionCreator.createLevelOrColumn(rack.id, 'column'))}
              >
                <PlusIcon />
              </div>
              {rack.columns > conditionRackOccupied(2) && (
                <div
                  className="button"
                  onClick={() => dispatch(locationsActionCreator.deleteColumn(rack.id, rack.columns))}
                >
                  <MinusIcon />
                </div>
              )}
            </div>

            <div className="button-box">
              Level
              <div
                className="button plus"
                onClick={() => dispatch(locationsActionCreator.createLevelOrColumn(rack.id, 'level'))}
              >
                <PlusIcon />
              </div>
              {rack.levels > conditionRackOccupied(0) && (
                <div
                  className="button"
                  onClick={() => dispatch(locationsActionCreator.deleteLevel(rack.id, rack.levels))}
                >
                  <MinusIcon />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Rack;
