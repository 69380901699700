import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './loaderStyle.scss';

function Loader() {
  return (

    <div
      className="loader-wrapepr"
    >

      <div className="lds-ring">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default Loader;
