import React from 'react';

function CustomPlaceholder({
  readonly,
  value,
  required,
  isOnlyPlaceholder,
  disabled,
  placeholder = '',
  setIsActive,
  setFocus,
  isActive,
}: any) {
  const renderPassivePlaceholder = () => {
    let result = null;
    if (required) {
      result = (

        <>

          <p className={!disabled ? 'd-star' : ''}>*</p>
          {' '}
          {placeholder}
        </>
      );
    } else {
      result = `${placeholder} ${isOnlyPlaceholder || readonly ? '' : '(Optional)'}`;
    }
    return (

      <span
        onClick={() => {
          setIsActive(true);
          setFocus();
        }}
        className="passive-label placeholder"
        style={{ display: !placeholder.length ? 'none' : 'inline-flex' }}
      >
        {result}
      </span>
    );
  };

  const renderActivePlaceholder = () => {
    let result = null;
    if (required) {
      if (!String(value).length) {
        result = (

          <>

            <p className={!disabled ? 'd-star' : ''}>*</p>
            {' '}
            {placeholder}
          </>
        );
      } else {
        result = placeholder;
      }
    } else if (!String(value).length) {
      result = `${placeholder} ${isOnlyPlaceholder || readonly ? '' : '(Optional)'}`;
    } else {
      result = placeholder;
    }
    return (

      <span style={{ display: !placeholder.length ? 'none' : 'inline-flex' }} className="active-label placeholder">
        {result}
      </span>
    );
  };
  return !isActive && !disabled && !String(value).length ? renderPassivePlaceholder() : renderActivePlaceholder();
}

export default CustomPlaceholder;
