import React, { useEffect, useState } from 'react';
import { CustomButton, CustomTextArea, ModalWindow } from 'components/globals';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

interface TerminateModalProps {
  selectedOrder: any;
  isTerminateModalVisible: boolean;
  closeCallback: (value: boolean) => void;
  actionCreator: any;
}

function TerminateModal({
  isTerminateModalVisible, selectedOrder, closeCallback, actionCreator,
}: TerminateModalProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [textAreaValue, setTextAreaValue] = useState('');

  const terminateClick = () => {
    dispatch(actionCreator.terminateOrder(selectedOrder?.id, { terminate_reason: textAreaValue }));
    closeCallback(false);
  };

  useEffect(() => {
    if (isTerminateModalVisible) {
      setTextAreaValue('');
    }
  }, [isTerminateModalVisible]);

  return (
    <ModalWindow
      className="terminate-order-modal"
      title={t('orders.orderTermination')}
      handleCancelModal={() => closeCallback(false)}
      isModalVisible={isTerminateModalVisible}
    >
      <CustomTextArea
        required
        value={textAreaValue}
        onChange={(e) => setTextAreaValue(e.target.value)}
        placeholder={t('orders.terminateRreason')}
      />

      <div className="buttons-wrapper">
        <CustomButton className="cancel-button" onClick={() => closeCallback(false)}>
          {t('orders.cancel')}
        </CustomButton>
        <CustomButton isImportant disabled={!textAreaValue} className="terminate-button" onClick={terminateClick}>
          {t('orders.terminate')}
        </CustomButton>
      </div>
    </ModalWindow>
  );
}

export default TerminateModal;
