import {
  IExternalIdType, IExternal, IUnit, IAxiosResponse, IItem, IPagination, IStore,
} from 'interfaces';
import photosAPI from 'services/photosAPI';
import { getPagination } from 'utils/getPagination';
import documentsAPI from 'services/documentsAPI';
import { EItemsAction } from './types';
import { notifier } from '../../../utils/notifier';
import { tryCatchHandler } from '../../../utils/tryCatchHandler';
import { itemsAPI, storesAPI } from '../../../services';
import { IItemsSearchParams } from './reducer';

export const itemsActionCreator = {
  setItems: (items: IItem[]) => ({
    type: EItemsAction.SET_ITEMS,
    payload: items.sort((a: IItem, b: IItem) => a.id - b.id),
  }),
  setSelectedItem: (selectedItem: any) => ({
    type: EItemsAction.SET_SELECTED_ITEM,
    payload: selectedItem,
  }),

  setExternalIDTypes: (exIDTypes: IExternalIdType[]) => ({
    type: EItemsAction.SET_EXTERNAL_ID_TYPES,
    payload: exIDTypes,
  }),
  setPagination: (pagination: IPagination) => ({
    type: EItemsAction.SET_PAGINATION,
    payload: pagination,
  }),
  setSelectedUOMItems: (data: any) => ({
    type: EItemsAction.SET_SELECTED_UOM_ITEMS,
    payload: data,
  }),
  setSearchParams: (search: IItemsSearchParams) => ({
    type: EItemsAction.SET_SEARCH_PARAMS,
    payload: search,
  }),

  fetchItems: (per_page?: number, page?: number, search_params?: IItemsSearchParams) => async (dispatch, getState) => {
    const { pagination, search_params: searchFromStore } = getState().itemsReducer;

    const search = search_params || searchFromStore;
    const perPageToSearch = per_page || pagination.per_page;
    const pageToSearch = page || pagination.current_page;

    const isSearch = !!search_params;

    await dispatch(itemsActionCreator.setSearchParams(search));

    await tryCatchHandler(dispatch, async () => {
      const { data, meta }: IAxiosResponse<IItem[]> = await itemsAPI.getAllItems(
        perPageToSearch,
        getPagination(isSearch, page, pageToSearch),
        search,
      );
      const { data: stores }: { data: IStore[] } = await storesAPI.getStores();

      const items = data.map((item: IItem) => ({
        ...item,
        id: item.item_id,
        store: stores.find((store: IStore) => store.id === item.store_id)?.company_name || 'unknown store',
        externalId: item?.external_ids?.find((extID) => extID?.default)?.external_id_value,
      }));

      dispatch(itemsActionCreator.setItems(items));
      dispatch(
        itemsActionCreator.setPagination({
          total: meta.total,
          per_page: meta.per_page,
          current_page: meta.current_page,
        }),
      );
    });
  },
  fetchItem: (item_id: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: IAxiosResponse<any> = await itemsAPI.getItem(item_id);

      const itemData = { ...data, store: data?.store_id?.company_name };

      dispatch(itemsActionCreator.setSelectedItem(itemData));
    });
  },
  fetchUOMOptions: (item_id: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data }: any = await itemsAPI.getUOMOptions(item_id);

    const updateUOM = await data
      .sort((a) => (a.text === 'Each' ? -1 : 1))
      .reverse()
      .map((option) => ({
        value: option.text,
        content: option.text,
        contains: option.contains,
        id: option.id,
        default: option.default,
      }));

    return updateUOM;
  }),
  createItem: (item: IItem) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: { data?: IItem } = await itemsAPI.postItem(item);

      const {
        pagination: { per_page, total },
      } = getState().itemsReducer;
      const newPage = Math.ceil((total + 1) / per_page);

      await dispatch(itemsActionCreator.fetchItems(per_page, newPage));
      await dispatch(itemsActionCreator.fetchItem(data.id));
      notifier('success', 'Success!', 'Item created');
    });
  },
  updateItem: (newItem: IItem) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await itemsAPI.postItem(newItem);
      const {
        selectedItem,
        pagination: { per_page, current_page },
      } = getState().itemsReducer;

      await dispatch(itemsActionCreator.fetchItems(per_page, current_page));
      await dispatch(itemsActionCreator.fetchItem(selectedItem.id));

      notifier('success', 'Success!', 'Item saved');
    });
  },
  uploadPhoto: (file: FormData) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await photosAPI.uploadPhoto(file);
    return data;
  }),
  deleteItem: (itemId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const {
        pagination: { per_page, current_page },
      } = getState().itemsReducer;

      await itemsAPI.deleteItem(itemId);

      dispatch(itemsActionCreator.fetchItems(per_page, current_page));
      notifier('success', 'Success!', 'Item deleted');
    });
  },
  fetchExternalIDTypes: () => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: { data?: IExternalIdType[] } = await itemsAPI.getExternalIDTypes();

      dispatch(itemsActionCreator.setExternalIDTypes(data));
    });
  },
  addExternalIDs: (extIdsArray: any) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const { selectedItem, pagination: { per_page, current_page } } = getState().itemsReducer;

      const { data }: { data?: IExternal } = await itemsAPI.addExternalIDs({
        item_id: selectedItem?.id,
        array: { data: extIdsArray },
      });

      await dispatch(itemsActionCreator.fetchItems(per_page, current_page));
      await dispatch(itemsActionCreator.fetchItem(selectedItem?.id));

      notifier('success', 'Success!', 'External IDs updated!');
      return data;
    });
  },

  updateUnit: (itemId: number, unit: IUnit) => async (dispatch) => {
    await tryCatchHandler(
      dispatch,
      async () => {
        await itemsAPI.putUnit(itemId, unit);
        notifier('success', 'Success!', 'Changes are saved successfully');
        return true;
      },
      '',
      false,
    );
  },

  uploadDocument: (itemId: number, file: any) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await documentsAPI.uploadDocument(file);

    if (data) {
      await documentsAPI.attachDocumentToItem({ item_id: itemId, document_id: data.id });
      await dispatch(itemsActionCreator.fetchItem(itemId));
    }
  }),

  removeDocument: (itemId: number, documentId: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    await documentsAPI.removeItemDocument(documentId);
    await dispatch(itemsActionCreator.fetchItem(itemId));
  }),

  clearItems: () => async (dispatch) => {
    dispatch(itemsActionCreator.setItems([]));
    dispatch(itemsActionCreator.setSelectedItem(null));
    dispatch(itemsActionCreator.setExternalIDTypes([]));
  },
};
