import React, { useState, useEffect } from 'react';
import { MainWrapper, RightCard, RightWrapper } from 'components/layout';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { CustomTable } from 'components/globals';
import { warehousesActionCreator } from 'store/modules/warehouses/action-creators';
import { RootState } from 'App';
import { tableColumns } from 'constants/warehouses/warehousesData';
import WarehouseInfoBlock from 'components/views/warehouses/warehouseInfoBlock/WarehouseInfoBlock';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { IWarehouse } from 'interfaces/warehouses';
import RelatedUsers from 'components/views/relatedUsers/RelatedUsers';
import { RelationTypes, UserRoles } from 'constants/users/userRolesAndTypes';
import { usersActionCreator } from 'store/action-creators';
import { IUser } from 'interfaces';
import {
  requiredAbilitiesForWarehousesInfoBlock,
  requiredAbilitiesForWarehouseStaffBlock,
} from '../../constants/warehouses/pageAbilities';

function Warehouses() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const roles = useSelector((state: RootState) => state.rolesReducer.roles);
  const { warehouses, pagination, selectedWarehouse } = useSelector((state: RootState) => state.warehousesReducer);

  const [searchValue, setSearchValue] = useState(null);
  const [isRightBlockOpen, setIsRightBlockOpen] = useState(false);

  const selectWarehouse = async (id: number) => {
    await dispatch(warehousesActionCreator.fetchWarehouse(id));
    setIsRightBlockOpen(true);
  };

  const createNewWarehouse = () => {
    const newWarehouseBody: IWarehouse = {
      address_1: '',
      address_2: '',
      description: '',
      abbreviation: '',
      city: '',
      name: '',
      country: '',
      email: '',
      phone: '',
      postal_code: '',
      state: '',
      fax: '',
      metric: 1,
      users: [],
    };

    dispatch(warehousesActionCreator.setSelectedWarehouse(newWarehouseBody));
    setIsRightBlockOpen(true);
  };

  const getRoleIdByValue = (roleValue: string) => {
    const currentRole = roles?.find((role) => role.title === roleValue);
    return currentRole ? currentRole.id : null;
  };

  const addRelatedUser = async (user: IUser) => {
    const isUserAddedToWarehouse = await dispatch(
      warehousesActionCreator.addUserToWarehouse(selectedWarehouse?.id, user?.id),
    );
    if (isUserAddedToWarehouse) {
      if (!user?.relatedToWarehouses.length) {
        await dispatch(usersActionCreator.updateUserRole(user?.id, getRoleIdByValue(UserRoles.WAREHOUSE_ADMIN)));
      }
      await dispatch(warehousesActionCreator.fetchWarehouse(selectedWarehouse?.id));
    }
  };

  const removeRelatedUser = (userId: number) => {
    dispatch(warehousesActionCreator.deleteUserFromWarehouse(selectedWarehouse?.id, userId));
  };

  useEffect(() => {
    dispatch(
      warehousesActionCreator.fetchWarehouses(null, null, {
        search: searchValue !== '' ? searchValue : null,
      }),
    );
  }, [searchValue]);

  useEffect(() => {
    dispatch(warehousesActionCreator.fetchWarehouses());
    return () => {
      dispatch(warehousesActionCreator.clearWarehouses());
    };
  }, []);

  return (
    <>

      {/* iconAction={createNewWarehouse} TODO add when warehouses will be ready */}
      <MainWrapper
        title={t('warehouses.warehouses')}
        isFullScreen={!isRightBlockOpen}
      >
        <CustomTable
          columns={tableColumns}
          pagination={pagination}
          tableData={warehouses.map((warehouse: IWarehouse) => ({
            ...warehouse,
          }))}
          setSearchValue={setSearchValue}
          onSearch={setSearchValue}
          selectedItem={selectedWarehouse}
          itemClick={(warehouse: IWarehouse) => selectWarehouse(warehouse?.id)}
          getNewItems={(page: number, per_page: number) => dispatch(
            warehousesActionCreator.fetchWarehouses(per_page, page, {
              search: searchValue !== '' ? searchValue : null,
            }),
          )}
        />
      </MainWrapper>
      {isRightBlockOpen && (
        <RightWrapper
          onCancelClick={() => {
            setIsRightBlockOpen(false);
          }}
          title={t('warehouses.warehouse')}
        >
          <RightCard title={t('common.contactInformation')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForWarehousesInfoBlock}>
              <WarehouseInfoBlock selectedWarehouse={selectedWarehouse} />
            </WithAuthWrapper>
          </RightCard>

          <RightCard title={t('warehouses.warehouseStaff')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForWarehouseStaffBlock}>
              <RelatedUsers
                userRelationType={RelationTypes.WAREHOUSE}
                addUser={addRelatedUser}
                removeUser={removeRelatedUser}
                relatedUsers={selectedWarehouse?.users || []}
                rerenderParentComponentParams={warehousesActionCreator.fetchWarehouse?.(selectedWarehouse?.id)}
              />
            </WithAuthWrapper>
          </RightCard>
        </RightWrapper>
      )}
    </>
  );
}

export default Warehouses;
