import { useRef } from 'react';
import { Row } from 'antd';
import { CustomButton } from 'components/globals';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import moment from 'moment';

import { InboundType } from 'constants/reportType';
import { pageStylePrint } from './pageStylePrint';

import '../../../inventory/CurrentRowInfo/CurrentRowInfo';
import { ExpectedPDF } from './ExpectedPDF';
import { ExpectedResultPDF } from './ExpectedResultPDF';
import { OnGatePDF } from './OnGatePDF';

const statusForExpected = ['Ready', 'Dispatched', 'Confirmed', 'Arrived'];

function RenderPDF(props) {
  const { order } = props;

  if (order.type === InboundType.INBOUND) {
    const expectedCondition = statusForExpected.some((elem) => elem === order?.status);
    if (expectedCondition) {
      return <ExpectedPDF {...props} />;
    } if (order.status === 'Closed') {
      return <ExpectedResultPDF {...props} />;
    }
  }

  return <OnGatePDF {...props} />;
}

function ReportInboundPrint(props) {
  const printRef = useRef();
  const { t } = useTranslation();

  const handlePrint = useReactToPrint({
    pageStyle: pageStylePrint,
    copyStyles: true,
    content: () => printRef.current,
    documentTitle: `Inventory report ${moment(new Date(), 'MM.DD.YYYY').format('MM.DD.YYYY')}`,
  });

  return (
    <div className="create-report-row">
      <div className="body-report" ref={printRef}>
        {RenderPDF(props)}
        <div className="autorized-signature">
          <hr />
          Autorized Signature
        </div>
      </div>
      <Row justify="end" className="btn-print-report-wrap">
        <CustomButton className="btn-print-report" onClick={handlePrint}>
          {t('locations.print')}
        </CustomButton>
      </Row>
    </div>
  );
}

export default ReportInboundPrint;
