import React, { useEffect, useState } from 'react';
import { RootState } from 'App';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IBin, ILocation, IRack } from 'interfaces';
import Rack from '../../../locations/rack/Rack';
import { CustomButton, ModalWindow } from '../../../../globals';
import { locationsActionCreator } from '../../../../../store/action-creators';
import LocationsLegenda from '../../../locations/locationsLegenda/LocationsLegenda';
import CustomPopover from '../../../../globals/customPopover/CustomPopover';

import './locationChooserModal.scss';

interface LocationChooserModalProps {
  isModalVisible: boolean;
  isMoveClicked: boolean;
  className?: string;
  setIsModalVisible: (value: boolean) => void;
  setNewLocation: any;
}

function LocationChooserModal({
  isModalVisible,
  setIsModalVisible,
  setNewLocation,
  isMoveClicked,
  className = '',
}: LocationChooserModalProps) {
  const dispatch = useDispatch();

  const {
    bins, racks, locations, selectedRack, selectedLevel, selectedColumn, selectedLocation,
  } = useSelector(
    (state: RootState) => state.locationsReducer,
  );

  const selectedItem = useSelector((state: RootState) => state.inventoryReducer.selectedItem);

  const [selectedBin, setSelectedBin] = useState(null);
  const [isApplyEnabled, setIsApplyEnabled] = useState(false);
  const [applyDisabledMessage, setApplyDisabledMessage] = useState('');

  const applyClick = () => {
    setIsModalVisible(false);
    const params = {};
    if (selectedBin) {
      Object.assign(params, { bin: selectedBin });
    } else Object.assign(params, { location: selectedLocation });

    setNewLocation(params);

    dispatch(locationsActionCreator.setSelectedRack(null));
    dispatch(locationsActionCreator.setSelectedLevel(null));
    dispatch(locationsActionCreator.setSelectedColumn(null));
  };

  const selectLocation = async (level: number, column: number, rack: IRack) => {
    await dispatch(locationsActionCreator.fetchLocations(rack.id));

    dispatch(locationsActionCreator.setSelectedRack(rack));
    dispatch(locationsActionCreator.setSelectedLevel(level));
    dispatch(locationsActionCreator.setSelectedColumn(column));
    setSelectedBin(null);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    dispatch(locationsActionCreator.setSelectedRack(null));
    dispatch(locationsActionCreator.setSelectedLevel(null));
    dispatch(locationsActionCreator.setSelectedColumn(null));
  };

  useEffect(() => {
    if (locations.length) {
      const location = locations.find((loc: ILocation) => loc.level === selectedLevel && loc.column === selectedColumn);

      dispatch(locationsActionCreator.setSelectedLocation(location));
      setApplyDisabledMessage('');
    }
  }, [locations.length, selectedLevel, selectedColumn, selectedRack?.id]);

  useEffect(() => {
    (async () => {
      if (selectedLocation?.id) {
        await dispatch(locationsActionCreator.fetchBins(selectedLocation.id));
      }
    })();
  }, [selectedLocation]);

  useEffect(() => {
    const locationCoordinates = `${selectedLevel} ${selectedColumn}`;

    if ((selectedRack?.not_available || []).includes(locationCoordinates)) {
      dispatch(locationsActionCreator.setSelectedLocation(null));
      setApplyDisabledMessage('Location unavailable');
    }

    if (selectedLocation) {
      const alreadyAddedLocations = selectedItem.locations.map((loc: ILocation) => loc.id);
      if (alreadyAddedLocations.includes(selectedLocation.id) && !isMoveClicked) {
        dispatch(locationsActionCreator.setSelectedLocation(null));
        setApplyDisabledMessage('Location is already added');
      }
      if ((selectedRack?.occupied || []).includes(locationCoordinates)) {
        if (bins.length) {
          // TODO add logic to bins (check if it is already occupied by other items and if it is unavailable)
        }
        // if it is not misc locations
        else if (!(selectedRack?.misc_items || []).includes(locationCoordinates)) {
          const alreadyAddedLocations = selectedItem.locations.map((loc: ILocation) => loc.id);
          // and if this location is not occupied bu this item
          if (!alreadyAddedLocations.includes(selectedLocation.id)) {
            dispatch(locationsActionCreator.setSelectedLocation(null));
            setApplyDisabledMessage('Location occupied by another item');
          }
        }
      }
    }
    if (selectedBin) {
      const alreadyAddedBins = selectedItem.bins.map((bin: IBin) => bin.id);

      if (alreadyAddedBins.includes(selectedBin.id)) {
        setSelectedBin(null);
        setApplyDisabledMessage('Bin is already added');
      }
    }

    if (bins.length) {
      setIsApplyEnabled(selectedBin);
    } else {
      setIsApplyEnabled(selectedLocation);
    }
  }, [selectedLocation, bins, selectedBin]);

  return (
    <ModalWindow
      title="Locations"
      handleCancelModal={closeModal}
      isModalVisible={isModalVisible}
      width={860}
      className="loc-chooser-modal"
    >
      <Row className="loc-modal-racks-block">
        {racks.map((rack: IRack) => (
          <Col key={rack.id} span={24}>
            <Rack
              rack={rack}
              selectCell={(level: number, column: number, rack: IRack) => selectLocation(level, column, rack)}
              selectedRackId={selectedRack?.id}
              selectedLevel={selectedLevel}
              selectedColumn={selectedColumn}
              isModal
            >
              {!!bins.length && rack.bins.includes(`${selectedLocation?.level} ${selectedLocation?.column}`) && (
                <>
                  <div className="selected-location-bins-name">
                    {selectedLocation?.name}
                    {' '}
                    Bins
                  </div>

                  <Row className="bins-container">
                    {bins.map((bin: IBin) => (
                      <Col
                        className={`${'bin-block'} ${selectedBin?.id === bin.id && 'selected-bin'}`}
                        key={bin.id}
                        onClick={() => {
                          setSelectedBin(bin);
                        }}
                      >
                        {bin.name}
                      </Col>
                    ))}
                  </Row>
                </>
              )}
            </Rack>
          </Col>
        ))}
      </Row>

      <Row className="bottom-modal-row">
        <Col span={12}>
          <LocationsLegenda isModalWindow />
        </Col>

        <Col span={12}>
          <div className="button-cirlce-wrapper">
            <CustomButton onClick={applyClick} className="apply-modal-button" disabled={!isApplyEnabled}>
              Apply
            </CustomButton>
            {applyDisabledMessage && (
              <CustomPopover
                className="error-message-popover"
                content={<p className="error-message-text">{applyDisabledMessage}</p>}
              >
                <div className="error-message-circle">i</div>
              </CustomPopover>
            )}
          </div>
        </Col>
      </Row>
    </ModalWindow>
  );
}

export default LocationChooserModal;
