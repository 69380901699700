import crypto from 'crypto';

const algorithm = process.env.REACT_APP_ALGORITHM;
const password = process.env.REACT_APP_PASSWORD;

const configs = process.env.REACT_APP_MODE === 'local' ? process.env : {} as any;

function decrypt(text) {
  const decipher = crypto.createDecipher(algorithm, password);
  let dec = decipher.update(text, 'hex', 'utf8');
  dec += decipher.final('utf8');
  return dec;
}

const getEnv = async () => {
  const { env } = await fetch('/env').then((res) => res.json());
  Object.assign(configs, JSON.parse(decrypt(env)));

  return true;
};

export { getEnv, configs };
