import React, { useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { RootState } from 'App';
import { useDispatch, useSelector } from 'react-redux';
import { IBin, ILocation, IInventoryLocation } from 'interfaces';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { allowedRolesForUpdateUoms } from 'constants/inventory/pageAbilities';
import { userRole } from 'constants/users/userRolesAndTypes';
import { CustomButton } from '../../../globals';
import Location from './location/Location';
import { inventoryActionCreator, locationsActionCreator } from '../../../../store/action-creators';
import LocationChooserModal from './locationChooserModal/LocationChooserModal';

import './inventoryLocations.scss';

interface InventoryLocationsProps {
  isMoveClicked: boolean;
  setIsMoveClicked: (value: boolean) => void;
}

function InventoryLocations({ isMoveClicked, setIsMoveClicked }: InventoryLocationsProps) {
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.authReducer.user);
  const selectedItem = useSelector((state: RootState) => state.inventoryReducer.selectedItem);
  const { bins, floor, locations } = selectedItem;

  const isUserHasAccessToUpdateUoms = isAllowedForUserRole(allowedRolesForUpdateUoms, userRole[user.role_id]);

  const [uomId, setUomId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [locationsData, setLocationsData] = useState([]);

  useEffect(() => {
    if (floor && locations && bins) {
      const mainArray = [
        { data: [floor], type: 'floor' },
        { data: [...locations], type: 'locations' },
        { data: [...bins], type: 'bins' },
      ];

      setLocationsData(mainArray);
    }
  }, [floor, locations, bins]);

  const addAnotherLocation = async ({ bin, location }: { bin: IBin; location: ILocation }) => {
    if (bin) {
      if (isMoveClicked) {
        await dispatch(inventoryActionCreator.moveItemToAnotherLocation(uomId, { bin_id: bin.id, location_id: null }));
        setIsMoveClicked(false);

        setUomId(0);
      } else {
        const binsCopy = [...bins];
        binsCopy.push({
          available: 1,
          id: bin.id,
          name: bin.name,
          uom: [],
        });
        dispatch(inventoryActionCreator.setSelectedItem({ ...selectedItem, bins: binsCopy }));
      }
    } else if (location) {
      if (isMoveClicked) {
        await dispatch(
          inventoryActionCreator.moveItemToAnotherLocation(uomId, { bin_id: null, location_id: location.id }),
        );
        setIsMoveClicked(false);

        setUomId(0);
      } else {
        const locationsCopy = [...locations];
        locationsCopy.push({
          available: 1,
          id: location.id,
          name: location.name,
          uom: [],
        });
        dispatch(inventoryActionCreator.setSelectedItem({ ...selectedItem, locations: locationsCopy }));
      }
    }
  };

  const handleOtherLocation = async () => {
    await dispatch(locationsActionCreator.fetchRacks());
    setIsModalVisible(true);
  };

  return (
    <Row justify="end" className="inventory-locations-block">
      {isUserHasAccessToUpdateUoms && (
        <Col span={8}>
          <CustomButton onClick={handleOtherLocation} className="other-location-btn">
            Other Location +
          </CustomButton>
        </Col>
      )}

      <Col span={24}>
        {locationsData.map((parentLocation) => (
          <div key={parentLocation.type}>
            {parentLocation.data.map((l: IInventoryLocation, index: number) => (
              <Col className="location-block" key={index} span={24}>
                <Location
                  parentLocation={parentLocation}
                  isMoveClicked={isMoveClicked}
                  setIsMoveClicked={setIsMoveClicked}
                  location={l}
                  setUomId={(id: number) => setUomId(id)}
                  uomId={uomId}
                  isUserHasAccessToUpdateUoms={isUserHasAccessToUpdateUoms}
                />
              </Col>
            ))}
          </div>
        ))}
      </Col>
      {isUserHasAccessToUpdateUoms && (
        <LocationChooserModal
          className="location-chooser-modal-wrapper"
          isMoveClicked={isMoveClicked}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
          setNewLocation={addAnotherLocation}
        />
      )}
    </Row>
  );
}

export default InventoryLocations;
