import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { IUser, IUserRole } from 'interfaces';
import { OutsideClick } from 'utils';
import { ThreeDotsIcon } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { CustomButton, CustomDropdown, CustomSelect } from 'components/globals';
import { useDispatch, useSelector } from 'react-redux';
import { usersActionCreator } from 'store/action-creators';
import { RootState } from 'App';
import { RelationTypes, UserRoles } from 'constants/users/userRolesAndTypes';

import './relatedUserCard.scss';

interface RelatedUserCardProps {
  relatedUser: IUser;
  roles: IUserRole[];
  userRelationType: RelationTypes;
  removeUser: (userId: number) => void;
  rerenderParentComponentParams: any;
}

function RelatedUserCard({
  relatedUser,
  userRelationType,
  roles,
  removeUser,
  rerenderParentComponentParams,
}: RelatedUserCardProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  const currentUser = useSelector((state: RootState) => state.authReducer.user);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const outsideClicked = OutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  const getRolesOptionsByRelation = (relationType: RelationTypes) => {
    if (relationType === RelationTypes.STORE) {
      return [
        { value: UserRoles.STORE_INTERN, id: 1 },
        { value: UserRoles.STORE_ADMIN, id: 2 },
      ];
    }
    return [
      { value: UserRoles.WAREHOUSE_ADMIN, id: 1 },
      { value: UserRoles.WAREHOUSE_INTERN, id: 2 },
    ];
  };

  const getRoleIdByValue = (roleValue: string) => {
    const currentRole = roles?.find((role) => role.title === roleValue);
    return currentRole ? currentRole.id : null;
  };

  const getValueById = (roleId: any) => {
    const currentRole = roles?.find((role) => role.id === roleId);
    return currentRole ? currentRole.title : '';
  };

  const updateUserRole = async (newRole: string) => {
    await dispatch(usersActionCreator.updateUserRole(relatedUser?.id, getRoleIdByValue(newRole)));
    await dispatch(rerenderParentComponentParams);
  };

  useEffect(() => {
    if (isDropdownOpen && outsideClicked) {
      setIsDropdownOpen(false);
    }
  }, [outsideClicked, isDropdownOpen]);

  return (
    <Row align="middle" className="related-user-card-item" key={relatedUser.id}>
      <Col span={7}>{relatedUser.name}</Col>
      <Col span={9}>{relatedUser.email}</Col>
      <Col span={7}>
        {currentUser?.id !== relatedUser.id ? (
          <CustomSelect
            className="user-role-select"
            readonly={relatedUser?.relatedToStores?.length > 1 || relatedUser?.relatedToWarehouses?.length > 1}
            options={getRolesOptionsByRelation(userRelationType)}
            value={getValueById(relatedUser?.role_id?.id)}
            onSelect={updateUserRole}
          />
        ) : (
          getValueById(relatedUser?.role_id?.id)
        )}
      </Col>
      <Col ref={dropdownRef} className="three-dots-block" onClick={() => setIsDropdownOpen(!isDropdownOpen)} span={1}>
        <ThreeDotsIcon isActive={!!isDropdownOpen} />
        <CustomDropdown className="remove-user-dropdown" isDropdownOpen={isDropdownOpen}>
          <CustomButton className="edit-user-remit" onClick={() => {}}>
            {t('users.editPermitions')}
          </CustomButton>
          <CustomButton
            disabled={currentUser?.id === relatedUser?.id}
            className="remove-user-btn"
            onClick={() => removeUser(relatedUser.id)}
          >
            {t('common.remove')}
          </CustomButton>
        </CustomDropdown>
      </Col>
    </Row>
  );
}

export default RelatedUserCard;
