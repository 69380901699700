import React, { useState, useEffect, useRef } from 'react';
import { Input } from 'antd';
import OutsideClick from '../../../utils/outsideClick';

import CustomPlaceholder from '../customPlaceholder/CustomPlaceholder';
import './input.scss';
import { regEx } from '../../../constants/regex';

function CustomInput({
  readonly = false,
  placeholder,
  disabled,
  className = '',
  onKeyPress,
  required,
  isOnlyPlaceholder,
  pattern,
  onChange,
  withoutZero = false,
  defaultValue = '',
  type = 'text',
  ...rest
}: any) {
  const [isActive, setIsActive] = useState(!!rest.value);
  const placeholderRef = useRef(null);
  const setFocus = () => {
    if (placeholderRef.current) placeholderRef.current.focus();
  };
  const wrapperRef = useRef(null);
  const wrapperPasswordRef = useRef(null);

  const outsideClicked = OutsideClick(wrapperRef, () => setIsActive(false));

  const outsideClickedPass = OutsideClick(wrapperPasswordRef, () => setIsActive(false));

  const checkKeyPressType = (type) => {
    switch (type) {
      case 'numbers':
        return (event) => {
          if (!regEx.onlyNumber.test(event.key)) {
            event.preventDefault();
          }
        };
      case 'phones':
        return (event) => {
          if (!regEx.onlyNumber.test(event.key)) {
            event.preventDefault();
          }
        };
      case 'floats':
        return (event) => {
          if (!regEx.numbersAndDot.test(event.key)) {
            event.preventDefault();
          }
        };
      default:
        return onKeyPress;
    }
  };

  const customOnChange = (withoutZero, type) => {
    if (withoutZero) {
      return (event) => (event.target.value.startsWith('0') ? event.preventDefault() : onChange(event));
    }
    if (type === 'numbers') {
      return (event) => (event.target.value.match(regEx.startsWithZero) ? event.preventDefault() : onChange(event));
    }
    if (type === 'floats') {
      return (event) => {
        if (event.target.value.match(regEx.startsWithZero) || event.target.value.startsWith('.')) {
          event.preventDefault();
        } else {
          onChange(event);
        }
      };
    }
    if (type === 'text' || type === 'phones') {
      return (event) => onChange(event);
    }
  };

  useEffect(() => {
    if (isActive && outsideClicked && readonly) {
      setIsActive(false);
    }
  }, [outsideClicked, isActive]);

  useEffect(() => {
    if (isActive && outsideClickedPass) {
      setIsActive(false);
    }
  }, [outsideClickedPass, isActive]);

  useEffect(() => {
    if (wrapperRef && wrapperRef.current) {
      wrapperRef.current.click();
    }
    if (wrapperPasswordRef && wrapperPasswordRef.current) {
      wrapperPasswordRef.current.click();
    }
  }, []);

  return type === 'password' ? (
    <div
      ref={wrapperPasswordRef}
      className={`input-pass-container ${readonly ? 'readonly-input-container' : ''} ${className}`}
      onClick={() => (!readonly ? setIsActive(true) : setIsActive(false))}
    >
      <CustomPlaceholder
        readonly={readonly}
        isActive={isActive}
        value={rest.value}
        isOnlyPlaceholder={isOnlyPlaceholder}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        setIsActive={setIsActive}
        setFocus={setFocus}
      />

      <Input.Password
        ref={placeholderRef}
        pattern={pattern}
        onKeyPress={onKeyPress}
        onChange={onChange}
        disabled={disabled || readonly}
        {...rest}
      />
    </div>
  ) : (
    <div
      ref={wrapperRef}
      className={`input-container ${className} ${readonly ? 'readonly-input-container' : ''} `}
      onClick={() => (setIsActive(true))}
    >
      <CustomPlaceholder
        readonly={readonly}
        isActive={isActive}
        isOnlyPlaceholder={isOnlyPlaceholder}
        value={rest.value}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        setIsActive={setIsActive}
        setFocus={setFocus}
      />

      <Input
        defaultValue={defaultValue}
        ref={placeholderRef}
        disabled={disabled || readonly}
        pattern={pattern}
        onChange={customOnChange(withoutZero, type)}
        onKeyPress={
          checkKeyPressType(type)
        }
        type={type}
        {...rest}
      />
    </div>
  );
}

export default CustomInput;
