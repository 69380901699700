import { colors } from 'assets/css/variables';

function UsersIcon() {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0638 0C14.8607 0 14.6658 0.0808553 14.5222 0.224779C14.3786 0.368702 14.2979 0.563904 14.2979 0.767442C14.2979 0.97098 14.3786 1.16618 14.5222 1.31011C14.6658 1.45403 14.8607 1.53488 15.0638 1.53488C15.9441 1.53488 16.7883 1.88526 17.4108 2.50892C18.0333 3.13259 18.383 3.97847 18.383 4.86047C18.383 5.74246 18.0333 6.58834 17.4108 7.21201C16.7883 7.83567 15.9441 8.18605 15.0638 8.18605C14.8607 8.18605 14.6658 8.2669 14.5222 8.41083C14.3786 8.55475 14.2979 8.74995 14.2979 8.95349C14.2979 9.15703 14.3786 9.35223 14.5222 9.49615C14.6658 9.64007 14.8607 9.72093 15.0638 9.72093C16.3504 9.72093 17.5843 9.20885 18.494 8.29733C19.4038 7.38582 19.9149 6.14954 19.9149 4.86047C19.9149 3.57139 19.4038 2.33511 18.494 1.4236C17.5843 0.512083 16.3504 0 15.0638 0V0ZM17.1064 12.2791C16.9032 12.2791 16.7084 12.3599 16.5647 12.5038C16.4211 12.6478 16.3404 12.843 16.3404 13.0465C16.3404 13.2501 16.4211 13.4453 16.5647 13.5892C16.7084 13.7331 16.9032 13.814 17.1064 13.814H18.3319C19.4289 13.814 20.4809 14.2506 21.2566 15.0278C22.0323 15.8049 22.4681 16.859 22.4681 17.9581C22.4681 18.623 22.2044 19.2607 21.7352 19.7308C21.266 20.201 20.6295 20.4651 19.9659 20.4651H17.1064C16.9032 20.4651 16.7084 20.546 16.5647 20.6899C16.4211 20.8338 16.3404 21.029 16.3404 21.2326C16.3404 21.4361 16.4211 21.6313 16.5647 21.7752C16.7084 21.9191 16.9032 22 17.1064 22H19.9659C21.0358 22 22.0619 21.5742 22.8184 20.8162C23.575 20.0582 24 19.0301 24 17.9581C24 17.2124 23.8534 16.4739 23.5685 15.7849C23.2837 15.0958 22.8662 14.4698 22.3398 13.9424C21.8135 13.4151 21.1887 12.9968 20.501 12.7114C19.8133 12.426 19.0762 12.2791 18.3319 12.2791H17.1064Z"
        fill={colors.grayCardLight}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.59574 4.86047C4.59574 3.97847 4.94544 3.13259 5.5679 2.50892C6.19036 1.88526 7.0346 1.53488 7.91489 1.53488C8.79519 1.53488 9.63942 1.88526 10.2619 2.50892C10.8843 3.13259 11.234 3.97847 11.234 4.86047C11.234 5.74246 10.8843 6.58834 10.2619 7.21201C9.63942 7.83567 8.79519 8.18605 7.91489 8.18605C7.0346 8.18605 6.19036 7.83567 5.5679 7.21201C4.94544 6.58834 4.59574 5.74246 4.59574 4.86047ZM7.91489 0C6.62831 0 5.39442 0.512083 4.48467 1.4236C3.57492 2.33511 3.06383 3.57139 3.06383 4.86047C3.06383 6.14954 3.57492 7.38582 4.48467 8.29733C5.39442 9.20885 6.62831 9.72093 7.91489 9.72093C9.20148 9.72093 10.4354 9.20885 11.3451 8.29733C12.2549 7.38582 12.766 6.14954 12.766 4.86047C12.766 3.57139 12.2549 2.33511 11.3451 1.4236C10.4354 0.512083 9.20148 0 7.91489 0V0ZM5.66809 12.2791C4.16482 12.2791 2.72312 12.8774 1.66014 13.9424C0.597172 15.0075 0 16.452 0 17.9581C0 19.0301 0.425014 20.0582 1.18154 20.8162C1.93807 21.5742 2.96415 22 4.03404 22H11.7957C12.8656 22 13.8917 21.5742 14.6482 20.8162C15.4048 20.0582 15.8298 19.0301 15.8298 17.9581C15.8298 17.2124 15.6832 16.4739 15.3983 15.7849C15.1135 15.0958 14.696 14.4698 14.1696 13.9424C13.6433 13.4151 13.0185 12.9968 12.3308 12.7114C11.6431 12.426 10.906 12.2791 10.1617 12.2791H5.66809ZM1.53191 17.9581C1.53191 16.859 1.96769 15.8049 2.74337 15.0278C3.51905 14.2506 4.5711 13.814 5.66809 13.814H10.1617C11.2587 13.814 12.3107 14.2506 13.0864 15.0278C13.8621 15.8049 14.2979 16.859 14.2979 17.9581C14.2979 18.623 14.0343 19.2607 13.565 19.7308C13.0958 20.201 12.4594 20.4651 11.7957 20.4651H4.03404C3.37044 20.4651 2.73401 20.201 2.26477 19.7308C1.79553 19.2607 1.53191 18.623 1.53191 17.9581Z"
        fill={colors.blue}
      />
    </svg>
  );
}

export default UsersIcon;
