import { Col, Row } from 'antd';
import CustomPopover, { placementType } from 'components/globals/customPopover/CustomPopover';
import CustomSpinner from 'components/globals/spinner/CustomSpinner';
import { useDispatch } from 'react-redux';
import { inventoryActionCreator } from 'store/action-creators';

import './itemExtIdsToolTip.scss';

interface ItemExtIdsToolTipProps {
  item: any;
  position?: placementType;
  className?: string;
  getIdsForId?: boolean;
}

function ItemExtIdsToolTip({
  item, position = 'top', className = '', getIdsForId = false,
}: ItemExtIdsToolTipProps) {
  const dispatch = useDispatch();

  const handleGetExternalIds = () => {
    dispatch(inventoryActionCreator.featchExternalIds(item.item_id));
  };

  const extIdsArray = item?.external_ids?.map((extId: any) => ({
    type: extId.external_id_type_id?.name || extId?.name,
    value: extId.external_id_value,
  }));

  return (
    <CustomPopover
      className={`item-ext-id-tooltip ${position === 'topLeft' ? 'left-pos-popover' : ''} ${className}`}
      placement={position}
      onMouseEnterI={() => (getIdsForId ? handleGetExternalIds() : null)}
      content={
        extIdsArray?.length
          ? extIdsArray.map((extId: any) => (
            <Row className="ext-id-row">
              <Col span={10} className="ext-id-popover-ext-id-type">
                {extId.type}
                :
              </Col>
              <Col span={14} className="ext-id-popover-id-value">
                {extId.value}
              </Col>
            </Row>
          )) : (
            <Row className="spinner-ext-id-tooltip">
              <CustomSpinner />
            </Row>
          )
}
    />
  );
}

export default ItemExtIdsToolTip;
