import {
  IBin, IColumn, ILevel, ILocation, IRack, IRackBody, ISpecCondition,
  IAxiosResponse,
} from 'interfaces';
import { ELocationsAction } from './types';
import { notifier } from '../../../utils/notifier';
import { getCellCoordinates } from '../../../utils/getCellCoordinates';
import { tryCatchHandler } from '../../../utils/tryCatchHandler';
import { locationsAPI } from '../../../services';

export const locationsActionCreator = {
  setRacks: (racks: IRack[]) => ({
    type: ELocationsAction.SET_RACKS,
    payload: racks,
  }),
  setSelectedRack: (selectedRack: IRack) => ({
    type: ELocationsAction.SET_SELECTED_RACK,
    payload: selectedRack,
  }),
  setLocations: (locations: ILocation[]) => ({
    type: ELocationsAction.SET_LOCATIONS,
    payload: locations,
  }),
  setSelectedLocation: (selectedLocation: ILocation) => ({
    type: ELocationsAction.SET_SELECTED_LOCATION,
    payload: selectedLocation,
  }),
  setBins: (bins: IBin[]) => ({
    type: ELocationsAction.SET_BINS,
    payload: bins,
  }),
  setSelectedLevel: (level: number) => ({
    type: ELocationsAction.SET_SELECTED_LEVEL,
    payload: level,
  }),
  setSelectedColumn: (column: number) => ({
    type: ELocationsAction.SET_SELECTED_COLUMN,
    payload: column,
  }),
  setSpecialConditions: (spCond: ISpecCondition) => ({
    type: ELocationsAction.SET_SPECIAL_CONDITIONS,
    payload: spCond,
  }),
  fetchRacks: () => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: IAxiosResponse<IRack[]> = await locationsAPI.getRacks();

      const racks = data.map((rack: IRack) => ({
        ...rack,
        not_available: rack.not_available.map((item: string) => getCellCoordinates(item)),
        special_conditions: rack.special_conditions.map((item: string) => getCellCoordinates(item)),
        misc_items: rack.misc_items.map((item: string) => getCellCoordinates(item)),
        occupied: rack.occupied.map((item: string) => getCellCoordinates(item)),
        bins: rack.bins.map((bin: string) => getCellCoordinates(bin)),
      }));
      dispatch(locationsActionCreator.setRacks(racks));
    });
  },

  addRack: (rack: IRackBody) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { data } = await locationsAPI.postRack(rack);
    const { racks } = getState().locationsReducer;
    dispatch(locationsActionCreator.setRacks([...racks, ...data]));
    dispatch(locationsActionCreator.fetchRacks());
    notifier('success', 'Success!', 'Rack created');
    return data;
  }),
  updateRack: (rack: IRack) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const options = {
        description: rack.description,
        price: rack.price,
        active: rack.active,
        custom_name: rack.custom_name,
      };
      await locationsAPI.putRack(rack.id, options);
      dispatch(locationsActionCreator.fetchLocations(rack.id));
      dispatch(locationsActionCreator.fetchRacks());
      notifier('success', 'Success!', 'Changes are saved successfully');
    });
  },

  deleteRack: (rackId: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const data = await locationsAPI.deleteRack(rackId);

    dispatch(locationsActionCreator.fetchRacks());
    dispatch(locationsActionCreator.setSelectedRack(null));
    dispatch(locationsActionCreator.setSelectedLevel(0));
    dispatch(locationsActionCreator.setSelectedColumn(0));
    dispatch(locationsActionCreator.fetchRacks());

    notifier('success', 'Success!', 'Rack deleted');
    return data;
  }),
  createLevelOrColumn: (rackId: number, option: string) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      await locationsAPI.createLevelOrColumn(rackId, option);
      dispatch(locationsActionCreator.fetchRacks());
      dispatch(locationsActionCreator.fetchLocations(rackId));
    });
  },
  updateLevel: (rackId: number, levelNumber: number, level: ILevel) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const options = {
        level_number: levelNumber,
        height: +level.height,
        active: level.active,
      };
      await locationsAPI.patchLevel(rackId, options);
      dispatch(locationsActionCreator.fetchLocations(rackId));
      notifier('success', 'Success!', 'Changes are saved successfully');
    });
  },
  updateColumn: (rackId: number, columnNumber: number, column: IColumn) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const options = {
        column_number: columnNumber,
        width: +column.width,
        active: column.active,
      };
      await locationsAPI.patchColumn(rackId, options);
      dispatch(locationsActionCreator.fetchLocations(rackId));
      notifier('success', 'Success!', 'Changes are saved successfully');
    });
  },
  deleteLevel: (rackId: number, level: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      await locationsAPI.deleteLevel(rackId, level);
      dispatch(locationsActionCreator.fetchRacks());
    });
  },
  deleteColumn: (rackId: number, column: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      await locationsAPI.deleteColumn(rackId, column);
      dispatch(locationsActionCreator.fetchRacks());
    });
  },
  fetchLocations: (rackId: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data } = await locationsAPI.getLocations(rackId);
      dispatch(locationsActionCreator.setLocations(data));
    });
  },

  updateLocation: (rackId: number, location: ILocation) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const resp = await locationsAPI.putLocations({
      ...location,
      special_condition_ids: location.specialConditions.map((specCond: ISpecCondition) => specCond.id),
    });
    dispatch(locationsActionCreator.fetchLocations(rackId));
    notifier('success', 'Success!', 'Changes are saved successfully');
    return resp;
  }),

  fetchBins: (locationId: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data } = await locationsAPI.getBins(locationId);
      dispatch(locationsActionCreator.setBins(data));
    });
  },
  addBin: () => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const { selectedLocation, selectedRack } = getState().locationsReducer;

      await locationsAPI.postBin({ location_id: selectedLocation.id });
      await dispatch(locationsActionCreator.fetchLocations(selectedRack.id));
      await dispatch(locationsActionCreator.fetchBins(selectedLocation.id));
      await dispatch(locationsActionCreator.fetchRacks());
      notifier('success', 'Success!', 'Bin created');
    });
  },
  updateBin: (bin: IBin) => async (dispatch) => {
    await tryCatchHandler(
      dispatch,
      async () => {
        const resp = await locationsAPI.putBin(bin.id, bin);
        notifier('success', 'Success!', 'Bin updated');

        return resp;
      },
      '',
      false,
    );
  },
  deleteBin: (binId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await locationsAPI.deleteBin(binId);

      const { selectedLocation, selectedRack } = getState().locationsReducer;
      dispatch(locationsActionCreator.fetchRacks());
      await dispatch(locationsActionCreator.fetchLocations(selectedRack.id));
      await dispatch(locationsActionCreator.fetchBins(selectedLocation.id));

      notifier('success', 'Success!', 'Bin deleted');
    });
  },

  clearLocations: () => async (dispatch) => {
    dispatch(locationsActionCreator.setRacks([]));
    dispatch(locationsActionCreator.setSelectedRack(null));
    dispatch(locationsActionCreator.setLocations([]));
  },

  fetchSpecialConditions: () => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data } = await locationsAPI.getSpecialConditions();
      dispatch(locationsActionCreator.setSpecialConditions(data));
    });
  },
};
