import { Col } from 'antd';
import HeaderPlusIcon from '../../../assets/images/HeaderPlusIcon';
import './mainWrapper.scss';

function MainWrapper({
  title,
  icon = <HeaderPlusIcon />,
  iconAction,
  children,
  buttons,
  className,
  isFullScreen = true,
}: any) {
  return (
    <Col span={isFullScreen ? 24 : window.innerWidth >= 2500 ? 15 : 14}>
      <div className={`wrapper-main-block ${className}`}>
        <div className="wrapper-header">
          <div className="wrapper-header-title">{title}</div>
          {!buttons && (

          <div onClick={iconAction} className="wrapper-header-icon">
            {icon}
          </div>
          )}
          {
          buttons
        }
        </div>

        <div className="wrapper-body">{children}</div>
      </div>
    </Col>

  );
}

export default MainWrapper;
