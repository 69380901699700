import { colors } from 'assets/css/variables';

function OpenButtonIcon({ color = colors.blue }) {
  return (

    <svg width="9" height="5" viewBox="0 0 9 5" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.91763 1.06678C7.86377 1.02397 7.79148 1 7.7162 1C7.64092 1 7.56863 1.02397 7.51477 1.06678L4.5 3.45863L1.48583 1.06678C1.43198 1.02397 1.35968 1 1.2844 1C1.20912 1 1.13683 1.02397 1.08298 1.06678C1.05672 1.08759 1.03585 1.11247 1.02159 1.13995C1.00734 1.16744 1 1.19696 1 1.22679C1 1.25662 1.00734 1.28615 1.02159 1.31363C1.03585 1.34112 1.05672 1.366 1.08298 1.38681L4.28953 3.93033C4.34584 3.975 4.42136 4 4.5 4C4.57864 4 4.65416 3.975 4.71047 3.93033L7.91702 1.38681C7.94328 1.366 7.96415 1.34112 7.97841 1.31363C7.99266 1.28615 8 1.25662 8 1.22679C8 1.19696 7.99266 1.16744 7.97841 1.13995C7.96415 1.11247 7.94389 1.08759 7.91763 1.06678Z"
        fill={color}
        stroke={color}
        strokeWidth="0.5"
      />
    </svg>
  );
}

export default OpenButtonIcon;
