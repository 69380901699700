import { useEffect, useState } from 'react';

function OutsideClick(ref: any, isElemActive:any = true) {
  const [isClicked, setIsClicked] = useState(false);
  useEffect(() => {
    function handleClickOutside(event: any) {
      setIsClicked(ref.current && !ref.current.contains(event.target));
    }

    if (isElemActive) { document.addEventListener('mousedown', handleClickOutside); }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, isElemActive]);
  return isClicked;
}

export default OutsideClick;
