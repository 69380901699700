import { RootState } from 'App';
import { IRoute } from 'interfaces/routes';
import { useSelector } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { Routes } from '../../../constants/routes';
import { LoaderWrapper } from '../loading';
import { PageLayout } from '../pageLayout';

const routesArray: IRoute[] = Object.values(Routes);

function View() {
  const isLoading = useSelector((state: RootState) => state.generalReducer.isLoading);

  return (
    <Switch>
      {routesArray.map((route: IRoute) => (
        <Route path={route.path} key={route.path}>
          <LoaderWrapper isLoading={isLoading}>
            <PageLayout>
              <WithAuthWrapper childrenComponent={route.component} requiredAbilities={route.requiredAbilities} />
            </PageLayout>
          </LoaderWrapper>
        </Route>
      ))}
      <Redirect to="/404" />
    </Switch>
  );
}

export default View;
