import { Col, Row } from 'antd';
import { UploadPhoto } from 'components/globals';
import { IExternalId, IUser } from 'interfaces';
import { useTranslation } from 'react-i18next';

import './inventoryItemInfoBlock.scss';

interface InventoryItemInfoBlockProps {
  selectedItem: any;
  user: IUser;
  storeName: string;
}

function InventoryItemInfoBlock({ selectedItem, user, storeName }: InventoryItemInfoBlockProps) {
  const { t } = useTranslation();

  const extIdField = (extId: IExternalId) => (
    <div
      className={`${extId.default ? 'bold' : ''}`}
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {extId.external_id_type_id.name}
      :
      {'  '}
      {extId.external_id_value}
    </div>
  );

  const sortedExternalIdsArray = selectedItem?.external_ids?.sort((a: any, b: any) => b.default - a.default);
  return (
    <Row className="item-inventory-info-wrapper">
      <Col span={15}>
        <Row>
          <Col className="item-field" span={12}>
            <p className="item-field-label">Name</p>
            {selectedItem?.name}
          </Col>
          <Col className="item-field" span={12}>
            <p className="item-field-label">Store</p>
            {storeName}
          </Col>
          <div className="horisontal-line calc-width" />
          <Col className="item-field bold ext-id-title" span={24}>
            {t('items.externalIDs')}
          </Col>
          <Col className="col-external" span={24}>
            {sortedExternalIdsArray?.length ? (
              sortedExternalIdsArray?.map((extId: IExternalId) => (
                <Col className="item-field">
                  {extIdField(extId)}
                </Col>
              ))
            ) : (
              <p className="item-field">{t('items.noExternalIDs')}</p>
            )}
          </Col>
        </Row>
      </Col>
      <Col span={9}>
        <UploadPhoto preview readonly imageObject={selectedItem?.picture} />
      </Col>
      <div className="horisontal-line" />
      <Row
        style={{ display: 'flex', flexWrap: 'wrap' }}
        gutter={{
          sm: 32,
        }}
      >
        <Col className="gutter-row" span={5.5}>
          M Carton:
          {' '}
          {selectedItem?.mcarton}
        </Col>
        <Col className="gutter-row" span={5.5}>
          Carton:
          {' '}
          {selectedItem?.carton}
        </Col>
        <Col className="gutter-row" span={5.5}>
          Each:
          {' '}
          {selectedItem?.each}
        </Col>
        <Col className="gutter-row" span={8} style={{ fontWeight: 'bold' }}>
          <span style={{ flexWrap: 'nowrap' }}>
            {t('common.totalEaches')}
            :
          </span>
          {' '}
          {selectedItem?.total}
        </Col>
      </Row>
    </Row>
  );
}

export default InventoryItemInfoBlock;
