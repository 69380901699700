import { generalActionCreator } from '../store/modules/general/action-creators';

async function tryCatchHandler(dispatch: any, callback: any, errorMesage?: any, enableLoading = true) {
  try {
    if (enableLoading) { dispatch(generalActionCreator.setIsLoading(1)); }
    const returnValue = await callback();
    return returnValue;
  } catch (error:any) {
    if (errorMesage) {
      dispatch(generalActionCreator.setError(errorMesage));
    } else if (error.response?.data) {
      let message = 'Error | ';
      message += (typeof error.response.data === 'string')
        ? error.response.data
        : Array.isArray(error.response.data.errors)
          ? error.response.data.errors[0].message
          : 'Try again';
      dispatch(generalActionCreator.setError(String(message)));
    } else if (error.message) {
      const message = `Error | ${error.message}`;
      dispatch(generalActionCreator.setError(String(message)));
    }

    return false;
  } finally {
    if (enableLoading) dispatch(generalActionCreator.setIsLoading(-1));
  }
}

export { tryCatchHandler };
