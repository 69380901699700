import { RootState } from 'App';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { IOrderItem } from 'interfaces';
import { useEffect } from 'react';
import {
  CustomButton, CustomSearch, CustomTable, ModalWindow,
} from '../../../globals';
import { itemColumns } from '../../../../constants/orders/ordersData';

import './AddItemModal.scss';

interface AddItemModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean) => void;
  itemToAdd: IOrderItem;
  setItemToAdd: (item: IOrderItem) => void;
  addOrderItem?: () => void;
  items?: any;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSearch: any;
  setIsRemoveSearchClicked?: (value: boolean) => void;
}

function AddItemModal({
  isModalVisible,
  setIsModalVisible,
  itemToAdd,
  setItemToAdd,
  addOrderItem,
  items,
  searchValue,
  setSearchValue,
  setIsRemoveSearchClicked,
  onSearch,
}: AddItemModalProps) {
  const history = useHistory();
  const externalIdsParams = useSelector((state: RootState) => state.inventoryReducer.external_ids);

  useEffect(() => {
    setSearchValue('');
  }, [isModalVisible]);

  return (
    <ModalWindow
      className="orders_add-item-modal-body"
      title="Items"
      handleCancelModal={() => setIsModalVisible(false)}
      isModalVisible={isModalVisible}
    >
      <div className="search-box">
        <CustomSearch
          placeholder="Search"
          width={439}
          value={searchValue}
          className="search-item"
          onChange={(e) => setSearchValue(e.target.value)}
          onSearch={onSearch}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          setIsRemoveSearchClicked={setIsRemoveSearchClicked}
        />
        {!searchValue && (
          <CustomButton onClick={() => history.push('/items')} className="search-button">
            +
          </CustomButton>
        )}
      </div>
      {items?.length ? (
        <CustomTable
          scroll={{ y: 268 }}
          className="modal-table"
          noSearch
          noPagination
          columns={itemColumns}
          itemClick={(item) => {
            setItemToAdd(item);
          }}
          tableData={items.map((item) => ({
            ...item,
            item_id: item.id,
            externalId: item?.external_ids?.find((extID) => extID?.default)?.external_id_value || '',
            external_ids: externalIdsParams[item.item_id],
          }))}
        />
      ) : (
        <div className="no-data-block">No data</div>
      )}

      <CustomButton className="add-item-button" onClick={addOrderItem} disabled={!itemToAdd || !items?.length}>
        Add +
      </CustomButton>
    </ModalWindow>
  );
}

export default AddItemModal;
