import { useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { CustomButton } from 'components/globals';
import CustomDatePicker from 'components/globals/CustomDatePicker/CustomDatePicker';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { useDispatch } from 'react-redux';
import moment, { Moment } from 'moment';

import { InventoryReportRow } from 'components/globals/filesForPrintPDF';
import { pageStylePrint } from './pageStylePrint';

import './CurrentRowInfo.scss';
import { ReportType } from '../../../../constants/reportType';
import { StoreReportRow } from '../../../globals/filesForPrintPDF/StoreReportRow/StoreReportRow';
import { clearReportState, fetchReportState, getStateFromSelector } from '../../../../utils';
import ReportXLS from './ReportExcel/ReportXLS';
import { configs } from '../../../../utils/getEnv';

export const REPORT_TYPE = 'REPORT_TYPE';
export const EXCEL_TYPE = 'EXCEL_TYPE';

function ReportRow({
  selectedId,
  type,
  name,
  isWareHouseAdmin,
  pickerStart,
  pickerEnd,
  setPickerStart,
  setPickerEnd,
}: {
  selectedId: number,
  type: ReportType,
  name: string,
  isWareHouseAdmin?: boolean;
  pickerStart: Moment | null,
  pickerEnd: Moment | null,
  setPickerStart: (value: Moment | null) => void,
  setPickerEnd: (value: Moment | null) => void}) {
  const [reportType, setReportType] = useState('');

  const { t } = useTranslation();

  const printRef = useRef();
  const dispatch = useDispatch();
  const selectRowReport = getStateFromSelector(type);
  const MIN_DATE_FOR_STOCK_REPORT = configs.REACT_APP_MIN_DATE_FOR_STOCK_REPORT;

  const handleReportOrExcel = (typeHandle) => {
    const timeRefactor = (time) => moment.utc(time).format('YYYY-MM-DD HH:mm:ss');
    fetchReportState(type, selectedId, timeRefactor(pickerStart), timeRefactor(pickerEnd), dispatch);

    setReportType(typeHandle);
  };

  const handlePrint = useReactToPrint({
    pageStyle: pageStylePrint,
    copyStyles: true,
    content: () => printRef.current,
    documentTitle: `Inventory report ${moment(new Date(), 'MM.DD.YYYY').format('MM.DD.YYYY')}`,
  });

  const handleDatePick = (time, type, setPickerState) => {
    setPickerState(time);
    clearReportState(type, dispatch);
  };

  const conditionForReportType = selectRowReport && reportType === REPORT_TYPE;

  return (
    <div className="create-report-row">
      <Row justify="space-between" align="middle" className="date-picker">

        <Col>
          <CustomDatePicker
            placheholder="mm/dd/yyyy"
            placheholderTitle={type === ReportType.STOCK ? 'On date' : 'Period from'}
            value={pickerStart}
            minDate={type === ReportType.STOCK ? moment(MIN_DATE_FOR_STOCK_REPORT, 'YYYY-MM-DD') : undefined}
            handleChange={(time) => handleDatePick(time, type, setPickerStart)}
          />
        </Col>

        {type !== ReportType.STOCK && (
        <Col>
          <CustomDatePicker
            placheholder="mm/dd/yyyy"
            placheholderTitle="To"
            value={pickerEnd}
            minDate={pickerStart}
            handleChange={(time) => handleDatePick(time, type, setPickerEnd)}
          />
        </Col>
        )}

        {type !== ReportType.STOCK && (
        <CustomButton disabled={!(pickerStart && pickerEnd)} onClick={() => handleReportOrExcel(REPORT_TYPE)}>
          {t('locations.createReport')}
        </CustomButton>
        )}

        <ReportXLS
          data={selectRowReport}
          disabled={type !== ReportType.STOCK ? !(pickerStart && pickerEnd) : !pickerStart}
          reportType={reportType}
          type={type}
          setReportType={setReportType}
          handleReport={() => handleReportOrExcel(EXCEL_TYPE)}
          isWareHouseAdmin={isWareHouseAdmin}
          from={moment(pickerStart, 'MM.DD.YYYY').format('DD.MM.YYYY')}
          to={moment(pickerEnd, 'MM.DD.YYYY').format('DD.MM.YYYY')}
          itemOrStoreName={name}
        />
      </Row>
      {conditionForReportType && type !== ReportType.STOCK && (
      <>
        <div className="body-report" ref={printRef}>
          {
            type === ReportType.INVENTORY && pickerStart && pickerEnd && (
            <InventoryReportRow
              data={selectRowReport}
              time={{ pickerStart, pickerEnd }}
            />
            )
          }
          {
            type === ReportType.STORE && pickerStart && pickerEnd && (
            <StoreReportRow
              data={selectRowReport}
              time={{ pickerStart, pickerEnd }}
              storeName={name}
            />
            )
          }
          <div className="autorized-signature">
            <hr />
            Autorized Signature
          </div>
        </div>
        {conditionForReportType
        && (
        <Row justify="end" className="btn-print-report-wrap">
          <CustomButton className="btn-print-report" onClick={handlePrint}>
            {t('locations.print')}
          </CustomButton>
        </Row>
        )}
      </>
      )}
    </div>
  );
}

export default ReportRow;
