/* eslint-disable no-nested-ternary */
/* eslint-disable no-unsafe-optional-chaining */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { RootState } from 'App';
import { IOrderMeasurementUnit, ISelectedOrder } from 'interfaces';
import { timeoutToSave } from 'constants/saveOnFlyTimeout';
import { InboundOrderStatus, orderType } from 'constants/orders/status';
import { ConfirmationModal } from 'components/confirmationModal';
import { useTranslation } from 'react-i18next';
import { ordersActionCreator } from '../../../../store/action-creators';
import {
  ButtonWithSelect, CustomButton, CustomInput, CustomSelect,
} from '../../../globals';
import { BtnInsideInput } from '../../../globals/buttons';

import './MeasurementUnit.scss';

interface MeasurementUnitProps {
  unit: IOrderMeasurementUnit;
  measurement_unit_options: any[];
  removeUnit: (unitId: number, id?: number) => void;
  colItems?: any;
  orderId: number;
  itemId: number;
  selectedOrder: ISelectedOrder;
  isResultUom?: boolean;
  isReadOnlyByUserRole?: boolean;
}

function MeasurementUnit({
  unit,
  measurement_unit_options = [],
  removeUnit,
  colItems,
  orderId,
  itemId,
  selectedOrder,
  isResultUom = false,
  isReadOnlyByUserRole = false,
}: MeasurementUnitProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [unitValue, setUnitValue] = useState(null);
  const [delayTimeout, setDelayTimeout] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isBtnWithSelectDisabled, setIsBtnWithSelectDisabled] = useState(false);

  const isRecievedItem = unitValue?.is_received;

  const conditions = useSelector((state: RootState) => state.ordersReducer.conditions);
  const conditionOptions = conditions.map((c) => ({
    value: c.id,
    content: c.name,
  }));

  const inTransitCondition = conditions.find((c) => c.name === 'In Transit');
  const lostCondition = conditions.find((c) => c.name === 'Lost');
  const isOnGate = selectedOrder?.type === orderType.ON_GATE;

  const [colorSelect, setColorSelect] = useState('');
  const [isDisabledContains, setIsDisabledContains] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const isReadOnlyField = !isOnGate && !isResultUom && selectedOrder?.status !== InboundOrderStatus.Created;

  const isConditionSelectAvailable = isResultUom
    && (selectedOrder?.status === InboundOrderStatus.Arrived
      || selectedOrder?.status === InboundOrderStatus.PartiallyArrived);

  const each = measurement_unit_options.find((mu) => mu.content === 'Each');

  const carton = measurement_unit_options.find((mu) => mu.content === 'Carton');

  const masterCarton = measurement_unit_options.find((mu) => mu.content === 'M Сarton');

  const setNewValueMeasurementUnit = async (value, withSave = true) => {
    const newValue = { ...unitValue, item_id: itemId, ...value };
    setIsBtnWithSelectDisabled(true);
    setUnitValue(newValue);

    const isCondition = withSave && +newValue.quantity && (newValue.carton_id ? +newValue.contains : true);

    if (isCondition) {
      if (delayTimeout) {
        clearTimeout(delayTimeout);
      }
      setDelayTimeout(
        setTimeout(async () => {
          await dispatch(ordersActionCreator.updateMeasurementUnit(orderId, newValue));
          setIsBtnWithSelectDisabled(false);
        }, timeoutToSave),
      );
    }
  };

  const getUnitMU_OptionValue = (mu) => {
    if (mu?.each_id) return each?.content;
    if (mu?.carton_id) {
      // master carton has carton_id: number, custom carton has carton_id: true
      if (typeof mu.carton_id === 'number') {
        return masterCarton?.content;
      }
      return carton?.content;
    }

    return 0;
  };

  const setMU = (UOM_name: string) => {
    const clearOptions = { each_id: null, carton_id: null };
    setIsDisabledContains(false);
    if (UOM_name === each.value) setNewValueMeasurementUnit({ ...clearOptions, each_id: each.id });
    if (UOM_name === carton.value) setNewValueMeasurementUnit({ ...clearOptions, carton_id: true, contains: 1 });
    if (masterCarton && UOM_name === masterCarton.value) {
      setNewValueMeasurementUnit({ ...clearOptions, carton_id: masterCarton.id, contains: masterCarton.contains });
      setIsDisabledContains(true);
    }
  };

  const splitUnit = async (splitQuantity: number) => {
    const splittedMU = {
      id: null,
      each_id: unitValue?.each_id,
      carton_id: unitValue?.carton_id,
      condition_id: unitValue?.condition_id,
      quantity: +splitQuantity,
    };

    if (unitValue?.contains) Object.assign(splittedMU, { contains: unitValue?.contains });

    if (unitValue?.id) {
      // Id of newly created UOM after split

      // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '(dispatch: a... Remove this comment to see the full error message
      const { id } = await dispatch(
        ordersActionCreator.splitMeasurementUnit(unitValue?.id, +splitQuantity, unitValue?.quantity),
      );
      Object.assign(splittedMU, { id });
    }

    if (splittedMU.id) {
      setNewValueMeasurementUnit({ quantity: +unitValue?.quantity - +splitQuantity });
    }
    setIsDropdownActive(false);
  };

  const mergeClick = async () => {
    await dispatch(ordersActionCreator.mergeMeasurementUnit(unitValue?.id));
    setIsDropdownActive(false);
  };

  const setConditionColor = (condition_id: number) => {
    const foundCondition = conditions.find((c) => c.id === condition_id);
    if (!foundCondition) setColorSelect('');
    else {
      switch (foundCondition.name) {
        case 'In Transit':
          setColorSelect('in-transit');
          break;
        case 'Damaged':
          setColorSelect('damaged');
          break;
        case 'Lost':
          setColorSelect('lost');
          break;
        default:
          setColorSelect('ok');
      }
    }
  };

  const receiveUnit = async () => {
    const response = await dispatch(ordersActionCreator.receiveMeasurementUnit(unitValue?.id, orderId));

    if (response) {
      setNewValueMeasurementUnit({ is_received: true });
    }
  };

  const onChangeCondition = (condition_id: number) => {
    setNewValueMeasurementUnit({ condition_id });
    setConditionColor(condition_id);
  };

  useEffect(() => {
    if (unit) {
      setUnitValue(unit);
    }
  }, [unit]);

  useEffect(() => {
    // if master carton
    if (typeof unitValue?.carton_id === 'number') {
      setIsDisabledContains(true);
    }
    setConditionColor(unitValue?.condition_id);
  }, [unitValue]);

  const UomSelectCol = (
    <CustomSelect
      className="uom-select"
      disabled={isRecievedItem}
      readonly={isReadOnlyField || isReadOnlyByUserRole}
      options={measurement_unit_options}
      value={getUnitMU_OptionValue(unitValue)}
      onChange={setMU}
    />
  );

  const MeasurementInputCol = (
    <CustomInput
      className={`measurement-input ${!+unitValue?.quantity ? 'error-input' : ''}`}
      disabled={isRecievedItem}
      placeholder=""
      readonly={isReadOnlyField || isReadOnlyByUserRole}
      value={unitValue?.quantity}
      type="numbers"
      onChange={(e) => {
        setNewValueMeasurementUnit({ quantity: e.target.value });
      }}
    />
  );

  const ContainsInputCol = unitValue?.carton_id ? (
    <CustomInput
      className={`contains-input ${!+unitValue?.contains ? 'error-input' : ''}`}
      placeholder=""
      readonly={isReadOnlyField || isDisabledContains || isRecievedItem || isReadOnlyByUserRole}
      value={unitValue?.contains}
      type="numbers"
      onChange={(e) => {
        setNewValueMeasurementUnit({ contains: e.target.value });
      }}
    />
  ) : (
    <div>&nbsp;</div>
  );

  const ContainsSelectCol = (
    <CustomSelect
      className={`condition-select ${colorSelect}`}
      options={conditionOptions}
      disabled={isRecievedItem}
      readonly={isReadOnlyByUserRole}
      value={unitValue?.condition_id}
      onChange={(condition_id: number) => {
        onChangeCondition(condition_id);
      }}
    />
  );

  const MeasurementColumns = [
    { id: 1, component: UomSelectCol, className: 'unit-type' },
    { id: 2, component: MeasurementInputCol, className: 'unit-quantity' },
    { id: 3, component: ContainsInputCol, className: 'unit-contains' },
    { id: 4, component: isOnGate || isConditionSelectAvailable ? ContainsSelectCol : null, className: 'unit-item' },
  ];

  return (
    <Row gutter={10} className="unit-wrapper">
      {colItems.map((headerCol) => MeasurementColumns.map(
        (col) => headerCol.id === col.id && (
          <Col
            key={col.id}
            span={headerCol.span}
            className={`${col.className} ${isRecievedItem ? 'received-item' : ''}`}
          >
            {col.component}
          </Col>
        ),
      ))}
      <Col className="justify-end col-btn" span={5}>
        {isOnGate ? (
          !isRecievedItem ? (
            <ButtonWithSelect
              className={`${isReadOnlyByUserRole ? 'd-none' : ''}`}
              mainButtonProps={{
                handleClick: () => {
                  setIsModalVisible(true);
                },
                title: unitValue?.condition_id === lostCondition?.id ? 'Admit' : 'Recieve',
              }}
              important
              isDropdownActive={isDropdownActive}
              setIsDropdownActive={setIsDropdownActive}
              disabled={unitValue?.condition_id === inTransitCondition?.id || isBtnWithSelectDisabled}
            >
              <CustomButton
                className="remove-btn"
                onClick={() => {
                  removeUnit(unitValue?.id);
                  setIsDropdownActive(false);
                }}
              >
                Remove
              </CustomButton>
              {+unitValue?.quantity > 1 && (
                <BtnInsideInput type="split" count={unitValue?.quantity} handleClick={splitUnit} />
              )}
              <CustomButton onClick={mergeClick}> Merge same</CustomButton>
            </ButtonWithSelect>
          ) : (
            <div className={`received-text ${isReadOnlyByUserRole ? 'd-none' : ''}`}>
              {unitValue?.condition_id === lostCondition?.id ? 'Admited' : 'Recieved'}
            </div>
          )
        ) : !isReadOnlyByUserRole ? (
          !isRecievedItem ? (
            <ButtonWithSelect
              mainButtonProps={{
                handleClick: () => {
                  // eslint-disable-next-line no-unused-expressions
                  !isOnGate && !isResultUom ? mergeClick() : setIsModalVisible(true);
                },
                title:
                  !isOnGate && !isResultUom
                    ? 'Merge'
                    : unitValue?.condition_id === lostCondition?.id
                      ? 'Admit'
                      : 'Recieve',
              }}
              important={!isOnGate && isResultUom}
              isDropdownActive={isDropdownActive}
              setIsDropdownActive={setIsDropdownActive}
              disabled={unitValue?.condition_id === inTransitCondition?.id || isBtnWithSelectDisabled}
              className={`${isReadOnlyField ? 'display-none' : ''}`}
            >
              {+unitValue?.quantity > 1 && (
                <BtnInsideInput type="split" count={unitValue?.quantity} handleClick={splitUnit} />
              )}
              {!isOnGate && isResultUom && <CustomButton onClick={mergeClick}> Merge same</CustomButton>}
              <CustomButton
                className="remove-btn"
                onClick={() => {
                  removeUnit(unitValue?.id);
                  setIsDropdownActive(false);
                }}
              >
                Remove
              </CustomButton>
            </ButtonWithSelect>
          ) : (
            <div className="received-text">
              {unitValue?.condition_id === lostCondition?.id ? 'Admited' : 'Recieved'}
            </div>
          )
        ) : null}
      </Col>
      <ConfirmationModal
        isModalVisible={isModalVisible}
        confirmText={t('orders.confirmationCorrectQuantity')}
        confirmAction={() => receiveUnit()}
        closeCallback={() => setIsModalVisible(!isModalVisible)}
      />
    </Row>
  );
}

export default MeasurementUnit;
