import React, { useState, useEffect, useMemo } from 'react';
import { Col, Form, Row } from 'antd';
import {
  CustomButton, CustomInput, CustomSelect, SelectWithSearch,
} from 'components/globals';
import { canadaStates, usStates } from 'constants/outbound/states';
import { countryList } from 'constants/users/counrties';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { measurementSystemOptions } from 'constants/items/itemsData';
import { warehousesActionCreator } from 'store/modules/warehouses/action-creators';
import { IWarehouse } from 'interfaces/warehouses';

interface WarehouseInfoBlockProps {
  selectedWarehouse: IWarehouse;
}

function WarehouseInfoBlock({ selectedWarehouse }: WarehouseInfoBlockProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [country, setCountry] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [isCountryUpdated, setIsCountryUpdated] = useState(false);

  const countryOptions = countryList.map((country, index) => ({
    value: country,
    id: index,
  }));
  const usStatesOptions = usStates.map((state, index) => ({ value: state.name, id: index }));
  const canadaStatesOptions = canadaStates.map((state, index) => ({ value: state.name, id: index }));

  const initialValues: IWarehouse = useMemo(() => {
    let result = null;
    if (selectedWarehouse) {
      result = {
        address_1: selectedWarehouse.address_1,
        city: selectedWarehouse.city,
        name: selectedWarehouse.name,
        country: selectedWarehouse.country,
        email: selectedWarehouse.email,
        phone: selectedWarehouse.phone,
        postal_code: selectedWarehouse.postal_code,
        state: selectedWarehouse.state,
        fax: selectedWarehouse.fax,
        metric: selectedWarehouse.metric,
      };
    }
    return result;
  }, [selectedWarehouse]);

  const save = async (body: IWarehouse) => {
    body.id = selectedWarehouse?.id;
    if (selectedWarehouse?.id) {
      await dispatch(warehousesActionCreator.updatehWarehouse(body));
    } else {
      await dispatch(warehousesActionCreator.createWarehouse(body));
    }

    setSaveDisabled(true);
  };

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    setIsCountryUpdated(false);
  }, [isCountryUpdated]);
  return (
    <div>
      <Form
        form={form}
        onFinish={save}
        onFieldsChange={() => {
          setSaveDisabled(false);
        }}
      >
        <Row gutter={[18, 24]}>
          <Col className="col-padding" span={12}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `${t('warehouses.warehouseName')} is required`,
                },
                {
                  min: 3,
                  message: `${t('warehouses.warehouseName')} should be at least 3 signs`,
                },
              ]}
            >
              <CustomInput required placeholder={t('warehouses.warehouseName')} />
            </Form.Item>
          </Col>
          <Col className="col-padding" span={12}>
            <Form.Item
              name="address_1"
              rules={[
                {
                  required: true,
                  message: `${t('warehouses.address')} is required`,
                },
              ]}
            >
              <CustomInput required placeholder={t('warehouses.address')} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: `${t('warehouses.cityTown')} is required`,
                },
              ]}
            >
              <CustomInput required placeholder={t('warehouses.cityTown')} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: `${t('warehouses.country')} is required`,
                },
              ]}
            >
              <SelectWithSearch
                required
                placeholder="Country"
                onChange={(country) => {
                  if (!country.length) {
                    setIsCountryUpdated(true);
                    form.setFieldsValue({ state: '' });
                  }
                  setCountry(country);
                  form.setFieldsValue({ state: '' });
                }}
                onSelect={(country) => {
                  setCountry(country);
                  form.setFieldsValue({ state: '' });
                }}
                options={countryOptions}
                setNothingSelected={(isNothingSelected) => setSaveDisabled(isNothingSelected)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="postal_code"
              normalize={(input) => input.toUpperCase()}
              rules={[
                {
                  required: true,
                  message: `${t('warehouses.zipPostal')} is required`,
                },
              ]}
            >
              <CustomInput required placeholder={t('warehouses.zipPostal')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="state"
              rules={[
                {
                  required: country === 'Canada' || country === 'USA',
                  message: `${t('warehouses.stateProvince')} is required`,
                },
              ]}
            >
              {country === 'Canada' || country === 'USA' ? (
                <SelectWithSearch
                  options={country === 'Canada' ? canadaStatesOptions : usStatesOptions}
                  onSelect={(value) => value}
                  required={country === 'Canada' || country === 'USA'}
                  placeholder={t('warehouses.stateProvince')}
                />
              ) : (
                <CustomInput placeholder={t('warehouses.stateProvince')} />
              )}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: `${t('common.phone')} is required`,
                  type: 'string',
                },
                {
                  min: 8,
                  message: `${t('common.phone')} should be at least 8 signs`,
                },
              ]}
            >
              <CustomInput required type="phones" placeholder={t('common.phone')} />
            </Form.Item>
          </Col>

          <Col className="item-select-ms-wrapper" span={12}>
            <Form.Item
              name="metric"
              rules={[
                {
                  required: true,
                  message: 'Measurement system is required',
                },
              ]}
            >
              <CustomSelect required placeholder="Measurement system" options={measurementSystemOptions} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: `${t('common.email')} is required`,
                },
                {
                  type: 'email',
                  message: 'Should be a valid email',
                },
              ]}
            >
              <CustomInput required placeholder={t('common.email')} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="fax">
              <CustomInput placeholder={t('warehouses.fax')} />
            </Form.Item>
          </Col>

          <Col span={5} offset={19}>
            <Form.Item>
              <CustomButton disabled={saveDisabled} htmlType="submit">
                {t('common.save')}
              </CustomButton>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default WarehouseInfoBlock;
