import { CustomButton } from 'components/globals';
import { useHistory } from 'react-router-dom';

import './notFound.scss';

function NotFound() {
  const histoty = useHistory();

  return (
    <div className="not-found-page-wrapper">
      <p className="text-404">404</p>
      <p className="error-text-not-found">Page not found</p>
      <p className="error-text">This page is in develop</p>
      <p className="error-text">Coming soon!</p>

      <CustomButton className="go-back-404-btn" onClick={() => histoty.push('/items')}>Go back!</CustomButton>
    </div>
  );
}

export default NotFound;
