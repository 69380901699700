import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { IBin } from 'interfaces';
import { TrashIcon } from 'assets/images';
import { timeoutToSave } from 'constants/saveOnFlyTimeout';
import { CustomButton, CustomInput } from '../../../../globals';
import { locationsActionCreator } from '../../../../../store/modules/locations/action-creators';
import { checkMinAndMax } from '../../../../../utils/checkMinAndMaxRangeInInput';

import './bin.scss';

interface BinProps {
  bin: IBin;
  setNewValue: (newValue, index?: number) => void;
  isReadonlyByUserRole: boolean;
}

function Bin({ bin, setNewValue, isReadonlyByUserRole }: BinProps) {
  const dispatch = useDispatch();

  const [isDeleteBin, setIsDeleteBin] = useState(false);
  const [form] = Form.useForm();
  const [delayTimeout, setDelayTimeout] = useState(null);

  const onTrashClick = () => {
    setIsDeleteBin(true);
  };

  const deleteBin = async () => {
    await dispatch(locationsActionCreator.deleteBin(bin.id));
  };

  const autosave = (newValue) => {
    if (delayTimeout) {
      clearTimeout(delayTimeout);
    }
    // TODO create update bin in action createors and locationsApi

    setDelayTimeout(
      setTimeout(() => {
        dispatch(locationsActionCreator.updateBin({ ...bin, ...newValue }));
      }, timeoutToSave),
    );
  };

  const handleChange = async (newValue) => {
    setNewValue(newValue);
    try {
      await form.validateFields();
      autosave(newValue);
    } catch (error) {
      clearTimeout(delayTimeout);
    }
  };

  useEffect(() => {
    form.setFieldsValue(bin);
  }, [bin]);

  return !isDeleteBin ? (
    <div className="bin-container">
      <div className="bin-header">
        <div className="bin-name">{bin.name}</div>

        {!isReadonlyByUserRole && (
        <div onClick={() => onTrashClick()} className="delete-bin-button-wrapper">
          <TrashIcon />
        </div>
        )}

      </div>

      <Form form={form}>
        <Row style={{ padding: '24px' }} gutter={24}>
          <Col span={6}>
            <Form.Item
              name="width"
              rules={[
                {
                  required: true,
                  message: 'Width is required',
                },
                checkMinAndMax('width', 1, 500),
              ]}
            >
              <CustomInput
                required
                readonly={isReadonlyByUserRole}
                placeholder="Width/CM"
                onChange={(e) => handleChange({ width: e.target.value })}
                type="numbers"
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="height"
              rules={[
                {
                  required: true,
                  message: 'Height is required',
                },
                checkMinAndMax('height', 1, 500),
              ]}
            >
              <CustomInput
                required
                readonly={isReadonlyByUserRole}
                placeholder="Height/CM"
                onChange={(e) => handleChange({ height: e.target.value })}
                type="numbers"
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="depth"
              rules={[
                {
                  required: true,
                  message: 'Depth is required',
                },
                checkMinAndMax('depth', 1, 500),
              ]}
            >
              <CustomInput
                required
                readonly={isReadonlyByUserRole}
                placeholder="Depth/CM"
                onChange={(e) => handleChange({ depth: e.target.value })}
                type="numbers"
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              name="price"
              rules={[
                {
                  required: true,
                  message: 'Price are required',
                },

                checkMinAndMax('price', 1),
              ]}
            >
              <CustomInput
                isOnlyPlaceholder
                readonly={isReadonlyByUserRole}
                placeholder="Price"
                onChange={(e) => handleChange({ price: e.target.value })}
                type="numbers"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  ) : (
    <div className="delete-bin-alert-block">
      <span>Are you sure?</span>

      <CustomButton className="bin-alert button" onClick={deleteBin}>
        Yes
      </CustomButton>

      <CustomButton className="bin-alert button no-btn" onClick={() => setIsDeleteBin(false)}>
        No
      </CustomButton>
    </div>
  );
}
export default Bin;
