import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { RootState } from 'App';
import { IRack } from 'interfaces';
import { infoBlockModes } from '../../../../constants/infoBlockModes';
import { locationsActionCreator } from '../../../../store/modules/locations/action-creators';
import { checkMinAndMax } from '../../../../utils/checkMinAndMaxRangeInInput';
import {
  CustomButton, CustomCheckbox, CustomInput, CustomTextArea,
} from '../../../globals';

import './rackInfo.scss';

interface RackInfoProps {
  rack: IRack;
  mode: infoBlockModes;
  isReadonlyByUserRole: boolean;
  setMode: (mode: infoBlockModes) => void;
}

function RackInfo({
  mode, rack, setMode, isReadonlyByUserRole,
}: RackInfoProps) {
  const [form] = Form.useForm();

  const selectedRack = useSelector((state: RootState) => state.locationsReducer.selectedRack);

  const dispatch = useDispatch();
  const [saveDisabled, setSaveDisabled] = useState(true);

  const save = async () => {
    await dispatch(locationsActionCreator.updateRack(rack));
    setMode(infoBlockModes.VIEW);
  };

  const setNewValue = (newValue: any) => {
    dispatch(locationsActionCreator.setSelectedRack({ ...selectedRack, ...newValue }));
    setSaveDisabled(false);
  };

  useEffect(() => {
    form.setFieldsValue(selectedRack);
  }, [selectedRack]);

  return (
    <Form form={form} onFinish={save}>
      <Row className="rack-info-form-block" gutter={[24, 24]}>
        <Col className="rack-info-name-input" span={12}>
          <Form.Item
            name="custom_name"
            rules={[
              {
                min: 2,
                message: 'Name length should be at least 2 signs',
              },
            ]}
          >
            <CustomInput
              onChange={(e: any) => setNewValue({ custom_name: e.target.value?.trim() })}
              required
              readonly={isReadonlyByUserRole}
              placeholder="Name(Optional)"
              disabled={mode === infoBlockModes.VIEW}
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <div>
            <Row className="rack-def-values-block" gutter={[24, 24]}>
              <Col span={24} className="default-values-title">
                Default values
              </Col>

              <Col span={12}>
                <Form.Item
                  name="levels"
                  rules={[
                    {
                      required: true,
                      message: 'Levels are required',
                    },
                    checkMinAndMax('levels', 1, 50),
                  ]}
                >
                  <CustomInput
                    className="readonly-field"
                    type="numbers"
                    required
                    readonly={isReadonlyByUserRole}
                    placeholder="Levels"
                    onChange={(e: any) => setNewValue({ levels: e.target.value })}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="columns"
                  rules={[
                    {
                      required: true,
                      message: 'Columns are required',
                    },
                    checkMinAndMax('columns', 1, 50),
                  ]}
                >
                  <CustomInput
                    className="readonly-field"
                    type="numbers"
                    required
                    readonly={isReadonlyByUserRole}
                    placeholder="Columns"
                    onChange={(e: any) => setNewValue({ columns: e.target.value })}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="cell_width"
                  rules={[
                    {
                      required: true,
                      message: 'Width are required',
                    },
                    checkMinAndMax('cell_width', 1, 500),
                  ]}
                >
                  <CustomInput
                    className="readonly-field"
                    type="numbers"
                    required
                    readonly={isReadonlyByUserRole}
                    placeholder="Width/CM"
                    onChange={(e: any) => setNewValue({ cell_width: e.target.value })}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="cell_height"
                  rules={[
                    {
                      required: true,
                      message: 'Height are required',
                    },
                    checkMinAndMax('cell_height', 1, 500),
                  ]}
                >
                  <CustomInput
                    className="readonly-field"
                    type="numbers"
                    required
                    readonly={isReadonlyByUserRole}
                    placeholder="Height/CM"
                    onChange={(e: any) => setNewValue({ cell_height: e.target.value })}
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="cell_depth"
                  rules={[
                    {
                      required: true,
                      message: 'Depth are required',
                    },
                    checkMinAndMax('cell_depth', 1, 500),
                  ]}
                >
                  <CustomInput
                    className="readonly-field"
                    onChange={(e: any) => setNewValue({ cell_depth: e.target.value })}
                    type="numbers"
                    required
                    readonly={isReadonlyByUserRole}
                    placeholder="Depth/CM"
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: 'Price are required',
                    },

                    checkMinAndMax('price', 1),
                  ]}
                >
                  <CustomInput
                    onChange={(e: any) => setNewValue({ price: +e.target.value })}
                    required
                    readonly={isReadonlyByUserRole}
                    placeholder="Price"
                    disabled={mode === infoBlockModes.VIEW}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Col>

        <div className="rack-info-from-chekbox">
          <Col span={24}>
            <Form.Item name="not_available">
              <CustomCheckbox
                readonly={isReadonlyByUserRole}
                checked={rack?.active}
                onChange={(e: any) => setNewValue({ active: e.target.checked })}
                disabled={mode === infoBlockModes.VIEW}
                title="Not available"
              />
            </Form.Item>
          </Col>
        </div>

        <div className="rack-info-from-textarea">
          <Col span={24}>
            <Form.Item name="description">
              <CustomTextArea
                readonly={isReadonlyByUserRole}
                disabled={mode === infoBlockModes.VIEW}
                onChange={(e: any) => setNewValue({ description: e.target.value })}
                placeholder="Description"
              />
            </Form.Item>
          </Col>
        </div>
        {mode !== infoBlockModes.VIEW && (
          <div className="rack-info-save-btn-wrapper">
            <Form.Item>
              <CustomButton className="rack-info-save-btn" disabled={saveDisabled} htmlType="submit">
                Save
              </CustomButton>
            </Form.Item>
          </div>
        )}
      </Row>
    </Form>
  );
}

export default RackInfo;
