import React from 'react';

function DownLoadIcon() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="39" height="39" rx="9.5" fill="white" stroke="#CDD8DF" />
      <path opacity="0.8" d="M20.75 10C20.75 9.58579 20.4142 9.25 20 9.25C19.5858 9.25 19.25 9.58579 19.25 10L20.75 10ZM19.4697 25.5303C19.7626 25.8232 20.2374 25.8232 20.5303 25.5303L25.3033 20.7574C25.5962 20.4645 25.5962 19.9896 25.3033 19.6967C25.0104 19.4038 24.5355 19.4038 24.2426 19.6967L20 23.9393L15.7574 19.6967C15.4645 19.4038 14.9896 19.4038 14.6967 19.6967C14.4038 19.9896 14.4038 20.4645 14.6967 20.7574L19.4697 25.5303ZM19.25 10L19.25 25L20.75 25L20.75 10L19.25 10Z" fill="#18384A" />
      <line x1="16.5" y1="29.5" x2="23.5" y2="29.5" stroke="#CDD8DF" strokeLinecap="round" />
    </svg>

  );
}

export default DownLoadIcon;
