/* eslint-disable no-unsafe-optional-chaining */
import React from 'react';
import { Col, Row } from 'antd';

interface DefaultOutboundItemBodyProps {
  itemMU: any;
  orderItem: any;
  isStoreUser: boolean;
}

function DefaultOutboundItemBody({ itemMU, orderItem, isStoreUser }: DefaultOutboundItemBodyProps) {
  return isStoreUser ? (
    <Row className="items-with-locations-container">
      <Col className="ds-flex uom-info-block" span={24}>
        <Col span={7}>{itemMU?.text}</Col>
        <Col span={7}>{itemMU?.contains}</Col>
        <Col span={5}>{orderItem?.quantity}</Col>
        <Col span={5}>
          {itemMU?.contains ? orderItem?.quantity * +itemMU?.contains : orderItem?.quantity}
        </Col>
      </Col>
    </Row>
  ) : (
    <Row className="items-with-locations-container">
      <Col className="ds-flex uom-info-block" span={24}>
        <Col span={5}>{itemMU?.text}</Col>
        <Col span={7}>{itemMU?.package_id}</Col>
        <Col span={6}>{orderItem?.quantity}</Col>
        <Col span={4}>{itemMU?.contains}</Col>
        <Col span={2}>
          {itemMU?.contains ? orderItem?.quantity * +itemMU?.contains : orderItem?.quantity}
        </Col>
      </Col>
    </Row>
  );
}

export default DefaultOutboundItemBody;
