import { useEffect, useMemo, useState } from 'react';
import { Col, Form, Row } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'App';
import { useTranslation } from 'react-i18next';
import {
  relationType, RelationTypes, userRole, UserRoles,
} from 'constants/users/userRolesAndTypes';
import Countdown from 'antd/lib/statistic/Countdown';
import {
  CustomButton, CustomInput, CustomSelect, UploadPhoto,
} from '../../../globals';
import { authActionCreator, usersActionCreator } from '../../../../store/action-creators';
import { infoBlockModes } from '../../../../constants/infoBlockModes';

import './userInfoBlock.scss';

interface UserInfoBlockProps {
  mode: any;
  className?: string;
  setMode: (mode: any) => void;
}

function UserInfoBlock({ mode, setMode, className = '' }: UserInfoBlockProps) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { t } = useTranslation();

  const deadline = Date.now() + 1000 * 60;

  const selectedUser = useSelector((state: RootState) => state.usersReducer.selectedUser);
  const user = useSelector((state: RootState) => state.authReducer.user);

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [pictureDocument, setPictureDocument] = useState(null);
  const [isTimer, setIsTimer] = useState(false);
  const [isEmailSend, setIsEmailSend] = useState(false);
  const [emailError, setEmailError] = useState('');
  const userRelationsOptions = [
    { content: relationType[RelationTypes.STORE], value: 1 },
    { content: relationType[RelationTypes.WAREHOUSE], value: 2 },
  ];

  const initialValues = useMemo(() => {
    let result = null;
    if (selectedUser) {
      result = {
        name: selectedUser.name,
        email: selectedUser.email,
        picture: selectedUser.picture,
        relation_type: userRole[user.role_id] === UserRoles.STORE_ADMIN ? RelationTypes.STORE : (relationType[+selectedUser.relation_type] || ''),
      };
    }

    return result;
  }, [selectedUser]);

  const handleUploadPhoto = async (file: FormData) => {
    const photo = await dispatch(usersActionCreator.uploadPhoto(file));
    setPictureDocument(photo);
  };

  const getRelationTypeIdByValue = (relationTypeValue: string | number) => {
    if (typeof relationTypeValue === 'string') {
      const relationType = userRelationsOptions.find((relationOption) => relationOption.content === relationTypeValue);
      return relationType.value;
    }
    return relationTypeValue;
  };

  const handleResetPassword = async () => {
    const email = form.getFieldValue('email');
    const isSend = await dispatch(authActionCreator.resetPassword(email));
    if (isSend) {
      setIsEmailSend(!!isSend);
      setIsTimer(true);
    } else {
      setEmailError('User with this email is not registered');
    }
  };

  const save = async (body) => {
    body.id = selectedUser?.id;
    body.picture = pictureDocument?.id;
    body.relation_type = getRelationTypeIdByValue(body.relation_type);

    if (selectedUser?.id) {
      dispatch(usersActionCreator.updateUser(body));
    } else {
      dispatch(usersActionCreator.createUser(body));
    }
    setMode(infoBlockModes.VIEW);
  };

  useEffect(() => {
    if (mode === infoBlockModes.VIEW) {
      setSaveDisabled(true);
    }
  }, [mode]);

  useEffect(() => {
    setPictureDocument(selectedUser?.picture);
    setIsTimer(false);
  }, [selectedUser]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (mode === infoBlockModes.EDIT) {
      setSaveDisabled(false);
    }
  }, [pictureDocument]);

  return (
    <Form
      form={form}
      onFinish={save}
      autoComplete="off"
      onFieldsChange={() => {
        setSaveDisabled(false);
      }}
    >
      <Row className="user-information-container" gutter={[26, 26]}>
        <Col span={12}>
          <Row className="user-inputs-wrapper" gutter={[0, 26]}>
            <Col span={24}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Username is required',
                  },
                  {
                    min: 3,
                    message: 'Username length should be at least 3 signs',
                  },
                ]}
              >
                <CustomInput required disabled={mode === infoBlockModes.VIEW} placeholder="Username" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Email is required',
                  },
                  {
                    type: 'email',
                    message: 'Should be a valid email',
                  },
                ]}
                validateStatus={emailError ? 'error' : 'success'}
                extra={emailError ? (<span className="resend-pass-error-text">{emailError}</span>) : null}
              >
                <CustomInput onChange={() => setEmailError(null)} required disabled={mode === infoBlockModes.VIEW} placeholder="Email" />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col className="user-upload-image-container" span={10} offset={2}>
          <UploadPhoto
            disabled={mode === infoBlockModes.VIEW}
            imageObject={pictureDocument}
            handleUploadPhoto={handleUploadPhoto}
          />
        </Col>

        <Col span={12}>
          <Form.Item
            name="relation_type"
            rules={[
              {
                required: true,
                message: 'Relation is required',
              },
            ]}
          >
            <CustomSelect
              required
              disabled={mode === infoBlockModes.VIEW}
              readonly={selectedUser?.relatedToStores?.length || selectedUser?.relatedToWarehouses?.length || userRole[user.role_id] === UserRoles.STORE_ADMIN}
              className="user-info-select"
              placeholder={t('users.userRelations')}
              options={userRelationsOptions}
            />
          </Form.Item>
        </Col>

        <Col span={selectedUser?.id ? 12 : 6} offset={selectedUser?.id ? 0 : 6} className="user-info-buttons-wrapper">
          {selectedUser?.id && (
            <div>
              <CustomButton onClick={handleResetPassword} className="reset-pass-btn" disabled={isTimer}>
                {t('common.resetPassword')}
              </CustomButton>
              {isEmailSend
              && isTimer && (
              <span className="forgot-timer">
                Resend via
                <Countdown value={deadline} format="s" onFinish={() => setIsTimer(false)} />
                sec.
              </span>
              )}
            </div>
          )}
          <Form.Item>
            <CustomButton disabled={saveDisabled} htmlType="submit">
              {t('common.save')}
            </CustomButton>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default UserInfoBlock;
