import { useEffect } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Form } from 'antd';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { authActionCreator } from '../../../store/modules/auth/action-creators';
import { CustomButton, CustomInput } from '../../../components/globals';

import './style.scss';

function Login({ setAuthState }) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [form] = Form.useForm();

  const initialFormValues = {
    email: '',
    password: '',
  };

  const handleSubmitClick = async (values) => {
    const isAuth = await dispatch(authActionCreator.login(values));
    if (isAuth) history.push('/items');
  };

  useEffect(() => {
    form.setFieldsValue(initialFormValues);
  }, []);

  return (
    <Form
      className="login-form"
      form={form}
      autoComplete="off"
      onFinish={handleSubmitClick}
    >
      <Form.Item name="email" rules={[{ required: true, message: 'Please input your username!' }]}>
        <CustomInput required placeholder="Username" />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'Password is required' }]}>
        <CustomInput
          required
          placeholder="Password"
          type="password"
        />
      </Form.Item>
      <CustomButton type="primary" htmlType="submit">Sign In</CustomButton>
      <p className="forgot-password">
        <NavLink to="#" onClick={() => setAuthState('forgot')}>Forgot password?</NavLink>
      </p>
    </Form>
  );
}

export default withRouter(Login);
