import React from 'react';
import { Col, Row } from 'antd';
import WarehouseUom from './warehouseUom/WarehouseUom';

import './InventoryWarehouseInfoCard.scss';

interface InventoryWarehouseInfoCardProps {
  warehouse: any;
}

function InventoryWarehouseInfoCard({ warehouse }: InventoryWarehouseInfoCardProps) {
  return (
    <div className="warehouse-info-card">
      <div className="card-title-block">
        <div className="warehouse-name">{warehouse.name || 'Warehouse 1'}</div>
      </div>
      <Row className="card-header">
        <Col span={7}>UOM</Col>
        <Col span={6}>Quantity</Col>
        <Col span={5}>Contains</Col>
        <Col span={6}>Total</Col>
      </Row>
      <Row className="card-body">{warehouse.uoms.map((uom) => <WarehouseUom uom={uom} />)}</Row>
    </div>
  );
}

export default InventoryWarehouseInfoCard;
