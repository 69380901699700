import { IItemsSearchParams } from 'store/modules/items/reducer';
import baseAxios from './axiosInstance';

const mainPath = 'item';
const externalIdPath = 'ext-id';
const itemMeasurementUnitPath = 'item-measurement-unit';

const itemsAPI = {
  controllers: {
    fetchCategories: [],
    fetchUnits: [],
  },
  async getAllItems(per_page = 10, page = 1, search_params?: IItemsSearchParams) {
    const params = { limit: per_page, page, ...search_params };

    const { data } = await baseAxios.get(mainPath, { params });
    return data;
  },
  getUOMOptions(item_id: any) {
    return baseAxios.get(`${mainPath}/${item_id}/options`);
  },
  getItem(id: any) {
    return baseAxios.get(`${mainPath}/${id}`);
  },
  postItem(item: any) {
    if (item.id) { return baseAxios.put(`${mainPath}/${item.id}`, item); }
    return baseAxios.post(mainPath, item);
  },
  deleteItem(item: any) {
    return baseAxios.delete(`${mainPath}/${item.id}`);
  },

  getExternalIDTypes() {
    return baseAxios.get(`${externalIdPath}/types`);
  },

  getExternalIDTypesForId(item_id: string | number) {
    return baseAxios.get(`${externalIdPath}/item/${item_id}`);
  },

  addExternalIDs(extId: any) {
    return baseAxios.put(`${externalIdPath}/${extId.item_id}`, extId.array);
  },

  putUnit(itemId: any, unit: any) {
    return baseAxios.put(`${itemMeasurementUnitPath}/${itemId}`, unit);
  },
};

export default itemsAPI;
