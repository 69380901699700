import {
  IAxiosResponse, IInventoryItem, IMeta, IRecentActivityReport,
} from 'interfaces';
import { getPagination } from 'utils/getPagination';
import { EInventoryAction } from './types';
import { inventoryAPI, itemsAPI } from '../../../services';
import { tryCatchHandler } from '../../../utils';
import { IInventorySearchParams } from './reducer';

export const inventoryActionCreator = {
  setAllItems: (allItems: IInventoryItem[]) => ({
    type: EInventoryAction.SET_ALL_ITEMS,
    payload: allItems.sort((a: IInventoryItem, b: IInventoryItem) => a.id - b.id),
  }),
  setSelectedItem: (selectedItem: IInventoryItem) => ({
    type: EInventoryAction.SET_SELECTED_ITEM,
    payload: selectedItem,
  }),

  setItemWarehouses: (itemWarehouses: any[]) => ({
    type: EInventoryAction.SET_ITEM_WAREHOUSES,
    payload: itemWarehouses,
  }),

  setReportItem: (reportItem: IRecentActivityReport) => ({
    type: EInventoryAction.SET_REPORT_ITEM,
    payload: reportItem,
  }),

  setExternalIds: (external_ids: any, item_id: string | number) => ({
    type: EInventoryAction.SET_EXTERNAL_IDS,
    payload: { external_ids, item_id },
  }),
  addExternalIds: (external_ids: any) => ({
    type: EInventoryAction.ADD_EXTERNAL_IDS,
    payload: { external_ids },
  }),

  setPagination: (pagination: IMeta) => ({
    type: EInventoryAction.SET_PAGINATION,
    payload: pagination,
  }),
  setSearchParams: (search: IInventorySearchParams) => ({
    type: EInventoryAction.SET_SEARCH_PARAMS,
    payload: search,
  }),
  fetchAllItems:
    (per_page?: number, page?: number, search_params?: IInventorySearchParams) => async (dispatch, getState) => {
      const { pagination, search_params: searchFromStore } = getState().inventoryReducer;

      const search = search_params || searchFromStore;
      const isSearch = !!search;

      const perPageToSearch = per_page || pagination.per_page;
      const pageToSearch = page || pagination.current_page;

      await dispatch(inventoryActionCreator.setSearchParams(search));
      await tryCatchHandler(dispatch, async () => {
        const { data, meta }: IAxiosResponse<IInventoryItem[]> = await inventoryAPI.getItemsInInventory(
          perPageToSearch,
          getPagination(isSearch, page, pageToSearch),
          search,
        );

        const allItems = data.map((item) => ({
          ...item,
          store: item?.store_id?.company_name,
        }));

        dispatch(inventoryActionCreator.setAllItems(allItems));
        dispatch(inventoryActionCreator.setPagination(meta));
      });
    },

  featchExternalIds: (item_inventory_id: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { external_ids } = await getState().inventoryReducer;
    if (external_ids.hasOwnProperty(item_inventory_id)) {
      return false;
    }

    const { data } = await itemsAPI.getExternalIDTypesForId(item_inventory_id);
    dispatch(inventoryActionCreator.setExternalIds(data, item_inventory_id));
    return data;
  }, '', false),

  fetchItem: (item_id: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: IAxiosResponse<IInventoryItem> = await inventoryAPI.getItemInInventory(item_id);

      const itemInfo = {
        ...data,
        externalId: data?.external_ids?.find((extID) => extID?.default)?.external_id_value || 'Not defined',
        store: data?.store_id?.company_name,
        internalId: data?.internal_id,
        image: data.picture,
      };

      const { selectedItem } = getState().inventoryReducer;

      if (selectedItem && selectedItem.id === data.id) {
        const { locations: oldLocations, bins: oldBins } = selectedItem;
        let locationsToSet = [];
        let binsToSet = [];

        // save previously added locations
        for (const loc of oldLocations) {
          const findedLocation = data.locations.find((loc1) => loc1.id === loc.id);
          if (findedLocation) locationsToSet.push(findedLocation);
          else {
            locationsToSet.push({ ...loc, uom: [] });
          }
        }

        // save previously added bins
        for (const bin of oldBins) {
          const findedBin = data.bins.find((bin1) => bin1.id === bin.id);
          if (findedBin) binsToSet.push(findedBin);
          else {
            binsToSet.push({ ...bin, uom: [] });
          }
        }

        const oldLocationsIdList = oldLocations.map((loc) => loc.id);
        const oldBinsIdList = oldBins.map((bin) => bin.id);
        // add new locations that  had appeared after refresh
        locationsToSet = locationsToSet.concat(data.locations.filter((loc) => !oldLocationsIdList.includes(loc.id)));
        // add new bins that  had appeared after refresh
        binsToSet = binsToSet.concat(data.bins.filter((bin) => !oldBinsIdList.includes(bin.id)));

        Object.assign(itemInfo, {
          locations: locationsToSet,
          bins: binsToSet,
        });
      }
      dispatch(inventoryActionCreator.setSelectedItem(itemInfo));
    });
  },

  fetchItemWarehouses: (item_inventory_id: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data }: IAxiosResponse<any[]> = await inventoryAPI.getItemWarehouses(item_inventory_id);
    dispatch(inventoryActionCreator.setItemWarehouses(data));
  }),

  moveItemToAnotherLocation:
    (item_inventory_id: number, { location_id, bin_id }: { location_id: number; bin_id: number }) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
      const { data } = await inventoryAPI.moveItemToAnotherLocation(item_inventory_id, location_id, bin_id);

      const { selectedItem } = getState().inventoryReducer;
      await dispatch(inventoryActionCreator.fetchItem(selectedItem.id));
      return data;
    }),

  mergeSameItems: (item_inventory_id: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { data } = await inventoryAPI.mergeSameItems(item_inventory_id);

    const { selectedItem } = getState().inventoryReducer;
    await dispatch(inventoryActionCreator.fetchItem(selectedItem.id));
    return data;
  }),

  splitUOM: (item_inventory_id: number, quantity: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { data } = await inventoryAPI.splitUOM(item_inventory_id, quantity);

    const { selectedItem } = getState().inventoryReducer;
    await dispatch(inventoryActionCreator.fetchItem(selectedItem.id));
    return data;
  }),

  pickOutUOM: (item_inventory_id: number, quantity: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { data } = await inventoryAPI.pickOutUOM(item_inventory_id, quantity);

    const { selectedItem } = getState().inventoryReducer;
    await dispatch(inventoryActionCreator.fetchItem(selectedItem.id));
    return data;
  }),

  fetchReport: (item_inventory_id: number, dateStart: string, dateEnd: string) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data } = await inventoryAPI.reportInventory(item_inventory_id, {
        dateStart,
        dateEnd,
      });
      dispatch(inventoryActionCreator.setReportItem(data));
    });
  },

  clearReportInventory: () => async (dispatch) => {
    dispatch(inventoryActionCreator.setReportItem(null));
  },

  clearInventory: () => async (dispatch) => {
    dispatch(inventoryActionCreator.setAllItems([]));
    dispatch(inventoryActionCreator.setSelectedItem(null));
  },
};
