import React, { useEffect, useRef, useState } from 'react';
import { Select } from 'antd';
import { SelectArrowIcon } from 'assets/images';
import OutsideClick from '../../../utils/outsideClick';
import CustomPlaceholder from '../customPlaceholder/CustomPlaceholder';

import './customSelectStyle.scss';

const { Option } = Select;

function CustomSelect({
  onChange,
  defaultValue,
  selectPropRef,
  required,
  isOnlyPlaceholder,
  withCustomOptions,
  disabled,
  readonly = false,
  onSelect,
  className = '',
  value,
  children,
  options = [],
  placeholder,
  dropdownClassName = 'default-select-dropdown',
  ...rest
}: any) {
  const [isActive, setIsActive] = useState(!!value);
  const selectRef = useRef(null);
  const placeholderRef = useRef(null);
  const setFocus = () => {
    if (placeholderRef.current) placeholderRef.current.focus();
  };

  const outsideClicked = OutsideClick(selectRef, () => setIsActive(false));

  useEffect(() => {
    if (isActive && outsideClicked && readonly) {
      setIsActive(false);
    }
  }, [outsideClicked, isActive]);

  useEffect(() => {
    if (selectRef && selectRef.current) {
      selectRef.current.click();
    }
  }, []);

  return (
    <div
      ref={selectPropRef}
      className={`custom-select-wrapper ${readonly ? 'readonly-custom-select-container' : ''} ${className}  ${
        disabled ? 'custom-select-disabled' : ''
      }`}
      onClick={() => setIsActive(!isActive)}
    >
      <CustomPlaceholder
        readonly={readonly}
        isOnlyPlaceholder={isOnlyPlaceholder}
        isActive={isActive}
        value={value}
        required={required}
        disabled={disabled}
        placeholder={placeholder}
        setIsActive={setIsActive}
        setFocus={setFocus}
      />

      <Select
        suffixIcon={<SelectArrowIcon isActive={isActive} />}
        ref={placeholderRef}
        disabled={disabled || readonly}
        value={value}
        onSelect={onSelect}
        defaultValue={defaultValue}
        onChange={onChange}
        dropdownClassName={dropdownClassName}
        getPopupContainer={(trigger) => trigger.parentNode}
        {...rest}
      >
        {withCustomOptions && children}

        {!withCustomOptions
          && options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.content}
            </Option>
          ))}
      </Select>
    </div>
  );
}

export default CustomSelect;
