import { colors } from 'assets/css/variables';

function SelectArrowIcon({ isActive = false }) {
  return (
    <svg style={{ transform: isActive ? 'rotate(180deg)' : '' }} width="9" height="4" viewBox="0 0 9 4" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.89409 0.0890399C8.82485 0.0319572 8.7319 0 8.63511 0C8.53832 0 8.44538 0.0319572 8.37614 0.0890399L4.5 3.27817L0.62464 0.0890399C0.5554 0.0319572 0.462451 0 0.365663 0C0.268873 0 0.175924 0.0319572 0.106685 0.0890399C0.0729218 0.116786 0.0460863 0.14996 0.0277634 0.186603C0.00943947 0.223247 0 0.262617 0 0.30239C0 0.342164 0.00943947 0.381534 0.0277634 0.418178C0.0460863 0.454821 0.0729218 0.487995 0.106685 0.515741L4.22939 3.9071C4.30179 3.96666 4.3989 4 4.5 4C4.6011 4 4.69821 3.96666 4.77061 3.9071L8.89332 0.515741C8.92708 0.487995 8.95391 0.454821 8.97224 0.418178C8.99056 0.381534 9 0.342164 9 0.30239C9 0.262617 8.99056 0.223247 8.97224 0.186603C8.95391 0.14996 8.92708 0.116786 8.89332 0.0890399H8.89409Z"
        fill={colors.grayHight}
      />
    </svg>
  );
}

export default SelectArrowIcon;
