import { colors } from 'assets/css/variables';
import './trashIconStyle.scss';

interface TrashIconProps {
  isDisabled?: boolean;
}

function TrashIcon({ isDisabled = false }: TrashIconProps) {
  return (
    <svg
      className={`trash-icon-svg ${isDisabled ? 'disabled-trash-icon' : ''}`}
      cursor="pointer"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect className="trash-border" x="0.5" y="0.5" width="39" height="39" rx="9.5" fill={colors.white} stroke={colors.grayCardLight} />

      <path
        d="M19.5238 8.75C20.4381 8.74992 21.3176 9.08455 21.9811 9.68495C22.6447 10.2853 23.0417 11.1058 23.0905 11.9773L23.0952 12.1591H28.3333C28.5143 12.1591 28.6885 12.2248 28.8208 12.3427C28.953 12.4606 29.0334 12.6221 29.0457 12.7944C29.0581 12.9668 29.0014 13.1372 28.8872 13.2712C28.773 13.4052 28.6098 13.4928 28.4305 13.5164L28.3333 13.5227H27.3248L26.721 20.6427C26.2641 20.4975 25.7911 20.4038 25.3114 20.3636L25.8924 13.5227H13.1552L14.3086 27.0973C14.3521 27.6095 14.5961 28.0873 14.9919 28.4356C15.3877 28.784 15.9064 28.9774 16.4448 28.9773L19.2695 28.9782C19.5419 29.4769 19.8873 29.9361 20.2952 30.3418L16.4448 30.3409C15.5475 30.341 14.683 30.0186 14.0234 29.438C13.3638 28.8574 12.9573 28.061 12.8848 27.2073L11.7219 13.5227H10.7143C10.5417 13.5227 10.3749 13.4631 10.2448 13.3548C10.1147 13.2465 10.0301 13.0969 10.0067 12.9336L10 12.8409C10 12.6761 10.0625 12.517 10.176 12.3928C10.2894 12.2686 10.4461 12.1879 10.6171 12.1655L10.7143 12.1591H15.9524C15.9524 11.2549 16.3287 10.3878 16.9984 9.7485C17.6682 9.10917 18.5766 8.75 19.5238 8.75ZM19.5238 10.1136C18.9808 10.1136 18.4581 10.3103 18.0612 10.664C17.6643 11.0177 17.4229 11.502 17.3857 12.0191L17.381 12.1591H21.6667L21.6619 12.0191C21.6247 11.502 21.3833 11.0177 20.9864 10.664C20.5895 10.3103 20.0668 10.1136 19.5238 10.1136Z"
        fill={colors.darkText}
        fillOpacity="0.8"
      />

      <circle cx="24" cy="26" r="5" fill={colors.error} />

      <path d="M26.1479 23.9053L21.8523 28.0943" stroke="white" strokeWidth="0.7" strokeLinecap="round" />

      <path d="M21.9053 23.8525L26.0943 28.1482" stroke="white" strokeWidth="0.7" strokeLinecap="round" />
    </svg>
  );
}

export default TrashIcon;
