import { Store } from 'react-notifications-component';

export const notifier = (type: any, title: any, message: any) => Store.addNotification({
  title,
  message,
  type,
  insert: 'bottom',
  container: 'bottom-right',
  animationIn: ['animate__animated', 'animate__fadeIn'],
  animationOut: ['animate__animated', 'animate__fadeOut'],
  dismiss: {
    duration: 2000,
    onScreen: true,
  },
});
