import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { clearReportState, OutsideClick } from 'utils';

import { CustomButton, CustomDropdown, ModalWindow } from 'components/globals';
import CrtRowIcon from 'assets/images/CurrentRowIcon';
import ReportRow from './ReportRow';
import { ReportType } from '../../../../constants/reportType';
import './CurrentRowInfo.scss';

function CurrentRowInfo({
  selectId,
  chooseRowId,
  name,
  type,
  isWareHouseAdmin,
}: {
  selectId: number;
  chooseRowId: number;
  name: string;
  type: ReportType;
  isWareHouseAdmin?: boolean;
}) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [reportType, setReportType] = useState<ReportType>(type);
  const [pickerStart, setPickerStart] = useState(null);
  const [pickerEnd, setPickerEnd] = useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const outsideClicked = OutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  useEffect(() => {
    if (isDropdownOpen && outsideClicked) {
      setIsDropdownOpen(false);
    }
  }, [outsideClicked, isDropdownOpen]);

  const handleClickIcon = (e) => {
    e.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOpenReport = (e) => {
    e.stopPropagation();
    setIsModalVisible(true);
  };

  const handleOpenStockReport = (e) => {
    setReportType(ReportType.STOCK);
    handleOpenReport(e);
  };

  const handleCancelModal = () => {
    setIsModalVisible(false);
    setPickerStart(null);
    setPickerEnd(null);
    clearReportState(reportType, dispatch);
    setReportType(type);
  };

  return selectId === chooseRowId ? (
    <div ref={dropdownRef} onClick={handleClickIcon} className="current-row-icon">
      <CrtRowIcon />
      <div className="current-row-icon-popup">
        <CustomDropdown isDropdownOpen={isDropdownOpen}>
          <CustomButton onClick={handleOpenReport}>
            <div>{t('iventory.currentRowRecentActivity')}</div>
          </CustomButton>
          {reportType !== ReportType.INVENTORY && (
            <CustomButton onClick={handleOpenStockReport}>
              <div>{t('stores.stockReport')}</div>
            </CustomButton>
          )}
        </CustomDropdown>
      </div>

      <ModalWindow
        width={611}
        className="current-row-modal"
        handleCancelModal={() => handleCancelModal()}
        title={name}
        isModalVisible={isModalVisible}
        bodyStyle={{ height: reportType !== ReportType.STOCK ? '711px' : '300px' }}
      >
        <ReportRow
          selectedId={selectId}
          type={reportType}
          name={name}
          isWareHouseAdmin={isWareHouseAdmin}
          pickerStart={pickerStart}
          pickerEnd={pickerEnd}
          setPickerStart={setPickerStart}
          setPickerEnd={setPickerEnd}
        />
      </ModalWindow>
    </div>
  ) : null;
}

export default CurrentRowInfo;
