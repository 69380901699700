import { useEffect, useRef, useState } from 'react';
import { CustomInput } from '../../customInput';

import CustomButton from '../customButton/CustomButton';
import OutsideClick from '../../../../utils/outsideClick';

import './BtnInsideInput.scss';

const titleName = {
  pickOut: 'Pick Out',
  split: 'Split',
};

function BtnInsideInput({
  handleClick,
  count,
  type,
}: any) {
  const buttonRef = useRef(null);

  const [activeBtn, setActiveBtn] = useState(false);
  const [value, setValue] = useState(1);
  const [isDisabledBtnInput, setIsDisabledBtnInput] = useState(false);
  const outsideClicked = OutsideClick(buttonRef, () => setActiveBtn(false));

  const typeIsPickOut = type.toLowerCase() === titleName.pickOut.toLowerCase().split(' ').join('');

  useEffect(() => (
    typeIsPickOut
      ? value > +count ? setIsDisabledBtnInput(true) : setIsDisabledBtnInput(false)
      : value >= +count ? setIsDisabledBtnInput(true) : setIsDisabledBtnInput(false)), [value]);

  useEffect(() => {
    if (activeBtn && outsideClicked) {
      setActiveBtn(false);
      setValue(1);
    }
  }, [outsideClicked, activeBtn]);

  return (
    <div ref={buttonRef} className={`btn-input-inside_wrapper ${activeBtn && 'active-split-container'}`}>
      {!activeBtn ? (
        <CustomButton onClick={() => setActiveBtn(true)}>{titleName[type]}</CustomButton>
      ) : (

        <div className="btn-input-inside_input-wrapper">

          <CustomInput
            type="numbers"
            className="btn-input-inside_input"
            withoutZero
            value={value}
            onChange={(e: any) => setValue(e.target.value)}
          />

          <CustomButton
            disabled={isDisabledBtnInput}
            onClick={() => {
              handleClick(value);
              setActiveBtn(false);
            }}
            className={`btn-input-inside_value-btn ${isDisabledBtnInput && 'disabled-btn-inside'} `}
          >
            {titleName[type]}
          </CustomButton>
        </div>
      )}
    </div>
  );
}

export default BtnInsideInput;
