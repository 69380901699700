import { colors } from 'assets/css/variables';

function PlusIcon() {
  return (

    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.50008 7.5013H1.66675H7.50008Z" fill={colors.blue} />
      <path
        d="M7.50008 13.3346V7.5013M7.50008 7.5013V1.66797M7.50008 7.5013H13.3334M7.50008 7.5013H1.66675"
        stroke={colors.grayText}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default PlusIcon;
