import { colors } from 'assets/css/variables';

function ReportsIcon() {
  return (
    <svg width="24" height="31" viewBox="0 0 24 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.3871 11.1538C6.3871 10.8478 6.50945 10.5543 6.72723 10.338C6.94501 10.1216 7.24039 10 7.54839 10H13.7419C14.0499 10 14.3453 10.1216 14.5631 10.338C14.7809 10.5543 14.9032 10.8478 14.9032 11.1538C14.9032 11.4599 14.7809 11.7533 14.5631 11.9697C14.3453 12.1861 14.0499 12.3077 13.7419 12.3077H7.54839C7.24039 12.3077 6.94501 12.1861 6.72723 11.9697C6.50945 11.7533 6.3871 11.4599 6.3871 11.1538ZM11.0323 17.3077C11.0323 17.0017 11.1546 16.7082 11.3724 16.4918C11.5902 16.2754 11.8856 16.1538 12.1935 16.1538H16.8387C17.1467 16.1538 17.4421 16.2754 17.6599 16.4918C17.8776 16.7082 18 17.0017 18 17.3077C18 17.6137 17.8776 17.9072 17.6599 18.1236C17.4421 18.34 17.1467 18.4615 16.8387 18.4615H12.1935C11.8856 18.4615 11.5902 18.34 11.3724 18.1236C11.1546 17.9072 11.0323 17.6137 11.0323 17.3077ZM12.1935 22.3077C11.8856 22.3077 11.5902 22.4293 11.3724 22.6456C11.1546 22.862 11.0323 23.1555 11.0323 23.4615C11.0323 23.7676 11.1546 24.061 11.3724 24.2774C11.5902 24.4938 11.8856 24.6154 12.1935 24.6154H16.8387C17.1467 24.6154 17.4421 24.4938 17.6599 24.2774C17.8776 24.061 18 23.7676 18 23.4615C18 23.1555 17.8776 22.862 17.6599 22.6456C17.4421 22.4293 17.1467 22.3077 16.8387 22.3077H12.1935ZM9.09677 17.3077C9.09677 17.7157 8.93364 18.107 8.64326 18.3955C8.35288 18.6841 7.95904 18.8462 7.54839 18.8462C7.13773 18.8462 6.74389 18.6841 6.45351 18.3955C6.16313 18.107 6 17.7157 6 17.3077C6 16.8997 6.16313 16.5084 6.45351 16.2198C6.74389 15.9313 7.13773 15.7692 7.54839 15.7692C7.95904 15.7692 8.35288 15.9313 8.64326 16.2198C8.93364 16.5084 9.09677 16.8997 9.09677 17.3077ZM7.54839 25C7.95904 25 8.35288 24.8379 8.64326 24.5494C8.93364 24.2609 9.09677 23.8696 9.09677 23.4615C9.09677 23.0535 8.93364 22.6622 8.64326 22.3737C8.35288 22.0852 7.95904 21.9231 7.54839 21.9231C7.13773 21.9231 6.74389 22.0852 6.45351 22.3737C6.16313 22.6622 6 23.0535 6 23.4615C6 23.8696 6.16313 24.2609 6.45351 24.5494C6.74389 24.8379 7.13773 25 7.54839 25Z"
        fill={colors.grayCardLight}
      />
      <path
        d="M0.750004 4.89742L0.750051 24.1116C0.750054 25.3207 1.10712 26.5028 1.77646 27.5097C2.91425 29.2214 4.83318 30.25 6.88849 30.25H17.1116C19.1669 30.25 21.0859 29.2214 22.2236 27.5097C22.893 26.5028 23.2501 25.3207 23.2501 24.1116V4.99677C23.2501 3.63153 21.7563 2.79207 20.5901 3.50193L17.6982 5.26224C17.6097 5.31614 17.497 5.30981 17.4151 5.24633L13.1092 1.9105C12.4484 1.39858 11.5178 1.42545 10.8876 1.97466L7.14185 5.23926C7.06316 5.30784 6.95015 5.32031 6.8584 5.27055L3.33437 3.35913C2.16832 2.72666 0.750001 3.57089 0.750004 4.89742Z"
        stroke={colors.blue}
        strokeWidth="1.5"
      />
    </svg>
  );
}

export default ReportsIcon;
