import React, { useMemo } from 'react';
import Search from 'antd/lib/input/Search';

import './SearchStyles.scss';
import { CrossIcon } from 'assets/images';

function CustomSearch({
  onSearch,
  value,
  placeholder,
  onChange,
  width,
  className,
  searchValue,
  setSearchValue,
  setIsRemoveSearchClicked,
}: any) {
  const renderRemoveSearchValueButton = useMemo(() => {
    if (searchValue?.length) {
      return (
        <div
          className="remove-search-value-cross"
          onClick={() => {
            setSearchValue('');
            setIsRemoveSearchClicked(true);
          }}
        >
          <CrossIcon />
        </div>
      );
    }
  }, [searchValue]);
  return (
    <div className={`custom-search-wrapper ${className}`}>
      <Search value={value} onChange={onChange} placeholder={placeholder} onSearch={onSearch} style={{ width }} />
      {renderRemoveSearchValueButton}
    </div>
  );
}

export default CustomSearch;
