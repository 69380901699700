import React from 'react';
import { Checkbox } from 'antd';

import './CustomCheckboxStyle.scss';

function CustomCheckbox({
  onChange,
  title,
  disabled,
  className = '',
  defaultChecked,
  checked,
}: any) {
  return (

    <div className={`custom-checkbox-wrapper ${className} ${disabled && checked && 'disabled-and-checked'}`}>
      <Checkbox defaultChecked={defaultChecked} checked={checked} disabled={disabled} onChange={onChange}>
        {title}
      </Checkbox>
    </div>
  );
}

export default CustomCheckbox;
