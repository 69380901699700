export enum ReportType {
  INVENTORY,
  STORE,
  STOCK,
}

export enum InboundType {
  ONGATE = 'On gate',
  INBOUND = 'Inbound'
}
