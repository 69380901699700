import { Row } from 'antd';
import './pageLayout.scss';

function PageLayout({
  children,
}: any) {
  return (
    <div className="padding-wrapper">
      <Row>
        {children}
      </Row>
    </div>
  );
}

export default PageLayout;
