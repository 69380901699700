import { useState } from 'react';
import { Col, Row } from 'antd';
import { useDispatch } from 'react-redux';
import { IUOM } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { ConfirmationModal } from 'components/confirmationModal';
import { BtnInsideInput, ButtonWithSelect, CustomButton } from '../../../../globals';
import { ItemInventoryStatuses } from '../../../../../constants/items/itemsData';
import CustomPopover from '../../../../globals/customPopover/CustomPopover';
import { inventoryActionCreator } from '../../../../../store/action-creators';

import './locationMeasurementUnit.scss';

interface LocationMeasurementUnitProps {
  uom: IUOM;
  setIsMoveClicked: (value: boolean) => void;
  setUomId: (id: number) => void;
  clickedUOMId: number;
  isUserHasAccessToUpdateUoms: boolean;
  colItems?: any;
}

function LocationMeasurementUnit({
  uom,
  setIsMoveClicked,
  setUomId,
  clickedUOMId,
  colItems,
  isUserHasAccessToUpdateUoms,
}: LocationMeasurementUnitProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [confirmationModalData, setConfirmationModalData] = useState({ action: null, text: '' });
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const moveClick = () => {
    setIsMoveClicked(true);
    setUomId(uom.id);
  };

  const clickCancel = () => {
    setIsMoveClicked(false);
    setUomId(null);
  };

  const splitClick = async (splitValue: number) => {
    await dispatch(inventoryActionCreator.splitUOM(uom.id, splitValue));
    setIsDropdownActive(false);
  };

  const pickOutClick = async (value: number) => {
    setIsModalVisible(true);
    setConfirmationModalData({ action: () => dispatch(inventoryActionCreator.pickOutUOM(uom.id, value)), text: t('locations.pickOutAlert') });
  };

  const mergeClick = () => {
    setIsModalVisible(true);
    setConfirmationModalData({ action: () => dispatch(inventoryActionCreator.mergeSameItems(uom.id)), text: t('locations.mergeAlert') });
    setIsDropdownActive(false);
  };

  const validPickOut = uom?.quantity === 1 && !uom?.each_id;
  const validSplit = uom?.quantity > 1;

  const contentPopoverStatus = () => (
    <div className="custom-popover-content-status">
      <div className="d-flex">
        <div className="title">Status</div>
        <div className="title-info">{ItemInventoryStatuses[uom?.status]}</div>
      </div>

      <div className="d-flex">
        <div className="title">Condit.</div>
        <div className="title-info">{uom?.condition_id?.name}</div>
      </div>
    </div>
  );

  const UOM = (
    <div className="d-flex" style={{ left: '-19px' }}>
      <CustomPopover className="carton-statuses-popover" content={contentPopoverStatus} trigger="hover">
        <div className={`carton-statuses-id ${ItemInventoryStatuses[uom?.status]}`}>i</div>
      </CustomPopover>
      {uom?.carton_id ? (uom?.carton_id?.master ? 'M Carton' : 'Carton') : 'Each'}
    </div>
  );
  const Qty = uom?.quantity;
  const CONT = !uom?.carton_id ? '' : uom?.carton_id?.quantity;
  const PackageID = !uom?.carton_id ? '' : uom?.carton_id?.internal_id;

  const QtyColumns = [
    { id: 1, component: UOM, className: '' },
    { id: 2, component: Qty, className: 'text-center' },
    { id: 3, component: CONT, className: 'text-center' },
    { id: 4, component: PackageID, className: 'package-id-col' },
  ];

  const ContPickedReserve = uom?.status === '5' || uom?.status === '6' || uom?.status === '2';

  return (
    <Row className={`location-uom-container ${uom.id === clickedUOMId ? 'move-clicked-state-loc-container' : ''}`}>
      {colItems.map((headerCol) => QtyColumns.map(
        (col) => col.id === headerCol.id && (
          <Col key={col.id} span={headerCol.span} className={col.className}>
            {col.component}
          </Col>
        ),
      ))}
      {isUserHasAccessToUpdateUoms && (
        <Col className="move-btn-col" span={6}>
          {uom.id !== clickedUOMId ? (
            <ButtonWithSelect
              disabled={ContPickedReserve}
              mainButtonProps={{ handleClick: moveClick, title: 'Move' }}
              isDropdownActive={isDropdownActive}
              setIsDropdownActive={setIsDropdownActive}
            >
              <CustomButton className="merge-same-button" onClick={mergeClick}>
                {t('locations.mergeSame')}
              </CustomButton>

              {validPickOut && (
                <BtnInsideInput type="pickOut" count={uom?.carton_id?.quantity} handleClick={pickOutClick} />
              )}
              {validSplit && <BtnInsideInput type="split" count={uom?.quantity} handleClick={splitClick} />}
            </ButtonWithSelect>
          ) : (
            <div className="move-cancel-btn" onClick={clickCancel}>
              {t('orders.cancel')}
            </div>
          )}
        </Col>
      )}

      <ConfirmationModal
        isModalVisible={isModalVisible}
        confirmText={confirmationModalData.text}
        confirmAction={confirmationModalData.action}
        closeCallback={() => setIsModalVisible(!isModalVisible)}
      />
    </Row>
  );
}

export default LocationMeasurementUnit;
