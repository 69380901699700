import { useEffect, useRef } from 'react';
import { colors } from 'assets/css/variables';
import { OpenButtonIcon } from 'assets/images';
import CustomButton from '../customButton/CustomButton';
import OutsideClick from '../../../../utils/outsideClick';

import './buttonWirhSelectStyles.scss';

interface ButtonWithSelectProps {
  className?: string;
  children?: any;
  disabled?: boolean;
  important?: boolean;
  isDropdownEnabled?: number;
  isDropdownActive: boolean;
  htmlType?: string;
  setIsDropdownActive: (value: boolean) => void;
  mainButtonProps: { handleClick?: () => void; title: string };
}

function ButtonWithSelect({
  className = '',
  children,
  mainButtonProps,
  disabled,
  htmlType,
  important = false,
  isDropdownEnabled = 0,
  setIsDropdownActive,
  isDropdownActive,
}: ButtonWithSelectProps) {
  const wrapperRef = useRef(null);

  const outsideClicked = OutsideClick(wrapperRef, () => setIsDropdownActive(false));

  useEffect(() => {
    if (isDropdownActive && outsideClicked) {
      setIsDropdownActive(false);
    }
  }, [outsideClicked, isDropdownActive]);

  const IconAndDisabled = () => {
    if (disabled) {
      return colors.grayLight;
    }
    return isDropdownActive && colors.blue;
  };

  const isDropdownDisabled = () => (disabled && isDropdownEnabled === 0 ? 1 : 0);

  const isConditionChildren = children && children[0] ? children : [children];

  return (
    <div
      ref={wrapperRef}
      className={`button-with-select-wrapper
     ${className} ${isDropdownActive && 'active-button-with-select'}
     ${disabled && 'disabled-button-with-select'}
     ${important ? 'important-button-with-select' : ''}
     `}
    >
      <div className="button-with-select">
        <CustomButton
          htmlType={htmlType}
          disabled={disabled}
          className="btn-select-main-button"
          onClick={() => {
            if (htmlType !== 'submit') {
              mainButtonProps.handleClick();
            }
          }}
        >
          {mainButtonProps.title}
        </CustomButton>

        <div
          onClick={() => (isDropdownDisabled() === 0 && children ? setIsDropdownActive(!isDropdownActive) : null)}
          className={`open-img-wrapper ${
            isDropdownDisabled() === 0 && disabled && isConditionChildren ? 'enabled-dropdown' : 'default-dropdown'
          } ${disabled ? 'disabled-button-with-select' : ''} ${!children ? 'disabled-dropdown' : ''}`}
        >
          <div className="open-img">
            <OpenButtonIcon color={IconAndDisabled()} />
          </div>
        </div>
      </div>

      <div className="button-with-select-dropdown">{children}</div>
    </div>
  );
}

export default ButtonWithSelect;
