import React, { useState, useEffect } from 'react';
import { Col, Row } from 'antd';
import { IUser } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { RelationTypes } from 'constants/users/userRolesAndTypes';
import { RootState } from 'App';
import { useDispatch, useSelector } from 'react-redux';
import { rolesActionCreator } from 'store/action-creators';
import { CustomButton } from '../../globals';
import AddUserModal from '../addUserModal/AddUserModal';
import RelatedUserCard from './relatedUserCard/RelatedUserCard';

import './relatedUsersStyle.scss';

interface RelatedUsersProps {
  relatedUsers: IUser[];
  userRelationType: RelationTypes;
  addUser: (user: IUser) => void;
  removeUser: (userId: number) => void;
  rerenderParentComponentParams: any;
}

function RelatedUsers({
  relatedUsers,
  userRelationType,
  addUser,
  removeUser,
  rerenderParentComponentParams,
}: RelatedUsersProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const roles = useSelector((state: RootState) => state.rolesReducer.roles);

  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    dispatch(rolesActionCreator.fetchRoles());
  }, []);

  return (
    <div>
      {!!relatedUsers.length && (
        <Row className="related-user-card-header">
          <Col span={7}>{t('users.username')}</Col>
          <Col span={9}>{t('common.email')}</Col>
          <Col span={7}>{t('users.userRole')}</Col>
        </Row>
      )}

      {relatedUsers.map((user: IUser) => (
        <RelatedUserCard
          roles={roles}
          relatedUser={user}
          userRelationType={userRelationType}
          removeUser={removeUser}
          rerenderParentComponentParams={rerenderParentComponentParams}
        />
      ))}

      <Row>
        <Col span={4} offset={20}>
          <CustomButton className="add-related-user-button" onClick={() => setIsModalVisible(true)}>
            Add +
          </CustomButton>
        </Col>
      </Row>

      <AddUserModal
        addUser={addUser}
        isModalVisible={isModalVisible}
        className="add-user-modal-window"
        userRelationType={userRelationType}
        setIsModalVisible={setIsModalVisible}
        addedUsersEmailsList={relatedUsers.map((user: IUser) => user.email)}
      />
    </div>
  );
}

export default RelatedUsers;
