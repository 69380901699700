import {
  IAxiosResponse,
  ICondition,
  IConvertedOrder,
  IOrder,
  IOrderItem,
  IOrderMeasurementUnit,
  IOrderMeasurementUnitBody,
  IPagination,
  ISelectedOrder,
  ITerminateReason,
} from 'interfaces';
import {
  orderStatuses, orderType, orderTypes,
} from 'constants/orders/status';
import documentsAPI from 'services/documentsAPI';
import { getPagination } from 'utils/getPagination';
import { EOrdersAction } from './types';

import {
  convertTime, tryCatchHandler, notifier, timeRefactor,
} from '../../../utils';
import { inboundOrdersAPI } from '../../../services';
import { IInboundSearchParams } from './reducer';

export const ordersActionCreator = {
  setOrders: (orders: IConvertedOrder[]) => ({
    type: EOrdersAction.SET_ORDERS,
    payload: orders,
  }),
  setSelectedOrder: (selectedOrder: ISelectedOrder) => ({
    type: EOrdersAction.SET_SELECTED_ORDER,
    payload: selectedOrder,
  }),
  setOrderItems: (items: [{ item: IOrderItem; measurement_units: IOrderMeasurementUnit[] }] | unknown) => ({
    type: EOrdersAction.SET_ORDER_ITEMS,
    payload: items,
  }),
  setConditions: (conditions: ICondition[]) => ({
    type: EOrdersAction.SET_CONDITIONS,
    payload: conditions,
  }),
  setMeasurementUnitsOptions: (options: []) => ({
    type: EOrdersAction.SET_MEASUREMENT_UNITS_OPTIONS,
    payload: options,
  }),
  setPagination: (pagination: IPagination) => ({
    type: EOrdersAction.SET_PAGINATION,
    payload: pagination,
  }),
  setSearchParams: (search: IInboundSearchParams) => ({
    type: EOrdersAction.SET_SEARCH_PARAMS,
    payload: search,
  }),

  fetchOrders:
    (per_page?: number, page?: number, isLoader = true, search_params?: IInboundSearchParams) => async (dispatch, getState) => {
      await tryCatchHandler(
        dispatch,
        async () => {
          const { pagination, search_params: searchFromStore } = getState().ordersReducer;

          const search = search_params || searchFromStore;
          const isSearch = !!search;

          const perPageToSearch = per_page || pagination.per_page;
          const pageToSearch = page || pagination.current_page;

          await dispatch(ordersActionCreator.setSearchParams(search));
          const { data, meta }: IAxiosResponse<IOrder[]> = await inboundOrdersAPI.getAllOrders(
            perPageToSearch,
            getPagination(isSearch, page, pageToSearch),
            search,
          );

          const ordersData = data?.map((orderItem: IOrder) => ({
            id: orderItem.id,
            number: orderItem.id,
            store: orderItem.store_id.company_name,
            status: orderStatuses[orderItem.status],
            dateIssued: convertTime(orderItem.created_at),
            expected: orderItem.expected ? convertTime(orderItem.expected) : undefined,
            created_by: `${orderItem.created_by?.name} (${orderItem.created_by?.role_id?.title})`,
          }));

          dispatch(ordersActionCreator.setOrders(ordersData));
          dispatch(
            ordersActionCreator.setPagination({
              total: meta.total,
              per_page: meta.per_page,
              current_page: meta.current_page,
            }),
          );
        },
        '',
        isLoader,
      );
    },

  fetchConditions: () => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data } = await inboundOrdersAPI.getConditions();

      dispatch(ordersActionCreator.setConditions(data));
    });
  },
  fetchOrder:
    (order_id: number, isLoader = true) => async (dispatch, getState) => {
      await tryCatchHandler(
        dispatch,
        async () => {
          const order: any = await inboundOrdersAPI.getOrder(order_id);

          const orderItems = order?.orderItems?.map((orderItem) => ({
            item: {
              name: orderItem.item.name,
              idToDisplay: orderItem?.item?.external_ids?.find((extID) => extID?.default)?.external_id_value || orderItem?.item.id,
              id: orderItem.item.id,
              order_id,
              external_ids: orderItem.item.external_ids,
            },

            measurement_units: orderItem.packagesOfItem,
            result_measurement_units: orderItem.resultOfItem,
            expected_measurement_units: orderItem.expectedOfItem,
            created_at: orderItem.item.created_at,
          }));

          const { orderItems: prevOrderItems, selectedOrder } = getState().ordersReducer;

          const newOrderItemsIdsList = orderItems.map((orderItem) => orderItem?.item?.id);

          const missingPrevOrderItems = prevOrderItems
            .filter((orderItem) => !newOrderItemsIdsList.includes(orderItem?.item?.id))
            .map((missingOrderItem) => ({
              ...missingOrderItem,
              measurement_units: [],
              result_measurement_units: [],
              expected_measurement_units: [],
            }));

          const orderInfo = {
            id: order.id,
            createDate: order.created_at,
            updateDate: order.updated_at,
            status: orderStatuses[order.status],
            store_id: order.store_id.id,
            store: order?.store_id?.company_name,
            notes: order.notes,
            documents: order.documents,
            user: {
              name: order.created_by?.name,
              role: order.created_by?.role_id?.title,
            },
            tracking_number: order.tracking_number,
            expected: order.expected || undefined,
            type: orderTypes[parseInt(order?.type, 10)],
            terminate_reason: order.terminate_reason,
            internal_note: order.internal_note,
          };

          dispatch(ordersActionCreator.setSelectedOrder(orderInfo));

          const orderItemsData = selectedOrder?.id === order_id ? [...orderItems, ...missingPrevOrderItems] : orderItems;
          dispatch(ordersActionCreator.setOrderItems(orderItemsData));
        },
        '',
        isLoader,
      );
    },
  generateBodyOfNewOrderGate: () => async (dispatch) => {
    const orderInfo = {
      status: 'On Gate',
      store_id: null,
      notes: '',
      type: 'On Gate',
    };

    dispatch(ordersActionCreator.setSelectedOrder(orderInfo));
    dispatch(ordersActionCreator.setOrderItems([]));
  },

  generateBodyOfNewInbound: () => async (dispatch) => {
    const orderInfo = {
      store_id: null,
      notes: '',
      tracking_number: '',
      expected: '',
      type: 'Inbound',
    };

    dispatch(ordersActionCreator.setSelectedOrder(orderInfo));
    dispatch(ordersActionCreator.setOrderItems([]));
  },

  refreshSelectedOrder: () => async (dispatch, getState) => {
    const { selectedOrder } = getState().ordersReducer;
    await dispatch(ordersActionCreator.fetchOrder(selectedOrder.id));
  },

  createOrder: (orderData: ISelectedOrder) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const type = orderData.type === orderType.ON_GATE ? 1 : 3;

    const { data: createdOrder } = await inboundOrdersAPI.createOrder({ ...orderData, type });

    const {
      pagination: { per_page, current_page },
    } = getState().ordersReducer;

    await dispatch(ordersActionCreator.fetchOrders(per_page, current_page));

    dispatch(ordersActionCreator.setSelectedOrder({ ...createdOrder, status: createdOrder.status }));
    dispatch(ordersActionCreator.fetchOrder(createdOrder.id));
    notifier('success', 'Success!', 'Order created');
    return createdOrder;
  }),

  updateOrder: (order_id: number, orderData: ISelectedOrder) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const response = await inboundOrdersAPI.updateOrder(order_id, {
      ...orderData,
      expected: orderData?.expected ? timeRefactor(orderData?.expected).toString() : null,
    });

    const {
      pagination: { per_page, current_page },
    } = getState().ordersReducer;

    dispatch(ordersActionCreator.fetchOrders(per_page, current_page));
    dispatch(ordersActionCreator.fetchOrder(order_id));
    notifier('success', 'Success!', 'Changes are saved successfully');
    return response;
  }),
  saveMeasurementUnitsOfItemOrder:
    (order_id: number, item_id: number, measurement_units: IOrderMeasurementUnit[]) => async (dispatch) => {
      await tryCatchHandler(
        dispatch,
        async () => {
          await inboundOrdersAPI.saveUOMofItem(order_id, item_id, measurement_units);
        },
        '',
        false,
      );
    },

  removeOrderItem: (itemId: number) => async (dispatch, getState) => {
    const { orderItems } = getState().ordersReducer;

    const newOrderItemsArray = orderItems?.filter((orderItem) => orderItem.item.id !== itemId);
    dispatch(ordersActionCreator.setOrderItems(newOrderItemsArray));
  },

  receiveMeasurementUnit: (measurement_unit_id: number, orderId: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const response = await inboundOrdersAPI.receiveMeasurementUnit(measurement_unit_id);
    dispatch(ordersActionCreator.fetchOrder(orderId));
    return response;
  }),

  splitMeasurementUnit:
    (measurement_unit_id: number, quantityToSplit: number, currentQuantity: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
      const { data } = await inboundOrdersAPI.splitMeasurementUnit(
        measurement_unit_id,
        quantityToSplit,
        currentQuantity,
      );
      const { selectedOrder } = getState().ordersReducer;

      dispatch(ordersActionCreator.fetchOrder(selectedOrder.id));
      return data;
    }),

  createMeasurementUnit: (order_id: number, uomBody: IOrderMeasurementUnitBody) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await inboundOrdersAPI.createMeasurementUnit(order_id, uomBody);
    dispatch(ordersActionCreator.fetchOrder(order_id));
    return data;
  }),

  createInboundMeasurementUnit: (order_id: number, uomBody) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await inboundOrdersAPI.createInboundMeasurementUnit(order_id, uomBody);
    dispatch(ordersActionCreator.fetchOrder(order_id));
    return data;
  }),

  updateMeasurementUnit: (order_id: number, uomBody: IOrderMeasurementUnitBody) => async (dispatch, getState) => tryCatchHandler(
    dispatch,
    async () => {
      const {
        pagination: { per_page, current_page },
      } = getState().ordersReducer;
      const { data } = await inboundOrdersAPI.updateMeasurementUnit(order_id, uomBody);
      await dispatch(ordersActionCreator.fetchOrder(order_id, false));
      await dispatch(ordersActionCreator.fetchOrders(per_page, current_page, false));
      return data;
    },
    '',
    false,
  ),
  receiveOrder: (order_id: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await inboundOrdersAPI.receiveOrder(order_id);

      const {
        pagination: { per_page, current_page },
      } = getState().ordersReducer;

      await dispatch(ordersActionCreator.fetchOrders(per_page, current_page));
      await dispatch(ordersActionCreator.refreshSelectedOrder());
    });
  },

  uploadDocument: (orderId: number, file: any) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await documentsAPI.uploadDocument(file);

    if (data) {
      await documentsAPI.attachDocumentToOrder({ order_id: orderId, document_id: data.id });

      await dispatch(ordersActionCreator.fetchOrder(orderId));
    }
  }),

  removeDocument: (selectedOrderId: number, documentId: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    await documentsAPI.removeOrderDocument(documentId);

    await dispatch(ordersActionCreator.fetchOrder(selectedOrderId));
  }),

  removeMeasurementUnit: (measurement_unit_id: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const isDeleted = await inboundOrdersAPI.deleteMeasurementUnit(measurement_unit_id);
    const { selectedOrder } = getState().ordersReducer;
    if (isDeleted) {
      dispatch(ordersActionCreator.fetchOrder(selectedOrder?.id));
    }
  }),

  mergeMeasurementUnit: (item_inventory_id: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await inboundOrdersAPI.mergeMeasurementUnit(item_inventory_id);
    await dispatch(ordersActionCreator.refreshSelectedOrder());
    return data;
  }),
  confirmInboundOrder: (orderId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await inboundOrdersAPI.confirmInboundOrder(orderId);
      const {
        pagination: { per_page, current_page },
      } = getState().ordersReducer;

      dispatch(ordersActionCreator.fetchOrders(per_page, current_page));
      dispatch(ordersActionCreator.fetchOrder(orderId));
      return true;
    });
  },
  unconfirmInboundOrder: (orderId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await inboundOrdersAPI.unconfirmInboundOrder(orderId);
      const {
        pagination: { per_page, current_page },
      } = getState().ordersReducer;

      dispatch(ordersActionCreator.fetchOrders(per_page, current_page));
      dispatch(ordersActionCreator.fetchOrder(orderId));
      return true;
    });
  },
  acknowledgeInboundOrder: (orderId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await inboundOrdersAPI.acknowledgeInboundOrder(orderId);
      const {
        pagination: { per_page, current_page },
      } = getState().ordersReducer;

      dispatch(ordersActionCreator.fetchOrders(per_page, current_page));
      dispatch(ordersActionCreator.fetchOrder(orderId));
      return true;
    });
  },
  dispatchInboundOrder: (orderId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await inboundOrdersAPI.dispatchInboundOrder(orderId);
      const {
        pagination: { per_page, current_page },
      } = getState().ordersReducer;

      dispatch(ordersActionCreator.fetchOrders(per_page, current_page));
      dispatch(ordersActionCreator.fetchOrder(orderId));
      return true;
    });
  },
  acceptInboundOrder: (orderId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await inboundOrdersAPI.acceptInboundOrder(orderId);
      const {
        pagination: { per_page, current_page },
      } = getState().ordersReducer;

      dispatch(ordersActionCreator.fetchOrders(per_page, current_page));
      dispatch(ordersActionCreator.fetchOrder(orderId));
      return true;
    });
  },
  terminateOrder: (orderId: number, reasonData: ITerminateReason) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await inboundOrdersAPI.terminateInboundOrder(orderId, reasonData);
      const {
        pagination: { per_page, current_page },
      } = getState().ordersReducer;

      dispatch(ordersActionCreator.fetchOrders(per_page, current_page));
      dispatch(ordersActionCreator.fetchOrder(orderId));
      return true;
    });
  },
  clearOrdersState: () => async (dispatch) => {
    dispatch(ordersActionCreator.setOrders([]));
    dispatch(ordersActionCreator.setSelectedOrder(null));
    dispatch(ordersActionCreator.setOrderItems([]));
    dispatch(ordersActionCreator.setConditions([]));
    dispatch(ordersActionCreator.setMeasurementUnitsOptions([]));
  },
};
