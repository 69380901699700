import { CloseIcon, EditIcon } from 'assets/images';
import { infoBlockModes } from '../../../constants/infoBlockModes';

export const getEditCloseIcons = (mode: any, editIconClick: any, closeIconClick: any) => {
  if (mode === infoBlockModes.EDIT) {
    return (
      <button className="close-mode-button" onClick={closeIconClick}>
        <CloseIcon />
      </button>
    );
  }
  return (
    <button className="edit-mode-button" onClick={editIconClick}>
      <EditIcon />
    </button>
  );
};
