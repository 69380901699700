import { Row, Col, Table } from 'antd';
import { Document } from '@react-pdf/renderer';
import moment from 'moment';

import './InventoryReportRowStyle.scss';

const generalTable = {
  columns: (data) => ([
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: data.name,
      dataIndex: 'item',
    },
  ]),

  data: (data) => ([
    {
      key: '1',
      name: data.skuType ? data.skuType : 'ID: ',
      item: data.sku ? data.sku : 'Not defined',
    },
    {
      key: '2',
      name: 'Store',
      item: data.store,
    },
    {
      key: '3',
      name: 'Current Qty',
      item: data.currentQty,
    },
  ]),
};

const fullTable = {
  columns: () => [{
    title: 'Date',
    dataIndex: 'date',
    width: '73px',
  },
  {
    title: 'Transaction Type',
    dataIndex: 'transactionType',
    width: '94px',
  },
  {
    title: 'Order number',
    dataIndex: 'id',
    width: '80px',
  },
  {
    title: 'Reference number',
    dataIndex: 'reference_number',
    width: '100px',
  },
  {
    title: () => <div className="text-center">Qty(eaches)</div>,
    dataIndex: 'qty',
    render: (text) => <div style={{ textAlign: 'end' }}>{text}</div>,
    width: '73px',
  },
  {
    title: 'Note',
    dataIndex: 'note',
    width: '117px',
  }],

  data: (data) => data.map((item) => ({
    key: item.internal_id,
    date: item.date,
    transactionType: item.transaction_type,
    id: item.order_id,
    qty: item.transaction_type === 'Outbound' ? -item.totalContains : item.totalContains,
    note: item.notes,
    reference_number: item.reference_number,
  })),
};

export function InventoryReportRow({ data, time }) {
  const timeRefactor = (time) => moment(time, 'MM.DD.YYYY').format('MM.DD.YYYY');

  return (
    <Document>
      <div className="root-inventory-report-row">
        <div className="header">Stock In/Out</div>

        <Row className="time-block">
          <Col span={8} />
          <Col span={8}>
            {timeRefactor(time.pickerStart)}
            {' '}
            to
            {' '}
            {timeRefactor(time.pickerEnd)}
          </Col>
          <Col style={{ textAlign: 'end' }} span={8}>
            Date:
            {' '}
            {timeRefactor(new Date())}
          </Col>
        </Row>

        <div className="table-report-inventory">
          <Table
            pagination={false}
            columns={generalTable.columns(data)}
            dataSource={generalTable.data(data)}
            bordered
          />
        </div>

        <div className="table-report-inventory-full-table">
          <Table
            pagination={false}
            columns={fullTable.columns()}
            dataSource={fullTable.data(data.orders)}
            bordered
          />
        </div>

        <Row className="count-block-from-table">
          <Col>
            Number of Events :
            {data.numberOfEvents}
          </Col>
          <Col span={7}>
            Total Inbound (Eaches):
            {' '}
            {data.totalUsageInbound}
          </Col>

          <Col span={7}>
            Total Outbound (Eaches):
            {' '}
            {data.totalUsageOutbound}
          </Col>

        </Row>

      </div>
    </Document>
  );
}
