import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { generalActionCreator } from 'store/action-creators';
import { getEnv } from './getEnv';

interface EnvWrapperProps {
  children: any;
}

function EnvWrapper({ children }: EnvWrapperProps) {
  const dispatch = useDispatch();
  const [isEnvCreated, setIsEnvCreated] = useState(process.env.REACT_APP_MODE === 'local');

  useEffect(() => {
    if (process.env.REACT_APP_MODE !== 'local') {
      dispatch(generalActionCreator.setIsLoading(1));
      getEnv().then((res) => {
        setIsEnvCreated(res);
        dispatch(generalActionCreator.setIsLoading(-1));
      }).catch((e) => {
        dispatch(generalActionCreator.setError(e));
      });
    }
  }, []);
  return isEnvCreated && <div style={{ height: '100%', width: '100%' }}>{children}</div>;
}

export default EnvWrapper;
