import {
  IExternalIdType, IItem, IPagination, IReduxAction,
} from 'interfaces';
import { EItemsAction } from './types';

export interface IItemsSearchParams {
  store_id: number | null;
  search: string;
}

interface IItemsInitialState {
  items: IItem[];
  selectedItem: IItem | null;
  externalIDTypes: IExternalIdType[];
  pagination: IPagination;
  selectedUomItems: any;
  search_params: IItemsSearchParams;
}

const initialState: IItemsInitialState = {
  items: [],
  selectedItem: null,
  selectedUomItems: [],
  externalIDTypes: [],
  pagination: {
    current_page: 1,
    per_page: 10,
  },
  search_params: {
    store_id: null,
    search: '',
  },
};

export default function itemsReducer(state: IItemsInitialState = initialState, action: IReduxAction) {
  switch (action.type) {
    case EItemsAction.SET_ITEMS:
      return {
        ...state,
        items: action.payload,
      };
    case EItemsAction.SET_SELECTED_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case EItemsAction.SET_EXTERNAL_ID_TYPES:
      return {
        ...state,
        externalIDTypes: action.payload,
      };
    case EItemsAction.SET_PAGINATION:
      return {
        ...state,
        pagination: action.payload,
      };
    case EItemsAction.SET_SELECTED_UOM_ITEMS:
      return {
        ...state,
        selectedUomItems: action.payload,
      };
    case EItemsAction.SET_SEARCH_PARAMS:
      return { ...state, search_params: action.payload };
    default:
      return state;
  }
}
