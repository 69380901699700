import { IConsignee, IOutboundOrderBody, ITerminateReason } from 'interfaces';
import { IOutboundSearchParams } from 'store/modules/outbound/reducer';
import baseAxios from './axiosInstance';

const mainPath = 'out-orders';

const outboundOrderAPI = {
  async getOutboundOrders(per_page = 10, page = 1, search_params?: IOutboundSearchParams) {
    const params = { limit: per_page, page, ...search_params };

    const { data } = await baseAxios.get(mainPath, { params });
    return data;
  },

  async getOutboundOrder(id: number) {
    const { data } = await baseAxios.get(`${mainPath}/${id}`);
    return data;
  },

  async getOutboundItemOptions(itemId: number) {
    const { data } = await baseAxios.get(`item/${itemId}/out-options`);
    return data;
  },

  async getOutboundItems(store_id: number, search: string) {
    const params = { search };
    if (store_id) { Object.assign(params, { store_id }); }
    const { data } = await baseAxios.get(`${mainPath}/items`, { params });
    return data;
  },
  async getAllConsignees(per_page = 1000) {
    const { data } = await baseAxios.get('/consignee', { params: { limit: per_page } });
    return data;
  },
  postOutboundOrder(order: any) {
    return baseAxios.post(`${mainPath}`, order);
  },
  putOutboundOrder(order_id: number, order: IOutboundOrderBody) {
    return baseAxios.put(`${mainPath}/${order_id}`, order);
  },
  postOutboundOrderItem(item: any, orderId: number) {
    return baseAxios.post(`${mainPath}/${orderId}/uom`, item);
  },
  putOutboundOrderItem(itemId: number, item) {
    return baseAxios.put(`${mainPath}/order-items/${itemId}`, item);
  },
  claimOutboundItem(orderId: number) {
    return baseAxios.patch(`${mainPath}/${orderId}/claim`);
  },
  unclaimOutboundItem(orderId: number) {
    return baseAxios.patch(`${mainPath}/${orderId}/unclaim`);
  },
  goToPick(orderId, items: any) {
    return baseAxios.patch(`${mainPath}/${orderId}/go-to-pick`, items);
  },
  deleteItem(item_id: number) {
    return baseAxios.delete(`${mainPath}/order-items/${item_id}`);
  },
  postConsignee(consignee: IConsignee) {
    return baseAxios.post('/consignee', consignee);
  },
  assignConsignee(id, consignee_id) {
    return baseAxios.patch(`${mainPath}/${id}/assign-consignee`, consignee_id);
  },
  putConsignee(consigneeId: number, consignee: IConsignee) {
    return baseAxios.put(`/consignee/${consigneeId}`, consignee);
  },

  resetPickingID(orderId: number, body: { itemInventoriesIdList: number[] }) {
    return baseAxios.patch(`${mainPath}/${orderId}/reset-pick`, body);
  },

  pickItemInventoryId(itemInvId: number) {
    return baseAxios.put(`${mainPath}/order-items/${itemInvId}/pick`);
  },

  confirmItemInvetoryId(orderId: number) {
    return baseAxios.patch(`${mainPath}/${orderId}/confirm-pick`);
  },

  dispatchOrderId(orderId: number) {
    return baseAxios.patch(`${mainPath}/${orderId}/dispatch`);
  },

  terminateOrderId(orderId: number, reasonData: ITerminateReason) {
    return baseAxios.patch(`${mainPath}/${orderId}/terminate`, reasonData);
  },

  changeLocationsOrder(orderId: number) {
    return baseAxios.patch(`${mainPath}/${orderId}/change-locations`);
  },
};

export default outboundOrderAPI;
