export enum RelationTypes {
  STORE = 1,
  WAREHOUSE = 2,
}

export const relationType = {
  1: 'Store',
  2: 'Warehouse',
};

export enum UserRoles {
  SUPER_ADMIN = 'Super Admin',
  WAREHOUSE_ADMIN = 'Warehouse Admin',
  WAREHOUSE_INTERN = 'Warehouse Intern',
  STORE_ADMIN = 'Store Admin',
  STORE_INTERN = 'Store Intern',
}

export const userRole = {
  1: 'Super Admin',
  2: 'Warehouse Admin',
  3: 'Warehouse Intern',
  4: 'Store Admin',
  5: 'Store Intern',
};
