import { AbilityActions, AbilitySubjects } from 'constants/usersPermissions';

export const requiredAbilitiesForWarehousesInfoBlock = [
  { subject: AbilitySubjects.WAREHOUSES, action: AbilityActions.READ },
  { subject: AbilitySubjects.WAREHOUSES, action: AbilityActions.CREATE },
  { subject: AbilitySubjects.WAREHOUSES, action: AbilityActions.UPDATE },
];

export const requiredAbilitiesForWarehouseStaffBlock = [
  { subject: AbilitySubjects.WAREHOUSES, action: AbilityActions.READ },
  { subject: AbilitySubjects.WAREHOUSES, action: AbilityActions.CREATE },
  { subject: AbilitySubjects.WAREHOUSES, action: AbilityActions.UPDATE },
  { subject: AbilitySubjects.WAREHOUSES, action: AbilityActions.DELETE },
];
