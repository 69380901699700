import { ICouriers, IReduxAction } from 'interfaces';
import { CouriersAction } from './types';

interface ICouriersState {
  couriers: ICouriers[]
}

const initialState: ICouriersState = {
  couriers: [],
};

export default function couriersReducer(state: ICouriersState = initialState, action: IReduxAction) {
  switch (action.type) {
    case CouriersAction.SET_COURIERS:
      return { ...state, couriers: action.payload };
    default:
      return state;
  }
}
