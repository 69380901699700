import {
  IPagination, IReduxAction, IRecentActivityReport, IStore, IStoresWithUsers, IUser, IStockReport,
} from 'interfaces';

import { EStoresAction } from './types';

export interface IStoresSearchParams{
  search?: string;
}
interface IStoresInitialState {
  stores: IStore[];
  selectedStore: IStoresWithUsers;
  reportStore: IRecentActivityReport[];
  reportStock: IStockReport[];
  usersForStore: IUser[];
  pagination: IPagination;
  search_params: IStoresSearchParams;
}

const initialState: IStoresInitialState = {
  stores: [],
  selectedStore: null,
  reportStore: null,
  reportStock: null,
  usersForStore: [],
  pagination: { current_page: 1, per_page: 10 },
  search_params: { search: '' },
};

export default function storesReducer(state: IStoresInitialState = initialState, action: IReduxAction) {
  switch (action.type) {
    case EStoresAction.SET_STORES:
      return { ...state, stores: action.payload };
    case EStoresAction.SET_SELECTED_STORE:
      return { ...state, selectedStore: action.payload };
    case EStoresAction.SET_USERS_FOR_STORE:
      return { ...state, usersForStore: action.payload };
    case EStoresAction.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case EStoresAction.SET_REPORT_STORE:
      return { ...state, reportStore: action.payload };
    case EStoresAction.SET_REPORT_STOCK:
      return { ...state, reportStock: action.payload };
    case EStoresAction.SET_SEARCH_PARAMS:
      return { ...state, search_params: action.payload };
    default:
      return state;
  }
}
