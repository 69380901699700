import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App';
import { RelationTypes, userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import { usersActionCreator } from 'store/action-creators';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import UploadDocument from 'components/globals/uploadDocument/UploadDocument';
import { isDocumentsRemoveDisabled } from 'constants/document/documentAbilities';
import {
  allowedRolesForCreateStores,
  allowedRolesForSeeStoresPage,
  requiredAbilitiesForStoreInfoBlock,
  requiredAbilitiesForStoreStaffBlock,
} from '../../constants/stores/pageAbilities';
import { storesActionCreator } from '../../store/modules/stores/action-creators';
import { MainWrapper, RightCard, RightWrapper } from '../../components/layout';
import { CustomTable } from '../../components/globals';
import { useWindowDimensions } from '../../utils';
import { tableColumns } from '../../constants/stores/storesData';
import StoreInformationBlock from '../../components/views/stores/storeInfoBlock/StoreInformationBlock';
import RelatedUsers from '../../components/views/relatedUsers/RelatedUsers';
import { infoBlockModes } from '../../constants/infoBlockModes';
import { getEditCloseIcons } from '../../components/layout/rightCard';
import CurrentRowInfo from '../../components/views/inventory/CurrentRowInfo/CurrentRowInfo';
import { ReportType } from '../../constants/reportType';
import { IStore, IUser } from '../../interfaces';

import './StoresStyle.scss';

function Stores() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { stores, pagination, selectedStore } = useSelector((state: RootState) => state.storesReducer);
  const roles = useSelector((state: RootState) => state.rolesReducer.roles);
  const user = useSelector((state: RootState) => state.authReducer.user);

  const isUserHasAccessToSeeStoresPage = isAllowedForUserRole(allowedRolesForSeeStoresPage, userRole[user.role_id]);

  const isWareHouseAdmin = userRole[user.role_id as unknown as number] === UserRoles.WAREHOUSE_ADMIN
  || userRole[user.role_id as unknown as number] === UserRoles.SUPER_ADMIN;

  const [isRightBlockOpen, setIsRightBlockOpen] = useState(false);
  const [mode, setMode] = useState(infoBlockModes.VIEW);
  const [searchValue, setSearchValue] = useState(null);

  const { height } = useWindowDimensions();

  const newStore = () => {
    const store = {
      email: '',
      company_name: '',
      country: '',
      active: 1,
      address_1: '',
      address_2: '',
      city: '',
      created_at: '',
      phone: '',
      postal_code: '',
      state: '',
      users: [],
    };
    setMode(infoBlockModes.CREATE);
    dispatch(storesActionCreator.setSelectedStore(store));
    setIsRightBlockOpen(true);
  };

  const selectStore = (id: number) => {
    dispatch(storesActionCreator.fetchStore(id));

    setIsRightBlockOpen(true);
    setMode(infoBlockModes.VIEW);
  };

  const getIcon = () => {
    if (mode === infoBlockModes.CREATE) return '';

    return getEditCloseIcons(
      mode,
      () => setMode(infoBlockModes.EDIT),
      () => {
        setMode(infoBlockModes.VIEW);
        selectStore(selectedStore.id);
      },
    );
  };

  const getRoleIdByValue = (roleValue: string) => {
    const currentRole = roles?.find((role) => role.title === roleValue);
    return currentRole ? currentRole.id : null;
  };

  const addRelatedUser = async (user: IUser) => {
    const isUserAddedToStore = await dispatch(storesActionCreator.addUserForStore(selectedStore?.id, user?.id));
    if (isUserAddedToStore) {
      if (!user?.relatedToStores.length) {
        await dispatch(usersActionCreator.updateUserRole(user?.id, getRoleIdByValue(UserRoles.STORE_ADMIN)));
      }
      await dispatch(storesActionCreator.fetchStore(selectedStore?.id));
    }
  };

  const removeRelatedUser = (userId: number) => {
    dispatch(storesActionCreator.deleteUserForStore(selectedStore?.id, userId));
  };

  const handleUploadDocument = (storeId, file) => {
    dispatch(storesActionCreator.uploadDocument(storeId, file));
  };
  const handleRemoveDocument = (storeId, fileId) => {
    dispatch(storesActionCreator.removeDocument(storeId, fileId));
  };

  useEffect(() => {
    // When page render first time we don't need this api call
    if (typeof searchValue !== null) {
      dispatch(storesActionCreator.fetchStores(null, null, { search: searchValue }));
    }
  }, [searchValue]);

  useEffect(() => {
    dispatch(storesActionCreator.fetchStores(10, 1));
    return () => {
      dispatch(storesActionCreator.clearStores());
    };
  }, []);

  return isUserHasAccessToSeeStoresPage ? (
    <>
      <MainWrapper
        title={t('sidebar.stores')}
        buttons={!isAllowedForUserRole(allowedRolesForCreateStores, userRole[user.role_id]) && []}
        iconAction={newStore}
        isFullScreen={!isRightBlockOpen}
      >
        <CustomTable
          scroll={{ y: `${height - 355}px` }}
          className="orders"
          pagination={pagination}
          setSearchValue={setSearchValue}
          onSearch={setSearchValue}
          columns={[
            ...tableColumns,
            {
              title: '',
              dataIndex: 'currentRow',
              render: (_, store) => (
                <CurrentRowInfo
                  selectId={selectedStore?.id}
                  chooseRowId={store.id}
                  name={store.company_name}
                  type={ReportType.STORE}
                  isWareHouseAdmin={isWareHouseAdmin}
                />
              ),
            },
          ]}
          itemClick={(store: IStore) => selectStore(store.id)}
          selectedItem={selectedStore}
          tableData={stores.map((store: IStore) => ({
            ...store,
            key: store.id,
          }))}
          getNewItems={(page: number, per_page: number) => dispatch(storesActionCreator.fetchStores(per_page, page, { search: searchValue }))}
        />
      </MainWrapper>
      {isRightBlockOpen && (
        <RightWrapper
          title={selectedStore?.id ? `Store ${selectedStore.company_name}` : 'New store'}
          onCancelClick={() => setIsRightBlockOpen(false)}
        >
          <RightCard title={t('stores.contactInformation')} icon={getIcon()}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForStoreInfoBlock}>
              <StoreInformationBlock mode={mode} setMode={setMode} />
            </WithAuthWrapper>
          </RightCard>

          <RightCard className="related-users-right-card" title={t('stores.storeStaff')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForStoreStaffBlock}>
              <RelatedUsers
                userRelationType={RelationTypes.STORE}
                addUser={addRelatedUser}
                removeUser={removeRelatedUser}
                relatedUsers={selectedStore?.users || []}
                rerenderParentComponentParams={storesActionCreator.fetchStore(selectedStore?.id)}
              />
            </WithAuthWrapper>
          </RightCard>

          <RightCard title={t('common.documents')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForStoreStaffBlock}>
              <UploadDocument
                selected={selectedStore}
                isRemoveDisabled={(doc) => isDocumentsRemoveDisabled(doc, user)}
                uploadDocument={handleUploadDocument}
                removeDocument={handleRemoveDocument}
              />
            </WithAuthWrapper>
          </RightCard>
        </RightWrapper>
      )}
    </>
  ) : (
    <Redirect to="/items" />
  );
}

export default Stores;
