import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import { IUser } from 'interfaces';

export const isDocumentsRemoveDisabled = (doc: any, user: IUser) => {
  const isDocCreatedByStoreUser = userRole[doc?.created_by?.role_id] === UserRoles.STORE_INTERN
     || userRole[doc?.created_by?.role_id] === UserRoles.STORE_ADMIN;

  const isCurrentUserStoreUser = userRole[user?.role_id as unknown as number] === UserRoles.STORE_INTERN
     || userRole[user?.role_id as unknown as number] === UserRoles.STORE_ADMIN;

  return isCurrentUserStoreUser && !isDocCreatedByStoreUser;
};
