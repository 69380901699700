import { BinslegendaIcon, MiscItemsIcon } from 'assets/images';

import './locationsLegenda.scss';

interface LocationsLegendaProps{
  isRightBlockOpen?: boolean;
  isModalWindow?: boolean;
}

function LocationsLegenda({ isRightBlockOpen, isModalWindow }: LocationsLegendaProps) {
  return (
    <div
      className={`locations-legenda-containder ${isRightBlockOpen && 'with-right-block'} ${
        isModalWindow && 'with-modal'
      }`}
    >
      <div style={{ display: 'flex', marginBottom: '6px' }}>
        <div className="legenda-block2">
          <div className="color-block" />

          <span>Not available</span>
        </div>

        <div className="legenda-block1">
          <div className="color-block" />

          <span>Occupied</span>
        </div>

        <div className="legenda-block3">
          <div className="color-block" />

          <span>Available</span>
        </div>
      </div>

      <div style={{ display: 'flex', marginBottom: '6px' }}>
        <div className="legenda-block4">
          <div className="color-block" />

          <span>Special conditions</span>
        </div>

        <div className="legenda-block5">
          <div className="color-block">
            <MiscItemsIcon />
          </div>

          <span>Misc items</span>
        </div>

        <div className="legenda-block6">
          <div className="color-block">
            <BinslegendaIcon />
          </div>

          <span>Bins</span>
        </div>
      </div>
    </div>
  );
}

export default LocationsLegenda;
