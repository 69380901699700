import { colors } from 'assets/css/variables';

function BinslegendaIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="24" height="24" rx="4.5" fill={colors.grayText} stroke={colors.grayLight} />
      <rect x="3.8335" y="3.83398" width="7.33333" height="7.33333" rx="1.5" stroke="white" />
      <rect x="3.8335" y="13.834" width="7.33333" height="7.33333" rx="1.5" stroke="white" />
      <rect x="13.8335" y="3.83398" width="7.33333" height="7.33333" rx="1.5" stroke="white" />
      <rect x="13.8335" y="13.834" width="7.33333" height="7.33333" rx="1.5" stroke="white" />
    </svg>
  );
}

export default BinslegendaIcon;
