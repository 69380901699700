/* eslint-disable no-unsafe-optional-chaining */
import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Col, Row } from 'antd';
import { OutsideClick } from 'utils';
import { useDispatch, useSelector } from 'react-redux';
import { ThreeDotsIcon } from 'assets/images';
import { useTranslation } from 'react-i18next';
import { CustomButton } from 'components/globals';
import { timeoutToSave } from 'constants/saveOnFlyTimeout';
import { ItemOptionModel } from 'interfaces/outbound';
import { OrderStatus } from 'constants/orders/status';
import { CustomDropdown } from 'components/globals/customDropdown';
import { outboundOrdersActionCreator } from 'store/modules/outbound/action-creators';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { allowedRolesForResetPicking } from 'constants/outbound/pageAbilities';
import { RootState } from 'App';
import ItemExtIdsToolTip from 'components/views/items/itemExtIdsToolTip/ItemExtIdsToolTip';
import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import OutboundItemHeader from './OutboundItemHeader';
import СreatedItemComponent from './createdItemComponent/СreatedItemComponent';
import ItemsWithLocations from './itemsWithLocations/ItemsWithLocations';
import DefaultOutboundItemBody from './DefaultOutboundItemBody';

import './outboundItem.scss';

interface OutboundItemProps {
  orderItem: any;
  selectedOrder: any;
  allUomsfAllOrderItems: any;
  isValidQuantityInItems: any;
  selectedOrderItems: any;
  setIsValidQuantityInItems: any;
  setIsClaimDisabledByRequest: (value: boolean) => void;
  setIsConfirmDisabled: (value: boolean) => void;
  setIsChangeLocationsDisabled: (value: boolean) => void;
  setSelectedOrderItems: (value: any) => void;
  setOrderItems: (orderItemBody) => void;
}

export interface OptionModel {
  value: any;
  id: number;
  contains: number;
}

export type OptionTextType = 'Each' | 'Carton' | 'M Carton';

function OutboundItem({
  orderItem,
  selectedOrder,
  allUomsfAllOrderItems,
  isValidQuantityInItems,
  selectedOrderItems,
  setIsValidQuantityInItems,
  setIsClaimDisabledByRequest,
  setIsConfirmDisabled,
  setSelectedOrderItems,
  setIsChangeLocationsDisabled,
  setOrderItems,
}: OutboundItemProps) {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.authReducer.user);

  const [quantity, setQuantity] = useState(0);
  const [uomOption, setUomOption] = useState('');
  const [delayTimeout, setDelayTimeout] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [uom, setUom] = useState<ItemOptionModel | null>(null);
  const [isSomeFieldChanged, seIsSomeFieldChanged] = useState(false);
  const [uomOptions, setUomContainsOptions] = useState<OptionModel[]>([]);
  const [packageIdOptions, setPackageIdOptions] = useState<OptionModel[]>([]);

  const outsideClicked = OutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  const isResetPickingButtonVisibleByUserRole = isAllowedForUserRole(
    allowedRolesForResetPicking,
    userRole[user.role_id],
  );

  const isStoreUser = userRole[user.role_id] === UserRoles.STORE_ADMIN || userRole[user.role_id] === UserRoles.STORE_INTERN;

  const uniqueItemOptions: any = Array.from(
    new Map(orderItem?.options?.map((item: any) => [item.text, item])).values(),
  );

  const handleDispatch = (outboundOrderItemBody) => {
    setIsClaimDisabledByRequest(true);
    if (outboundOrderItemBody?.quantity) {
      if (delayTimeout) clearTimeout(delayTimeout);
      setDelayTimeout(
        setTimeout(() => {
          dispatch(outboundOrdersActionCreator.updateOutboundOrderItem(orderItem?.id, outboundOrderItemBody));
          setIsClaimDisabledByRequest(false);
        }, timeoutToSave),
      );
    }
  };

  const handleSelectOption = (option: OptionTextType) => {
    setUomOption(option);
    const outboundOrderItemBody: any = {
      quantity,
    };
    let result = null;
    switch (option) {
      case 'Each': {
        result = orderItem?.options.find((x: ItemOptionModel) => x.hasOwnProperty('each_id'));
        outboundOrderItemBody.each_id = result.each_id;
        break;
      }
      case 'M Carton': {
        result = orderItem?.options.find((x: ItemOptionModel) => x.hasOwnProperty('carton_id') && x.master);
        outboundOrderItemBody.carton_id = result.carton_id;

        break;
      }
      case 'Carton': {
        result = orderItem?.options.find((x: ItemOptionModel) => x.hasOwnProperty('carton_id') && !x.master);
        outboundOrderItemBody.carton_id = result.carton_id;
        break;
      }
      default:
        return null;
    }
    if (result) {
      setUom(result);
    }

    handleDispatch(outboundOrderItemBody);

    if (result?.each_id) {
      setOrderItems({ each_id: result.each_id, carton_id: null });
    } else if (result?.carton_id) setOrderItems({ each_id: null, carton_id: result.carton_id });
  };

  const handleSelectPackageId = (value: string) => {
    const findedItem: ItemOptionModel | null = orderItem?.options.find(
      (option: ItemOptionModel) => option.package_id === value,
    );

    if (findedItem) {
      setUom((prevState: ItemOptionModel) => ({
        ...prevState,
        package_id: value,
        contains: findedItem.contains,
        maxQuantity: findedItem.maxQuantity,
      }));

      handleDispatch({
        quantity,
        carton_id: findedItem.carton_id,
      });

      setOrderItems({ carton_id: findedItem.carton_id, each_id: null });
    }
  };

  const handleChangeQuantity = (e) => {
    setQuantity(e.target.value);

    const outboundOrderItemBody: any = {
      quantity: e.target.value,
    };

    if (uom.each_id) {
      outboundOrderItemBody.each_id = uom.each_id;
    } else {
      outboundOrderItemBody.carton_id = uom.carton_id;
    }

    if (e.target.value) {
      handleDispatch(outboundOrderItemBody);
    }

    setOrderItems({ quantity: e.target.value });
  };

  const renderItemBlockByOrderStatus = useMemo(() => {
    let result = null;
    if (selectedOrder) {
      switch (selectedOrder.status) {
        case OrderStatus.Created: {
          result = (
            <СreatedItemComponent
              uomOptions={uomOptions}
              uomOption={uomOption}
              uom={uom}
              isStoreUser={isStoreUser}
              quantity={quantity}
              packageIdOptions={packageIdOptions}
              handleSelectOption={(value) => handleSelectOption(value)}
              handleChangeQuantity={(value) => handleChangeQuantity(value)}
              handleSelectPackageId={(value) => handleSelectPackageId(value)}
            />
          );
          break;
        }
        case OrderStatus.Claimed: {
          result = (
            <ItemsWithLocations
              uom={uom}
              quantity={quantity}
              isStoreUser={isStoreUser}
              seIsSomeFieldChanged={seIsSomeFieldChanged}
              orderItem={orderItem}
              status={selectedOrder?.status}
              allUomsfAllOrderItems={allUomsfAllOrderItems}
              setIsConfirmDisabled={setIsConfirmDisabled}
              setIsChangeLocationsDisabled={setIsChangeLocationsDisabled}
            />
          );
          break;
        }
        case OrderStatus.ReadyToPick: {
          result = (
            <ItemsWithLocations
              uom={uom}
              quantity={quantity}
              isStoreUser={isStoreUser}
              orderItem={orderItem}
              seIsSomeFieldChanged={seIsSomeFieldChanged}
              status={selectedOrder?.status}
              allUomsfAllOrderItems={allUomsfAllOrderItems}
              setIsConfirmDisabled={setIsConfirmDisabled}
              setIsChangeLocationsDisabled={setIsChangeLocationsDisabled}
            />
          );
          break;
        }
        case OrderStatus.Picked: {
          result = <DefaultOutboundItemBody itemMU={uom} orderItem={orderItem} isStoreUser={isStoreUser} />;
          break;
        }
        case OrderStatus.Dispatched: {
          result = <DefaultOutboundItemBody itemMU={uom} orderItem={orderItem} isStoreUser={isStoreUser} />;
          break;
        }
        case OrderStatus.Terminated: {
          result = <DefaultOutboundItemBody itemMU={uom} orderItem={orderItem} isStoreUser={isStoreUser} />;
          break;
        }
        default: {
          result = null;
        }
      }
      return result;
    }
  }, [selectedOrder, quantity, packageIdOptions, uom]);

  useEffect(() => {
    const arrayOfCarton = orderItem?.options.reduce((acc: OptionModel[], option: ItemOptionModel, index: number) => {
      if (option.carton_id && !option.master) {
        acc.push({ value: option.package_id, id: index, contains: option.contains });
      }
      return acc;
    }, []);

    const arrayOfUoms = uniqueItemOptions.map((option: ItemOptionModel, index: number) => ({
      value: option.text,
      id: index,
    })).sort((a, b) => a.value.length - b.value.length).reverse();

    setPackageIdOptions(arrayOfCarton);
    setUomContainsOptions(arrayOfUoms);

    let uomOptionResult = null;

    if (orderItem.each_id) {
      uomOptionResult = orderItem.options.find((option) => option.each_id === orderItem.each_id);
    } else {
      uomOptionResult = orderItem.options.find((option) => option.carton_id === orderItem.carton_id);
    }

    setUomOption(uomOptionResult?.text);

    setUom(uomOptionResult);

    setQuantity(orderItem.quantity);
  }, [orderItem]);

  useEffect(() => {
    dispatch(outboundOrdersActionCreator.fetchOutboundItemOptions(orderItem.item_id));
  }, []);

  useEffect(() => {
    if (isDropdownOpen && outsideClicked) {
      setIsDropdownOpen(false);
    }
  }, [outsideClicked, isDropdownOpen]);

  useEffect(() => {
    if (uom) {
      const { locations, bins, floor } = uom;
      const allLocationsOfUom = [...locations, ...bins, ...floor];

      const allUomsofAllLocations = allLocationsOfUom.map((loc) => loc.uoms).flat(1);
      const sumOfAllQuantitiesInInputs = allUomsofAllLocations.reduce((acc: any, el: any) => acc + el.quantity, 0);

      const isValid = sumOfAllQuantitiesInInputs === orderItem.quantity;
      isValidQuantityInItems[orderItem.item_id.id] = isValid;

      const copiedOrderItem = JSON.parse(JSON.stringify(orderItem));
      if (uom.carton_id) {
        copiedOrderItem.carton_id = uom.carton_id;
        copiedOrderItem.each_id = null;
        copiedOrderItem.package_id = uom.package_id;
      } else {
        copiedOrderItem.each_id = uom.each_id;
        copiedOrderItem.carton_id = null;
        delete copiedOrderItem.package_id;
      }

      const findedSelectedUom = selectedOrderItems.find((uom: any) => uom.id === copiedOrderItem.id);
      if (findedSelectedUom) {
        const updatedUoms = selectedOrderItems.map((mu: any) => {
          if (mu.id === copiedOrderItem.id) {
            mu = copiedOrderItem;
          }
          return mu;
        });
        setSelectedOrderItems(updatedUoms);
      } else {
        setSelectedOrderItems([...selectedOrderItems, copiedOrderItem]);
      }

      setIsValidQuantityInItems(isValidQuantityInItems);
      seIsSomeFieldChanged(false);
    }
  }, [uom, isSomeFieldChanged]);

  const handleResetPicking = () => {
    const { locations, bins, floor } = uom;
    const data = [...locations, ...bins, ...floor].reduce((acc, option) => {
      const isPickedLocation = option.uoms.filter((elem) => elem.isPicked).map((elem) => elem.itemInvId);
      acc.push(...isPickedLocation);
      return acc;
    }, []);

    dispatch(outboundOrdersActionCreator.resetPickingID(selectedOrder?.id, { itemInventoriesIdList: data }));
  };

  const currentExternalIds = orderItem.item_id.external_ids.find((elem) => elem.default);

  return (
    <div className="outbound-item-container">
      <Row className="item-header">
        {currentExternalIds && <ItemExtIdsToolTip item={orderItem.item_id} />}
        <Col className="item-header-text">
          {currentExternalIds
            ? (
              <span>
                <span className="bold">
                  {currentExternalIds.external_id_type_id.name}
                </span>
                :
                {' '}
                {currentExternalIds.external_id_value}
              </span>
            )
            : <span>ID: Not defined</span>}
        </Col>

        <Col className="item-header-text">
          <span className="bold">
            {t('orders.consignee.name')}
            :
          </span>
          {' '}
          {orderItem?.item_id?.name}
        </Col>
        {selectedOrder?.status === OrderStatus.Picked || selectedOrder?.status === OrderStatus.Dispatched ? null : (
          <Col
            ref={dropdownRef}
            onClick={() => selectedOrder?.status !== OrderStatus.Terminated && setIsDropdownOpen(!isDropdownOpen)}
            className="three-dots-block"
          >
            {selectedOrder?.status !== OrderStatus.Terminated && selectedOrder?.status !== OrderStatus.Claimed && (
              <ThreeDotsIcon isActive={isDropdownOpen} />
            )}
            <div className="remove-item-dropdown-wrapper">
              <CustomDropdown isDropdownOpen={isDropdownOpen}>
                {selectedOrder?.status === 'Created' || selectedOrder?.status === 'Claimed' ? (
                  <CustomButton
                    onClick={() => selectedOrder?.status !== OrderStatus.Terminated
                      && dispatch(outboundOrdersActionCreator.deleteOrderItem(orderItem.id))}
                  >
                    {t('orders.remove')}
                  </CustomButton>
                ) : !isResetPickingButtonVisibleByUserRole ? null : (
                  <CustomButton onClick={handleResetPicking}>{t('orders.resetPicking')}</CustomButton>
                )}
              </CustomDropdown>
            </div>
          </Col>
        )}
      </Row>
      <OutboundItemHeader isStoreUser={isStoreUser} />
      {renderItemBlockByOrderStatus}
    </div>
  );
}

export default OutboundItem;
