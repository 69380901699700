import generalReducer from './modules/general/reducer';
import authReducer from './modules/auth/reducer';
import usersReducer from './modules/users/reducer';
import storesReducer from './modules/stores/reducer';
import rolesReducer from './modules/roles/reducer';
import itemsReducer from './modules/items/reducer';
import locationsReducer from './modules/locations/reducer';
import inventoryReducer from './modules/inventory/reducer';
import ordersReducer from './modules/inbound/reducer';
import outboundOrdersReducer from './modules/outbound/reducer';
import couriersReducer from './modules/couriers/reducer';
import warehousesReducer from './modules/warehouses/reducer';

export default {
  generalReducer,
  authReducer,
  usersReducer,
  storesReducer,
  rolesReducer,
  itemsReducer,
  warehousesReducer,
  locationsReducer,
  inventoryReducer,
  ordersReducer,
  outboundOrdersReducer,
  couriersReducer,
};
