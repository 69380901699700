import { sendPasswordResetEmail } from 'firebase/auth';
import { IUserSearchParams } from 'store/modules/users/reducer';
import baseAxios from './axiosInstance';
import { auth } from '../config';

const mainPath = 'user';

const usersAPI = {
  async getUsers(per_page = 10, page = 1, search_params?: IUserSearchParams) {
    const params = { limit: per_page, page, ...search_params };

    const { data } = await baseAxios.get(mainPath, { params });
    return data;
  },
  getStoreUsers(id: any) {
    return baseAxios.get(`storeuser/${id}`);
  },
  async forgotPassword(email: string) {
    try {
      return sendPasswordResetEmail(await auth, email);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
  getUser(id: any) {
    return baseAxios.get(`${mainPath}/${id}`);
  },
  getUserByEmail(email: string) {
    return baseAxios.get(`${mainPath}/email/${email}`).then((res) => res.data);
  },
  getRoles() {
    return baseAxios.get('role');
  },
  createOrUpdateUser(user: any) {
    if (user.id) { return baseAxios.put(`${mainPath}/${user.id}`, user); }
    return baseAxios.post(`${mainPath}`, user);
  },
  updateUserRole(userId: number, userRoleId: number) {
    return baseAxios.patch(`${mainPath}/${userId}/update-role`, { role_id: userRoleId });
  },
  deleteUser(user: any) {
    return baseAxios.delete(`${mainPath}/${user.id}`);
  },
};

export default usersAPI;
