import { EAuthAction } from './types';

const initialState = {
  user: {},
  isAuthUser: '',
  setPendingAuthUser: false,
};

export default function authReducer(state = initialState, action: any) {
  switch (action.type) {
    case EAuthAction.SET_USER:
      return { ...state, user: action.payload };
    case EAuthAction.SET_AUTH_USER_PENDING:
      return { ...state, setPendingAuthUser: true };

    case EAuthAction.SET_AUTH_USER_PENDING_SUCCESS:
      return { ...state, setPendingAuthUser: false };

    case EAuthAction.SET_AUTH_USER:
      return { ...state, isAuthUser: action.payload };

    case EAuthAction.SET_AUTH_USER_SUCCESS:
      return { ...state, isAuthUser: true };

    default:
      return state;
  }
}
