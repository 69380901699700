import { colors } from 'assets/css/variables';

function HeaderPlusIcon() {
  return (

    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12 12H2H12Z" fill={colors.blue} />
      <path d="M12 22V12M12 12V2M12 12H22M12 12H2" stroke="white" strokeWidth="3.33333" strokeLinecap="round" />
    </svg>
  );
}

export default HeaderPlusIcon;
