import { RootState } from 'App';
import { useHistory } from 'react-router-dom';
import { userAbility } from 'interfaces/userAbility';
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { NotFound } from 'views';
import { isUserHasAccess } from './isUserHasAccess';

interface WithAuthWrapperProps {
  requiredAbilities: userAbility[];
  childrenComponent?: JSX.Element;
  children?: JSX.Element;
}

// -----!!!'childrenComponent' props used for routes. In this case we need to redirect user to anouther page!!!-----
// -----!!!'children' props used for htlm components. In this case we neen to hide children component!!!-----

function WithAuthWrapper({ childrenComponent, requiredAbilities, children }: WithAuthWrapperProps) {
  const { abilities: usersAbilites } = useSelector((state: RootState) => state.authReducer.user);
  const history = useHistory();
  const [isHideChild, setIsHideChild] = useState(false);
  const [isAccess, setIsAccess] = useState(false);

  const renderChildrenComponent = useMemo(
    () => (isHideChild ? null : (
      <div style={{ width: '100%', height: '100%', display: children ? 'block' : 'flex' }}>{childrenComponent || children}</div>
    )),
    [isHideChild, requiredAbilities, children],
  );

  useEffect(() => {
    if (usersAbilites?.length) {
      const hasAccess = isUserHasAccess(requiredAbilities, usersAbilites);

      if (!hasAccess && !children) {
        history.push('/items');
      } else if (!hasAccess && children) {
        setIsHideChild(true);
      }
      setIsAccess(hasAccess);
    }
  }, [usersAbilites, requiredAbilities]);

  return isAccess ? renderChildrenComponent : <NotFound />;
}

export default WithAuthWrapper;
