import { UserRoles } from 'constants/users/userRolesAndTypes';
import { ISidebarOption } from 'interfaces/sidear';

const initialSidebarOptions = [
  {
    label: 'Admin.',
    key: '1',
    icon: null,
    children: null,
    path: null,
    type: null,
    isSelected: null,
    header: true,
  },
  {
    label: 'Oper.',
    key: '7',
    icon: null,
    children: null,
    path: null,
    type: null,
    isSelected: null,
    header: true,
  },
  {
    label: 'Billing',
    key: '11',
    icon: null,
    children: null,
    path: null,
    type: null,
    isSelected: null,
    header: true,
  },
  {
    label: 'Items',
    key: '4',
    icon: 'items',
    children: null,
    path: '/items',
    type: null,
    isSelected: null,
    header: false,
  },
  {
    label: 'Inbound',
    key: '8',
    icon: 'inbound',
    children: null,
    path: '/inbound',
    type: null,
    isSelected: null,
    header: false,
  },
  {
    label: 'Inventory',
    key: '9',
    icon: 'inventory',
    children: null,
    path: '/inventory',
    type: null,
    isSelected: null,
    header: false,
  },
  {
    label: 'Outbound',
    key: '10',
    icon: 'outbound',
    children: null,
    path: '/outbound',
    type: null,
    isSelected: null,
    header: false,
  },
  {
    label: 'Reports',
    key: '12',
    icon: 'reports',
    children: null,
    path: '/reports',
    type: null,
    isSelected: null,
    header: false,
  },
];

export const sidebarOptionByUserRole = (userRole: UserRoles) => {
  let resultSidebarOptions = initialSidebarOptions;

  switch (userRole) {
    case UserRoles.STORE_INTERN: {
      resultSidebarOptions = initialSidebarOptions;
      break;
    }
    case UserRoles.STORE_ADMIN: {
      resultSidebarOptions = [
        ...initialSidebarOptions,
        {
          label: 'Users',
          key: '2',
          icon: 'users',
          children: null,
          path: '/users',
          type: null,
          isSelected: null,
          header: false,
        },
        {
          label: 'Stores',
          key: '3',
          icon: 'stores',
          children: null,
          path: '/stores',
          type: null,
          isSelected: null,
          header: false,
        },
      ];
      break;
    }
    case UserRoles.WAREHOUSE_INTERN: {
      resultSidebarOptions = [
        ...initialSidebarOptions,
        {
          label: 'Locations',
          key: '6',
          icon: 'locations',
          children: null,
          path: '/locations',
          type: null,
          isSelected: null,
          header: false,
        },
      ];
      break;
    }
    default:
      resultSidebarOptions = [
        ...initialSidebarOptions,
        {
          label: 'Users',
          key: '2',
          icon: 'users',
          children: null,
          path: '/users',
          type: null,
          isSelected: null,
          header: false,
        },
        {
          label: 'Stores',
          key: '3',
          icon: 'stores',
          children: null,
          path: '/stores',
          type: null,
          isSelected: null,
          header: false,
        },
        {
          label: 'Warehouses',
          key: '5',
          icon: 'warehouses',
          children: null,
          path: '/warehouses',
          type: null,
          isSelected: null,
          header: false,
        },
        {
          label: 'Locations',
          key: '6',
          icon: 'locations',
          children: null,
          path: '/locations',
          type: null,
          isSelected: null,
          header: false,
        },
      ];
  }

  return resultSidebarOptions.sort((a: any, b: any) => a.key - b.key) as ISidebarOption[];
};

export const changeSidebarContainerNames = (sidebarOptionsArray: ISidebarOption[]) => {
  const changedSidebaroptions = sidebarOptionsArray.map((x: ISidebarOption) => {
    if (x.label === 'Admin.') {
      x.label = 'Administration';
    }
    else if (x.label === 'Administration') {
      x.label = 'Admin.';
    }
    if (x.label === 'Oper.') {
      x.label = 'Operation';
    }
    else if (x.label === 'Operation') {
      x.label = 'Oper.';
    }

    return x;
  });
  return changedSidebaroptions;
};
