import { useDispatch, useSelector } from 'react-redux';
import { useState, useMemo } from 'react';
import { Col, Form, Row } from 'antd';
import { RootState } from 'App';
import { IColumn, ILocation } from 'interfaces';
import { locationsActionCreator } from '../../../../store/modules/locations/action-creators';
import { CustomButton, CustomCheckbox, CustomInput } from '../../../globals';
import { infoBlockModes } from '../../../../constants/infoBlockModes';
import { checkMinAndMax } from '../../../../utils/checkMinAndMaxRangeInInput';

interface ColumnInfoProps {
  mode: infoBlockModes;
  setMode: (mode: infoBlockModes) => void;
  isReadonlyByUserRole: boolean;
}

function ColumnInfo({ mode, setMode, isReadonlyByUserRole }: ColumnInfoProps) {
  const [form] = Form.useForm();

  const [isAvailable, setIsAvailable] = useState(false);

  const { selectedRack, locations, selectedColumn } = useSelector((state: RootState) => state.locationsReducer);

  const dispatch = useDispatch();

  const initialValues = useMemo(() => {
    const locationsOfSelectedLevel = locations?.filter((loc: ILocation) => loc.column === selectedColumn);
    const allLocationsUnvailable = locationsOfSelectedLevel?.every((loc: ILocation) => !loc.active);

    setIsAvailable(!allLocationsUnvailable);

    return { width: selectedColumn, active: !allLocationsUnvailable };
  }, [selectedColumn, mode, locations]);

  const save = async (body: IColumn) => {
    await dispatch(locationsActionCreator.updateColumn(selectedRack?.id, selectedColumn, body));

    dispatch(locationsActionCreator.fetchRacks());
    setMode(infoBlockModes.VIEW);
  };

  return (
    <Form form={form} onFinish={save} initialValues={initialValues}>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            name="width"
            rules={[
              {
                required: true,
                message: 'Width is required',
              },
              checkMinAndMax('cell_width', 1, 500),
            ]}
          >
            <CustomInput
              readonly={isReadonlyByUserRole}
              required
              placeholder="Width/CM"
              disabled={mode === infoBlockModes.VIEW}
              type="numbers"
            />
          </Form.Item>
        </Col>

        <Col span={24}>
          <Form.Item name="active">
            <CustomCheckbox
              readonly={isReadonlyByUserRole}
              checked={!isAvailable}
              onChange={(e) => {
                setIsAvailable(!e.target.checked);
                form.setFieldsValue({ active: !e.target.checked });
              }}
              disabled={mode === infoBlockModes.VIEW}
              title="Not available"
            />
          </Form.Item>
        </Col>

        <Col offset={15} span={8}>
          {mode === infoBlockModes.EDIT && (
            <div>
              <Form.Item>
                <CustomButton htmlType="submit">Save</CustomButton>
              </Form.Item>
            </div>
          )}
        </Col>
      </Row>
    </Form>
  );
}

export default ColumnInfo;
