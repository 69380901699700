import React from 'react';
import { useDispatch } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { IRackBody } from 'interfaces';
import { locationsActionCreator } from '../../../../store/modules/locations/action-creators';
import {
  CustomButton, CustomInput, CustomTextArea, ModalWindow,
} from '../../../globals';
import { checkMinAndMax } from '../../../../utils/checkMinAndMaxRangeInInput';
import './newRackModal.scss';

interface NewRackModalProps{
  isModalVisible: boolean;
  setIsModalVisible: (value: boolean)=>void;
  className?: string,
}

function NewRackModal({ isModalVisible, setIsModalVisible, className = '' }: NewRackModalProps) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const initialValues: IRackBody = {
    warehouse_id: 1,
    active: 0,
    description: '',
    levels: 5,
    columns: 5,
    cell_width: 20,
    cell_height: 20,
    cell_depth: 20,
    price: 40,
  };

  const cancel = () => {
    setIsModalVisible(false);
  };

  const addNewRack = async (body: IRackBody) => {
    const { warehouse_id, active } = initialValues;
    const isAdded = await dispatch(locationsActionCreator.addRack({ warehouse_id, active, ...body }));

    if (isAdded) {
      cancel();
      setIsModalVisible(false);
    }
  };

  return (
    <ModalWindow
      title="Add new Rack"
      handleCancelModal={() => setIsModalVisible(false)}
      isModalVisible={isModalVisible}
    >
      <Form form={form} onFinish={addNewRack} initialValues={initialValues}>
        <Row className="new-rack-modal-block" gutter={[24, 24]}>
          <Col span={24}>
            <Row className="new-rack-lev-col-block" gutter={[16, 24]}>
              <Col span={12}>
                <Form.Item
                  name="levels"
                  rules={[
                    {
                      required: true,
                      message: 'Levels are required',
                    },
                    checkMinAndMax('levels', 1, 50),
                  ]}
                >
                  <CustomInput placeholder="Levels" required type="numbers" />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="columns"
                  rules={[
                    {
                      required: true,
                      message: 'Columns are required',
                    },
                    checkMinAndMax('columns', 1, 50),
                  ]}
                >
                  <CustomInput placeholder="Columns" required type="numbers" />
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col span={24}>
            <Row className="new-rack-def-values-block" gutter={[16, 24]}>
              <Col span={24} className="default-values-title">
                Default values
              </Col>
              <Col span={6}>
                <Form.Item
                  name="cell_width"
                  rules={[
                    {
                      required: true,
                      message: 'Width are required',
                    },
                    checkMinAndMax('cell_width', 1, 500),
                  ]}
                >
                  <CustomInput placeholder="Width/CM" required type="numbers" />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="cell_height"
                  rules={[
                    {
                      required: true,
                      message: 'Height are required',
                    },
                    checkMinAndMax('cell_height', 1, 500),
                  ]}
                >
                  <CustomInput placeholder="Height/CM" required type="numbers" />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="cell_depth"
                  rules={[
                    {
                      required: true,
                      message: 'Depth are required',
                    },
                    checkMinAndMax('cell_depth', 1, 500),
                  ]}
                >
                  <CustomInput placeholder="Depth/CM" required type="numbers" />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item
                  name="price"
                  rules={[
                    {
                      required: true,
                      message: 'Price are required',
                    },

                    checkMinAndMax('price', 1),
                  ]}
                >
                  <CustomInput placeholder="Price" required type="numbers" />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <div className="rack-info-from-textarea">
            <Col span={24}>
              <Form.Item name="description">
                <CustomTextArea placeholder="Description" />
              </Form.Item>
            </Col>
          </div>
        </Row>

        <Col className="new-rack-modal-button" span={8} style={{ float: 'right' }}>
          <Form.Item>
            <CustomButton htmlType="submit">Add +</CustomButton>
          </Form.Item>
        </Col>
      </Form>
    </ModalWindow>
  );
}

export default NewRackModal;
