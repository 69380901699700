import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Form, Row } from 'antd';
import { RootState } from 'App';

import { locationsActionCreator } from '../../../../store/modules/locations/action-creators';
import {
  CustomButton, CustomCheckbox, CustomInput, CustomTextArea,
} from '../../../globals';
import { infoBlockModes } from '../../../../constants/infoBlockModes';
import { checkMinAndMax } from '../../../../utils/checkMinAndMaxRangeInInput';

import './locationInfo.scss';

interface LocationInfoProps {
  mode: infoBlockModes;
  isReadonlyByUserRole: boolean;
  setMode: (mode: infoBlockModes) => void;
}

function LocationInfo({ mode, setMode, isReadonlyByUserRole }: LocationInfoProps) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const {
    locations, selectedRack, selectedLevel, selectedColumn, specialConditions,
  } = useSelector(
    (state: RootState) => state.locationsReducer,
  );

  const [cell, setCell] = useState(null);
  const [initCell, setInitCell] = useState(null);

  const handleChange = (newValue: any) => {
    setCell({ ...cell, ...newValue });
  };

  const selectSpecialCondition = (condition: any, checked: any) => {
    if (checked) {
      setCell({ ...cell, specialConditions: [...cell.specialConditions, condition] });
    } else {
      const oldConditions = [...cell.specialConditions];
      const indexOfConditionToRemove = oldConditions.indexOf((c: any) => c.id === condition.id);
      oldConditions.splice(indexOfConditionToRemove, 1);

      setCell({ ...cell, specialConditions: oldConditions });
    }
  };
  const isSpecCondChecked = (conditionId: any) => {
    const isChecked = (cell?.specialConditions || []).map((cond: any) => cond.id).includes(conditionId);
    return isChecked;
  };

  const save = async () => {
    const isUpdated = await dispatch(locationsActionCreator.updateLocation(selectedRack.id, cell));
    if (isUpdated) {
      dispatch(locationsActionCreator.fetchRacks());
      setMode(infoBlockModes.VIEW);
    }
  };

  useEffect(() => {
    const location = locations.find((cell: any) => cell.level === selectedLevel && cell.column === selectedColumn);

    if (location) {
      setCell(location);
      setInitCell(location);
      dispatch(locationsActionCreator.setSelectedLocation(location));
    }
  }, [selectedRack, selectedLevel, selectedColumn, locations]);

  useEffect(() => {
    form.setFieldsValue(cell);
  }, [cell]);

  useEffect(() => {
    if (mode === infoBlockModes.VIEW) {
      if (initCell) setCell(initCell);
    }
  }, [mode]);

  return (
    <Form form={form} onFinish={save}>
      <Row className="cell-info-form-block" gutter={20} style={{ marginBottom: '14px' }}>
        <Col span={12}>
          <Form.Item name="custom_name">
            <CustomInput
              readonly={isReadonlyByUserRole}
              required
              placeholder="Name(Optional)"
              disabled={mode === infoBlockModes.VIEW}
              onChange={(e: any) => handleChange({ custom_name: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="cell-info-form-block" gutter={20}>
        <Col span={6}>
          <Form.Item
            name="width"
            rules={[
              {
                required: true,
                message: 'Width is required',
              },
              checkMinAndMax('width', 1, 500),
            ]}
          >
            <CustomInput
              readonly={isReadonlyByUserRole}
              required
              placeholder="Width/CM"
              disabled={mode === infoBlockModes.VIEW}
              onChange={(e: any) => handleChange({ width: e.target.value })}
              type="numbers"
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="height"
            rules={[
              {
                required: true,
                message: 'Height is required',
              },
              checkMinAndMax('height', 1, 500),
            ]}
          >
            <CustomInput
              readonly={isReadonlyByUserRole}
              placeholder="Height/CM"
              disabled={mode === infoBlockModes.VIEW}
              onChange={(e: any) => handleChange({ height: e.target.value })}
              required
              type="numbers"
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="depth"
            rules={[
              {
                required: true,
                message: 'Depth is required',
              },
              checkMinAndMax('depth', 1, 500),
            ]}
          >
            <CustomInput
              readonly={isReadonlyByUserRole}
              placeholder="Depth/CM"
              disabled={mode === infoBlockModes.VIEW}
              onChange={(e: any) => handleChange({ depth: e.target.value })}
              required
              type="numbers"
            />
          </Form.Item>
        </Col>

        <Col span={6}>
          <Form.Item
            name="price"
            rules={[
              {
                required: true,
                message: 'Price is required',
              },
            ]}
          >
            <CustomInput
              readonly={isReadonlyByUserRole}
              placeholder="Price"
              disabled={mode === infoBlockModes.VIEW}
              onChange={(e: any) => handleChange({ price: e.target.value })}
              required
              type="numbers"
            />
          </Form.Item>
        </Col>

        <Col className="cell-info-checkboxx-block" span={24}>
          <Row>
            <Col span={8}>
              <Form.Item>
                <CustomCheckbox
                  readonly={isReadonlyByUserRole}
                  title="Misc Items"
                  checked={+(cell?.misc_items || 0)}
                  onChange={(e: any) => handleChange({ misc_items: e.target.checked ? '1' : '0' })}
                  disabled={mode === infoBlockModes.VIEW}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item>
                <CustomCheckbox
                  title="Not available"
                  readonly={isReadonlyByUserRole}
                  checked={!+(cell?.active || 0)}
                  onChange={(e: any) => handleChange({ active: e.target.checked ? '0' : '1' })}
                  disabled={mode === infoBlockModes.VIEW}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {specialConditions.map((spCond: any) => (
              <Col span={8} key={spCond.id}>
                <Form.Item>
                  <CustomCheckbox
                    title={spCond.name}
                    readonly={isReadonlyByUserRole}
                    checked={isSpecCondChecked(spCond.id)}
                    onChange={(e: any) => {
                      selectSpecialCondition(spCond, e.target.checked);
                    }}
                    disabled={mode === infoBlockModes.VIEW}
                  />
                </Form.Item>
              </Col>
            ))}
          </Row>
        </Col>

        <Col span={24}>
          <Form.Item name="description">
            <CustomTextArea
              placeholder="Description"
              readonly={isReadonlyByUserRole}
              disabled={mode === infoBlockModes.VIEW}
              onChange={(e: any) => handleChange({ description: e.target.value })}
            />
          </Form.Item>
        </Col>
      </Row>

      <div className="cell-info-save-btn-wrapper">
        {mode !== infoBlockModes.VIEW && (
          <Form.Item>
            <CustomButton className="cell-info-save-btn" htmlType="submit">
              Save
            </CustomButton>
          </Form.Item>
        )}
      </div>
    </Form>
  );
}

export default LocationInfo;
