import './customDropdown.scss';

interface CustomDropdownProps {
  children: any;
  isDropdownOpen: boolean;
  className?: string;
}

function CustomDropdown({
  children, className = '', isDropdownOpen,
}: CustomDropdownProps) {
  return (
    <div
      className={`custom-dropdown-container ${className} ${!isDropdownOpen ? 'hide-dropdown' : 'dropdown-visible'}`}
    >
      {children}
    </div>
  );
}

export default CustomDropdown;
