import baseAxios from './axiosInstance';

const mainPath = 'rack';
const locationPath = 'location';
const binPath = 'bin';
const locationsAPI = {

  async getRacks(warehouse_id = 1) {
    const { data } = await baseAxios.get(`${mainPath}/${warehouse_id}`);
    return data;
  },
  postRack(rack: any) {
    return baseAxios.post(mainPath, rack);
  },
  deleteRack(rackId: any) {
    return baseAxios.delete(`${mainPath}/${rackId}`);
  },
  putRack(rackId: any, options: any) {
    return baseAxios.put(`${mainPath}/update/${rackId}`, options);
  },
  patchLevel(rackId: any, options: any) {
    return baseAxios.patch(`${mainPath}/${rackId}/level`, options);
  },
  patchColumn(rackId: any, options: any) {
    return baseAxios.patch(`${mainPath}/${rackId}/column`, options);
  },

  createLevelOrColumn(rackId: any, option: any) {
    return baseAxios.put(`${mainPath}/${rackId}`, { option });
  },
  deleteLevel(rackId: any, level: any) {
    return baseAxios.delete(`${mainPath}/level/${rackId}`, { data: { level } });
  },
  deleteColumn(rackId: any, column: any) {
    return baseAxios.delete(`${mainPath}/column/${rackId}`, { data: { column } });
  },

  getLocations(rackId: any) {
    return baseAxios.get(`${locationPath}/${rackId}`);
  },
  putLocations(cell: any) {
    return baseAxios.put(`${locationPath}/${cell.id}`, cell);
  },

  getBins(locationId: any) {
    return baseAxios.get(`${binPath}/${locationId}`);
  },
  postBin(bin: any) {
    return baseAxios.post(`${binPath}`, bin);
  },
  putBin(binId: any, binData: any) {
    return baseAxios.put(`${binPath}/${binId}`, binData);
  },
  deleteBin(binId: any) {
    return baseAxios.delete(`${binPath}/${binId}`);
  },

  getSpecialConditions() {
    return baseAxios.get(`${mainPath}/spec-cond`);
  },
};

export default locationsAPI;
