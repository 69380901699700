import Loader from './Loader';

function LoaderWrapper({
  isLoading,
  children,
}: {isLoading:number, children:any}) {
  return (
    <>
      {isLoading > 0 && <Loader />}
      {children}
    </>
  );
}

export default LoaderWrapper;
