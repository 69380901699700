/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-unsafe-optional-chaining */

// This component using for two order statuses : Claimed and Ready to Pick

import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { RootState } from 'App';
import { IOptionUOM } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { OrderStatus } from 'constants/orders/status';

import { CustomButton, CustomInput } from 'components/globals';
import { outboundOrdersActionCreator } from 'store/modules/outbound/action-creators';
import CustomPopover from 'components/globals/customPopover/CustomPopover';
import { colors } from 'assets/css/variables';

import './itemsWithLocations.scss';
import { IconWarningInfo } from 'assets/images';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { allowedRolesForSeeItemsWithLocationsBlock } from 'constants/outbound/pageAbilities';
import { userRole } from 'constants/users/userRolesAndTypes';

interface ItemsWithLocationsProps {
  uom: any;
  orderItem: any;
  quantity: number;
  isStoreUser: boolean;
  seIsSomeFieldChanged: (value: boolean) => void;
  status: OrderStatus;
  allUomsfAllOrderItems: any;
  setIsConfirmDisabled: (value: boolean) => void;
  setIsChangeLocationsDisabled: (value: boolean) => void;
}

function ItemsWithLocations({
  uom,
  status,
  orderItem,
  isStoreUser,
  allUomsfAllOrderItems,
  setIsConfirmDisabled,
  seIsSomeFieldChanged,
  setIsChangeLocationsDisabled,
}: ItemsWithLocationsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state: RootState) => state.authReducer.user);
  const { uomsData } = useSelector((state: RootState) => state.outboundOrdersReducer);
  const isSomeUomNotValid = uomsData.some((x) => x.isError);

  const handleChangeInputs = (quantityValue: number, mu) => {
    const findedUom = uomsData.find((uom) => uom.itemInvId === mu.itemInvId);

    mu.isError = quantityValue > findedUom.inStock;
    let copyUomsArray = [...allUomsfAllOrderItems];

    copyUomsArray = copyUomsArray.map((copiedUom: IOptionUOM) => {
      if (copiedUom.itemInvId === findedUom.itemInvId) {
        copiedUom.quantity = quantityValue;
      }

      return copiedUom;
    });

    seIsSomeFieldChanged(true);
    dispatch(outboundOrdersActionCreator.setUomsData(copyUomsArray));
  };

  const handleClickPickButtons = (mu: IOptionUOM) => {
    dispatch(outboundOrdersActionCreator.pickItemInventoryId(mu.itemInvId));
  };

  const statusForUomsGroup = (um: any) => {
    let groupClassname = '';
    const readyToPickUoms = um.uoms.filter((uomItem) => uomItem.quantity);

    if (status === OrderStatus.ReadyToPick) {
      if (readyToPickUoms.length > 1) {
        groupClassname = 'uoms-group';
      } else {
        groupClassname = '';
      }
    } else if (um.uoms.length > 1) {
      groupClassname = 'uoms-group';
    }
    return groupClassname;
  };

  useEffect(() => {
    // sometimes it is duplicated because of using same items(so options are the same and they are duplicated)
    const uniqueUOMsValues = allUomsfAllOrderItems.filter(
      (value, index, self) => index === self.findIndex((t) => t.itemInvId === value.itemInvId),
    );

    if (uom) {
      dispatch(outboundOrdersActionCreator.setUomsData(uniqueUOMsValues));
    }
  }, [uom]);

  useEffect(() => {
    const filteredUomsData = uomsData?.filter((uomItem) => uomItem.quantity);
    const isAllUomsPicked = filteredUomsData.every((uom) => uom.isPicked);
    const isAnyUomsPicked = filteredUomsData.some((uom) => uom.isPicked);

    setIsConfirmDisabled(!isAllUomsPicked);
    setIsChangeLocationsDisabled(isAnyUomsPicked);
  }, [uom, uomsData, orderItem, isSomeUomNotValid]);

  const uomInStock = ({ isDamaged, inStock }) => (
    <div className="uom-in-stock-custom-component" style={{ color: isDamaged && colors.error }}>
      {isDamaged && (
        <div className="uom-in-stock-custom-component_popover">
          <CustomPopover
            className="uom-in-stock-popover"
            content={<div className="text-sm-10">Damaged</div>}
            trigger="hover"
          >
            <div style={{ display: 'flex' }}>
              <IconWarningInfo />
            </div>
          </CustomPopover>
        </div>
      )}
      {inStock}
    </div>
  );

  const uomLocation = ({ name, custom_name }) => (
    <div className="uom-location-custom-component_popover">
      {custom_name ? (
        <CustomPopover
          className="uom-location-popover"
          content={<div className="text-sm-10">{custom_name}</div>}
          trigger="hover"
        >
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>{name}</div>
        </CustomPopover>
      ) : (
        name
      )}
    </div>
  );

  return (
    <Row className="items-with-locations-container">
      <Col className="ds-flex uom-info-block" span={24}>
        <Col span={isStoreUser ? 7 : 5}>{uom?.text}</Col>
        {!isStoreUser && <Col span={7}>{uom?.package_id}</Col>}
        <Col span={isStoreUser ? 7 : 6}>{orderItem?.quantity}</Col>
        <Col span={isStoreUser ? 5 : 4}>{uom?.contains}</Col>
        <Col span={isStoreUser ? 5 : 2}>
          {uom?.contains ? orderItem?.quantity * +uom?.contains : orderItem?.quantity}
        </Col>
      </Col>
      {isAllowedForUserRole(allowedRolesForSeeItemsWithLocationsBlock, userRole[user.role_id]) && (
        <>
          <Col className="ds-flex" span={24}>
            <Col className="ds-flex locations-title" span={12}>
              <Col span={8}>{t('orders.location')}</Col>
              <Col span={8}>{t('orders.inStock')}</Col>
              <Col span={8}>
                {t('orders.pick')}
              </Col>
            </Col>
            <Col className="ds-flex locations-title" span={12}>
              <Col span={8}>{t('orders.location')}</Col>
              <Col span={8}>{t('orders.inStock')}</Col>
              <Col span={8}>{t('orders.pick')}</Col>
            </Col>
          </Col>
          <Row>
            {uom?.floor.map((um) => (
              <Col className={`ds-flex uom-loation-info ${statusForUomsGroup(um)}`} span={12}>
                {/* if order status is Ready to pick we shouldn't display uoms where quantity equals 0 */}
                {status === OrderStatus.ReadyToPick ? (
                  um.uoms.every((uomItem) => !uomItem.quantity) ? null : (
                    <Col span={8} className="location-name-block">
                      {um.name}
                    </Col>
                  )
                ) : (
                  <Col span={8} className="location-name-block">
                    {um.name}
                  </Col>
                )}

                <Col className="text-center" span={16}>
                  {um.uoms.map((mu) => {
                    // If Order status = Ready to pick we must display block with input else block with 'pick' button
                    if (status === OrderStatus.ReadyToPick) {
                      if (mu.quantity !== 0) {
                        return (
                          <Col className="ds-flex" span={24}>
                            <Col className="justify-center" span={12}>
                              {uomInStock(mu)}
                            </Col>
                            <Col className="justify-center" span={12}>
                              {!mu.isPicked ? (
                                <CustomButton className="picking-button" onClick={() => handleClickPickButtons(mu)}>
                                  {t('orders.pick')}
                                </CustomButton>
                              ) : (
                                t('orders.picked')
                              )}
                            </Col>
                          </Col>
                        );
                      }
                    } else {
                      return (
                        <Col className="ds-flex" span={24}>
                          <Col className="justify-center" span={12}>
                            {uomInStock(mu)}
                          </Col>
                          <Col className="justify-center" span={12}>
                            <CustomInput
                              type="numbers"
                              withoutZero
                              onChange={(e) => handleChangeInputs(+e.target.value, mu)}
                              value={mu.quantity !== 0 ? mu.quantity : ''}
                              className={`uom-input ${mu.isError ? 'error-input' : ''}`}
                            />
                          </Col>
                        </Col>
                      );
                    }
                  })}
                </Col>
              </Col>
            ))}
            {uom?.bins.map((um) => (
              <Col className={`ds-flex uom-loation-info ${statusForUomsGroup(um)}`} span={12}>
                {/* //if order status is Ready to pick we shouldn't display uoms where quantity equals 0 */}
                {status === OrderStatus.ReadyToPick ? (
                  um.uoms.every((uomItem) => !uomItem.quantity) ? null : (
                    <Col span={8} className="location-name-block bin-name-block">
                      {uomLocation(um)}
                      <p className="bin-name">(Bin)</p>
                    </Col>
                  )
                ) : (
                  <Col span={8} className="location-name-block bin-name-block">
                    {uomLocation(um)}
                    <p className="bin-name">(Bin)</p>
                  </Col>
                )}
                <Col className="text-center" span={16}>
                  {um.uoms.map((mu) => {
                    // If Order status = Ready to pick we must display block with input else block with 'pick' button
                    if (status === OrderStatus.ReadyToPick) {
                      if (mu.quantity !== 0) {
                        return (
                          <Col className="ds-flex" span={24}>
                            <Col className="justify-center" span={12}>
                              {uomInStock(mu)}
                            </Col>
                            <Col className="justify-center" span={12}>
                              {!mu.isPicked ? (
                                <CustomButton className="picking-button" onClick={() => handleClickPickButtons(mu)}>
                                  {t('orders.pick')}
                                </CustomButton>
                              ) : (
                                t('orders.picked')
                              )}
                            </Col>
                          </Col>
                        );
                      }
                    } else {
                      return (
                        <Col className="ds-flex" span={24}>
                          <Col className="justify-center" span={12}>
                            {uomInStock(mu)}
                          </Col>
                          <Col className="justify-center" span={12}>
                            <CustomInput
                              type="numbers"
                              withoutZero
                              onChange={(e) => handleChangeInputs(+e.target.value, mu)}
                              value={mu.quantity !== 0 ? mu.quantity : ''}
                              className={`uom-input ${mu.isError ? 'error-input' : ''}`}
                            />
                          </Col>
                        </Col>
                      );
                    }
                  })}
                </Col>
              </Col>
            ))}
            {uom?.locations.map((um) => (
              <Col className={`ds-flex uom-loation-info ${statusForUomsGroup(um)}`} span={12}>
                {/* //if order status is Ready to pick we shouldn't display uoms where quantity equals 0 */}
                {status === OrderStatus.ReadyToPick ? (
                  um.uoms.every((uomItem) => !uomItem.quantity) ? null : (
                    <Col span={8} className="location-name-block">
                      {uomLocation(um)}
                    </Col>
                  )
                ) : (
                  <Col span={8} className="location-name-block">
                    {uomLocation(um)}
                  </Col>
                )}
                <Col className="text-center" span={16}>
                  {um.uoms.map((mu) => {
                    // If Order status = Ready to pick we must display block with input else block with 'pick' button
                    if (status === OrderStatus.ReadyToPick) {
                      if (mu.quantity !== 0) {
                        return (
                          <Col className="ds-flex" span={24}>
                            <Col className="justify-center" span={12}>
                              {uomInStock(mu)}
                            </Col>
                            <Col className="justify-center" span={12}>
                              {!mu.isPicked ? (
                                <CustomButton className="picking-button" onClick={() => handleClickPickButtons(mu)}>
                                  {t('orders.pick')}
                                </CustomButton>
                              ) : (
                                t('orders.picked')
                              )}
                            </Col>
                          </Col>
                        );
                      }
                    } else {
                      return (
                        <Col className="ds-flex" span={24}>
                          <Col className="justify-center" span={12}>
                            {uomInStock(mu)}
                          </Col>
                          <Col className="justify-center" span={12}>
                            <CustomInput
                              type="numbers"
                              withoutZero
                              onChange={(e) => handleChangeInputs(+e.target.value, mu)}
                              value={mu.quantity !== 0 ? mu.quantity : ''}
                              className={`uom-input ${mu.isError ? 'error-input' : ''}`}
                            />
                          </Col>
                        </Col>
                      );
                    }
                  })}
                </Col>
              </Col>
            ))}
          </Row>
        </>
      )}
    </Row>
  );
}

export default ItemsWithLocations;
