import React, { useEffect } from 'react';
import { RootState } from 'App';
import { IItem } from 'interfaces';
import { IWarehouse } from 'interfaces/warehouses';
import { useDispatch, useSelector } from 'react-redux';
import { inventoryActionCreator } from 'store/action-creators';
import InventoryWarehouseInfoCard from './inventoryWarehouseInfo/InventoryWarehouseInfoCard';

interface InventoryWarehousesBlockProps{
    selectedItem: IItem;
}

function InventoryWarehousesBlock({ selectedItem }: InventoryWarehousesBlockProps) {
  const dispatch = useDispatch();

  const warehouses = useSelector((state: RootState) => state.inventoryReducer.itemWarehouses);

  useEffect(() => {
    dispatch(inventoryActionCreator.fetchItemWarehouses(selectedItem?.id));
  }, [selectedItem]);

  return (
    <div>
      {warehouses?.map((warehouse: IWarehouse) => (
        <InventoryWarehouseInfoCard warehouse={warehouse} />
      ))}
    </div>
  );
}

export default InventoryWarehousesBlock;
