import {
  setPersistence, signInWithEmailAndPassword, browserLocalPersistence, signOut, sendPasswordResetEmail,
} from 'firebase/auth';
import baseAxios from './axiosInstance';
import { auth } from '../config';

const authAPI = {
  getUserByToken(token) {
    return baseAxios.post('get-user-by-token', { token }).then((res) => res.data);
  },

  loginFirebase: async (
    email: string,
    password: string,
  ) => {
    const userCredential = await setPersistence(await auth, browserLocalPersistence).then(async () => signInWithEmailAndPassword(await auth, email, password));

    return userCredential.user;
  },

  signUp(data) {
    return baseAxios.post('register', data);
  },

  signOut: async () => signOut(await auth),

  resetPassword: async (email: string) => sendPasswordResetEmail(await auth, email),
};

export default authAPI;
