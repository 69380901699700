import { Col, Row } from 'antd';
import { RootState } from 'App';
import {
  ICondition, IOrderItem, IOrderMeasurementUnit, IOrderMeasurementUnitBody,
} from 'interfaces';
import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CustomDropdown } from 'components/globals/customDropdown';
import { ThreeDotsIcon } from 'assets/images';
import { OutsideClick } from 'utils';
import { InboundOrderStatus, orderType } from 'constants/orders/status';
import { renderItemOrderColumns } from 'utils/itemOrderColumns';
import { useTranslation } from 'react-i18next';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import {
  allowedRolesForAddOrUpdateOnGateUoms,
  allowedRolesForSeeExpectedAndResultAtArrivedStatus,
  allowedRolesForUpdateResultUomsInInbound,
} from 'constants/orders/pageAbilities';
import { userRole } from 'constants/users/userRolesAndTypes';
import { calculateTotalOfUnits } from 'utils/calculateTotalOfUoms';
import ItemExtIdsToolTip from 'components/views/items/itemExtIdsToolTip/ItemExtIdsToolTip';
import FooterOfItemBlock from './FooterOfItemBlock';
import { ordersActionCreator, itemsActionCreator, inventoryActionCreator } from '../../../../store/action-creators';
import { CustomButton } from '../../../globals';
import MeasurementUnit from '../measurementUnit/MeasurementUnit';

import './ItemWithUOM.scss';

interface ItemWithUOMProps {
  orderItem: {
    item: IOrderItem;
    measurement_units: IOrderMeasurementUnit[];
    expected_measurement_units: IOrderMeasurementUnit[];
    result_measurement_units: IOrderMeasurementUnit[];
  };
  setNewValueOrderItem: any;
  removeItem: any;
}

function ItemWithUOM({ orderItem, setNewValueOrderItem, removeItem }: ItemWithUOMProps) {
  const {
    item, measurement_units = [], expected_measurement_units = [], result_measurement_units = [],
  } = orderItem;
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.authReducer.user);
  const { conditions, selectedOrder } = useSelector((state: RootState) => state.ordersReducer);
  const dataCurrentUOM = useSelector((state: RootState) => state.itemsReducer.selectedUomItems);
  const externalIdsParams = useSelector((state: RootState) => state.inventoryReducer.external_ids);

  const isReadonlyResultUomsByUserRole = !isAllowedForUserRole(
    allowedRolesForUpdateResultUomsInInbound,
    userRole[user.role_id],
  );

  const isExpectedVSResultVisibleAtArrivedStatusByUserRole = (selectedOrder?.status === InboundOrderStatus.Arrived
      || selectedOrder?.status === InboundOrderStatus.PartiallyArrived)
    && isAllowedForUserRole(allowedRolesForSeeExpectedAndResultAtArrivedStatus, userRole[user.role_id]);

  const [UOM_Options, setUOM_Option] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dispatch = useDispatch();

  const outsideClicked = OutsideClick(dropdownRef, () => setIsDropdownOpen(false));

  const isOnGate = selectedOrder?.type === orderType.ON_GATE;
  const isArrivedOrClosed = selectedOrder?.status === InboundOrderStatus.Arrived
    || selectedOrder?.status === InboundOrderStatus.Closed
    || selectedOrder?.status === InboundOrderStatus.Terminated
    || selectedOrder?.status === InboundOrderStatus.PartiallyArrived;

  const isClosedOrder = selectedOrder?.status === InboundOrderStatus.Closed || selectedOrder?.status === InboundOrderStatus.Terminated;

  const isNoExpectedUoms = !isOnGate && !orderItem?.expected_measurement_units?.length;

  const addNewMU = async (customBody?: IOrderMeasurementUnitBody, isResultUom = false) => {
    const defaultOption = UOM_Options.find((option) => option.default === 1);

    const updateOption = defaultOption.value === 'Each'
      ? { each_id: defaultOption.id }
      : { carton_id: defaultOption.id, contains: defaultOption.contains };

    if (isOnGate || isResultUom) {
      const okCondition = conditions.find((c: ICondition) => c.name === 'OK');
      const new_measurement_units = [...measurement_units];

      const measurement_unit = {
        each_id: null,
        carton_id: null,
        condition_id: okCondition.id,
        quantity: 1,
        is_received: false,
        ...updateOption,
      };

      if (customBody) Object.assign(measurement_unit, customBody);

      if (!customBody) {
        const newMu: any = await dispatch(
          ordersActionCreator.createMeasurementUnit(item.order_id, { ...measurement_unit, item_id: item.id }),
        );
        Object.assign(measurement_unit, newMu?.id);
      }
      new_measurement_units.push(measurement_unit);
      setNewValueOrderItem({ measurement_units: new_measurement_units });
    } else {
      const new_expected_measurement_units = [...expected_measurement_units];
      const uomBody = {
        carton_id: null,
        each_id: null,
        is_received: false,
        quantity: 1,
        ...updateOption,
      };
      await dispatch(ordersActionCreator.createInboundMeasurementUnit(item.order_id, { ...uomBody, item_id: item.id }));
      new_expected_measurement_units.push(uomBody);
    }
  };

  const removeUnit = async (unit_id: number, index: number) => {
    const response = await dispatch(ordersActionCreator.removeMeasurementUnit(unit_id));

    if (response) {
      measurement_units.splice(index, 1);
      setNewValueOrderItem({ measurement_units });
    }
  };

  const setUomOptions = async (itemId) => {
    const uomOptions: any = await dispatch(itemsActionCreator.fetchUOMOptions(itemId));
    setUOM_Option(uomOptions);
  };

  const isAddUomButtonVisible = () => {
    if (isOnGate && isAllowedForUserRole(allowedRolesForAddOrUpdateOnGateUoms, userRole[user.role_id])) {
      return true;
    }
    if (!isOnGate && selectedOrder?.status === InboundOrderStatus.Created) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    setUomOptions(item.id);
    dispatch(inventoryActionCreator.featchExternalIds(item.id));
  }, [item.id]);

  useEffect(() => {
    setUOM_Option(dataCurrentUOM);
  }, [dataCurrentUOM]);

  useEffect(() => {
    if (isDropdownOpen && outsideClicked) {
      setIsDropdownOpen(false);
    }
  }, [outsideClicked, isDropdownOpen]);

  const defaultExternalIds = externalIdsParams[item.id]?.find((elem) => elem.default);

  return (
    <div
      className={`item-container ${
        !isOnGate && selectedOrder?.status !== InboundOrderStatus.Created ? 'readonly-item-container' : ''
      }`}
    >
      <Row className="item-header">
        {defaultExternalIds && <ItemExtIdsToolTip item={{ ...item, external_ids: externalIdsParams[item.id] }} />}
        <Col className="item-header-text">
          {defaultExternalIds ? (
            <span>
              <span className="bold">{defaultExternalIds.name}</span>
              :
              {' '}
              {defaultExternalIds.external_id_value}
            </span>
          ) : (
            <span>ID: Not defined</span>
          )}
        </Col>

        <Col className="item-header-text">
          <span className="bold">Name:</span>
          {' '}
          {item?.name}
        </Col>
        {!orderItem?.measurement_units?.length
        && !orderItem?.expected_measurement_units?.length
        && selectedOrder?.status !== InboundOrderStatus.Arrived ? (
          <Col ref={dropdownRef} className="three-dots-block" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
            <ThreeDotsIcon isActive={isDropdownOpen} />
            <CustomDropdown isDropdownOpen={isDropdownOpen}>
              <CustomButton className="remove-item-btn" onClick={() => removeItem(orderItem?.item?.id)}>
                Remove
              </CustomButton>
            </CustomDropdown>
          </Col>
          ) : null}
      </Row>
      {(!isOnGate && isArrivedOrClosed) || isExpectedVSResultVisibleAtArrivedStatusByUserRole ? (
        <p className="expected-title">{t('orders.expected')}</p>
      ) : null}
      {selectedOrder?.status !== InboundOrderStatus.Created && isNoExpectedUoms ? (
        <p className="no-expected-text">{t('orders.noExpected')}</p>
      ) : null}
      <Row gutter={10} className="titles">
        {renderItemOrderColumns(selectedOrder?.type, selectedOrder?.status).map((col) => (
          <Col key={col.id} span={col.span} className={`uom-title-header ${col.className}`}>
            {col.title}
          </Col>
        ))}

        {isAddUomButtonVisible() && (
          <Col span={5} className="add-button">
            <CustomButton
              onClick={() => addNewMU()}
              type="primary"
              disabled={selectedOrder?.status === InboundOrderStatus.Closed}
            >
              Add+
            </CustomButton>
          </Col>
        )}
      </Row>

      <div className={`selects-wrapper ${isNoExpectedUoms ? 'ds-none' : ''}`}>
        <div className="conditions-block">
          {measurement_units.length
            ? measurement_units.map((unit: IOrderMeasurementUnit, index: number) => (
              <MeasurementUnit
                unit={unit}
                key={index}
                selectedOrder={selectedOrder}
                colItems={renderItemOrderColumns(selectedOrder?.type, selectedOrder?.status)}
                measurement_unit_options={UOM_Options}
                removeUnit={(unit_id: number) => removeUnit(unit_id, index)}
                orderId={item.order_id}
                itemId={item.id}
                isReadOnlyByUserRole={
                    !isAllowedForUserRole(allowedRolesForAddOrUpdateOnGateUoms, userRole[user.role_id])
                  }
              />
            ))
            : expected_measurement_units.map((unit: IOrderMeasurementUnit, index: number) => (
              <MeasurementUnit
                unit={unit}
                key={index}
                selectedOrder={selectedOrder}
                colItems={renderItemOrderColumns(selectedOrder?.type, selectedOrder?.status)}
                measurement_unit_options={UOM_Options}
                removeUnit={(unit_id: number) => removeUnit(unit_id, index)}
                orderId={item.order_id}
                itemId={item.id}
              />
            ))}
        </div>
      </div>
      {isOnGate ? (
        <FooterOfItemBlock conditions={conditions} uoms={measurement_units} />
      ) : (
        <div
          className={`total-block ${!expected_measurement_units?.length ? 'display-none' : ''} ${
            result_measurement_units?.length ? 'total-block-with-result-uoms' : ''
          }`}
        >
          <Col span={isArrivedOrClosed ? 11 : 15} className="uom-title-header col-total">
            Total:
          </Col>
          {calculateTotalOfUnits(expected_measurement_units)}
        </div>
      )}
      {!isOnGate && result_measurement_units?.length ? (
        <>
          <div className="result-row-wrapper">
            {isExpectedVSResultVisibleAtArrivedStatusByUserRole || isClosedOrder ? (
              <p className="result-title">{t('orders.result')}</p>
            ) : null}
            {!isReadonlyResultUomsByUserRole && !isClosedOrder && !isOnGate && (
              <CustomButton
                disabled={selectedOrder?.status === InboundOrderStatus.Closed}
                onClick={() => addNewMU(null, true)}
                type="primary"
              >
                Add+
              </CustomButton>
            )}
          </div>
          <div className="result-selects-wrapper">
            {isExpectedVSResultVisibleAtArrivedStatusByUserRole || isClosedOrder
              ? result_measurement_units.map((unit: IOrderMeasurementUnit, index: number) => (
                <MeasurementUnit
                  isReadOnlyByUserRole={isReadonlyResultUomsByUserRole}
                  unit={unit}
                  key={index}
                  selectedOrder={selectedOrder}
                  colItems={renderItemOrderColumns(selectedOrder?.type, selectedOrder?.status)}
                  measurement_unit_options={UOM_Options}
                  removeUnit={(unit_id: number) => removeUnit(unit_id, index)}
                  orderId={item.order_id}
                  itemId={item.id}
                  isResultUom
                />
              ))
              : null}
          </div>
          {isExpectedVSResultVisibleAtArrivedStatusByUserRole || isClosedOrder ? (
            <FooterOfItemBlock
              selectedOrder={selectedOrder}
              conditions={conditions}
              uoms={result_measurement_units}
              expectedUoms={expected_measurement_units}
              isResultUoms
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default ItemWithUOM;
