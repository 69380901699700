import { DatePicker } from 'antd';
import DatePickerIcon from 'assets/images/DatePickerIcon';
import { Moment } from 'moment';

import './CustomDatePicker.scss';

interface CustomDatePickerProps {
  placheholder?: string;
  className?: string;
  format?: string;
  handleChange?: any;
  minDate?: any;
  showTime?: boolean;
  value?: Moment;
  readonly?: boolean;
  placheholderTitle?: string;
}
function CustomDatePicker({
  placheholder = '',
  placheholderTitle = '',
  className = '',
  format = 'MM/DD/YYYY',
  handleChange,
  minDate,
  value,
  showTime = false,
  readonly = false,
}: CustomDatePickerProps) {
  const disabledDate = (current) => {
    if (minDate) {
      return minDate.isAfter(current);
    }
  };

  return (
    <div className={`custom-date-picker ${className} ${readonly ? 'readonly-date-picker' : ''}`}>
      <DatePicker
        disabled={readonly}
        value={value}
        showTime={showTime}
        disabledDate={disabledDate}
        format={format}
        placeholder={placheholder}
        onChange={handleChange}
        suffixIcon={<DatePickerIcon />}
      />
      { placheholderTitle && <div className="date-placeholder">{placheholderTitle}</div> }
    </div>
  );
}

export default CustomDatePicker;
