import { AbilityActions, AbilitySubjects } from './usersPermissions';

export const usersAbilites = [{ subject: AbilitySubjects.USERS, action: AbilityActions.READ }];

export const itemsAbilites = [{ subject: AbilitySubjects.ITEMS, action: AbilityActions.READ }];

export const locationsAbilites = [{ subject: AbilitySubjects.LOCATIONS, action: AbilityActions.READ }];

export const inventoryAbilites = [{ subject: AbilitySubjects.INVENTORY, action: AbilityActions.READ }];

export const storesAbilites = [{ subject: AbilitySubjects.STORES, action: AbilityActions.READ }];

export const inboundAbilites = [{ subject: AbilitySubjects.INBOUND, action: AbilityActions.READ }];

export const outboundAbilites = [{ subject: AbilitySubjects.OUTBOUND, action: AbilityActions.READ }];

export const warehousesAbilites = [{ subject: AbilitySubjects.WAREHOUSES, action: AbilityActions.READ }];
