import React from 'react';
import { Radio } from 'antd';

import './customRadioButton.scss';

interface CustomRadioButtonProps {
  title: string;
  value: any;
  checked?: boolean;
  className?: string;
  onClick?: any;
  readonly?: boolean;
  disabled?: boolean;
}

function CustomRadioButton({
  title,
  className = '',
  value,
  checked,
  onClick,
  readonly = false,
  disabled = false,
  ...rest
}: CustomRadioButtonProps) {
  return (
    <div className={`custom-radio-button-wrapper ${className} ${readonly ? 'readonly-radio-button-wrapper' : ''} `}>
      <Radio disabled={disabled || readonly} value={value} checked={checked} onClick={onClick} {...rest}>
        <span className="radio-title">{title}</span>
      </Radio>
    </div>
  );
}

export default CustomRadioButton;
