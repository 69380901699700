export const ELocationsAction = {
  SET_RACKS: 'SET_RACKS',
  SET_SELECTED_RACK: 'SET_SELECTED_RACK',
  SET_LOCATIONS: 'SET_LOCATIONS',
  SET_SELECTED_LOCATION: 'SET_SELECTED_LOCATION',
  SET_BINS: 'SET_BINS',
  SET_SELECTED_LEVEL: 'SET_SELECTED_LEVEL',
  SET_SELECTED_COLUMN: 'SET_SELECTED_COLUMN',
  SET_SPECIAL_CONDITIONS: 'SET_SPECIAL_CONDITIONS',
};
