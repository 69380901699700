import React, { useEffect, useState } from 'react';
import { RootState } from 'App';
import { IOutboundOrder, IStore } from 'interfaces';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { storesActionCreator } from 'store/action-creators';
import { CustomButton, CustomTable, SelectWithSearch } from 'components/globals';
import { tableColumns } from 'constants/outbound/outboundOrdersData';
import { MainWrapper, RightWrapper, RightCard } from 'components/layout';
import { OutboundItems, ConsigneeBlock } from 'components/views/outbound';
import { isDocumentsRemoveDisabled } from 'constants/document/documentAbilities';
import { outboundOrdersActionCreator } from 'store/modules/outbound/action-creators';
import UploadDocument from 'components/globals/uploadDocument/UploadDocument';
import OutboundInfoBlock from 'components/views/outbound/OutboundInfoBlock/OutboundInfoBlock';
import {
  isDocumentsDisabled,
  requiredAbilitiesForConsigneeBlock,
  requiredAbilitiesForOutboundDocumentsBlock,
  requiredAbilitiesForOutboundInfoBlock,
  requiredAbilitiesForOutboundItemsBlock,
} from '../../constants/outbound/pageAbilities';

import './outbound.scss';

function Outbound() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchOrderValue, setSearchOrderValue] = useState('');
  const [isRightBlockOpen, setIsRightBlockOpen] = useState(false);
  const [selectedStore, setSelectedStore] = useState('All stores');
  const [showDispatchedFilter, setShowDispatchedFilter] = useState(false);

  const { orders, pagination, selectedOrder } = useSelector((state: RootState) => state.outboundOrdersReducer);
  const stores = useSelector((state: RootState) => state.storesReducer.stores);
  const user = useSelector((state: RootState) => state.authReducer.user);

  const storesOptionsArray = stores?.map((store: IStore) => ({
    value: store.company_name,
    id: store.id,
  }));

  const storesOptions = [{ value: 'All stores', id: 0 }, ...storesOptionsArray];

  const getStoreIdByValue = (storeValue: any) => {
    const currentStore = storesOptions?.find((store) => store.value === storeValue);
    return currentStore ? currentStore.id : null;
  };

  const renderRightWrapperTitle = () => {
    if (selectedOrder?.id) {
      return (
        <div>
          {t('orders.order')}
          {' #'}
          {selectedOrder?.id}
          <span className="right-wrapper-order-status">
            {' '}
            -
            {' '}
            {selectedOrder?.status}
          </span>
        </div>
      );
    }
    return t('orders.newOrder');
  };

  const newOutboundOrder = () => {
    dispatch(outboundOrdersActionCreator.generateBodyOfNewOrder());
    setIsRightBlockOpen(true);
  };

  const selectOrder = async (id: number) => {
    await dispatch(outboundOrdersActionCreator.fetchOrder(id));
    setIsRightBlockOpen(true);
  };

  const handleUploadDocument = (orderId, file) => {
    dispatch(outboundOrdersActionCreator.uploadDocument(orderId, file));
  };
  const handleRemoveDocument = (orderId, docId) => {
    dispatch(outboundOrdersActionCreator.removeDocument(orderId, docId));
  };

  useEffect(() => {
    dispatch(
      outboundOrdersActionCreator.fetchOrders(null, null, {
        store_id: getStoreIdByValue(selectedStore) || null,
        search: searchOrderValue !== '' ? searchOrderValue : null,
        show_closed: showDispatchedFilter,
      }),
    );
  }, [selectedStore, searchOrderValue, showDispatchedFilter]);

  useEffect(() => {
    dispatch(outboundOrdersActionCreator.fetchOrders());
    dispatch(storesActionCreator.fetchStores(1000));
  }, []);
  return (
    <>
      <MainWrapper
        buttons={(
          <CustomButton onClick={newOutboundOrder} className="new-outbound-btn">
            {t('orders.newOutboundOrder')}
          </CustomButton>
        )}
        title={t('orders.outboundOrders')}
        isFullScreen={!isRightBlockOpen}
        className="outbound-order-wrapper"
      >
        <CustomTable
          className="outbound-table"
          pagination={pagination}
          columns={tableColumns}
          filterCheckbox={{
            checkboxTitle: t('orders.showClosed'),
            checkboxValue: showDispatchedFilter,
            changeAction: () => setShowDispatchedFilter(!showDispatchedFilter),
          }}
          setSearchValue={setSearchOrderValue}
          onSearch={setSearchOrderValue}
          select={(
            <SelectWithSearch
              onSelect={(store) => {
                setSelectedStore(store);
              }}
              onChange={(value) => value}
              options={storesOptions}
              value={selectedStore}
              isOnlyPlaceholder
              className="table-select"
            />
          )}
          itemClick={(order: IOutboundOrder) => {
            selectOrder(order.id);
          }}
          selectedItem={selectedOrder}
          tableData={orders.map((order: IOutboundOrder) => ({
            ...order,
          }))}
          getNewItems={(page: number, per_page: number) => dispatch(
            outboundOrdersActionCreator.fetchOrders(per_page, page, {
              store_id: getStoreIdByValue(selectedStore) || null,
              search: searchOrderValue !== '' ? searchOrderValue : null,
              show_closed: showDispatchedFilter,
            }),
          )}
        />
      </MainWrapper>
      {isRightBlockOpen && (
        <RightWrapper
          onCancelClick={() => {
            setIsRightBlockOpen(false);
          }}
          title={renderRightWrapperTitle()}
          className="orders-right-wrapper"
        >
          <RightCard title={t('orders.orderInformation')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForOutboundInfoBlock}>
              <OutboundInfoBlock selectedOrder={selectedOrder} />
            </WithAuthWrapper>
          </RightCard>
          <RightCard collapsible={selectedOrder?.id ? '' : 'disabled'} title={t('orders.consignee.consignee')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForConsigneeBlock}>
              <ConsigneeBlock />
            </WithAuthWrapper>
          </RightCard>
          <RightCard
            collapsible={selectedOrder?.id && selectedOrder?.consignee_id ? '' : 'disabled'}
            className="outbound-orders-items-right-card"
            title={t('orders.items')}
          >
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForOutboundItemsBlock}>
              <OutboundItems />
            </WithAuthWrapper>
          </RightCard>
          <RightCard collapsible={selectedOrder?.id ? '' : 'disabled'} title={t('common.documents')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForOutboundDocumentsBlock}>
              <UploadDocument
                selected={selectedOrder}
                isDocumentsDisabled={isDocumentsDisabled(selectedOrder?.status)}
                isRemoveDisabled={(doc) => isDocumentsRemoveDisabled(doc, user)}
                uploadDocument={handleUploadDocument}
                removeDocument={handleRemoveDocument}
              />
            </WithAuthWrapper>
          </RightCard>
        </RightWrapper>
      )}
    </>
  );
}

export default Outbound;
