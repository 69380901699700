import { message, Upload } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { useTranslation } from 'react-i18next';
import { CustomButton } from '../buttons';

import './uploadFile.scss';

interface UploadFileProps {
  className?: string;
  disabled?: boolean;
  handleUploadFile: (file: FormData) => void;
}

function UploadFile({ className = '', disabled = false, handleUploadFile }: UploadFileProps) {
  const { t } = useTranslation();

  const accept = 'image/*,.pdf,.doc,.docx';

  const beforeUpload = (file: RcFile) => {
    const formData = new FormData();
    // size of 1 GB
    const isCorrectSize = file.size < 1073741824;
    if (!isCorrectSize) {
      message.error('File must smaller than 1GB!');
    } else {
      formData.append('file', file, file.name);
      handleUploadFile(formData);
    }
  };

  return (
    <div className={`upload-file-wrapper ${className}`}>
      <Upload
        disabled={!disabled}
        className="file-upload-container"
        accept={accept}
        multiple={false}
        beforeUpload={beforeUpload}
        maxCount={1}
      >
        <p className="upload-file-text">{t('common.uploadFile.dragAndDrop')}</p>
        <CustomButton disabled={!disabled} className="browse-btn">
          {t('common.uploadFile.browse')}
          {' '}
          +
        </CustomButton>
      </Upload>
    </div>
  );
}

export default UploadFile;
