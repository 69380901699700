import baseAxios from './axiosInstance';

const mainPath = 'documents/uploadPhoto';

const photosAPI = {
  uploadPhoto(file: FormData) {
    return baseAxios.post(mainPath, file);
  },
};

export default photosAPI;
