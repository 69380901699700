import { IReduxAction } from 'interfaces';
import { EOrdersAction } from './types';

export interface IInboundSearchParams {
  store_id: number | null;
  search: string;
  show_closed: boolean;
}

const initialState = {
  orders: [],
  selectedOrder: null,
  orderItems: [],
  conditions: [],
  measurementUnitOptions: [],
  pagination: { current_page: 1, per_page: 10 },
  search_params: { store_id: null, search: '', show_closed: false },
};

export default function ordersReducer(state = initialState, action: IReduxAction) {
  switch (action.type) {
    case EOrdersAction.SET_ORDERS:
      return { ...state, orders: action.payload };
    case EOrdersAction.SET_SELECTED_ORDER:
      return { ...state, selectedOrder: action.payload };
    case EOrdersAction.SET_ORDER_ITEMS:
      return { ...state, orderItems: action.payload };
    case EOrdersAction.SET_CONDITIONS:
      return { ...state, conditions: action.payload };
    case EOrdersAction.SET_MEASUREMENT_UNITS_OPTIONS:
      return { ...state, measurementUnitOptions: action.payload };
    case EOrdersAction.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case EOrdersAction.SET_SEARCH_PARAMS:
      return { ...state, search_params: action.payload };
    default:
      return state;
  }
}
