import React, { useEffect, useMemo, useState } from 'react';
import { RootState } from 'App';
import { Col, Form, Row } from 'antd';
import { IStore, IUser } from 'interfaces';
import { useDispatch, useSelector } from 'react-redux';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import { userRole } from 'constants/users/userRolesAndTypes';
import { allowedRolesForSeeItemStatistic } from 'constants/inventory/pageAbilities';
import { infoBlockModes } from '../../../../constants/infoBlockModes';
import { measurementSystemOptions } from '../../../../constants/items/itemsData';
import { itemsActionCreator, storesActionCreator } from '../../../../store/action-creators';
import {
  CustomButton,
  CustomInput,
  CustomSelect,
  CustomTextArea,
  SelectWithSearch,
  UploadPhoto,
} from '../../../globals';

import './ItemInformationStyle.scss';

interface ItemInformationBlockProps {
  mode: infoBlockModes;
  isReadonlyByUserRole?: boolean;
  setMode: (value: infoBlockModes) => void;
  user?: IUser;
}

function ItemInformationBlock({
  mode,
  setMode,
  isReadonlyByUserRole,
  user,
}: ItemInformationBlockProps) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const stores = useSelector((state: RootState) => state.storesReducer.stores);
  const selectedItem = useSelector((state: RootState) => state.itemsReducer.selectedItem);

  const [storeId, setStoreId] = useState('');
  const [pictureDocument, setPictureDocument] = useState(null);

  const [saveDisabled, setSaveDisabled] = useState(true);

  const storesOptions = stores.map((store: IStore) => ({
    value: store.company_name,
    id: store.id,
  }));

  const getStoreIdByCompanyName = (company: string) => {
    const findedId = stores?.find((store: IStore) => store.company_name === company);
    setStoreId(String(findedId.id));
  };

  const initialValues = useMemo(() => {
    let result = null;
    if (selectedItem) {
      result = {
        name: selectedItem.name,
        store: selectedItem.store,
        description: selectedItem.description,
        metric: selectedItem.metric,
        picture: selectedItem.picture,
        internal_id: selectedItem.internal_id,
        externalId: selectedItem.externalId,
      };
    }
    return result;
  }, [selectedItem]);

  const handleUploadPhoto = async (file: FormData) => {
    const photo = await dispatch(itemsActionCreator.uploadPhoto(file));
    setPictureDocument(photo);
  };

  const save = async (body: any) => {
    setMode(infoBlockModes.VIEW);
    body.picture = pictureDocument?.id;
    body.store_id = storeId;
    body.id = selectedItem?.id;

    if (selectedItem?.id) {
      await dispatch(itemsActionCreator.updateItem(body));
    } else {
      await dispatch(itemsActionCreator.createItem(body));
    }

    setSaveDisabled(true);
  };

  useEffect(() => {
    (async () => {
      await dispatch(storesActionCreator.fetchStores(1000));
    })();

    return () => {
      dispatch(storesActionCreator.clearStores());
    };
  }, []);

  useEffect(() => {
    setStoreId(String(selectedItem?.store_id?.id));
    setPictureDocument(selectedItem?.picture);
  }, [selectedItem]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    if (mode === infoBlockModes.EDIT) {
      setSaveDisabled(false);
    }
  }, [pictureDocument]);

  return (
    <Form
      className="item-info-form-block"
      form={form}
      onFinish={save}
      onFieldsChange={() => {
        setSaveDisabled(false);
      }}
    >
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: 'Name is required',
              },
              {
                min: 3,
                message: 'Name length should be at least 3 signs',
              },
            ]}
          >
            <CustomInput
              disabled={mode === infoBlockModes.VIEW}
              required
              readonly={isReadonlyByUserRole}
              placeholder="Name"
            />
          </Form.Item>
        </Col>
        {isAllowedForUserRole(allowedRolesForSeeItemStatistic, userRole[user?.role_id as unknown as number]) && (
          <Col span={12}>
            <Form.Item name="internal_id">
              <CustomInput disabled isOnlyPlaceholder readonly={isReadonlyByUserRole} placeholder="Internal ID" />
            </Form.Item>
          </Col>
        )}

        <Col className="item-select-store-wrapper" span={12}>
          <Form.Item
            name="store"
            rules={[
              {
                required: true,
                message: 'Store is required',
              },
            ]}
          >
            <SelectWithSearch
              readonly={mode === infoBlockModes.VIEW || selectedItem?.external_ids?.length || isReadonlyByUserRole}
              required
              disabled={selectedItem?.external_ids.length}
              placeholder="Store"
              onSelect={(e) => getStoreIdByCompanyName(e)}
              options={storesOptions}
              setNothingSelected={(isNothingSelected) => setSaveDisabled(isNothingSelected)}
            />
          </Form.Item>
        </Col>

        <Col className="item-select-ms-wrapper" span={12}>
          <Form.Item
            name="metric"
            rules={[
              {
                required: true,
                message: 'Measurement system is required',
              },
            ]}
          >
            <CustomSelect
              required
              readonly={mode === infoBlockModes.VIEW || isReadonlyByUserRole}
              placeholder="Measurement system"
              options={measurementSystemOptions}
            />
          </Form.Item>
        </Col>

        <Col className="item-textarea-desc-wrapper" span={14}>
          <Form.Item name="description">
            <CustomTextArea readonly={mode === infoBlockModes.VIEW || isReadonlyByUserRole} placeholder="Description" />
          </Form.Item>
        </Col>

        <Col span={10}>
          <Form.Item name="picture">
            <UploadPhoto
              preview
              readonly={mode === infoBlockModes.VIEW || isReadonlyByUserRole}
              disabled={mode === infoBlockModes.VIEW}
              imageObject={pictureDocument}
              handleUploadPhoto={handleUploadPhoto}
            />
          </Form.Item>
        </Col>

        {mode !== infoBlockModes.VIEW && (
          <Col span={5} offset={19}>
            <Form.Item>
              <CustomButton disabled={saveDisabled} htmlType="submit">
                Save
              </CustomButton>
            </Form.Item>
          </Col>
        )}
      </Row>
    </Form>
  );
}

export default ItemInformationBlock;
