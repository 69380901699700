import { IPagination, IReduxAction } from 'interfaces';
import { EWarehousesAction } from './types';

export interface IWarehousesSearchParams{
  search: string;
}

interface IWarehousesState {
    warehouses: [],
    selectedWarehouse: null,
    pagination: IPagination,
    search_params: IWarehousesSearchParams;
  }

const initialState: IWarehousesState = {
  warehouses: [],
  selectedWarehouse: null,
  pagination: { current_page: 1, per_page: 10 },
  search_params: { search: '' },
};

export default function warehousesReducer(state: IWarehousesState = initialState, action: IReduxAction) {
  switch (action.type) {
    case EWarehousesAction.SET_WAREHOUSES:
      return { ...state, warehouses: action.payload };
    case EWarehousesAction.SET_SELECTED_WAREHOUSE:
      return { ...state, selectedWarehouse: action.payload };
    case EWarehousesAction.SET_PAGINATION:
      return { ...state, pagination: action.payload };
    case EWarehousesAction.SET_SEARCH_PARAMS:
      return { ...state, search_params: action.payload };
    default:
      return state;
  }
}
