import { colors } from 'assets/css/variables';

function MinusIcon() {
  return (

    <svg width="11" height="2" viewBox="0 0 11 1" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="0.5" y1="0.5" x2="10.5" y2="0.5" stroke={colors.grayText} strokeLinecap="round" />
    </svg>
  );
}

export default MinusIcon;
