import React from 'react';

function CrossIcon() {
  return (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M12 1L1 12L12 1Z" fill="white" />
        <path d="M12 12L1 1M12 1L1 12" stroke="#18384A" strokeWidth="1.5" strokeLinecap="round" />
      </g>
    </svg>
  );
}

export default CrossIcon;
