export const pageStylePrint = `
.autorized-signature {
  display: block;
  width: 144px;
  font-size: 8px;
  font-weight: 300;
  text-align: center;
  position: relative;
  z-index: 999;
  bottom: 0;
  left: 0;
  margin: 50px 0;
}

@media print {
  @page {
      margin-top: 15px;
      margin-bottom: 0;
  }
  // body {
  //     padding-top: 72px;
  //     padding-bottom: 72px ;
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  // }
}
`;
