import { orderStatuses } from 'constants/orders/status';
import {
  IAxiosResponse,
  IPagination,
  IConsignee,
  IOutboundOrder,
  IOutboundOrderBody,
  ItemOptionModel,
  ITerminateReason,
} from 'interfaces';

import outboundOrderAPI from 'services/outboundOrdersAPI';
import { convertTime, tryCatchHandler, notifier } from 'utils';
import documentsAPI from 'services/documentsAPI';
import { IDocument } from 'interfaces/documents';
import { getPagination } from 'utils/getPagination';
import { sortBy } from 'lodash';
import { OutboundOrdersAction } from './types';
import { couriersActionCreator } from '../couriers/action-creators';
import { IOutboundSearchParams } from './reducer';

export const outboundOrdersActionCreator = {
  setOrders: (orders: IOutboundOrder[]) => ({
    type: OutboundOrdersAction.SET_OUTBOUND_ORDERS,
    payload: orders,
  }),
  setSelectedOutboundOrder: (selectedOrder: IOutboundOrderBody) => ({
    type: OutboundOrdersAction.SET_SELECTED_OUTBOUND_ORDER,
    payload: selectedOrder,
  }),
  setOutboundItems: (items: any) => ({
    type: OutboundOrdersAction.SET_OUTBOUND_ITEMS,
    payload: items,
  }),
  setOutboundItemOptions: (options: ItemOptionModel[]) => ({
    type: OutboundOrdersAction.SET_OUTBOUND_ITEM_OPTIONS,
    payload: options,
  }),
  setOutboundOrderItems: (items: any) => ({
    type: OutboundOrdersAction.SET_OUTBOUND_ORDER_ITEMS,
    payload: items,
  }),
  setOutboundDocuments: (documents: IDocument[]) => ({
    type: OutboundOrdersAction.SET_OUTBOUND_DOCUMENTS,
    payload: documents,
  }),
  setConsignees: (consignees: IConsignee[]) => ({
    type: OutboundOrdersAction.SET_CONSIGNEES,
    payload: consignees,
  }),
  setPagination: (pagination: IPagination) => ({
    type: OutboundOrdersAction.SET_OUTBOUND_PAGINATION,
    payload: pagination,
  }),

  setUomsData: (uomsData: any[]) => ({
    type: OutboundOrdersAction.SET_UOMS_DATA,
    payload: uomsData,
  }),
  setSearchParams: (search: IOutboundSearchParams) => ({
    type: OutboundOrdersAction.SET_SEARCH_PARAMS,
    payload: search,
  }),

  fetchOrders:
    (per_page?: number, page?: number, search_params?: IOutboundSearchParams) => async (dispatch, getState) => {
      await tryCatchHandler(dispatch, async () => {
        const { pagination, search_params: searchFromStore } = getState().outboundOrdersReducer;

        const search = search_params || searchFromStore;
        const isSearch = !!search;

        const perPageToSearch = per_page || pagination.per_page;
        const pageToSearch = page || pagination.current_page;

        await dispatch(outboundOrdersActionCreator.setSearchParams(search));
        const { data, meta }: IAxiosResponse<any> = await outboundOrderAPI.getOutboundOrders(
          perPageToSearch,
          getPagination(isSearch, page, pageToSearch),
          search,
        );

        const orders = data?.map((order: any) => ({
          id: order.id,
          store: order.store_id.company_name,
          status: orderStatuses[order.status],
          placedOn: convertTime(order.created_at),
          consignee: order?.consignee_id?.name,
          createdBy: `${order.created_by?.name} (${order.created_by?.role_id?.title})`,
        }));

        dispatch(outboundOrdersActionCreator.setOrders(orders));
        dispatch(
          outboundOrdersActionCreator.setPagination({
            total: meta.total,
            per_page: meta.per_page,
            current_page: meta.current_page,
          }),
        );

        dispatch(couriersActionCreator.getCouriers());
      });
    },

  fetchOrder: (id: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const data: any = await outboundOrderAPI.getOutboundOrder(id);

      const selectedOrder = {
        id: data?.id,
        store_id: data?.store_id,
        store: data?.store_id?.company_name,
        internal_id: data?.internal_id,
        notes: data?.notes,
        tracking_number: data?.tracking_number,
        reference_number: data?.reference_number,
        placed_on: convertTime(data?.created_at),
        created_by: {
          name: data.created_by?.name,
          role: data.created_by?.role_id?.title,
        },
        consignee_id: data?.consignee_id,
        status: orderStatuses[data.status],
        courier_id: data?.courier_id,
        courier: data?.courier_id?.name,
        terminate_reason: data?.terminate_reason,
        documents: data?.documents,
        orderItems: data?.orderItems,
        dispatched: convertTime(data?.dispatched),
        terminated: convertTime(data?.updated_at),
        internal_note: data.internal_note,
      };

      dispatch(outboundOrdersActionCreator.setOutboundOrderItems(sortBy(data?.orderItems, 'created_at').reverse()));
      dispatch(outboundOrdersActionCreator.setSelectedOutboundOrder(selectedOrder));
    });
  },

  fetchOutboundItems:
    (store_id, search = '') => async (dispatch) => {
      await tryCatchHandler(dispatch, async () => {
        const data: any = await outboundOrderAPI.getOutboundItems(store_id, search);

        const itemsData = data?.map((item) => ({
          id: item?.id,
          item_id: item?.id,
          name: item?.name,
          defaultExtId: item?.external_ids?.find((extID) => extID?.default)?.external_id_value || '',
          external_id: item?.external_ids?.find((extID) => extID?.default)?.external_id_value || '',
          store: item?.store_id?.company_name,
          company_name: item?.store_id?.company_name,
          external_ids: item?.external_ids,
          each_id: item.item_measurement_units[0].each_id,
          carton_id: item.item_measurement_units[0].carton_id,
        }));

        dispatch(outboundOrdersActionCreator
          .setOutboundItems(sortBy(itemsData, 'created_at').reverse()));
      });
    },

  fetchOutboundItemOptions: (item: any) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const data: ItemOptionModel[] = await outboundOrderAPI.getOutboundItemOptions(item.id);
      dispatch(outboundOrdersActionCreator.setOutboundItemOptions(data));
    });
  },
  fetchAllConsignees: () => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: any = await outboundOrderAPI.getAllConsignees();

      dispatch(outboundOrdersActionCreator.setConsignees(data));
    });
  },

  createConsignee: (orderId, consignee) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      const {
        pagination: { per_page, current_page },
      } = getState().outboundOrdersReducer;

      const { data }: any = await outboundOrderAPI.postConsignee(consignee);

      await outboundOrderAPI.assignConsignee(orderId, { consignee_id: data?.id });
      dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
      dispatch(outboundOrdersActionCreator.fetchAllConsignees());
      dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
    });
  },

  updateConsignee: (consigneeId, consignee) => async (dispatch, getState) => {
    const {
      selectedOrder,
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;
    await tryCatchHandler(dispatch, async () => {
      await outboundOrderAPI.putConsignee(consigneeId, consignee);
      dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
      dispatch(outboundOrdersActionCreator.fetchAllConsignees());
      dispatch(outboundOrdersActionCreator.fetchOrder(selectedOrder?.id));
    });
  },

  generateBodyOfNewOrder: () => async (dispatch) => {
    const orderInfo: IOutboundOrderBody = {
      store_id: null,
      notes: '',
      internal_id: '',
      placed_on: '',
      created_by: {
        name: '',
        role: '',
      },
      consignee_id: null,
      id: 0,
    };

    dispatch(outboundOrdersActionCreator.setSelectedOutboundOrder(orderInfo));
    dispatch(outboundOrdersActionCreator.setOutboundOrderItems([]));
  },

  createOutboundOrder: (orderData: any) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { data: createdOrder } = await outboundOrderAPI.postOutboundOrder(orderData);

    const {
      pagination: { per_page },
    } = getState().outboundOrdersReducer;

    await dispatch(outboundOrdersActionCreator.fetchOrders(per_page));

    dispatch(outboundOrdersActionCreator.setSelectedOutboundOrder({ ...createdOrder, status: createdOrder.status }));

    dispatch(outboundOrdersActionCreator.fetchOrder(createdOrder.id));
    notifier('success', 'Success!', 'Changes are saved successfully');
    return createdOrder;
  }),

  claimOutboundItem: (orderId: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const {
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;

    await outboundOrderAPI.claimOutboundItem(orderId);
    dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
    dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
  }),

  unclaimOutboundItem: (orderId: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const {
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;

    await outboundOrderAPI.unclaimOutboundItem(orderId);
    dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
    dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
  }),

  uploadDocument: (orderId: number, file: any) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await documentsAPI.uploadDocument(file);

    if (data) {
      await documentsAPI.attachDocumentToOrder({ order_id: orderId, document_id: data.id });
      dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
    }
  }),

  removeDocument: (selectedOrderId: number, documentId: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    await documentsAPI.removeOrderDocument(documentId);

    dispatch(outboundOrdersActionCreator.fetchOrder(selectedOrderId));
  }),

  goToPick: (orderId: number, items: any) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const {
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;

    await outboundOrderAPI.goToPick(orderId, items);
    dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
    dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
  }),

  createOutboundOrderItem:
    ({
      orderId, itemId, carton_id, // null
      each_id, // 69
    }: { orderId: number; itemId: number, carton_id?: number | undefined; each_id?: number | undefined; }) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
      const { itemOptions } = getState().outboundOrdersReducer;
      const outboundItemBody: any = {
        item_id: itemId,
        quantity: 1,
      };

      const [itemUOMOptions] = itemOptions;

      if (itemOptions.find((elem) => elem.carton_id === carton_id)) {
        outboundItemBody.carton_id = carton_id;
      } else if (itemOptions.find((elem) => elem.each_id === each_id)) {
        outboundItemBody.each_id = each_id;
      } else if (itemUOMOptions.carton_id) {
        outboundItemBody.carton_id = itemUOMOptions.carton_id;
      } else {
        outboundItemBody.each_id = itemUOMOptions.each_id;
      }

      await outboundOrderAPI.postOutboundOrderItem(outboundItemBody, orderId);
      dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
    }),

  updateOutboundOrderItem: (orderItemId: number, item: any) => async (dispatch) => tryCatchHandler(
    dispatch,
    async () => {
      await outboundOrderAPI.putOutboundOrderItem(orderItemId, item);
    },
    '',
    false,
  ),

  updateOutboundOrder: (order_id: number, order: IOutboundOrderBody) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const {
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;

    const response = await outboundOrderAPI.putOutboundOrder(order_id, order);

    dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
    if (response) {
      dispatch(outboundOrdersActionCreator.fetchOrder(order_id));
    }
    return response;
  }),

  deleteOrderItem: (orderItemId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await outboundOrderAPI.deleteItem(orderItemId);

      const { selectedOrder } = getState().outboundOrdersReducer;
      dispatch(outboundOrdersActionCreator.fetchOrder(selectedOrder.id));
    });
  },

  resetPickingID: (orderId: number, body: { itemInventoriesIdList: number[] }) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      await outboundOrderAPI.resetPickingID(orderId, body);
      dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
      return true;
    });
  },

  pickItemInventoryId: (itemInvId: number) => async (dispatch, getState) => {
    await tryCatchHandler(dispatch, async () => {
      await outboundOrderAPI.pickItemInventoryId(itemInvId);
      const { selectedOrder } = getState().outboundOrdersReducer;
      dispatch(outboundOrdersActionCreator.fetchOrder(selectedOrder.id));
      return true;
    });
  },

  confirmItemInvetoryId: (orderId: number) => async (dispatch, getState) => {
    const {
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;

    await tryCatchHandler(dispatch, async () => {
      await outboundOrderAPI.confirmItemInvetoryId(orderId);
      dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
      dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
      return true;
    });
  },

  dispatchOrderId: (orderId: number) => async (dispatch, getState) => {
    const {
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;

    await tryCatchHandler(dispatch, async () => {
      await outboundOrderAPI.dispatchOrderId(orderId);
      dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
      dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
      return true;
    });
  },

  terminateOrder: (orderId: number, reasonData: ITerminateReason) => async (dispatch, getState) => {
    const {
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;

    await tryCatchHandler(dispatch, async () => {
      await outboundOrderAPI.terminateOrderId(orderId, reasonData);
      dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
      dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
    });
  },

  changeLocationsOrder: (orderId: number) => async (dispatch, getState) => {
    const {
      pagination: { per_page, current_page },
    } = getState().outboundOrdersReducer;

    await tryCatchHandler(dispatch, async () => {
      await outboundOrderAPI.changeLocationsOrder(orderId);
      dispatch(outboundOrdersActionCreator.fetchOrders(per_page, current_page));
      dispatch(outboundOrdersActionCreator.fetchOrder(orderId));
      return true;
    });
  },
};
