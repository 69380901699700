import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from 'App';
import { IStore } from 'interfaces';
import { CustomTable, SelectWithSearch } from 'components/globals';

import CurrentRowInfo from 'components/views/inventory/CurrentRowInfo/CurrentRowInfo';
import { storesActionCreator } from 'store/action-creators';
import WithAuthWrapper from 'utils/WithAuthWrapper';
import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import { isAllowedForUserRole } from 'utils/isAllowedForUserRole';
import InventoryWarehousesBlock from 'components/views/inventory/warehouses/InventoryWarehousesBlock';
import InventoryItemInfoBlock from 'components/views/inventory/inventoryItemInfoBlock/InventoryItemInfoBlock';
import { ReportType } from '../../constants/reportType';
import { inventoryActionCreator } from '../../store/modules/inventory/action-creators';
import { MainWrapper, RightCard, RightWrapper } from '../../components/layout';
import { inventoryItemsColumns } from '../../constants/inventory/inventoryData';
import {
  allowedRolesForSeeItemStatistic,
  allowedRolesForSeeLocationsBlock,
  requiredAbilitiesForInventoryLocationsBlock,
  requiredAbilitiesForInventoryWarehousesBlock,
  requiredAbilitiesForItemInfoBlock,
} from '../../constants/inventory/pageAbilities';
import InventoryLocations from '../../components/views/inventory/locations/InventoryLocations';

import './inventory.scss';

function Inventory() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { pagination, allItems, selectedItem } = useSelector((state: RootState) => state.inventoryReducer);
  const isLoading = useSelector((state: RootState) => state.generalReducer.isLoading);
  const stores = useSelector((state: RootState) => state.storesReducer.stores);
  const user = useSelector((state: RootState) => state.authReducer.user);
  const externalIdsParams = useSelector((state: RootState) => state.inventoryReducer.external_ids);

  const isWareHouseAdmin = userRole[user.role_id as unknown as number] === UserRoles.WAREHOUSE_ADMIN
  || userRole[user.role_id as unknown as number] === UserRoles.SUPER_ADMIN;

  const [selectedStore, setSelectedStore] = useState('All stores');
  const [isRightBlockOpen, setIsRightBlockOpen] = useState(false);
  const [isMoveClicked, setIsMoveClicked] = useState(false);
  const [storesOptions, setStoreOptions] = useState([]);
  const [searchValue, setSearchValue] = useState(null);
  const [selectItemRow, setSelectItemRow] = useState('');

  const selectItem = async (company_name: string, item_id: number) => {
    setSelectItemRow(company_name);

    await dispatch(inventoryActionCreator.fetchItem(item_id));
    setIsRightBlockOpen(true);
  };

  const getStoreIdByValue = (storeValue: any) => {
    const currentStore = storesOptions?.find((store) => store.value === storeValue);
    return currentStore ? currentStore.id : null;
  };

  // deleted text "/page" in select options
  useEffect(() => {
    if (stores && stores.length) {
      const storesOptionsArray = stores.map((store: IStore) => ({
        value: store.company_name,
        id: store.id,
      }));
      const storesOptions = [{ value: 'All stores', id: 0 }, ...storesOptionsArray];

      setStoreOptions(storesOptions);
    }
  }, [stores]);

  useEffect(() => {
    setIsMoveClicked(false);
  }, [selectedItem]);

  const handleClickStores = () => {
    if (storesOptions.length === 0) { dispatch(storesActionCreator.fetchStores(1000, null, null)); }
  };

  const handleSelectStore = (store: string) => {
    setSelectedStore(store);

    if (store !== selectedStore) {
      dispatch(
        inventoryActionCreator.fetchAllItems(
          null,
          null,
          {
            store_id: getStoreIdByValue(store) || null,
            search: searchValue !== '' ? searchValue : null,
          },
        ),
      );
    }
  };

  useEffect(() => {
    dispatch(inventoryActionCreator.fetchAllItems());
  }, []);

  useEffect(() => {
    if (storesOptions.length > 1) {
      dispatch(
        inventoryActionCreator.fetchAllItems(
          null,
          null,
          {
            store_id: getStoreIdByValue(selectedStore) || null,
            search: searchValue !== '' ? searchValue : null,
          },
        ),
      );
    }
  }, [searchValue]);

  return (
    <>
      <MainWrapper title={t('sidebar.inventory')} isFullScreen={!isRightBlockOpen} buttons={<div />}>
        <CustomTable
          isLoading={isLoading}
          className="inventory-table"
          pagination={pagination}
          onSearch={setSearchValue}
          setSearchValue={setSearchValue}
          onClickSelect={handleClickStores}
          select={(
            <SelectWithSearch
              onSelect={(store) => handleSelectStore(store)}
              onChange={(value) => value}
              options={storesOptions}
              value={selectedStore}
              isOnlyPlaceholder
              className="table-select"
            />
          )}
          columns={
            isRightBlockOpen
            || !isAllowedForUserRole(allowedRolesForSeeItemStatistic, userRole[user.role_id as unknown as number])
              ? [
                ...inventoryItemsColumns,
                {
                  title: '',
                  dataIndex: 'currentRow',
                  render: (_, item) => (
                    <CurrentRowInfo
                      selectId={selectedItem?.id}
                      chooseRowId={item.item_id}
                      name={item.name}
                      type={ReportType.INVENTORY}
                      isWareHouseAdmin={isWareHouseAdmin}
                    />
                  ),
                },
              ]
              : [
                ...inventoryItemsColumns,
                {
                  title: '',
                  dataIndex: 'currentRow',
                  render: (_, item) => (
                    <CurrentRowInfo
                      selectId={selectedItem?.id}
                      chooseRowId={item.item_id}
                      name={item.name}
                      type={ReportType.INVENTORY}
                      isWareHouseAdmin={isWareHouseAdmin}
                    />
                  ),
                },
              ]
          }
          tableData={allItems?.map((item) => ({
            ...item,
            id: item.item_id,
            externalId: item?.external_ids?.find((extID) => extID?.default)?.external_id_value || '',
            key: item.number,
            external_ids: externalIdsParams[item.item_id],
          }))}
          itemClick={(item) => selectItem(item.company_name, item.item_id)}
          selectedItem={selectedItem}
          getNewItems={(page: number, per_page: number) => dispatch(
            inventoryActionCreator.fetchAllItems(
              per_page,
              page,
              {
                store_id: getStoreIdByValue(selectedStore) || null,
                search: searchValue !== '' ? searchValue : null,
              },
            ),
          )}
        />
      </MainWrapper>
      {isRightBlockOpen && (
        <RightWrapper
          title={selectedItem?.name}
          onCancelClick={() => {
            setIsRightBlockOpen(false);
          }}
        >
          <RightCard title={t('iventory.itemInformation')}>
            <WithAuthWrapper requiredAbilities={requiredAbilitiesForItemInfoBlock}>
              <InventoryItemInfoBlock selectedItem={selectedItem} user={user} storeName={selectItemRow} />
            </WithAuthWrapper>
          </RightCard>
          {isAllowedForUserRole(allowedRolesForSeeLocationsBlock, userRole[user.role_id as unknown as number]) ? (
            <RightCard
              className={`inventory-locations-right-card ${isMoveClicked ? 'blured-inventory-loc-block' : ''}`}
              title={t('iventory.locations')}
            >
              <WithAuthWrapper requiredAbilities={requiredAbilitiesForInventoryLocationsBlock}>
                <InventoryLocations isMoveClicked={isMoveClicked} setIsMoveClicked={setIsMoveClicked} />
              </WithAuthWrapper>
            </RightCard>
          ) : (
            <RightCard title={t('warehouses.warehouses')}>
              <WithAuthWrapper requiredAbilities={requiredAbilitiesForInventoryWarehousesBlock}>
                <InventoryWarehousesBlock selectedItem={selectedItem} />
              </WithAuthWrapper>
            </RightCard>
          )}
        </RightWrapper>
      )}
    </>
  );
}

export default Inventory;
