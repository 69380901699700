/* eslint-disable dot-notation */
import { utils, WorkSheet } from 'xlsx-js-style';
import moment from 'moment';
import { IStockReport } from '../interfaces';
import { ReportType } from '../constants/reportType';
import {
  applyCellStyle, applyFillColor, bold, border, CellFillColors,
} from './stylesForCellExcel';

interface CalculateLengthCellExcel {
    wch: number;
}

export enum ExcelColumnName {
  A= 'A',
  B= 'B',
  C= 'C',
  D= 'D',
  E= 'E',
  F= 'F',
  G= 'G',
  H= 'H',
  I= 'I',
  J= 'J',
  K= 'K',
}

export const calculateLengthCellExcel = (data):CalculateLengthCellExcel[] => (data[0] ? Object.keys(data[0]).map(() => ({ wch: 15 })) : []);

export const updateDataActivity = (data: any[], isWarehouseAdmin: boolean) => data.reduce((acc, value) => {
  const infoExternal = value?.orders[0]?.external_ids?.find((elem) => elem.default);
  const skuName = {
    [ExcelColumnName.A]: infoExternal?.external_id_type_id.name ? infoExternal?.external_id_type_id.name : ' ',
    [ExcelColumnName.B]: infoExternal?.external_id_value ? infoExternal?.external_id_value : ' ',
    [ExcelColumnName.C]: 'Name',
    [ExcelColumnName.D]: value?.name ? value?.name : ' ',
    [ExcelColumnName.E]: ' ',
    [ExcelColumnName.F]: ' ',
    [ExcelColumnName.G]: ' ',
    [ExcelColumnName.H]: ' ',
    [ExcelColumnName.I]: ' ',
    [ExcelColumnName.J]: ' ',
    [ExcelColumnName.K]: ' ',
  };

  const headers = {
    [ExcelColumnName.A]: 'Date',
    [ExcelColumnName.B]: 'Transaction Type',
    [ExcelColumnName.C]: 'Order ID int.',
    [ExcelColumnName.D]: 'Order reference number',
    [ExcelColumnName.E]: 'UOM',
    [ExcelColumnName.F]: 'Qty',
    [ExcelColumnName.G]: 'Contains',
    [ExcelColumnName.H]: 'Qty (Eaches)',
    [ExcelColumnName.I]: 'Before transaction',
    [ExcelColumnName.J]: 'After transaction',
    [ExcelColumnName.K]: 'Internal note',
  };

  const orders = value?.orders.map((order) => ({
    [ExcelColumnName.A]: order?.date ? order.date : ' ',
    [ExcelColumnName.B]: order?.transaction_type ? order.transaction_type : ' ',
    [ExcelColumnName.C]: order?.order_id ? order.order_id : ' ',
    [ExcelColumnName.D]: order?.reference_number ? order.reference_number : ' ',
    [ExcelColumnName.E]: order?.uom ? order.uom : ' ',
    [ExcelColumnName.F]: order?.current_qty ? order.current_qty : ' ',
    [ExcelColumnName.G]: order?.contains ? order.contains : ' ',
    [ExcelColumnName.H]: order?.totalContains ? order.totalContains : ' ',
    [ExcelColumnName.I]: order?.beforeTr ? order.beforeTr : ' ',
    [ExcelColumnName.J]: order?.afterTr ? order.afterTr : ' ',
    [ExcelColumnName.K]: order?.internal_note ? order.internal_note : ' ',
  }));

  let result = [skuName, headers, ...orders];

  // Remove column Internal note for not warehouse admins
  if (!isWarehouseAdmin) {
    result = result.map((value) => {
      delete value[ExcelColumnName.K];
      return value;
    });
  }
  acc.push(result);

  return acc;
}, []);

export const updateDataStock = (data: IStockReport[]) => data.reduce((acc, value) => {
  const skuName = {
    [ExcelColumnName.A]: value.skuType,
    [ExcelColumnName.B]: value.sku,
    [ExcelColumnName.C]: 'Name',
    [ExcelColumnName.D]: value.name,
    [ExcelColumnName.E]: ' ',
  };

  const headers = {
    [ExcelColumnName.A]: 'UOM',
    [ExcelColumnName.B]: 'Condition',
    [ExcelColumnName.C]: 'Qty',
    [ExcelColumnName.D]: 'Contains',
    [ExcelColumnName.E]: 'Total',
  };

  const uoms = value.uom.map((uom) => ({
    [ExcelColumnName.A]: uom.uomName,
    [ExcelColumnName.B]: uom.conditionName,
    [ExcelColumnName.C]: uom.quantity,
    [ExcelColumnName.D]: uom?.contains ? uom.contains : ' ',
    [ExcelColumnName.E]: uom.total,
  }));

  const total = {
    [ExcelColumnName.A]: ' ',
    [ExcelColumnName.B]: ' ',
    [ExcelColumnName.C]: ' ',
    [ExcelColumnName.D]: 'In stock',
    [ExcelColumnName.E]: value.total,
  };

  acc.push([skuName, headers, ...uoms, total]);

  return acc;
}, []);

export const headingInventoryELSX = [
  {
    external_id: 'Ext. ID',
    external_type: 'Ext. ID Type',
    name: 'Name',
    internal_note: 'Internal note',
    date: 'Date',
    transaction_type: 'Transaction Type',
    order_id: 'Order ID int.',
    reference_number: 'Order reference number',
    uom: 'UOM',
    quantity: 'Qty',
    contains: 'Contains',
    total: 'Qty (Eaches)',
    beforeTr: 'Before transaction',
    afterTr: 'After transaction',
  },
];

export const characterHeaderInventoryELSX = [
  'external_id',
  'external_type',
  'name',
  'internal_note',
  'date',
  'transaction_type',
  'order_id',
  'reference_number',
  'uom',
  'quantity',
  'contains',
  'total',
  'beforeTr',
  'afterTr',
];

export const setDataToExcelRecentActivity = (data, from: string, to: string, xlsxData: any[]): WorkSheet => {
  let currentRow = 1;
  const ws = utils.json_to_sheet([{ [ExcelColumnName.A]: 'Activity Report', [ExcelColumnName.B]: data?.length ? data[0].store : data?.store }], { skipHeader: true });
  currentRow++;

  utils.sheet_add_json(ws, [{
    [ExcelColumnName.A]: 'Period from', [ExcelColumnName.B]: from, [ExcelColumnName.C]: 'to', [ExcelColumnName.D]: to,
  }], { skipHeader: true, origin: `${ExcelColumnName.A}${currentRow}` });
  currentRow += 2;

  // Set style for cells A1, A2 and C2
  for (let row = 1; row <= 2; row++) {
    applyCellStyle(ws, ExcelColumnName.A, row, bold);
    if (row === 2) applyCellStyle(ws, ExcelColumnName.C, row, bold);
  }

  // Set data and styles
  xlsxData.forEach((data) => {
    utils.sheet_add_json(ws, data, { skipHeader: true, origin: `${ExcelColumnName.A}${currentRow}` });
    for (let row = currentRow; row < data.length + currentRow; row++) {
      const startRowOfBlock = currentRow;
      const isFirstRowOfBlock = row === startRowOfBlock;
      const isSecondRowOfBlock = row - startRowOfBlock === 1;
      Object.keys(data[0]).forEach((column) => {
        if ((isFirstRowOfBlock && (column === ExcelColumnName.A || column === ExcelColumnName.C)) || isSecondRowOfBlock) {
          applyCellStyle(ws, column, row, { ...border, ...bold, ...applyFillColor(CellFillColors.GRAY) });
        } else if ((isFirstRowOfBlock && (column === ExcelColumnName.B || column === ExcelColumnName.D)) || (!isFirstRowOfBlock && !isSecondRowOfBlock)) {
          applyCellStyle(ws, column, row, border);
        }
      });
    }
    currentRow += data.length + 1;
  });

  return ws;
};

export const setDataToExcelStock = (data: IStockReport[], onDate: string, xlsxData: any[]) => {
  let currentRow = 1;
  const ws = utils.json_to_sheet([
    { [ExcelColumnName.A]: 'Stock report', [ExcelColumnName.B]: data[0].store },
    { [ExcelColumnName.A]: 'On date', [ExcelColumnName.B]: onDate },
    { [ExcelColumnName.A]: 'Report date', [ExcelColumnName.B]: moment().format('DD.MM.YYYY') },
  ], { skipHeader: true });
  currentRow += 4;

  for (let row = 1; row <= 3; row++) {
    applyCellStyle(ws, ExcelColumnName.A, row, { ...bold });
  }

  // Set data and styles
  xlsxData.forEach((data) => {
    utils.sheet_add_json(ws, data, { skipHeader: true, origin: `${ExcelColumnName.A}${currentRow}` });
    for (let row = currentRow; row < data.length + currentRow; row++) {
      const startRowOfBlock = currentRow;
      const isFirstRowOfBlock = row === startRowOfBlock;
      const isSecondRowOfBlock = row - startRowOfBlock === 1;
      const isLastRowOfBlock = row - startRowOfBlock === data.length - 1;
      Object.keys(data[0]).forEach((column) => {
        if ((isFirstRowOfBlock && (column === ExcelColumnName.A || column === ExcelColumnName.C)) || isSecondRowOfBlock) {
          applyCellStyle(ws, column, row, { ...border, ...bold, ...applyFillColor(CellFillColors.GRAY) });
        } else if (
          (isFirstRowOfBlock && !(
            column === ExcelColumnName.A
            || column === ExcelColumnName.C
            || column === ExcelColumnName.E
          ))
          || (!isFirstRowOfBlock && !isSecondRowOfBlock && !isLastRowOfBlock)
        ) {
          applyCellStyle(ws, column, row, { ...border });
        } else if (isLastRowOfBlock && !(column === ExcelColumnName.A || column === ExcelColumnName.B || column === ExcelColumnName.C)) {
          applyCellStyle(ws, column, row, { ...border, ...bold, ...applyFillColor(CellFillColors.LIGHT_YELLOW) });
        }
      });
    }
    currentRow += data.length + 1;
  });

  return ws;
};

export const setFileName = (type: ReportType, itemOrStoreName: string, from: string, to?: string, fileExtension = '.xlsx') => {
  switch (type) {
    case ReportType.INVENTORY:
      return `Inventory report ${itemOrStoreName} ${from} - ${to}${fileExtension}`;
    case ReportType.STORE:
      return `Store report ${itemOrStoreName} ${from} - ${to}${fileExtension}`;
    case ReportType.STOCK:
      return `Stock report ${itemOrStoreName} ${from}${fileExtension}`;
    default:
      return 'Unknown report';
  }
};
