import React, { useEffect, useRef, useState } from 'react';
import { RootState } from 'App';
import { OutsideClick } from 'utils';
import { Menu, MenuProps } from 'antd';
import Sider from 'antd/lib/layout/Sider';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { authActionCreator } from 'store/action-creators';
import { ThreeDotsIcon, sidebar } from 'assets/images';
import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';
import { changeSidebarContainerNames, sidebarOptionByUserRole } from 'constants/sidebar/sidebarInfo';
import noAvatar from '../../../assets/images/defaultItemIcon.png';

import './sidebar.scss';

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  path?: string,
  children?: MenuItem[],
  type?: 'group',
  isSelected?: boolean,
): MenuItem {
  return {
    icon,
    key,
    children,
    path,
    label,
    type,
    isSelected,
  } as MenuItem;
}

function Sidebar() {
  const sidebarRef = useRef(null);
  const menuRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.authReducer.user);

  const [collapsed, setCollapsed] = useState(true);
  const [isSelected, setIsSelected] = useState(false);
  const [currentPath, setCurrentPath] = useState('');
  const [props, setProps] = useState({});
  const [sidebarOptions, setSidebarOptions] = useState([]);

  const outsideClicked = OutsideClick(sidebarRef, () => setCollapsed(true));

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const items: MenuItem[] = sidebarOptions.map((item) => getItem(
    <span className={`sidebar-item${item.header ? 's-header' : ''}`}>{item.label}</span>,
    item.key,
    sidebar[item.icon] ? sidebar[item.icon] : null,
    sidebar[item.icon] && item.path,
  ));

  const handleSelectItem = (path: string) => {
    history.push(path);
    setIsSelected(!isSelected);
  };

  const logout = () => {
    dispatch(authActionCreator.signOut());
    history.push('/auth');
  };

  useEffect(() => {
    const copiedSidebarArray = JSON.parse(JSON.stringify(sidebarOptions));
    if (!collapsed) {
      setProps({ openKeys: ['1', '2', '3'] });
      setSidebarOptions(changeSidebarContainerNames(copiedSidebarArray));
    } else {
      setProps({});
      setSidebarOptions(changeSidebarContainerNames(copiedSidebarArray));
    }
  }, [collapsed]);

  useEffect(() => {
    if (sidebarRef && sidebarRef.current) {
      setCollapsed(true);
    }
  }, [outsideClicked]);

  useEffect(() => {
    setCurrentPath(history.location.pathname);
  }, [isSelected]);

  useEffect(() => {
    if (user?.role_id) {
      setSidebarOptions(sidebarOptionByUserRole(userRole[user.role_id] as UserRoles));
    }
  }, [user]);

  return (
    <div className="sidebar-wrapper">
      <Sider
        width={260}
        ref={sidebarRef}
        className={`sidebar ${collapsed ? 'collapsed-sidebar' : ''}`}
        collapsible
        collapsed={collapsed}
        onCollapse={toggleCollapsed}
      >
        {!collapsed ? <div className="hide-text">Hide</div> : null}
        <Menu
          ref={menuRef}
          defaultSelectedKeys={['1']}
          defaultOpenKeys={['sub1']}
          mode="inline"
          theme="light"
          inlineCollapsed={collapsed}
          {...props}
        >
          {items.map((item: any) => (
            <Menu.Item
              className={!collapsed ? 'aligned-item' : ''}
              key={item.key}
              onClick={() => handleSelectItem(item.path)}
            >
              {currentPath === item.path ? <div className="select-arrow" /> : null}
              {item.icon}
              {item.label}
            </Menu.Item>
          ))}
        </Menu>
        <div className="user-block">
          <div className="avatar-block">
            <img src={user?.picture?.download_url || noAvatar} alt="no avatar" />
          </div>
          <div className="user-options-block">
            <div className="name-container">
              <span className="user-name">{user.name}</span>
              <ThreeDotsIcon />
            </div>
            <button onClick={logout} className="logout-button">
              {t('common.logout')}
            </button>
          </div>
        </div>
      </Sider>
    </div>
  );
}

export default Sidebar;
