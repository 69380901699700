import React, { Dispatch } from 'react';
import { Col, Row } from 'antd';
import { TrashIcon } from 'assets/images';
import { CustomInput, CustomSelect } from 'components/globals';
import CustomRadioButton from 'components/globals/customRadioButton/CustomRadioButton';
import { IExternal, IExternalIdType, IUser } from 'interfaces';
import { userRole, UserRoles } from 'constants/users/userRolesAndTypes';

interface ExternalIDProps {
  isExtIdFieldsReadonly: boolean;
  extID: IExternal;
  extIDsData: IExternal[];
  externalIdIndex: number;
  externalIDTypes: IExternalIdType[];
  setIsSaveDisabled: (value: boolean) => void;
  types: {content: string, value: number}[];
  setExtIDsData: Dispatch<any>;
}

type ExternalIdKey = 'default' | 'externalType' | 'externalValue' | 'removeExternalId';

function ExternalID({
  isExtIdFieldsReadonly,
  extID,
  extIDsData,
  externalIdIndex,
  externalIDTypes,
  types,
  setIsSaveDisabled,
  setExtIDsData,
}: ExternalIDProps) {
  const getValueById = (id: number) => {
    const findedType = externalIDTypes?.find((type: IExternalIdType) => type.id === id);
    if (findedType) {
      return findedType.name;
    }
    return 'No Id';
  };

  const updateField = (key: ExternalIdKey, value: any) => {
    switch (key) {
      case 'default': {
        const deepCopyExtIdsData = JSON.parse(JSON.stringify(extIDsData));
        const mappedExtIdsData = deepCopyExtIdsData.map((extId: any) => {
          extId.default = 0;
          return extId;
        });

        mappedExtIdsData[externalIdIndex].default = value;
        setExtIDsData(mappedExtIdsData);
        break;
      }
      case 'externalType': {
        extIDsData[externalIdIndex].external_id_type_id = value;
        setExtIDsData(extIDsData);
        break;
      }

      case 'externalValue': {
        if (value) {
          extIDsData[externalIdIndex].isFail = false;
        } else {
          extIDsData[externalIdIndex].isFail = true;
        }
        extIDsData[externalIdIndex].external_id_value = value;

        setExtIDsData(extIDsData);
        break;
      }
      case 'removeExternalId': {
        extIDsData.splice(externalIdIndex, 1);
        setExtIDsData(extIDsData);
        break;
      }
      default:
        break;
    }
    setIsSaveDisabled(false);
  };

  return (
    <Row className="mar-bot" gutter={[20, 20]}>
      <Col className="text-center" span={6}>
        <CustomRadioButton
          checked={!!extID.default}
          value={extID.default}
          onClick={() => updateField('default', 1)}
          title="Default"
          readonly={isExtIdFieldsReadonly}
        />
      </Col>
      <Col span={6}>
        <CustomSelect
          isOnlyPlaceholder
          value={getValueById(+extID.external_id_type_id)}
          options={types}
          onSelect={(value) => updateField('externalType', value)}
          placeholder="ID type"
          readonly={isExtIdFieldsReadonly}
        />
      </Col>
      <Col className="pos-relative" span={9}>
        <CustomInput
          required
          className={extID.isFail && !extID.external_id_value ? 'error-input' : ''}
          value={extID.external_id_value}
          isOnlyPlaceholder
          onChange={({ target }: any) => updateField('externalValue', target.value.toUpperCase())}
          placeholder="Value"
          readonly={isExtIdFieldsReadonly}
        />
        {extID.isFail && !extID.external_id_value ? <p className="error-message">Please enter ID value</p> : null}
      </Col>
      <Col span={3}>
        <div
          className="item-delete-ex-id"
          onClick={() => (!extID.default ? updateField('removeExternalId', externalIdIndex) : null)}
        >
          <TrashIcon isDisabled={!!extID.default || isExtIdFieldsReadonly} />
        </div>
      </Col>
    </Row>
  );
}

export default ExternalID;
