import { colors } from 'assets/css/variables';

function EditIcon() {
  return (

    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 17.4767C0 17.2115 0.105357 16.9571 0.292893 16.7696L12.4179 4.64461C12.8084 4.25408 13.4416 4.25408 13.8321 4.64461L16.3554 7.16789C16.7459 7.55842 16.7459 8.19158 16.3554 8.58211L4.23039 20.7071C4.04286 20.8946 3.7885 21 3.52329 21H1C0.447715 21 0 20.5523 0 20V17.4767ZM15.1441 3.33211C14.7538 2.94154 14.7539 2.30856 15.1444 1.91813L16.3554 0.707107C16.7459 0.316582 17.3791 0.316582 17.7696 0.707107L20.2929 3.23039C20.6834 3.62092 20.6834 4.25408 20.2929 4.64461L19.081 5.85647C18.6904 6.24709 18.0571 6.24698 17.6666 5.85623L15.1441 3.33211Z"
        fill={colors.blue}
      />
    </svg>
  );
}

export default EditIcon;
