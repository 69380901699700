import { ELocationsAction } from './types';

const initialState = {
  racks: [],
  selectedRack: null,
  locations: [],
  selectedLocation: null,
  selectedLevel: null,
  selectedColumn: null,
  bins: [],
  specialConditions: [],
};

export default function locationsReducer(state = initialState, action: any) {
  switch (action.type) {
    case ELocationsAction.SET_RACKS:
      return { ...state, racks: action.payload };
    case ELocationsAction.SET_SELECTED_RACK: {
      return { ...state, selectedRack: action.payload };
    }
    case ELocationsAction.SET_LOCATIONS:
      return { ...state, locations: action.payload };
    case ELocationsAction.SET_SELECTED_LOCATION:
      return { ...state, selectedLocation: action.payload };
    case ELocationsAction.SET_BINS:
      return { ...state, bins: action.payload };
    case ELocationsAction.SET_SELECTED_LEVEL:
      return { ...state, selectedLevel: action.payload };
    case ELocationsAction.SET_SELECTED_COLUMN:
      return { ...state, selectedColumn: action.payload };
    case ELocationsAction.SET_SPECIAL_CONDITIONS:
      return { ...state, specialConditions: action.payload };
    default:
      return state;
  }
}
