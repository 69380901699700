import { Col } from 'antd';
import { CloseIcon } from 'assets/images';

import './rightWrapper.scss';

interface RightWrapperProps{
  title: any
  children: any;
  className?: string;
  onCancelClick?: ()=>void;
}

function RightWrapper({
  title,
  children,
  onCancelClick,
  className = '',
}: RightWrapperProps) {
  return (
    <Col className={`right-wrapper-col ${className}`} span={window.innerWidth >= 2500 ? 9 : 10}>
      <div className="right-wrapper-block">
        <div className="right-wrapper-header">
          {title}
          <div onClick={onCancelClick}>
            <CloseIcon />
          </div>
        </div>
        <div className="right-wrapper-body">{children}</div>
      </div>
    </Col>

  );
}

export default RightWrapper;
