import {
  BrowserRouter as Router, Route, Switch, Redirect, useRoutes,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReactNotifications } from 'react-notifications-component';

import { withTranslation } from 'react-i18next';
import { Sidebar } from 'components/layout/sidebar';
import EnvWrapper from 'utils/EnvWrapper';
import { useCallback, useEffect, useState } from 'react';
import { NotFound } from 'views';
import { authActionCreator } from './store/action-creators';
import PrivateRoute from './utils/PrivateRoute';
import configureStore from './store/store';
import Auth from './views/auth/index';
import { View } from './components/layout';
import Loader from './components/layout/loading/Loader';

import 'antd/dist/antd.css';
import 'assets/css/App.scss';
import 'react-notifications-component/dist/theme.css';
import { setIconAndTitle } from './utils/setIconAndTitle';

const store = configureStore();
export type RootState = ReturnType<typeof store.getState>;

function App() {
  const dispatch = useDispatch();
  const [pending, setPending] = useState(false);
  const isAuthUser: string = useSelector((state: RootState) => state.authReducer.isAuthUser);

  const user = useSelector((state: RootState) => state.authReducer.user);
  const isPending = useSelector((state: RootState) => state.authReducer.setPendingAuthUser);
  const isLoading = useSelector((state: RootState) => state.generalReducer.isLoading);

  useEffect(() => {
    setIconAndTitle();
    // if (window.location.pathname !== '/auth' || window.location.pathname.length === 0) {
    setPending(true);
    dispatch(authActionCreator.onAuthState());
    // }
  }, []);

  useEffect(() => {
    if (isAuthUser === 'yes' || isAuthUser === 'not') {
      setPending(false);
    }
  }, [isAuthUser]);

  return (
    <Router>
      <Switch>
        <EnvWrapper>
          {/* {viewLayout()} */}
          {(isAuthUser === '' && pending) || pending ? <Loader /> : null}

          {isAuthUser === 'not' && !pending && !user.id ? (
            <>
              <Route path="/auth">
                {/* <EnvWrapper> */}
                <Auth />
                {/* </EnvWrapper> */}
              </Route>
              <Redirect to="/auth" />
            </>
          ) : null }

          {(isAuthUser === 'yes' && !pending) || !!user.id ? (
            <PrivateRoute path="/">
              <div className="App">
                <Sidebar />
                {/* <EnvWrapper> */}
                <View />
                {/* </EnvWrapper> */}
                <ReactNotifications />
              </div>
            </PrivateRoute>
          ) : null }

        </EnvWrapper>
      </Switch>
    </Router>
  );
}

export default withTranslation()(App);
