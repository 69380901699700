import { configs } from './getEnv';
import { timeout } from './timeout';

export const setIconAndTitle = async (): Promise<void> => {
  if (!Object.keys(configs).length) {
    await timeout(1500);
  }
  const content: any = document.getElementById('content');
  const title = document.getElementById('title');

  const appId = configs.REACT_APP_ID || '';
  switch (appId) {
    case 'dev':
      content.content = 'DEV 3PL CRM';
      title.innerText = 'DEV 3PL CRM';
      break;
    case 'qa':
      content.content = 'QA 3PL CRM';
      title.innerText = 'QA 3PL CRM';
      break;
    default:
      content.content = '3PL CRM';
      title.innerText = '3PL CRM';
  }
};
