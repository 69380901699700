import {
  IAxiosResponse, IPagination, IRecentActivityReport, IStockReport, IStore, IStoresWithUsers, IUser,
} from 'interfaces';

import photosAPI from 'services/photosAPI';
import { getPagination } from 'utils/getPagination';
import documentsAPI from 'services/documentsAPI';
import { EStoresAction } from './types';
import { storesAPI } from '../../../services';
import { notifier, tryCatchHandler } from '../../../utils';
import { IStoresSearchParams } from './reducer';
import { ReportType } from '../../../constants/reportType';

export const storesActionCreator = {
  setStores: (stores: IStore[]) => ({
    type: EStoresAction.SET_STORES,
    payload: stores.sort((a: IStore, b: IStore) => a.id - b.id),
  }),
  setPagination: (pagination: IPagination) => ({
    type: EStoresAction.SET_PAGINATION,
    payload: pagination,
  }),
  setSelectedStore: (selectedStore: IStoresWithUsers) => ({
    type: EStoresAction.SET_SELECTED_STORE,
    payload: selectedStore,
  }),
  setUsersForStore: (users: IUser[]) => ({
    type: EStoresAction.SET_USERS_FOR_STORE,
    payload: users,
  }),
  setReportStore: (reportStore: IRecentActivityReport[]) => ({
    type: EStoresAction.SET_REPORT_STORE,
    payload: reportStore,
  }),
  setReportStock: (reportStock: IStockReport[]) => ({
    type: EStoresAction.SET_REPORT_STOCK,
    payload: reportStock,
  }),
  setSearchParams: (search: IStoresSearchParams) => ({
    type: EStoresAction.SET_SEARCH_PARAMS,
    payload: search,
  }),

  fetchStores: (per_page?: number, page?: number, search_params?: IStoresSearchParams, isLoad = true) => async (dispatch, getState) => {
    await tryCatchHandler(
      dispatch,
      async () => {
        const { pagination, search_params: searchFromStore } = getState().storesReducer;

        const search = search_params || searchFromStore;
        const perPageToSearch = per_page || pagination.per_page;
        const pageToSearch = page || pagination.current_page;

        await dispatch(storesActionCreator.setSearchParams(search));

        const { data, meta }: IAxiosResponse<IStore[]> = await storesAPI.getStores(
          perPageToSearch,
          getPagination(!!search, page, pageToSearch),
          search,
        );
        dispatch(storesActionCreator.setStores(data));
        dispatch(
          storesActionCreator.setPagination({
            total: meta.total,
            per_page: meta.per_page,
            current_page: meta.current_page,
          }),
        );
      },
      '',
      isLoad,
    );
  },
  fetchStore: (id: number) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data } = await storesAPI.getStore(id);
      dispatch(storesActionCreator.setSelectedStore(data));
    });
  },

  createStore: (store: IStore) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const { pagination: { per_page, current_page } } = getState().storesReducer;

    const response = await storesAPI.postStore(store);
    await dispatch(storesActionCreator.fetchStores(per_page, current_page));
    await dispatch(storesActionCreator.fetchStore(response.data.id));

    notifier('success', 'Success!', 'Store saved');
    return response;
  }),

  updateStore: (store: IStoresWithUsers) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const {
      stores,
      selectedStore,
      pagination: { per_page, current_page },
    } = getState().storesReducer;

    const response = await storesAPI.postStore(store);

    const filteredStores = stores.filter((item) => item.id !== store.id);
    dispatch(storesActionCreator.setStores([store, ...filteredStores]));
    dispatch(storesActionCreator.setSelectedStore(store));

    await dispatch(storesActionCreator.fetchStores(per_page, current_page));
    await dispatch(storesActionCreator.fetchStore(selectedStore.id));
    notifier('success', 'Success!', 'Store saved');
    return response;
  }),

  uploadPhoto: (file: FormData) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await photosAPI.uploadPhoto(file);
    return data;
  }),
  deleteStore: (store: IStore) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      await storesAPI.deleteStore(store);
    });
  },

  addUserForStore: (storeId: number, userId: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const response = await storesAPI.postUsersForStore(storeId, userId);

    const { selectedStore } = getState().storesReducer;
    await dispatch(storesActionCreator.fetchStore(selectedStore.id));

    return response;
  }),

  deleteUserForStore: (storeId: number, userId: number) => async (dispatch, getState) => tryCatchHandler(dispatch, async () => {
    const response = await storesAPI.deleteUserForStore(storeId, userId);

    const { selectedStore } = getState().storesReducer;
    await dispatch(storesActionCreator.fetchStore(selectedStore.id));

    return response;
  }),

  fetchReport: (store_id: number, dateStart: string, dateEnd: string, type: ReportType) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data } = await storesAPI.reportStore(store_id, {
        dateStart,
        dateEnd: type === ReportType.STOCK ? undefined : dateEnd,
        reportType: type,
      });
      switch (type) {
        case ReportType.STORE:
          dispatch(storesActionCreator.setReportStore(data));
          break;
        case ReportType.STOCK:
          dispatch(storesActionCreator.setReportStock(data));
          break;
        default:
          break;
      }
    });
  },

  uploadDocument: (storeId: number, file: any) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    const { data } = await documentsAPI.uploadDocument(file);

    if (data) {
      await documentsAPI.attachDocumentToStore({ store_id: storeId, document_id: data.id });
      await dispatch(storesActionCreator.fetchStore(storeId));
    }
  }),

  removeDocument: (storeId: number, documentId: number) => async (dispatch) => tryCatchHandler(dispatch, async () => {
    await documentsAPI.removeStoreDocument(documentId);

    await dispatch(storesActionCreator.fetchStore(storeId));
  }),

  clearReportStore: () => async (dispatch) => {
    dispatch(storesActionCreator.setReportStore(null));
  },

  clearReportStock: () => async (dispatch) => {
    dispatch(storesActionCreator.setReportStock(null));
  },

  clearStores: () => async (dispatch) => {
    dispatch(storesActionCreator.setStores([]));
    dispatch(storesActionCreator.setSelectedStore(null));
    dispatch(storesActionCreator.setUsersForStore([]));
  },
};
