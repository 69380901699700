import { IAuthUser } from 'interfaces';
import { EAuthAction } from './types';
import { authAPI, usersAPI } from '../../../services';
import { tryCatchHandler, notifier } from '../../../utils';
import { auth } from '../../../config';

export const authActionCreator = {
  setUser: (user: IAuthUser | unknown) => ({
    type: EAuthAction.SET_USER,
    payload: user,
  }),

  setUserAuthPending: () => ({
    type: EAuthAction.SET_AUTH_USER_PENDING,
    payload: {},
  }),

  setUserAuthPendingSucces: () => ({
    type: EAuthAction.SET_AUTH_USER_PENDING_SUCCESS,
    payload: {},
  }),

  setUserAuth: (type: string) => ({
    type: EAuthAction.SET_AUTH_USER,
    payload: type,
  }),

  setUserAuthSuccess: () => ({
    type: EAuthAction.SET_AUTH_USER_SUCCESS,
    payload: {},
  }),

  login: ({ email, password }: { email: string; password: string }) => async (dispatch) => {
    const isAuth = await tryCatchHandler(dispatch, async () => {
      const userFromFirebase = await authAPI.loginFirebase(email, password);
      const token = await userFromFirebase.getIdToken();
      const user = await authAPI.getUserByToken(token);

      dispatch(authActionCreator.setUser(user));
      notifier('success', 'Success!', 'You are successfully logged in');
      return true;
    });

    return isAuth;
  },
  signUp: (data) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      await authAPI.signUp(data);
    });
  },
  signOut: () => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      await authAPI.signOut();
      dispatch(authActionCreator.setUser({}));
    });
  },
  onAuthState: () => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      (await auth).onAuthStateChanged(async (currentUser) => {
        if (currentUser) {
          const user = await usersAPI.getUserByEmail(currentUser.email);
          dispatch(authActionCreator.setUser(user));

          dispatch(authActionCreator.setUserAuth('yes'));
          return;
        }
        dispatch(authActionCreator.setUserAuth('not'));
      });
    });
  },
  resetPassword: (email: string) => async (dispatch) => {
    const isEmailSend = await tryCatchHandler(dispatch, async () => {
      await authAPI.resetPassword(email);
      return true;
    });

    return isEmailSend;
  },
};
