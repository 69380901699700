import { IAxiosResponse, IUserRole } from 'interfaces';
import { ERolesAction } from './types';
import { usersAPI } from '../../../services';
import { tryCatchHandler } from '../../../utils/tryCatchHandler';

export const rolesActionCreator = {
  setRoles: (roles: IUserRole[]) => ({
    type: ERolesAction.SET_ROLES,
    payload: roles,
  }),
  fetchRoles: (isLoader = true) => async (dispatch) => {
    await tryCatchHandler(dispatch, async () => {
      const { data }: IAxiosResponse<IUserRole[]> = await usersAPI.getRoles();
      dispatch(rolesActionCreator.setRoles(data));
    }, '', isLoader);
  },
};
