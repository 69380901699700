import { colors } from 'assets/css/variables';

function OutboundIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4083 5.82688C11.5653 5.67009 11.7781 5.58203 11.9999 5.58203C12.2218 5.58203 12.4346 5.67009 12.5916 5.82688L17.0567 10.292C17.1389 10.3686 17.2049 10.4611 17.2507 10.5638C17.2964 10.6665 17.321 10.7773 17.323 10.8897C17.325 11.0021 17.3043 11.1138 17.2622 11.2181C17.2201 11.3223 17.1574 11.417 17.0779 11.4965C16.9984 11.576 16.9037 11.6387 16.7995 11.6808C16.6952 11.7229 16.5836 11.7436 16.4712 11.7416C16.3588 11.7396 16.2479 11.715 16.1452 11.6692C16.0425 11.6235 15.9501 11.5575 15.8734 11.4752L12.8372 8.43897V17.5813C12.8372 17.8033 12.749 18.0163 12.5919 18.1733C12.4349 18.3303 12.222 18.4185 11.9999 18.4185C11.7779 18.4185 11.565 18.3303 11.408 18.1733C11.2509 18.0163 11.1627 17.8033 11.1627 17.5813V8.43897L8.12646 11.4752C7.96775 11.6231 7.75784 11.7036 7.54095 11.6998C7.32405 11.696 7.11711 11.6081 6.96372 11.4547C6.81033 11.3013 6.72246 11.0944 6.71864 10.8775C6.71481 10.6606 6.79532 10.4507 6.9432 10.292L11.4083 5.82688Z"
        fill={colors.grayCardLight}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.72949 0H14.2705C15.8009 0 17.002 5.82185e-08 17.9665 0.0781396C18.9488 0.159628 19.7604 0.324837 20.4949 0.699907C21.7027 1.3153 22.6847 2.29729 23.3001 3.50512C23.6752 4.23963 23.8415 5.05116 23.9207 6.03349C24 6.99795 24 8.19907 24 9.72949V14.2705C24 15.8009 24 17.002 23.9219 17.9665C23.8415 18.9488 23.6752 19.7604 23.3001 20.4949C22.6847 21.7027 21.7027 22.6847 20.4949 23.3001C19.7604 23.6752 18.9488 23.8415 17.9665 23.9207C17.002 24 15.8009 24 14.2705 24H9.72949C8.19907 24 6.99795 24 6.03349 23.9219C5.05116 23.8415 4.23963 23.6752 3.50512 23.3001C2.29729 22.6847 1.3153 21.7027 0.699907 20.4949C0.324837 19.7604 0.159628 18.9488 0.0792558 17.9665C-2.49508e-08 17.002 0 15.8009 0 14.2705V9.72949C0 8.19907 5.82185e-08 6.99795 0.0781396 6.03349C0.159628 5.05116 0.324837 4.23963 0.699907 3.50512C1.3153 2.29729 2.29729 1.3153 3.50512 0.699907C4.23963 0.324837 5.05116 0.159628 6.03349 0.0792558C6.99795 -2.49508e-08 8.19907 0 9.72949 0ZM6.16967 1.74809C5.29116 1.81954 4.72409 1.95795 4.2653 2.19126C3.37223 2.64617 2.64617 3.37223 2.19126 4.2653C1.95684 4.72409 1.81954 5.29116 1.74809 6.16967C1.67553 7.05823 1.67442 8.19014 1.67442 9.76744V14.2326C1.67442 15.8087 1.67442 16.9418 1.74809 17.8303C1.81954 18.7088 1.95795 19.2759 2.19126 19.7358C2.64636 20.6285 3.3724 21.3541 4.2653 21.8087C4.72409 22.0432 5.29116 22.1805 6.16967 22.2519C7.05823 22.3245 8.19014 22.3256 9.76744 22.3256H14.2326C15.8087 22.3256 16.9418 22.3256 17.8303 22.2519C18.7088 22.1805 19.2759 22.042 19.7358 21.8087C20.6283 21.3539 21.3539 20.6283 21.8087 19.7358C22.0432 19.2759 22.1805 18.7088 22.2519 17.8303C22.3245 16.9418 22.3256 15.8099 22.3256 14.2326V9.76744C22.3256 8.19014 22.3256 7.05823 22.2519 6.16967C22.1805 5.29116 22.042 4.72409 21.8087 4.2653C21.3541 3.3724 20.6285 2.64636 19.7358 2.19126C19.2759 1.95684 18.7088 1.81954 17.8303 1.74809C16.9418 1.67553 15.8099 1.67442 14.2326 1.67442H9.76744C8.19014 1.67442 7.05823 1.67442 6.16967 1.74809Z"
        fill={colors.blue}
      />
    </svg>
  );
}

export default OutboundIcon;
