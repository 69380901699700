import { Button } from 'antd';
import './customButton.scss';

interface CustomButtonProps {
  type?: any;
  onClick?: any;
  children?: any;
  htmlType?: any;
  disabled?: boolean;
  className?: string;
  isImportant?: boolean;
}

function CustomButton({
  type = 'button',
  className = '',
  isImportant = false,
  onClick,
  children,
  htmlType,
  disabled,
}: CustomButtonProps) {
  return (
    <div className={`button-wrapper ${className} ${isImportant ? 'important-button-wrapper' : ''}`}>
      <Button onClick={onClick} disabled={disabled} type={type} htmlType={htmlType}>
        {children}
      </Button>
    </div>
  );
}

export default CustomButton;
