import Outbound from 'views/outbound/Outbound';
import {
  Inventory, Items, Locations, NotFound, Orders, Stores, Users, Warehouses,
} from '../views';
import {
  inboundAbilites,
  inventoryAbilites,
  itemsAbilites,
  locationsAbilites,
  outboundAbilites,
  storesAbilites,
  usersAbilites,
  warehousesAbilites,
} from './RoutesAbilities';

export const Routes = {
  USERS: {
    path: '/users',
    component: <Users />,
    requiredAbilities: usersAbilites,
  },
  ITEMS: {
    path: '/items',
    component: <Items />,
    requiredAbilities: itemsAbilites,
  },
  LOCATIONS: {
    path: '/locations',
    component: <Locations />,
    requiredAbilities: locationsAbilites,
  },
  INVENTORY: {
    path: '/inventory',
    component: <Inventory />,
    requiredAbilities: inventoryAbilites,
  },
  STORES: {
    path: '/stores',
    component: <Stores />,
    requiredAbilities: storesAbilites,
  },
  WAREHOUSE: {
    path: '/warehouses',
    component: <Warehouses />,
    requiredAbilities: warehousesAbilites,
  },
  ORDERS: {
    path: '/inbound',
    component: <Orders />,
    requiredAbilities: inboundAbilites,
  },
  OUTBOUND: {
    path: '/outbound',
    component: <Outbound />,
    requiredAbilities: outboundAbilites,
  },
  REPORTS: { path: '/reports', component: <NotFound /> },
  NOT_FOUND: { path: '/404', component: <NotFound /> },
};
