import { colors } from 'assets/css/variables';

function MiscItemsIcon() {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="24" height="24" rx="4.5" fill={colors.secondaryLight} stroke={colors.grayLight} />
      <rect x="3.3335" y="13.334" width="8.33333" height="8.33333" rx="2" fill={colors.orange} />
      <rect x="13.3335" y="3.33398" width="8.33333" height="8.33333" rx="2" fill={colors.allocatedColor} />
      <rect x="13.3335" y="13.334" width="8.33333" height="8.33333" rx="2" fill={colors.processingColor} />
      <rect x="3.3335" y="3.33398" width="8.33333" height="8.33333" rx="2" fill={colors.success} />
    </svg>
  );
}

export default MiscItemsIcon;
