import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { ReactNotifications } from 'react-notifications-component';
import { Logo, WelcomeIcon, Icon3PLCRM } from 'assets/images';
import Signup from './signup/Signup';
import Login from './login/Login';
import Forgot from './forgot/Forgot';

import '../../assets/css/auth.scss';

function Auth() {
  const [authState, setAuthState] = useState('login');

  const renderFormContent = (authState: string) => {
    switch (authState) {
      case 'login':
        return <Login setAuthState={setAuthState} />;
      // TODO
      // case 'signup':
      //   return <Signup setAuthState={setAuthState} />;
      case 'forgot':
        return <Forgot setAuthState={setAuthState} />;
      default:
        return null;
    }
  };

  return (
    <div className="login-main-container">
      <div className="left-side">
        <WelcomeIcon />
      </div>
      <div className="right-side">
        <Icon3PLCRM />
      </div>
      <div className="login-block">
        <div className={`${authState === 'forgot' ? 'display-none' : ''}`}>
          <Logo />
          {/* TODO */}
          {/* <div className="links-container">
            <p className={authState === 'login' ? 'active' : ''} onClick={() => setAuthState('login')}>
              Log in
            </p>
            <div className="horizontal-line" />
            <p className={authState === 'signup' ? 'active' : ''} onClick={() => setAuthState('signup')}>
              Sign up
            </p>
          </div> */}
        </div>
        {renderFormContent(authState)}
      </div>
      <ReactNotifications />
    </div>
  );
}

export default withRouter(Auth);
