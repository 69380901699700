import React, { useEffect, useRef } from 'react';

import './copiedMessageBlock.scss';

interface CopiedMessageBlockProps {
  isCopied: boolean;
  text: string;
  setIsCopied: (value: boolean) => void;
}

function CopiedMessageBlock({ isCopied, text, setIsCopied }: CopiedMessageBlockProps) {
  const messageRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (messageRef && messageRef.current) {
        messageRef.current.style.display = 'none';
        setIsCopied(false);
      }
    }, 3000);
  }, []);

  return (
    <div ref={messageRef} className={isCopied ? 'show-copied-block' : ''}>
      <p className="copied-message">
        {text}
        copied
      </p>
    </div>
  );
}

export default CopiedMessageBlock;
