import { Col, Row, Table } from 'antd';
import { Document } from '@react-pdf/renderer';
import { calculateTotalOfUnits } from 'utils/calculateTotalOfUoms';

import './printExpectedResult.scss';
import { RootState } from 'App';
import { useSelector } from 'react-redux';
import moment from 'moment';

const table = {
  columnsExpected: () => [{
    title: 'UOM',
    dataIndex: 'uom',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Contains',
    dataIndex: 'contains',
  }],

  columnsResult: () => [{
    title: 'UOM',
    dataIndex: 'uom',
  },
  {
    title: 'Condition',
    dataIndex: 'condition',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Contains',
    dataIndex: 'contains',
  }],

  dataExpected: (data) => data.map((item) => ({
    key: item.id,
    uom: item.carton_id
      ? typeof item.carton_id !== 'boolean' ? 'M Carton' : 'Carton'
      : 'Each',
    quantity: item.quantity,
    contains: item.contains || 1,
  })),

  dataResult: (data, conditions) => data.map((item) => ({
    key: item.id,
    uom: item.carton_id
      ? typeof item.carton_id !== 'boolean' ? 'M Carton' : 'Carton'
      : 'Each',

    condition: conditions.find((elem) => elem.id === item.condition_id).name,
    quantity: item.quantity,
    contains: item.contains || 1,
  })),
};

function TableResultInfo({ dataTable, conditions }) {
  const dataTableReportCoint = dataTable.reduce((obj, elem) => {
    const conditionName = conditions.find((condit) => condit.id === elem.condition_id).name;
    const count = elem.contains ? elem.contains : elem.quantity;

    obj[conditionName] += count;
    return obj;
  }, {
    Damaged: 0,
    Lost: 0,
    OK: 0,
  });

  return (
    <Row>
      <Col span={4} className="text-sm-10 text-left">
        Damaged:
        {' '}
        {dataTableReportCoint.Damaged}
      </Col>
      <Col span={3} className="text-sm-10 text-left">
        Lost:
        {' '}
        {dataTableReportCoint.Lost}
      </Col>
      <Col span={3} className="text-sm-10 text-left bold">
        Ok:
        {' '}
        {dataTableReportCoint.OK}
      </Col>
      <Col span={3} className="text-sm-10 text-left bold">
        Total:
        {calculateTotalOfUnits(dataTable)}
      </Col>
    </Row>
  );
}

export function OnGatePDF(props) {
  const conditions = useSelector((state: RootState) => state.ordersReducer.conditions);

  const { order, stores, orderItems } = props;
  const store = stores.find((elem) => elem.id === order.store_id);

  const summaryRow = (dataTable) => (
    <Table.Summary.Row>
      <Table.Summary.Cell index={0} colSpan={3}>
        <span className="bold">TOTAL</span>
        (Eaches):
      </Table.Summary.Cell>
      <Table.Summary.Cell index={1} colSpan={1}>
        {calculateTotalOfUnits(dataTable)}
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );

  const timeRefactor = (time) => (time ? moment(time).format('MM.DD.YYYY') : '');
  const externalInfo = (data) => {
    const dataExternal = data?.find((elem) => (elem.default === 1));
    return (
      <span>
        {dataExternal.external_id_type_id.name}
        :
        {' '}
        {dataExternal.external_id_value}
      </span>
    );
  };

  return (
    <Document>
      <div className="root-report-order-inbound">
        <div className="header">
          Inbound order
        </div>
        <Row justify="space-between" className="time-block">
          <Col>
            <Col>
              Type:
              {' '}
              {order.type}
            </Col>
            <Col>
              Status:
              {' '}
              {order.status}
            </Col>
          </Col>

          <Col>
            Date:
            {' '}
            {timeRefactor(new Date())}
          </Col>
        </Row>
        <div className="header-inbound">
          <Row className="row-header-info">
            <Col span={12}>
              <Row>
                <Col span={12}>Number:</Col>
                <Col span={12} className="bold">{order.id}</Col>
              </Row>

              <Row>
                <Col span={12}>Store:</Col>
                <Col span={12} className="bold">{store.company_name}</Col>
              </Row>

            </Col>

            <Col span={12} />
          </Row>

          <Row className="row-header-note">
            Note:
            {' '}
            {order.notes}
          </Row>
        </div>

        <div>
          {
            orderItems.map((elem) => (
              <div className="table-block">
                <Row className="table-header">
                  <Col span={24} className="table-item-info">
                    <div className="item-info">
                      {elem.item.external_ids.length
                        ? externalInfo(elem.item.external_ids)
                        : 'ID: Not defined'}
                    </div>

                    <div className="item-info">
                      Item:
                      {' '}
                      <span>{elem.item.name}</span>
                    </div>
                  </Col>

                </Row>
                <Row justify="space-between">
                  <Col span={24}>
                    <Table
                      className="table-report-full-table"
                      pagination={false}
                      columns={table.columnsResult()}
                      dataSource={table.dataResult(elem.measurement_units, conditions)}
                      bordered
                      summary={(data) => summaryRow(data)}
                    />
                    <TableResultInfo dataTable={elem.measurement_units} conditions={conditions} />
                  </Col>
                </Row>
              </div>
            ))
          }
        </div>
      </div>
    </Document>
  );
}
