import React, { useEffect, useMemo, useState } from 'react';
import { RootState } from 'App';
import { Col, Form, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { canadaStates, usStates } from 'constants/outbound/states';
import {
  CustomButton, CustomInput, SelectWithSearch, UploadPhoto,
} from '../../../globals';
import { infoBlockModes } from '../../../../constants/infoBlockModes';
import { storesActionCreator } from '../../../../store/action-creators';
import { countryList } from '../../../../constants/users/counrties';

import './StoreInformationBlock.scss';

interface StoreInformationBlockProps {
  mode: infoBlockModes;
  setMode: (movde: infoBlockModes) => void;
}

function StoreInformationBlock({ mode, setMode }: StoreInformationBlockProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [form] = Form.useForm();

  const [country, setCountry] = useState('');
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [pictureDocument, setPictureDocument] = useState(null);
  const [isCountryUpdated, setIsCountryUpdated] = useState(false);

  const selectedStore = useSelector((state: RootState) => state.storesReducer.selectedStore);

  const countryOptions = countryList.map((country, index) => ({
    value: country,
    id: index,
  }));
  const usStatesOptions = usStates.map((state, index) => ({ value: state.name, id: index }));
  const canadaStatesOptions = canadaStates.map((state, index) => ({ value: state.name, id: index }));

  const handleUploadPhoto = async (file: FormData) => {
    const photo = await dispatch(storesActionCreator.uploadPhoto(file));
    setPictureDocument(photo);
  };

  const initialValues = useMemo(() => {
    let result = null;
    if (selectedStore) {
      result = {
        address_1: selectedStore.address_1,
        address_2: selectedStore.address_2,
        city: selectedStore.city,
        company_name: selectedStore.company_name,
        country: selectedStore.country,
        email: selectedStore.email,
        phone: selectedStore.phone,
        postal_code: selectedStore.postal_code,
        picture: selectedStore.picture,
        state: selectedStore.state,
        fax: selectedStore.fax,
      };
    }
    return result;
  }, [selectedStore]);

  const save = async (body) => {
    body.id = selectedStore?.id;
    body.picture = pictureDocument?.id;
    if (selectedStore?.id) {
      dispatch(storesActionCreator.updateStore(body));
    } else {
      dispatch(storesActionCreator.createStore(body));
    }
    setMode(infoBlockModes.VIEW);
  };

  useEffect(() => {
    setPictureDocument(selectedStore?.picture);
    if (!selectedStore?.id) {
      setCountry('');
    } else {
      setCountry(selectedStore?.country);
    }
  }, [selectedStore]);

  useEffect(() => {
    form.setFieldsValue(initialValues);
  }, [form, initialValues]);

  useEffect(() => {
    setIsCountryUpdated(false);
  }, [isCountryUpdated]);

  useEffect(() => {
    if (mode === infoBlockModes.EDIT) {
      setSaveDisabled(false);
    }
  }, [pictureDocument]);

  return (
    <div className="store-info-container">
      <Form
        form={form}
        onFinish={save}
        onFieldsChange={() => {
          setSaveDisabled(false);
        }}
      >
        <Row gutter={[18, 24]}>
          <Row gutter={[18, 24]}>
            <Col className="col-padding" span={24}>
              <Form.Item
                name="company_name"
                rules={[
                  {
                    required: true,
                    message: `${t('stores.companyName')} is required`,
                  },
                  {
                    min: 3,
                    message: `${t('stores.companyName')} should be at least 3 signs`,
                  },
                ]}
              >
                <CustomInput
                  required
                  placeholder={t('stores.companyName')}
                  disabled={mode === infoBlockModes.VIEW}
                />
              </Form.Item>
            </Col>
            <Col className="col-padding" span={24}>
              <Form.Item
                name="address_1"
                rules={[
                  {
                    required: true,
                    message: `${t('stores.address1')} is required`,
                  },
                ]}
              >
                <CustomInput
                  required
                  placeholder={t('stores.address1')}
                  disabled={mode === infoBlockModes.VIEW}
                />
              </Form.Item>
            </Col>
          </Row>

          <Col span={8} offset={4}>
            <Form.Item name="picture">
              <UploadPhoto
                preview
                className="store-photo-field"
                disabled={mode === infoBlockModes.VIEW}
                imageObject={pictureDocument}
                handleUploadPhoto={handleUploadPhoto}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="address_2">
              <CustomInput
                placeholder={t('stores.address2')}
                disabled={mode === infoBlockModes.VIEW}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="city"
              rules={[
                {
                  required: true,
                  message: `${t('stores.cityTown')} is required`,
                },
              ]}
            >
              <CustomInput
                required
                placeholder={t('stores.cityTown')}
                disabled={mode === infoBlockModes.VIEW}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="country"
              rules={[
                {
                  required: true,
                  message: `${t('stores.country')} is required`,
                },
              ]}
            >
              <SelectWithSearch
                disabled={mode === infoBlockModes.VIEW}
                required
                placeholder="Country"
                onChange={(country) => {
                  if (!country.length) {
                    setIsCountryUpdated(true);
                    form.setFieldsValue({ state: '' });
                  }
                  setCountry(country);
                  form.setFieldsValue({ state: '' });
                }}
                onSelect={(country) => {
                  setCountry(country);
                  form.setFieldsValue({ state: '' });
                }}
                options={countryOptions}
                setNothingSelected={(isNothingSelected) => setSaveDisabled(isNothingSelected)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="postal_code"
              normalize={(input) => input.toUpperCase()}
              rules={[
                {
                  required: true,
                  message: `${t('stores.zipPostal')} is required`,
                },
              ]}
            >
              <CustomInput
                required
                placeholder={t('stores.zipPostal')}
                disabled={mode === infoBlockModes.VIEW}
              />
            </Form.Item>
          </Col>
          <Col span={12}>

            <Form.Item
              name="state"
              rules={[
                {
                  required: country === 'Canada' || country === 'USA',
                  message: `${t('stores.state')} is required`,
                },
              ]}
            >
              {country === 'Canada' || country === 'USA' ? (
                <SelectWithSearch
                  disabled={mode === infoBlockModes.VIEW}
                  options={country === 'Canada' ? canadaStatesOptions : usStatesOptions}
                  onSelect={(value) => value}
                  required={country === 'Canada' || country === 'USA'}
                  placeholder={t('orders.consignee.state')}
                />
              ) : (
                <CustomInput
                  placeholder={t('stores.state')}
                  disabled={mode === infoBlockModes.VIEW}
                />
              )}
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="phone"
              rules={[
                {
                  required: true,
                  message: `${t('stores.phone')} is required`,
                  type: 'string',
                },
                {
                  min: 8,
                  message: `${t('stores.phone')} should be at least 8 signs`,
                },
              ]}
            >
              <CustomInput
                required
                type="phones"
                placeholder={t('stores.phone')}
                disabled={mode === infoBlockModes.VIEW}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: `${t('stores.email')} is required`,
                },
                {
                  type: 'email',
                  message: 'Should be a valid email',
                },
              ]}
            >
              <CustomInput
                required
                placeholder={t('stores.email')}
                disabled={mode === infoBlockModes.VIEW}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="fax">
              <CustomInput
                placeholder={t('stores.fax')}
                disabled={mode === infoBlockModes.VIEW}
              />
            </Form.Item>
          </Col>

          {mode !== infoBlockModes.VIEW && (
            <Col span={5} offset={19}>
              <Form.Item>
                <CustomButton disabled={saveDisabled} htmlType="submit">
                  Save
                </CustomButton>
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>
    </div>
  );
}

export default StoreInformationBlock;
