import { InboundOrderStatus, orderType } from 'constants/orders/status';

export const renderItemOrderColumns = (ordertype: string, orderStatus: string) => {
  const isOnGate = ordertype === orderType.ON_GATE;

  const isArrivedOrClosed = orderStatus === InboundOrderStatus.Arrived
    || orderStatus === InboundOrderStatus.Closed
    || orderStatus === InboundOrderStatus.PartiallyArrived;

  const itemColumns = [
    {
      id: 1,
      span: isOnGate || isArrivedOrClosed ? 5 : 7,
      title: 'UOM',
      className: '',
    },
    {
      id: 2,
      span: isOnGate || isArrivedOrClosed ? 4 : 6,
      title: 'Quantity',
      className: '',
    },
    {
      id: 3,
      span: isOnGate || isArrivedOrClosed ? 4 : 6,
      title: 'Contains',
      className: '',
    },
  ];

  if (isOnGate || isArrivedOrClosed) {
    itemColumns.push({
      id: 4,
      span: 5,
      title: 'Condition',
      className: '',
    });
  }
  return itemColumns;
};
