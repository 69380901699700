import baseAxios from './axiosInstance';

const mainPath = '/documents';

const documentsAPI = {
  uploadDocument(file: any) {
    return baseAxios.post(mainPath, file);
  },

  attachDocumentToOrder(obj: { order_id: number; document_id: number }) {
    return baseAxios.post(`${mainPath}/attachToOrder`, obj);
  },

  attachDocumentToStore(obj: { store_id: number; document_id: number }) {
    return baseAxios.post(`${mainPath}/attachToStore`, obj);
  },

  attachDocumentToItem(obj: { item_id: number; document_id: number }) {
    return baseAxios.post(`${mainPath}/attachToItem`, obj);
  },

  removeOrderDocument(documentId: number) {
    return baseAxios.delete(`${mainPath}/${documentId}/order`);
  },

  removeStoreDocument(documentId: number) {
    return baseAxios.delete(`${mainPath}/${documentId}/store`);
  },

  removeItemDocument(documentId: number) {
    return baseAxios.delete(`${mainPath}/${documentId}/item`);
  },
};

export default documentsAPI;
