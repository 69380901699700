import { renderItemExtId } from 'constants/items/itemsData';
import emptyImage from '../../assets/images/defaultItemIcon.png';

const renderImage = (imgPath: any) => (
  <img
    style={{
      width: '59px',
      height: '44px',
      objectFit: 'cover',
      position: 'absolute',
      borderRadius: '10px',
      top: 'calc(50% - 23px)',
      left: '30px',
    }}
    src={imgPath || emptyImage}
    alt="item"
  />
);

export const inventoryItemsColumns = [
  {
    title: '',
    dataIndex: 'download_url',
    width: '15',
    render: renderImage,
  },
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'Default External ID',
    dataIndex: 'external_id',
    render: (externalId, item) => renderItemExtId(externalId, item, 'top', '', true),
  },
  {
    title: 'Store',
    dataIndex: 'company_name',
  },
  {
    title: 'Total',
    dataIndex: 'total',
  },
];

export const modalWindowTableColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'ID (SKU)',
    dataIndex: 'internal_id',
  },
  {
    title: 'Store',
    dataIndex: 'store_name',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    width: 130,
  },
];
