import { colors } from 'assets/css/variables';
import './selectedBinIcon.scss';

interface SelectedBinIconProps {
  color: string;
}

function SelectedBinIcon({ color }: SelectedBinIconProps) {
  return (
    <svg className={!color ? 'white-selected-bin-icon' : ''} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.5 11C21.5 10.7239 21.2761 10.5 21 10.5C20.7239 10.5 20.5 10.7239 20.5 11H21.5ZM19.0257 16.9655L18.6245 16.6672L19.0257 16.9655ZM2.66176 16.5203L2.24485 16.7963L2.66176 16.5203ZM1.01479 10.4563L1.51405 10.4834L1.01479 10.4563ZM3.31051 4.6069L3.69498 4.92656L3.31051 4.6069ZM14.71 2.25437C14.9642 2.36221 15.2577 2.24355 15.3655 1.98934C15.4734 1.73512 15.3547 1.44162 15.1005 1.33378L14.71 2.25437ZM20.5 11C20.5 13.0415 19.8423 15.0288 18.6245 16.6672L19.427 17.2638C20.7731 15.4528 21.5 13.2564 21.5 11H20.5ZM18.6245 16.6672C17.4066 18.3057 15.6933 19.5083 13.7384 20.0968L14.0266 21.0543C16.1873 20.4039 18.0809 19.0748 19.427 17.2638L18.6245 16.6672ZM13.7384 20.0968C11.7835 20.6852 9.69104 20.6283 7.77104 19.9344L7.43115 20.8749C9.55325 21.6418 11.866 21.7047 14.0266 21.0543L13.7384 20.0968ZM7.77104 19.9344C5.85105 19.2405 4.20566 17.9466 3.07867 16.2443L2.24485 16.7963C3.49047 18.6778 5.30905 20.1079 7.43115 20.8749L7.77104 19.9344ZM3.07867 16.2443C1.95168 14.542 1.40305 12.522 1.51405 10.4834L0.515534 10.4291C0.392841 12.6822 0.999228 14.9149 2.24485 16.7963L3.07867 16.2443ZM1.51405 10.4834C1.62506 8.44493 2.38981 6.4964 3.69498 4.92656L2.92603 4.28725C1.48347 6.02233 0.638226 8.17597 0.515534 10.4291L1.51405 10.4834ZM3.69498 4.92656C5.00015 3.35672 6.77632 2.24909 8.76031 1.76779L8.52455 0.795974C6.33172 1.32794 4.36859 2.55217 2.92603 4.28725L3.69498 4.92656ZM8.76031 1.76779C10.7443 1.28648 12.8306 1.4571 14.71 2.25437L15.1005 1.33378C13.0232 0.452585 10.7174 0.264003 8.52455 0.795974L8.76031 1.76779Z"
        fill={colors.blueLegenda}
      />
      <path d="M8 10.5L11 13L16 8" stroke={!color ? colors.white : colors.blueLegenda} strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

export default SelectedBinIcon;
