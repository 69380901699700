import { UserRoles } from 'constants/users/userRolesAndTypes';
import { AbilityActions, AbilitySubjects } from 'constants/usersPermissions';

export const requiredAbilitiesForItemInfoBlock = [
  { subject: AbilitySubjects.INVENTORY, action: AbilityActions.READ },
];

export const requiredAbilitiesForInventoryLocationsBlock = [
  { subject: AbilitySubjects.INVENTORY, action: AbilityActions.READ },
];

export const requiredAbilitiesForInventoryWarehousesBlock = [
  { subject: AbilitySubjects.INVENTORY, action: AbilityActions.READ },
];

export const allowedRolesForSeeItemStatistic = [UserRoles.SUPER_ADMIN, UserRoles.WAREHOUSE_ADMIN, UserRoles.WAREHOUSE_INTERN];

// If user hasn't access to see this block he will see warehouses block
export const allowedRolesForSeeLocationsBlock = [UserRoles.SUPER_ADMIN, UserRoles.WAREHOUSE_ADMIN, UserRoles.WAREHOUSE_INTERN];

export const allowedRolesForUpdateUoms = [UserRoles.SUPER_ADMIN, UserRoles.WAREHOUSE_ADMIN];
